import React, { useEffect, useState } from 'react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { getOfferHistory, getPropertyById, getUser } from '../../lib/data/bluebidData'
import { Col, Container, Row } from 'reactstrap'
import { currencyFormatter, relativeDate } from '../../lib/utils'
import styled from "styled-components";
import { CompleteOfferActionModal } from '../../components/adminActions/completeOfferAction';
import { SendExpirationReminderAction } from '../../components/adminActions/SendExpirationReminderAction'
import { Link } from 'react-router-dom';

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

// offersInactive passed from detailCellRendererParams
export const OfferHistoryCellRenderer = (params) => {
    const {
        data,           // the inPlay row
        api,            // grid API
        offersInactive // true if viewing inactive offers
    } = params
    const [history, setHistory] = useState([])
    const [owner, setOwner] = useState()
    const [bidder, setBidder] = useState()
    const [property, setProperty] = useState(undefined)
    const [lastOffer, setLastOffer] = useState(undefined)

    useEffect(() => {
        let offerId = data.detail ? data.offerId : data.id
        getOfferHistory(offerId)
            .then(async (hist) => {
                let property = await getPropertyById(data.propertyId)
                let owner = await getUser(property.ownerId)

                const firstBid = hist[0];
                const lastBid = hist[hist.length - 1]
                let bidder = await getUser(firstBid.bidderId)
                // console.log('last', lastBid)
                // console.log('history', hist)
                // console.log(property, owner, bidder)
                setOwner(owner)
                setBidder(bidder)
                setProperty(property)
                setLastOffer(lastBid)
                hist.forEach(h => {
                    h.bidder = h.bidderId === property.ownerId ? owner : bidder
                    h.owner = owner
                })
                setHistory(hist)
            })

        return () => {
        };
    }, []);

    const reloadGridData = () => {
        setTimeout(() => {
            api.refreshServerSide({ purge: true })
        }, 1000)
    }

    const lastOfferId = lastOffer?.id
    return (
        <>
            <DetailContainer>
                <Row>
                    <Col md={10}>
                        <OfferItemRow>
                            <Col sm={1}>
                                <DetailLabel>Property: </DetailLabel>
                            </Col>
                            <Col>
                                <DetailValue><Link to={`/admin/view-thread/${lastOffer?.bidderId}/${lastOffer?.threadId}`}>{property?.address}</Link></DetailValue>
                            </Col>

                            <Col sm={1}>
                                <DetailLabel>Owner price: </DetailLabel>
                            </Col>
                            <Col>
                                <DetailValue>{currencyFormatter.format(property?.detail?.owner_sell_price || 0)}</DetailValue>
                            </Col>

                        </OfferItemRow>
                        <OfferItemRow>
                            <Col sm={1}>
                                <DetailLabel>Owner: </DetailLabel>
                            </Col>
                            <Col>
                                <DetailValue>{owner?.email}</DetailValue>
                            </Col>

                            <Col sm={1}>
                                <DetailLabel>Valuation: </DetailLabel>
                            </Col>
                            <Col>
                                <DetailValue>{currencyFormatter.format(property?.detail?.valuation?.value || 0)}</DetailValue>
                            </Col>
                        </OfferItemRow>
                        <OfferItemRow>
                            <Col sm={1}>
                                <DetailLabel>Buyer: </DetailLabel>
                            </Col>
                            <Col>
                                <DetailValue>{bidder?.email}</DetailValue>
                            </Col>

                            <Col sm={1}>
                                <DetailLabel>Range: </DetailLabel>
                            </Col>
                            <Col>
                                <DetailValue>{currencyFormatter.format(property?.detail?.valuation?.low || 0)} - {currencyFormatter.format(property?.detail?.valuation?.high || 0)}</DetailValue>
                            </Col>
                        </OfferItemRow>
                    </Col>

                    <Col md={2} className="">
                        {property && lastOffer && !offersInactive &&
                            <>
                            <Row  className="mb-1 mt-1">
                                <CompleteOfferActionModal reloadData={reloadGridData}
                                                          lastAction={data.lastAction}
                                                          offerId={lastOfferId}
                                                          ownerId={owner?.id}
                                                          bidderId={bidder?.id} />
                            </Row>
                            <Row>
                                <SendExpirationReminderAction
                                    offerId={lastOfferId}
                                    history={history} />
                            </Row>
                            </>
                        }
                    </Col>
                </Row>
            </DetailContainer>

            <OfferHistoryTable history={history} />
        </>
    );
}

const OfferHistoryTable = ({history}) => {
    const Header = ({children}) => <Col>{children}</Col>
    return (
        <HeaderContainer>
            <HeaderRow>
                <Header>Date</Header>
                <Header>User</Header>
                <Header>Action</Header>
                <Header>Amount</Header>
            </HeaderRow>
            {history.map((h,n) => <OfferHistoryEntry key={`hist-${n}`} entry={h} ndx={n} />)}
        </HeaderContainer>
    )
}

const OfferHistoryEntry = ({entry, ndx}) => {
    const Column = ({children}) => <OfferItemColumn>{children}</OfferItemColumn>
    return (
        <OfferItemRow>
            <Column>
                {relativeDate(entry.createdAt)}
            </Column>
           <Column>
               {entry.bidder.email}
           </Column>
            <Column>
                {/*{ndx === 0 ? 'Offer placed' : entry.status}*/}
                {entry.status}
            </Column>
            <Column>
                {currencyFormatter.format(entry.bidAmount)}
            </Column>
        </OfferItemRow>
    )
}

const DetailContainer = styled(Container)`
    // background-color: aliceblue;
`

const DetailLabel = styled.b`
    padding: 4px;
    margin: 4px;
    font-weight: bold;
`
const DetailValue = styled.span`
    padding: 4px;
    margin: 4px;
`


const OfferItemRow = styled(Row)`
    padding: 5px;
`
const HeaderContainer = styled(Container)`
    margin-left: 20px;
    margin-top: 10px;
    width: 95%;
`

const HeaderRow = styled(Row)`
    padding: 10px 0;
    font-weight: bold;
    // font-size: 1.1em;
    border: 1px solid lightgrey;
`
const OfferItemColumn = styled(Col)`
`
