import React, { useEffect, useState } from 'react'
import apiCall from '../../../lib/data/apiCall'
import { formatDate } from '../../../lib/utils'

export const useApiVersion = () => {
  const [apiVersion, setApiVersion] = useState('')
  const [coreVersion, setCoreVersion] = useState('')

  useEffect(() => {
      apiCall('/version')
        .then(version => {
          const a = version.apiVersion.split(' - ')
          const c = version.coreVersion.split(' - ')
          setApiVersion(`${a[0]} / ${formatDate(a[1])}`)
          setCoreVersion(`${c[0]} / ${formatDate(c[1])}`)
        })
        .catch(err => {
          console.error(err)
        })
    }
    , [])

  return {
    apiVersion,
    coreVersion,
  }
}
