import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { parseAddress } from 'vladdress';
import parser from 'parse-address'

const ToastTitle = styled.p`
    font-weight: 600;
`
const ToastDetail = styled.label`
    font-weight: 500;
`

const ToastBlock = styled.div`
    color: #000000;
`

export const successToast = (message, duration = 5000) => {
  toast.custom(
    (t) => (
      <SuccessToastPopup>
        <SuccessToastDismissButton onClick={() => toast.dismiss(t.id)}>X</SuccessToastDismissButton>
        <b>{message}</b>
      </SuccessToastPopup>
    ),
    {
      duration
    },
    { id: 'login-error' }
  );
}

export const errorToast = (message, duration = 5000) => {
  toast.custom(
    (t) => (
      <ErrorToastPopup>
        <ErrorToastDismissButton onClick={() => toast.dismiss(t.id)}>X</ErrorToastDismissButton>
        <b>{message}</b>
      </ErrorToastPopup>
    ),
    {
      duration
    },
    { id: 'login-error' }
  );
}

const CommonToast = styled.div`
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    transition: 'opacity 100ms ease-in-out'
`

const SuccessToastPopup = styled(CommonToast)`
    background-color: #87c59a;
    color: rgb(82 64 64);
`

const ErrorToastPopup = styled(CommonToast)`
    background-color: #ec4040;
    color: white;
`
const CommonDismissButton = styled.button` 
    border-radius: 20px;
    height: 30px;
    width: 30px;
    margin-right: 20px;
    border: none;
    font-weight: bold;

    :hover {
        border: 1px solid white;
        background-color: #d8dbdc;
    }
`
const ErrorToastDismissButton = styled(CommonDismissButton)`
    color: red;
`
const SuccessToastDismissButton = styled(CommonDismissButton)`
    color: rgb(82 64 64);
    background-color: white;
`

export const verifyEmailToast = () => {

    const VerifyEmailToast = () =>{
        return (
        <ToastBlock>
            <ToastTitle>Verify your email address</ToastTitle>
            <ToastDetail>A verification link has been sent to your email inbox. Please click on the link to continue using Bluebid Homes.</ToastDetail>
        </ToastBlock>
        )
    }

    toast((t) => <VerifyEmailToast id={t.id} />, {
        style: {
            border: '1px solid #fcfcfc',
            background: 'white',
            borderRadius: '5px',
        }
    })
}

export const calculateTotalBath = (structureBath, structurePartialBath) => {
    const fullBath = parseFloat(structureBath) || 0;
    const halfBath = parseFloat(structurePartialBath) || 0;
    const bathCounts = (fullBath) + (halfBath * 0.5);
    return bathCounts;
}

export const HomeTypesEnum = {
    SingleFamily: "Single Family",
    MultiFamily: "Multi Family",
    CondoTownhouse: "Condo / Townhouse",
    Other: "Other",
}

// Our hook
export default function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      // Set debouncedValue to value (passed in) after the specified delay
      const timeout = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timeout);
      };
    }, [value]);

    return debouncedValue;
  }

/**
 * This builds params to the Wix form so they may be parsed and pre-populated.
 * We use two parsers because neither of them cover all address use cases by themself.
 *
 * @param address
 * @param user
 * @returns URL parameters
 */
export const addressUserToURLParams = (address, user) => {
    let firstName = user?.firstName ? `fn=${user.firstName}` : ''
    let lastName = user?.lastName ? `ln=${user.lastName}` : ''
    let email = user?.email ? `em=${user.email}` : ''
    let phone = user?.phone ? `fn=${user.phone}` : ''

    let parse1, parse2
    try {
        parse1 = parseAddress(address)
        parse2 = parser.parseAddress(address)
    } catch(e) {}

    let addr = parse1?.addressLine1 ? `ad=${parse1.addressLine1}` : ''
    let apt = (parse2?.sec_unit_type || parse2?.sec_unit_num) ? `ap=${parse2?.sec_unit_type || ''} ${parse2?.sec_unit_num || ''}` : ''
    let city = parse2?.city ? `c=${parse2.city}` : ''
    let state = parse2?.state ? `s=${parse2.state}` : ''
    let zip = parse2?.zip ? `z=${parse2.zip}` : ''
    let params = [firstName,lastName,email,phone,addr,apt,city,state,zip].join('&')
    return params
}

export const getUserDisplayName = function(user) {
    let first = user?.firstName || ''
    let last = user?.lastName || ''
    let name = `${first ? first + ' ' : ''}${last ? last + ' ' : last}`
    let id = user?.email || user?.sub || '(unknown)'
    return `${name}<${id}>`;
}

export const PriceTypeEnum = {
    PRICE_TO_MOVE: 'owner\'s estimate',
    ESTIMATE: 'market estimate'
}

export const getFullAssetArrayOld = (property) => {
    const mimeType = 'image/jpeg';
    let mainUrl = property?.mainImage || property?.expanded?.mapImages?.streetViewUrl
    let mainImages = [
        {url: mainUrl, mimeType: mimeType}
    ]
    const fixedAssets = [
        {url: property?.expanded?.mapImages?.satViewUrl, mimeType: mimeType},
        {url: property?.expanded?.mapImages?.mapViewUrl, mimeType: mimeType}
    ]
    const assets = [...property?.expanded?.assets || []]

    if (assets && assets.length) {
        const assetMimeType = assets[0].mimeType
        if (assetMimeType.startsWith('image') || assets.length <= 1) {
            if (assetMimeType.startsWith('video') && assets[0]?.url === mainImages[0]?.url) {
                mainImages = [{ url : assets[0]?.url, mimeType: assets[0].mimeType }]
            }
            assets.shift()
        } else if (assets.length > 1 && assetMimeType.startsWith('video')) {
            assets.splice(1, 1)
        }
    }
    return [...mainImages, ...(assets), ...fixedAssets];
}

export const getFullAssetArray = (property) => {
    const mimeType = 'image/jpeg';

    // these are assets we always show
    let fixedAssets = [
        {url: property?.expanded?.mapImages?.satViewUrl, mimeType: mimeType},
        {url: property?.expanded?.mapImages?.mapViewUrl, mimeType: mimeType}
    ]

    // only show the StreetView image if available (eg, not the 'NoStreetViewDefault' image)
    let isStreetViewMissing = property?.expanded?.mapImages?.streetViewUrl === 'https://assets.bluebid.io/NoStreetViewDefault.png'
    if (!isStreetViewMissing) {
        fixedAssets.splice(0, 0,
            {url: property?.expanded?.mapImages?.streetViewUrl, mimeType: mimeType})
    }
    const fullAssetsArray = [...property?.expanded?.assets || [], ...fixedAssets];

    // now we will go through them and mark any that are video items
    // so that the carousel can display the proper tile type
    // Possible mime-types that may be uploaded are:
    //   ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif', 'video/mp4', 'video/wav'];
    const isVideo = (a) => (a?.mimeType === 'video/mp4' || a?.mimeType === 'video/wav')

    // sweep through and:
    //  - assign a unique Id
    //  - set isImage flag
    fullAssetsArray.forEach(a => {
        a.isImage = !isVideo(a.mimeType)
        a.id = a.url
    })

    // for now only handle images
    return fullAssetsArray.filter(f => f.isImage)
}

export const address = (params) => {
    const isFirst = params.data.id === params.data.threadId
    if (isFirst) {
        return <b>{params.data.address}</b>
    } else {
        return params.data.address
    }
}

export const startCase = (string = '') => 
    string
      .toLowerCase()
      .replace(/[_\-\s]+/g, ' ')
      .replace(/(?:^|\s)\S/g, match => match.toUpperCase())