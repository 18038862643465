import React from 'react'
import { Label, Input } from 'reactstrap'
import styled from 'styled-components'

export const CheckboxList = ({ options, selectedOptions, name, onChange }) => {
    const handleOnChange = (option, checked) => {
        const newSelectedOptions = {
            ...selectedOptions,
        }

        newSelectedOptions[option.value] = checked !== undefined ? checked : !newSelectedOptions[option.value]

        onChange(newSelectedOptions)
    }

    return (
        <Container>
            {options.map((option) => (
                <Item key={option.value}>
                    <Checkbox
                        name={name}
                        value={option.value}
                        checked={selectedOptions[option.value]}
                        onChange={(e) => handleOnChange(option, e.target.checked)}
                    />
                    <ClickableLabel onClick={() => handleOnChange(option)}>{option.label}</ClickableLabel>
                </Item>
            ))}
        </Container>
    )
}

const Container = styled.ul`
    margin: 0;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    list-style-type: none;
    border: 1px solid #ced4da;
    border-radius: 4px;
`
const Item = styled.li`
    padding-top: 4px;
    margin-right: 20px;
    vertical-align: middle;
`

const Checkbox = styled(Input).attrs({ type: 'checkbox' })`
    position: static;
    margin: 1px 6px 0 -1.25rem;
`

const ClickableLabel = styled(Label)`
    cursor: pointer;
`
