import React, { useEffect, useMemo, useState } from 'react'
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV, faEye, faPlus, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import combinations from 'combinations'

import {
  AgentServiceOption,
  AgentServices as AgentServicesModel,
  AgentServicesSelections,
  generateAgentServicesInquiryPrefill,
} from '@bluebid-sdk/core'
import {
  ApiClient,
  deleteAgentServices as deleteAgentServicesApiFn,
  getAgentServices,
  isSuccessResponse,
  updateAgentServices,
} from '@bluebid-sdk/api-client'
import { api, BlockUI, NewPrimaryButton } from 'bb-lib-desktop'

import confirm from '../Confirm'
import { errorToast, successToast } from '../../utils/common'
import { RED } from '../../constants/colors'

const SortableItem: React.FC<{
  id: string
  agentServiceOption: AgentServiceOption
  onEdit: (newValue: string) => void
  remove: () => void
  onBlur?: (modified: boolean, value: string) => void
}> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id })
  const [modified, setModified] = useState(false)
  const agentServiceOption = props.agentServiceOption

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'auto',
  }

  return (
    <div ref={setNodeRef} style={style}>
      <ListGroupItem className="my-1 p-1">
        <div className="d-flex">
          <Button
            title="Remove"
            color="link"
            size="sm"
            onClick={() => {
              props.remove()
            }}
          >
            <FontAwesomeIcon style={{ color: '#CCC' }} icon={faTrash} />
          </Button>
          <input
            className="flex-grow-1"
            style={{ border: 0, padding: 0, lineHeight: '12px', fontSize: '12px', outline: 'none' }}
            value={agentServiceOption.label}
            onChange={(e) => {
              setModified(true)

              props.onEdit(e.target.value)
            }}
            onBlur={(e) => {
              props.onBlur?.(modified, e.target.value)

              setModified(false)
            }}
          />

          <Button
            title="Move"
            color="link"
            size="sm"
            style={{
              cursor: 'grab',
            }}
            {...attributes}
            {...listeners}
          >
            <FontAwesomeIcon style={{ color: '#CCC' }} icon={faArrowsAltV} />
          </Button>
        </div>
      </ListGroupItem>
    </div>
  )
}

export const AgentServices: React.FC<{ licenseId?: string }> = ({ licenseId }) => {
  const sensors = useSensors(useSensor(PointerSensor))
  const [agentServices, setAgentServices] = useState<AgentServicesModel>()
  const apiClient = useMemo(() => new ApiClient(api), [])

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [modified, setModified] = useState(false)
  const [selections, setSelections] = useState<AgentServicesSelections>({})
  const [selectionMessage, setSelectionMessage] = useState<string>()
  const [jsonViewerIsOpen, setJsonViewerIsOpen] = useState(false)
  const disableActions = loading || saving
  const canDelete = !modified && licenseId
  const selectedKeys = Object.keys(selections)
    .filter((key) => selections[key])
    .sort()

  useEffect(() => {
    fetchAgentServices()
  }, [licenseId])

  useEffect(() => {
    const prefill = generateAgentServicesInquiryPrefill(agentServices, selections)

    setSelectionMessage(prefill || '')
  }, [agentServices, selections])

  const fetchAgentServices = async () => {
    setLoading(true)

    const response = await apiClient.callFn(getAgentServices, { licenseId })

    if (!isSuccessResponse(response)) {
      errorToast('Problem fetching agent services')

      return
    }

    // if no saved options exist create empty agent services
    const data: AgentServicesModel = response.data?.options?.length
      ? response.data
      : { options: [], inquiryMessages: {} }

    // ensure inquiryMessage key strings are sorted for proper combination editing
    Object.keys(data.inquiryMessages).forEach((keyStr) => {
      const sortedKeyStr = keyStr.split(',').sort().join(',')

      if (sortedKeyStr !== keyStr) {
        const message = data.inquiryMessages[keyStr]

        data.inquiryMessages[sortedKeyStr] = message

        delete data.inquiryMessages[keyStr]
      }
    })

    setAgentServices(data)
    setModified(false)
    setLoading(false)
  }

  const onChangeOption = (key: string, label: string) => {
    setAgentServices((prevAgentServices) => {
      const newAgentServices = {
        ...prevAgentServices,
      }

      const index = newAgentServices.options.findIndex((option) => option.key === key)

      if (index > -1) {
        newAgentServices.options[index].label = label
      }

      return newAgentServices
    })
    setModified(true)
  }

  const onChangeMessage = (keys: string[], message: string) => {
    setSelectionMessage(message)
    setAgentServices((prevAgentServices) => {
      const newAgentServices = {
        ...prevAgentServices,
      }
      const keyStr = keys.join(',')

      newAgentServices.inquiryMessages[keyStr] = message

      return newAgentServices
    })
    setModified(true)
  }

  const generateMessages = (key: string) => {
    const newMessages: { [keys: string]: string } = {}

    const allKeys = agentServices.options.map((option) => option.key)
    const allCombinations = combinations(allKeys)

    allCombinations
      .filter((combination) => combination.includes(key))
      .forEach((combination) => {
        const keys = combination.sort()
        const keyStr = keys.join(',')

        if (!agentServices.inquiryMessages[keyStr]) {
          const services = keys.map((k) => agentServices.options.find((option) => k === option.key)?.label)
          const message =
            services.length === 1
              ? `I'm interested in ${services[0].toLowerCase()}.`
              : `I am interested in the following:\n${services.map((service) => `  - ${service}`).join('\n')}`

          newMessages[keyStr] = message
        }
      })

    if (Object.keys(newMessages).length) {
      setAgentServices((prevAgentServices) => {
        const newAgentServices = {
          ...prevAgentServices,
        }

        newAgentServices.inquiryMessages = {
          ...prevAgentServices.inquiryMessages,
          ...newMessages,
        }

        return newAgentServices
      })
    }
  }

  const cleanUpMessageKeys = (as: AgentServicesModel, oldKey: string) => {
    Object.keys(as.inquiryMessages).forEach((keyStr) => {
      const keys = keyStr.split(',')

      if (keys.includes(oldKey)) {
        delete as.inquiryMessages[keyStr]
      }
    })
  }

  const toggleSelections = (option: string) => () =>
    setSelections({
      ...selections,
      [option]: !selections[option],
    })

  const toggleJsonViewer = () => {
    setJsonViewerIsOpen((prev) => !prev)
  }

  const add = () => {
    setAgentServices((prevAgentServices) => {
      const newAgentServices = {
        ...prevAgentServices,
      }
      const count = newAgentServices.options.length + 1
      const label = `New option ${count}`

      newAgentServices.options.push({ key: uuidv4(), label })

      return newAgentServices
    })
    setModified(true)
    setSelections({})
  }

  const remove = (key: string) => {
    setAgentServices((prevAgentServices) => {
      const newAgentServices = {
        ...prevAgentServices,
      }

      const index = newAgentServices.options.findIndex((option) => option.key === key)

      if (index > -1) {
        newAgentServices.options.splice(index, 1)

        cleanUpMessageKeys(newAgentServices, key)
      }

      return newAgentServices
    })
    setModified(true)
    setSelections({})
  }

  const revert = () => {
    fetchAgentServices()
    setSelections({})
  }

  const validate = () => {
    if (!agentServices?.options?.length) {
      errorToast('You must add at least one agent service option')

      return false
    }

    if (
      combinations(agentServices.options.map((option) => option.key)).length >
      Object.keys(agentServices.inquiryMessages).filter((keyStr) => agentServices.inquiryMessages[keyStr]).length
    ) {
      errorToast('You must enter an inquiry message for EVERY combination')

      return false
    }

    return true
  }

  const save = async () => {
    if (!modified) {
      return
    }

    if (!validate()) {
      return
    }

    setSaving(true)

    const response = await apiClient.callFn(updateAgentServices, { licenseId, agentServices })

    setSaving(false)

    if (!isSuccessResponse(response)) {
      errorToast('Error updating agent services')
      return
    }

    successToast('Succesfully updated agent services')
    setModified(false)
    setSelections({})
  }

  const deleteAgentServices = async () => {
    const result = await confirm({
      title: 'Are you sure?',
      message: 'This will delete all agent services defined for license.',
      cancelText: 'No',
      confirmColor: 'danger',
      confirmText: 'Yes',
    })

    if (result) {
      setLoading(true)

      const response = await apiClient.callFn(deleteAgentServicesApiFn, { licenseId })

      if (!isSuccessResponse(response)) {
        errorToast(response.errorMessage || 'Could not remove agent services')
        setLoading(false)

        return
      }

      successToast('Agent services removed from license')

      window.location.reload()
    }
  }

  return (
    <>
      {!!loading && <BlockUI blocking={loading} altStyle={true} />}
      {!loading && (
        <>
          <div className="d-flex justify-content-between align-items-end">
            <Label style={{ textDecoration: 'none', fontWeight: 'bold', fontSize: '1.1em' }}>Select Options</Label>
            <Button
              title="Preview JSON"
              color="link"
              size="sm"
              disabled={disableActions}
              onClick={() => {
                toggleJsonViewer()
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </div>

          <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            collisionDetection={closestCenter}
            onDragEnd={(event) => {
              const { active, over } = event

              if (active.id !== over.id) {
                setAgentServices((prevAgentServices) => {
                  const newAgentServices = {
                    ...prevAgentServices,
                  }

                  const oldIndex = newAgentServices.options.findIndex((option) => option.key === active.id)
                  const newIndex = newAgentServices.options.findIndex((option) => option.key === over.id)

                  const options = arrayMove(newAgentServices.options, oldIndex, newIndex)

                  newAgentServices.options = options

                  return newAgentServices
                })
                setModified(true)
              }
            }}
          >
            <ListGroup>
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={agentServices.options.map((option) => option.key)}
              >
                {agentServices.options.map((option) => (
                  <SortableItem
                    key={option.key}
                    id={option.key}
                    agentServiceOption={option}
                    onEdit={(value) => {
                      onChangeOption(option.key, value)
                    }}
                    remove={() => remove(option.key)}
                    onBlur={(itemModified) => {
                      if (itemModified) {
                        generateMessages(option.key)
                      }
                    }}
                  />
                ))}
              </SortableContext>
            </ListGroup>
          </DndContext>
          <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <Button
              color="link"
              size="sm"
              style={{ marginTop: '-2px' }}
              disabled={disableActions}
              onClick={() => {
                add()
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <div className="mt-1">
            <Label style={{ textDecoration: 'none', fontWeight: 'bold', fontSize: '1.1em' }}>
              Inquiry Message Combinations
            </Label>
            {agentServices.options.map((option) => (
              <FormGroup key={`checkbox-${option.key}`} check>
                <Input
                  id={`checkbox-${option.key}`}
                  checked={selections[option.key]}
                  type="checkbox"
                  onClick={toggleSelections(option.key)}
                />{' '}
                <Label for={`checkbox-${option.key}`} check>
                  {option.label}
                </Label>
              </FormGroup>
            ))}
            <FormGroup>
              <Input
                style={{ fontSize: '12px', minHeight: '65px' }}
                className="mt-2"
                name="text"
                type="textarea"
                placeholder={!selectedKeys.length ? 'Select an option to edit message' : ''}
                disabled={disableActions || !selectedKeys.length}
                value={selectionMessage}
                onChange={(e) => onChangeMessage(selectedKeys, e.target.value)}
              />
            </FormGroup>
          </div>
          <div className={`d-flex ${modified || canDelete ? 'justify-content-between' : 'justify-content-end'}`}>
            {canDelete && (
              <Button
                title="Delete"
                color="link"
                size="sm"
                style={{ color: RED, marginTop: '-2px' }}
                disabled={disableActions}
                onClick={() => {
                  deleteAgentServices()
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
            {modified && (
              <Button
                title="Revert"
                color="link"
                size="sm"
                style={{ marginTop: '-2px' }}
                disabled={disableActions}
                onClick={() => {
                  revert()
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            )}
            <NewPrimaryButton className="mt-2" disabled={disableActions || !modified} onClick={() => save()}>
              Save Changes
            </NewPrimaryButton>
          </div>
          <Modal size="xl" isOpen={jsonViewerIsOpen} toggle={toggleJsonViewer}>
            <ModalHeader toggle={toggleJsonViewer}>Raw JSON</ModalHeader>
            <ModalBody>
              <Card style={{ background: '#EEE' }}>
                <CardBody>
                  <pre>{JSON.stringify(agentServices, undefined, 2)}</pre>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  )
}
