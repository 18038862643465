import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from '../../constants/context'
import { useContext } from 'react'
import { UserName } from '../UserName'
import styled from 'styled-components'
import { White } from '../../constants/colors'

export const UserDropDownMenu = () => {
    const {bluebidUser, signOut} = useContext(AuthContext)

    const isAuthenticated = bluebidUser !== undefined

    if (!isAuthenticated) return <></>

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret id="profileDropDown">
                <UserLabel className="username"><UserName user={bluebidUser} /></UserLabel>
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem header>
                    {bluebidUser.name}
                </DropdownItem>

                <DropdownItem tag={NavLink}
                              to="/profile"
                              className={'dropdown-profile'}>
                    <FontAwesomeIcon icon="user" className="mr-3"/> Profile
                </DropdownItem>

                <DropdownItem id="qsLogoutBtn" onClick={() => signOut()}>
                    <FontAwesomeIcon icon="power-off" className="mr-3"/> Sign Out
                </DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>
    )
}
const UserLabel = styled.label`
  margin-top: 10px;
  color: ${White};
`
