import React, { useMemo } from 'react'
import { ColDef } from '@ag-grid-community/core'

import { User } from '@bluebid-sdk/core'

import { NotificationsGridComponent, notificationsGridColumns } from '../notifications'

export const UserNotifications: React.FC<{ user: User }> = ({ user }) => {
  const columnDefs = useMemo(() => {
    const columns = notificationsGridColumns()
    const getColumnByHeader = (header: string) => columns.find((column) => column.headerName === header)

    const typeCol: ColDef = {
      headerName: 'Sent/Received',
      valueGetter: (params) => {
        return params?.data?.senderId === user?.id ? 'Sent' : 'Received'
      }
    }

    return [
      typeCol,
      getColumnByHeader('Type'),
      getColumnByHeader('Channel'),
      getColumnByHeader('Date Sent'),
      getColumnByHeader('Sent'),
      getColumnByHeader('Sender Name'),
      getColumnByHeader('Recipient Name'),
      getColumnByHeader('Recipient Contact'),
      getColumnByHeader('Property'),
      getColumnByHeader('Address')
    ].filter((column) => !!column)
  }, [])

  if (!user?.id) {
    return
  }

  return (
    <NotificationsGridComponent
      userId={user.id}
      sessionKey="usernotifications"
      overrideColumnDefs={columnDefs}
      title=""
    />
  )
}
