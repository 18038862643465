import React, { useRef, useState } from 'react'
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import GoogleAutoComplete from './map/GoogleAutoComplete'

const MarkerPin = ({ lat, lng, address }) => {
    return (
        <MarkerPinStyle>
            <LatLng>
                <Address>{address}</Address>
                <div>Lat: {lat}</div>
                <div>Lon: {lng}</div>
            </LatLng>
        </MarkerPinStyle>
    )
}


const LAT = 42.4060113
const LNG = -71.1640815

export const MapAddressBrowser = ({onUseAddress, onCancel, location, locationOnly, initialAddress}) => {
    const [apiLoaded, setApiLoaded] = useState(false)
    const [mapInstance, setMapInstance] = useState()
    const [mapApi, setMapApi] = useState()

    const [lat, setLat] = useState(location?.latitude || location?.lat || LAT)
    const [lng, setLng] = useState(location?.longitude || location?.lon || LNG)
    const [address, setAddress] = useState(initialAddress || '')
    const [useAddress, setUseAddress] = useState(true)
    const acRef = useRef(null); // Initialize the reference

    const center = [lat, lng]

    const toggleAddress = () => {
        setUseAddress(!useAddress)
    }
    const zoom = locationOnly ? 18 : 15

    const onApiLoaded = (instance, api) => {
        instance.setOptions({
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: api.MapTypeControlStyle.HORIZONTAL_BAR,
                mapTypeIds: ['roadmap', 'terrain', 'hybrid', 'satellite'],
            },
            streetViewControl: true,
            scaleControl: true,
            rotateControl: true,
            style: 'HORIZONTAL_BAR',
        })
        setMapInstance(instance)
        setMapApi(api)
        setApiLoaded(true)
    }

    const locationToAddress = (latitude, longitude) => {
        const geocoder = new mapApi.Geocoder();
        return new Promise((resolve, reject) => {
            return geocoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
                // console.log(results)
                if (status === 'OK') {
                    if (results[0]) {
                        let addr = results[0].formatted_address
                        if (addr.endsWith(', USA')) {
                            addr = addr.substring(0, addr.length - 5)
                        }
                        resolve(addr)
                    } else {
                        reject('No results found')
                    }
                } else {
                    reject('Geocoder failed due to: ' + status)
                }
            })
        })
    }

    const onMapClick = async ({ x, y, lat, lng, event }) => {
        let address = await locationToAddress(lat, lng)
        setLat(lat)
        setLng(lng)
        setAddress(address)
        if (!locationOnly) {
            acRef.current.setSearchText(address)
        }
    }

    const onSelectAddress = async (places) => {
        console.log(places)
        if (places) {
            let l = places.geometry.location.lat()
            let n = places.geometry.location.lng()
            setLat(l)
            setLng(n)
            let address = await locationToAddress(l, n)
            setAddress(address)
        }
    }

    const useLocation = (e) => {
        e.preventDefault()
        let update = {latitude: lat, longitude: lng}
        if (useAddress) {
            update.address = address
        }
        onUseAddress(update)
    }
    return (
        <Container>
                <Row>
                    <Col md={12}>
                        {!locationOnly && <>
                            <h5>
                                1. Enter an address or pick a location on the map
                            </h5>
                            <h5>
                                2. Adjust lat / lng location
                            </h5>
                        </>}
                        {locationOnly &&
                            <>
                                <h4>Select latitude / longitude</h4>
                            </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {!locationOnly && apiLoaded && (
                            <GoogleAutoComplete
                                ref={acRef}
                                initialAddress={initialAddress}
                                map={mapInstance} mapApi={mapApi} onSelect={onSelectAddress} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <MapContainer>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                                    libraries: ['places', 'geometry', 'drawing', 'visualization'],
                                }}
                                defaultCenter={center}
                                defaultZoom={zoom}
                                onClick={onMapClick}
                                onGoogleApiLoaded={({ map, maps }) => onApiLoaded(map, maps)}
                                yesIWantToUseGoogleMapApiInternals={true}
                            >
                                <MarkerPin
                                    lat={lat}
                                    lng={lng}
                                    address={address}
                                />
                            </GoogleMapReact>
                        </MapContainer>
                    </Col>
                </Row>

                <Row>
                    <Col md={4} className={'pull-right'}>
                        <ActionButton className={"btn-primary btn-margin form-control"} onClick={onCancel}>
                            Cancel
                        </ActionButton>
                    </Col>
                    <Col md={4} className={'pull-right'}>
                        <ActionButton className={"btn-primary btn-margin form-control"} onClick={useLocation}>
                            <FontAwesomeIcon icon={faSearchLocation} /> {locationOnly ? 'Update Location' : 'Use Location'}
                        </ActionButton>
                    </Col>
                    <Col>
                        {!locationOnly &&
                            <CheckboxGroup check inline>
                                <Input type="checkbox" onChange={toggleAddress} checked={useAddress} />
                                <Label check>
                                    {useAddress ? 'Returning both Location and Address' : 'Returning Location only' }
                                </Label>
                            </CheckboxGroup>
                        }
                    </Col>
                </Row>

            </Container>
    )
}

const CheckboxGroup = styled(FormGroup)`
    padding-top: 10px;
    margin-top: 10px;
    font-size: 1rem;
    input {
    }
    label {
        color: lightgrey;
    }
`

const Address = styled.div`
    height: 40px;
    font-weight: bold;
`
const MarkerPinStyle = styled.div`
    font-family: Montserrat, sans-serif;
    font-size: 10pt;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #007fff;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
`
const LatLng = styled.div`
    padding: 4px;
    position: absolute;
    top: 20px;
    left: -90px;
    width: 180px;
    height: 80px;
    border: 1px solid lightblue;
    border-radius: 5px;
    background-color: white;
`

const ActionButton = styled(Button)`
    margin-top: 10px;
`

const MapContainer = styled.div`
    height: 600px;
    width: 100%;
    padding: 10px 0;
`
