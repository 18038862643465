import React, { useContext, useState } from 'react'
import { AuthContext } from '../../constants/context'
import { MessageBubble } from './MessageBubble'
import { MessageDate } from './MessageDate'
import styled from 'styled-components'
import { FullScreenCarousel } from '../detail/PropertyImagesCarousel'
import { AdminManagePhotosAction } from '../adminActions/AdminManagePhotosAction'
import { OfferDetailsLabel, OfferLine } from '../detail/common'
import { getUserDisplayName } from '../../utils/common'

export const MessageImagesBubble = ({ msg, onUpdateMessageList, sender, isSender }) => {
    const { bluebidUser: user } = useContext(AuthContext)
    const [isModalOpen, setModalOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const toggleModal = () => setModalOpen(!isModalOpen)
    const closeModal = () => setModalOpen(false)

    if (!user || !msg)
        return null

    const onUpdate = () => {
      onUpdateMessageList()
    }

    return <>
        <MessageBubble>
            <div className={`${isSender ? 'from-me' : 'from-them'} bubble-div`}>
                <AdminManagePhotosAction message={msg} assets={msg.data?.images} shouldUpdateProfile={onUpdate}/>
                <BubbleParagraph>
                  <OfferLine>
                    <OfferDetailsLabel><strong>{`${getUserDisplayName(sender)}`}</strong></OfferDetailsLabel>
                  </OfferLine>
                  <PhotoBox>
                      <PhotoStack isSender={isSender} size={msg.data?.images?.length}>
                          {msg.data?.images && msg.data?.images.map((img, ndx) => (
                              <img src={img?.url} key={ndx} alt={`Attachment+${ndx}`} onClick={() => {
                                  setSelectedIndex(ndx)
                                  toggleModal()
                              }}/>
                          ))}
                          {msg.data?.images?.length > 1 && <p>{msg.data?.images?.length} photos</p>}
                      </PhotoStack>
                  </PhotoBox>
                  <MessageDate msg={msg} />
                </BubbleParagraph>
            </div>
        </MessageBubble>
        <FullScreenCarousel show={isModalOpen} selectedIndex={selectedIndex} close={closeModal}
                            fullAssetsArray={msg.data?.images} />
    </>
}

const PhotoBox = styled.div.attrs({
    className: 'photo-box',
})`
  margin-bottom: 10px;
  min-height: 160px;
`

const PhotoStack = styled.div`
  perspective: 600px;
  position: relative;
  margin-left: ${props => props.isSender ? 'auto' : ''};
  height: ${props => props.size > 1 ? '190px' : ''};
  margin-top: 10px;

  & img {
    width: 120px;
    height: 150px;
    margin: 10px;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    position: absolute;
    box-shadow: 0 1px 3px -2px rgba(0, 0, 0, .5);
  }

  > p {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    //font-weight: bold;
    z-index: 1000;
    margin: 0;
  }

  img:nth-child(1) {
    z-index: 999;
  }

  img:nth-child(2) {
    transform: rotate3d(0, 0, 1, 3deg);
  }

  img:nth-child(3) {
    transform: rotate3d(0, 0, 1, -3deg);
  }

  img:nth-child(4) {
    transform: rotate3d(0, 0, 1, 2deg);
  }

  &:hover img:nth-child(1) {
    transform: scale(1.2);
    border-radius: 4px;
    border: 1px white solid;
    z-index: 9999;
  }

  img:nth-child(2) {
    transform: rotate3d(0, 0, 1, 8deg);
  }

  img:nth-child(3) {
    transform: rotate3d(0, 0, 1, -6deg);
  }

  .photo-stack img:nth-child(4) {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  &:hover img:nth-child(5) {
    transform: translate3d(-5%, -2%, 0) rotate3d(0, 0, 1, 2deg);
  }
`

export const BubbleParagraph = styled.div.attrs({ className: 'bubble-paragraph' })`
  margin-bottom: 0;
  padding-bottom: 0;
`

