import React, { useState, useEffect, useRef } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Button } from 'reactstrap'
import { annotateMessage, getMessagesIndexTimes } from '../../lib/data/bluebidData'
import { waitForIndexing } from '../../lib/data/waitForIndexing'
import { successToast, errorToast } from '../../utils/common'
import LoadingInline from '../LoadingInline'

const MessageAnnotationModal = ({ isOpen, initialNote, onSave, onClose, messageId }) => {
  const [isWorking, setIsWorking] = useState(false)
  const [newNote, setNewNote] = useState(initialNote)
  const textareaRef = useRef(null)

  useEffect(() => {
    setNewNote(initialNote)

    setTimeout(() => {
      if (isOpen && textareaRef.current) {
        textareaRef.current.focus()
      }
    }, 0)
  }, [isOpen, initialNote])

  if (!isOpen) {
    return null
  }

  const waitForMessageIndexing = waitForIndexing({
    maxRetries: 20,
    pollingInterval: 1000,
    fetchIndexTimes: () => getMessagesIndexTimes({ messageIds: [messageId] })
  })

  const handleSave = async () => {
    setIsWorking(true)

    try {
      const threshold = new Date().toISOString()

      await annotateMessage({ messageId, note: newNote })
      await waitForMessageIndexing({ ids: [messageId], threshold })

      successToast('Annotation saved')
      onSave({ messageId, note: newNote })
    } catch (e) {
      errorToast('Error saving annotation')
      console.error(e)
    } finally {
      setIsWorking(false)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Admin Message Annotation</ModalHeader>
      <ModalBody>
        <Input
          type="textarea"
          innerRef={textareaRef}
          onChange={(e) => setNewNote(e.target.value)}
          value={newNote}
          placeholder={'Annotation...'}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSave} disabled={isWorking}>{isWorking ? <LoadingInline /> : null} Save</Button>
      </ModalFooter>
    </Modal>
  )
}

export default MessageAnnotationModal
