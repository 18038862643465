import { currencyFormatter } from '../../lib/utils'
import { OfferAmount, OfferDetailsLabel, OfferLine } from '../detail/common'
import { MessageBubble } from './MessageBubble'
import React, { useContext } from 'react'
import { AuthContext } from '../../constants/context'
import { MessageActionsOrStatus } from '../actions/MessageActionsOrStatus'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { sizes } from '../../utils/responsive'
import { BubbleParagraph } from './MessageNoteBubble'
import { MessageType, OfferStatus } from '../../constants/messsages'
import { MessageDate } from './MessageDate'
import { MessageReadUnread } from './MessageReadUnread'

export const OfferCounteredBubble = ({ parent, property, msg, isCurrent, onUpdateMessageList, sender, isSender }) => {
    const { bluebidUser: user } = useContext(AuthContext)

    const isPropertyOwner = property?.ownerId === user?.id
    const from = isSender ? 'you' : isPropertyOwner ? 'buyer' : 'owner'
    let { offer } = msg?.data
    // console.log('offer', offer)

    // TODO: offer status will have a new value 'Expired'. If the pointed to Offer is in this state then
    //   do not allow any actions and display an 'Offer has expired' message bubble instead
    // UI is TBD
    if (offer.status === OfferStatus.Expired) {
        return <MessageBubble>
            <div>
                <Row>
                    <Col xs>
                        Bluebid has expired.
                    </Col>
                </Row>
            </div>
        </MessageBubble>
    }

    const action = msg.messageType === MessageType.Offer.Countered.title ? ' countered' : ' sent a Bluebid'
    return (
        <MessageBubble>
            <BubbleDiv isCurrent={isCurrent} className={`${isSender ? 'from-me' : 'from-them'} bubble-div`}>
                <Row>
                    <Col xs>
                        <BubbleParagraph>
                            <OfferLine>
                                <OfferDetailsLabel><strong>{`${sender?.firstName} ${sender?.lastName} <${sender?.email}>`}</strong></OfferDetailsLabel>
                            </OfferLine>
                            <p>{from}{action}:</p>
                            <OfferAmount>{currencyFormatter.format(msg?.data?.bidAmount)}</OfferAmount>
                            <br />
                            <MessageDate msg={msg} />
                            <MessageReadUnread isRead={msg?.isRead} />
                        </BubbleParagraph>
                    </Col>
                    {isCurrent && <Col xs={5} className='pl-0'>
                        <MessageActionsOrStatus
                            messageDetail={msg}
                            id={parent?.id}
                            showMessageList={onUpdateMessageList}
                        />
                    </Col>}
                </Row>
            </BubbleDiv>
        </MessageBubble>
    )
}

const BubbleDiv = styled.div`
  width: ${props => props.isCurrent ? '45%' : '30%'} !important;

  @media (max-width: ${sizes.laptopM}) {
    width: ${props => props.isCurrent ? '60%' : '35%'} !important;
  }
  @media (max-width: ${sizes.tabletXL}) {
    width: ${props => props.isCurrent ? '90%' : '50%'} !important;
  }
  @media (max-width: ${sizes.tabletL}) {
    width: ${props => props.isCurrent ? '100%' : '60%'} !important;
  }
  @media (max-width: ${sizes.mobileXL}) {
    width: ${props => props.isCurrent ? '100%' : '75%'} !important;
  }
`
