import { parseSubscriber } from '../lib/utils'

/**
 * UserName display in menu drop-down
 * Rules:
 *  First try to show firstName + lastName
 *  If missing, show the email UNLESS appleId was used for signin
 *  In which case we will display 'Apple ID'
 *
 * @param user
 * @returns {string|*}
 * @constructor
 */
export const UserName = ({user}) => {
    // try first/last name first
    if (!user) {
        return 'User'
    }

    if (user.firstName || user.lastName) {
        return `${user?.firstName?.toUpperCase() || ''} ${user?.lastName?.toUpperCase() || ''}`
    }

    // fallback to email except for apple, which uses an obscured email
    let sub = parseSubscriber(user.sub)
    switch (sub) {
        case 'apple':
            return `Apple ID`
        default:
            return user.email
    }
}

