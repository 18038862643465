import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { InquiriesGridComponent } from '../../components'
import Loading from '../../components/Loading'

const ListInquiries = () => {
  return <InquiriesGridComponent />
}

export default withAuthenticationRequired(ListInquiries, {
  onRedirecting: () => <Loading />,
})
