import React, { useEffect, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'

import { BlockUI } from 'bb-lib-desktop'
import Loading from '../../components/Loading'
import { getTemplates } from '../../lib/data/templates'
import { Template } from '../../lib/templates'
import { GridListHeader } from './GridListHeader'
import { Table } from 'reactstrap'

const ListTemplates = () => {
  const [isWorking, setIsWorking] = useState(false)
  const [templates, setTemplates] = useState<Template[]>([])

  const load = () => {
    setIsWorking(true)
    getTemplates().then(t => {
      setIsWorking(false)
      setTemplates(t)
    })
  }

  useEffect(() => {
    load()
  }, [])

  return (
    <>
      <BlockUI blocking={isWorking} />

      <GridListHeader title={'Templates'} />

      <div style={{ width: '500px' }}>
        <Table bordered striped>
          <thead>
            <tr>
              <th>Template Name</th>
              <th>Channel</th>
            </tr>
          </thead>
          <tbody>
            { renderTemplates(templates) }
          </tbody>
        </Table>
      </div>
    </>
  )
}

const renderTemplates = tList => {
  return tList.map((t, n) => {
      return (<tr key={`t-${n}`}>
        <td><Link to={`/admin/templates/${t.templateKey}`}>{t.name}</Link></td>
        <td>{t.channel}</td>
      </tr>)
  })
}

export default withAuthenticationRequired(ListTemplates, {
  onRedirecting: () => <Loading />,
})
