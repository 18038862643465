import React, { useContext, useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from "../../constants/context";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { assignPropertyOwner, getPropertyOffers } from "../../lib/data/bluebidData";
import BlockUI from "../BlockUI";
import styled from "styled-components";
import { errorToast, successToast } from "../../utils/common";
import { UserSearch } from '../UserSearch'
import { ModalSubHeader } from '../actions/ManagePhotosAction'
import { PrimaryButton, SecondaryButton } from '../actions/common'
import { CenteredModalFooter, DialogFooterBlock } from './completeOfferAction'
import { AdminActionButton, AdminSecondaryActionButton } from './common'

const WarningMessage = styled.span`
    font-size: 1.2rem;
    color: red;
`

export const AssignOwnerAction = ({property, shouldUpdateProfile}) => {
    const { bluebidUser, signIn } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [user, setUser] = useState()
    const [flagOffersAvailable, setFlagOffersAvailable] = useState(false)
    const [modal, setModal] = useState(false)

    const selectEmail = (s) => setUser(s.data)
    const toggle = () => setModal(!modal)

    const assignOwnerAction = () => {
        if (!bluebidUser) {
            signIn();
        } else {
            setIsLoading(true)
            getPropertyOffers(property?.propertyId)
            .then(offers => {
                if (offers && offers.length) {
                    setFlagOffersAvailable(true)
                }
                setIsLoading(false)
            })
            toggle()
        }
    }

    const resetAssignOwner = () => {
        toggle();
        setUser(undefined);
        setIsLoading(false);
    }

    const assignOwner = () => {
        const propertyOwnerData = {
            ownerId: user?.id
        }
        // console.log('propertyOwner', propertyOwnerData);
        setIsLoading(true)
        assignPropertyOwner(property?.propertyId, propertyOwnerData).then(result => {
            if (result.statusCode && result.statusCode >= 400) {
                console.error({result});
                setIsLoading(false);
                errorToast(result.errorMessage);
               } else {
                setIsLoading(false);
                toggle();
                successToast(`Property assign to ${user?.firstName} ${user?.lastName}`);
                resetAssignOwner();
                shouldUpdateProfile();
               }
        }).catch(error => {
            console.log('error', error);
            errorToast(`something went wrong!`);
            setIsLoading(false);
        })
    }

    return (
    <>
        <AdminSecondaryActionButton onClick={assignOwnerAction} >
            <FontAwesomeIcon icon={faUser} /> Change Owner
        </AdminSecondaryActionButton>

        <Modal size="lg" isOpen={modal} backdrop="static"
               toggle={toggle}
               modalTransition={{ timeout: 200 }}>
            <ModalHeader>
                <div>
                    <h3>Assign Owner</h3>
                    <ModalSubHeader>
                        {property?.address}
                    </ModalSubHeader>
                </div>
            </ModalHeader>
            <ModalBody>
                <BlockUI blocking={isLoading} />
                <div className="container">
                    {flagOffersAvailable && <WarningMessage>Property has Bluebids and cannot be re-assigned</WarningMessage>}
                    {!flagOffersAvailable &&
                        <UserSearch onSelect={selectEmail} />
                    }
                </div>

            </ModalBody>
            {!flagOffersAvailable && <CenteredModalFooter>
                <DialogFooterBlock>
                    <PrimaryButton className={"btn-margin"} disabled={isLoading} onClick={() => assignOwner()}>Assign</PrimaryButton>
                    <SecondaryButton className={"btn-margin"} disabled={isLoading} onClick={() => resetAssignOwner()}>Cancel</SecondaryButton>
                </DialogFooterBlock>
            </CenteredModalFooter> }
        </Modal>
    </>
    )
}
