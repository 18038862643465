/**
 * Standard colors, font sized, etc. throughout the app
 * @type {string}
 */
export const LINE_COLOR = '#f1f1f1'

export const LightBlue = '#027ffe8a'
export const DarkBlue = '#166cc5'
export const DarkGrey = '#909090'
export const LightGrey = '#ccc'
export const BluebidBlue = '#027ffe'
export const Black = '#000000'
export const DarkDarkGrey = '#5a6268'
export const White = '#ffffff'
export const BB = 'hsl(222deg 89% 61%)'
export const OffSwitchColor = '#888'

export const YELLOW = '#f3ba42'
export const BLUE = '#007fff'
export const GREEN = '#34a853'
export const RED = '#e62e2e'
