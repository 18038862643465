import React, { useCallback, useMemo, useRef, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ModuleRegistry } from '@ag-grid-community/core'

import { Datasource } from 'bb-lib-desktop'
import { DateValueFormatter, DeleteActionRenderer, GroupLinkRenderer, JSONObjectRenderer, OwnerFieldLinkRenderer, restoreGridState, ResultFieldRenderer } from '../../components/grid/GridRenderers'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import BlockUI from '../../components/BlockUI'
import { useSessionStorage } from '../../lib/utils'
import { GridListHeader } from './GridListHeader'
import { CreateAlertAction } from '../../components/adminActions/CreateAlertAction'
import { deleteClaimAlert } from '../../lib/data/bluebidData'
import { errorToast, successToast } from '../../utils/common'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])
const datasource = new Datasource({
  index: 'claimAlerts',
  sort: '-createdAt',
  fields: 'owner',
  adminSearch: true,
})

const ListClaimAlerts = () => {
  const gridRef = useRef()

  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const [isWorking] = useState(false)
  const [colState, setColState] = useSessionStorage('claimAlerts', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])
  //needed for serverSide data model
  const getRowId = useCallback((params) => params.data.id, [])

  const onDelete = async (id) => {
    try {
      await deleteClaimAlert(id)
      successToast(`Listing alert removed successfully!`)
    } catch (e) {
      errorToast(e)
    }

    // add slight delay for reloading to reflect deleted opportunity
    setTimeout(() => {
      onBtnRefresh()
    }, 300)
  }

  const columnDefs = useMemo(() => [
    { field: 'id', hide: true, width: 300, headerName: 'Id' },
    {
      headerName: 'Action',
      cellRenderer: DeleteActionRenderer,
      cellRendererParams: { onDelete },
      suppressColumnsToolPanel: true,
      width: 120,
      sortable: false
    },
    { field: 'name', headerName: 'Alert Name', width: 350, filter: 'agTextColumnFilter', cellRenderer: GroupLinkRenderer, cellRendererParams: { prefix: '/admin/group' } },
    // { field: 'userId', headerName: 'User Id', width: 350, filter: 'agTextColumnFilter', cellRenderer: LinkRenderer, cellRendererParams: { prefix: `/admin/userprofile` } },
    { headerName:'User Info', children:[
          { field:'ownerId', headerName: 'User', cellRenderer: OwnerFieldLinkRenderer, sortable: false  },
          { field:'Email', cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'owner', field: 'email'}, sortable: false },
          // { field:'Phone', cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'owner', field: 'phone' }, sortable: false },
    ]},
    { field: 'filter', headerName: 'Filter', width: 350, cellRenderer: JSONObjectRenderer },
    {
      headerName: 'Created / Modified', children: [
        { field: 'lastRanAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
        { field: 'createdAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
        { field: 'modifiedAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
      ],
    },
  ], [])

  return (<>
      <BlockUI blocking={isWorking} />

      <GridListHeader title={'Claim Alerts'} onRefresh={onBtnRefresh}>
        <CreateAlertAction />
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection='true'
            rowSelection='multiple'
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            getRowId={getRowId}
            serverSideInfiniteScroll={true}
            suppressRowClickSelection='true'
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(ListClaimAlerts, {
  onRedirecting: () => <Loading />,
})
