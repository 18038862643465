import apiCall from './apiCall'

export const getTemplate = async ({ templateKey }) => {
  return apiCall(`/templates/${templateKey}`)
}

export const getTemplates = async () => {
  return apiCall(`/templates`)
}

export const getRevisions = async ({ templateKey }) => {
  return apiCall(`/templates/${templateKey}/revisions`)
}

export const getRevision = async ({ revisionId }) => {
  return apiCall(`/templates/revisions/${revisionId}`)
}

export const getDeployedRevision = async ({ templateKey }) => {
  return apiCall(`/templates/${templateKey}/deployed`)
}

export const preview = async ({ templateKey, source, args }) => {
  return apiCall(`/templates/${templateKey}/preview`, {
    method: 'POST',
    body: {
      source,
      args
    }
  })
}

export const createRevision = async ({ templateKey, source, label }) => {
  return apiCall(`/templates/revisions`, {
    method: 'POST',
    body: {
      templateKey,
      source,
      label
    }
  })
}

export const deployRevision = async ({ revisionId }) => {
  return apiCall(`/templates/revisions/${revisionId}/deploy`, {
    method: 'POST'
  })
}

export const deleteRevision = async ({ revisionId }) => {
  return apiCall(`/templates/revisions/${revisionId}`, {
    method: 'DELETE'
  })
}

export const cloneRevision = async ({ revisionId, label }) => {
  return apiCall(`/templates/revisions/${revisionId}/clone`, {
    method: 'POST',
    body: {
      label
    }
  })
}

export const saveRevision = async ({ revisionId, source, label }) => {
  return apiCall(`/templates/revisions/${revisionId}`, {
    method: 'PUT',
    body: {
      source,
      label
    }
  })
}

export const describeContextSchema = async ({ templateKey }) => {
  return apiCall(`/templates/${templateKey}/describeContextSchema`)
}
