import { Container, Table, Card, CardBody, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { getEnv } from '../../../config';

export const HomeFactsModified = (userAction) => {
    const { address, changes, propertyId } = userAction.data

    const fieldToHumanFormat = (str) =>
        str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

    const hasChanges = changes =>
        changes && Object.entries(changes).length > 0

    return (
        <Container>
            <Card>
                <CardBody>
                    <CardTitle tag="h5">{address}</CardTitle>
                    <a href={`${getEnv().appHost}/claimedProperty/${propertyId}`} rel="noreferrer" target="_blank" className="text-decoration-none">
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Open (Bluebid)
                    </a>
                    <a href={`/admin/propertyedit/${propertyId}`} target="_blank" rel="noreferrer" className="text-decoration-none ml-3">
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Edit (Admin)
                    </a>
                    <Table striped className="mt-3">
                        <tbody>
                            <tr>
                                <td width="200"><strong>Property ID</strong></td>
                                <td>{propertyId}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table bordered striped className="mt-3">
                        <thead>
                            <tr>
                                <th width="300">Field</th>
                                <th>Old Value</th>
                                <th>New Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!hasChanges(changes) && (
                                <tr>
                                    <td colSpan={3}>No changes</td>
                                </tr>
                            )}
                            {hasChanges(changes) && Object.entries(changes).map(([field, { oldValue, newValue }]) => (
                                <tr key={field}>
                                    <td><strong>{fieldToHumanFormat(field)}</strong></td>
                                    <td>{oldValue || '-'}</td>
                                    <td>{newValue || '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Container>
    )
}