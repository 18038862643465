import { getAllCounts, getResourceCount } from '../../lib/data/bluebidData'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { faBan, faDollarSign, faHome, faSyncAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { successToast } from '../../utils/common'

export const ItemCounts = () => {
  const [propertiesCount, setPropertiesCount] = useState(0)
  const [usersCount, setUsersCount] = useState(0)
  // const [offersCount, setOffersCount] = useState(0)
  // const [inactiveOffersCount, setInactiveOffersCount] = useState(0)

  const getMetricByQuery = async (index, query, setResult) => {
    getResourceCount(index, query).then(result => setResult(result?.count))
  }

  const getProperties = (setResult) =>
    getMetricByQuery('properties', 'NOT status:unclaimed', setResult)
  const getUsers = async (setResult) =>
    getMetricByQuery('users', '', setResult)
  // const getOffers = async (setResult) =>
  //   getMetricByQuery('inplay', '', setResult)
  // const getExpiredOffers = async (setResult) =>
  //   getMetricByQuery('offers', 'NOT _exists_:childId AND (status:Revoked OR status:Rejected or status:Accepted or status:Expired)', setResult)

  const countProperties = async () => getProperties(setPropertiesCount)
  const countUsers = () => getUsers(setUsersCount)
  // const countOffers = () => getOffers(setOffersCount)
  // const countExpiredOffers = () => getExpiredOffers(setInactiveOffersCount)

    return (
      <>
        <Col xs={3}>
          <MetricCard
            metricGetter={countProperties}
            metricLabel={'Claims'}
            metricValue={propertiesCount}
            to={'/admin/list-properties'}>
              <FontAwesomeIcon className='fa-2x' icon={faHome} />
          </MetricCard>
        </Col>
        <Col xs={3}>
          <MetricCard
            icon={faUser}
            color={'#1cc88a'}
            metricGetter={countUsers}
            metricLabel={'Users'}
            metricValue={usersCount}
            to={'/admin/list-users'}>
              <FontAwesomeIcon className='fa-2x' icon={faUser} />
          </MetricCard>
        </Col>

        {/*Probably no longer useful but carry over anyways */}
        {/*<Col xs={3}>*/}
        {/*  <MetricCard*/}
        {/*    color={'#36b9cc'}*/}
        {/*    metricGetter={countOffers}*/}
        {/*    metricLabel={'Offers'}*/}
        {/*    metricValue={offersCount}*/}
        {/*    to={'/admin/active-offers'}>*/}
        {/*      <FontAwesomeIcon className='fa-2x' icon={faDollarSign} />*/}
        {/*  </MetricCard>*/}
        {/*</Col>*/}
        {/*<Col xs={3}>*/}
        {/*  <MetricCard*/}
        {/*    color={'#f6c23e'}*/}
        {/*    metricGetter={countExpiredOffers}*/}
        {/*    metricLabel={'Inactive Offers'}*/}
        {/*    metricValue={inactiveOffersCount}*/}
        {/*    to={'/admin/inactive-offers'}>*/}
        {/*      <span className="fa-layers fa-2x">*/}
        {/*        <FontAwesomeIcon icon={faDollarSign} className={'fa-stack-1x'}/>*/}
        {/*        <FontAwesomeIcon icon={faBan} className={'fa-stack-1x'} color={'#f3b6b6'}/>*/}
        {/*    </span>*/}
        {/*  </MetricCard>*/}
        {/*</Col>*/}
      </>)
}

const MetricCard = ({ metricGetter, metricLabel, metricValue, to, color, children }) => {
  const navigate = useNavigate()

  const onRefresh = () => {
    metricGetter()
      .then(() => {
        successToast(`${metricLabel} refreshed.`)
      })
  }

  useEffect(() => {
    metricGetter()
    let to = setInterval(onRefresh, 120000)
    return () => {
      clearInterval(to)
    }
  }, [])

  return (
    <MetricCardContainer color={color} >
      <CardBody>
        <Row>
          <Col className='mr-2'>
            <MetricLabel color={color}>
              {metricLabel}
            </MetricLabel>
            <div className={`h5 mb-0 font-weight-bold`}>
              {metricValue}
            </div>
            <Refresher onClick={() => onRefresh()}>
              <FontAwesomeIcon icon={faSyncAlt} />
            </Refresher>
          </Col>
          <Col className={'fa-2x text-right'} style={{color: '#dddfeb'}} onClick={() => navigate(to)}>
            {children}
          </Col>
        </Row>
      </CardBody>
    </MetricCardContainer>
  )
}

const Refresher = styled.div`
  padding-top: 8px;
  margin: 0;
  cursor: pointer;
`

const MetricLabel = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.color ? `${props.color}` : '#4e73df'} ;
`

const MetricCardContainer = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border-left: ${(props) => props.color ? `0.25rem solid ${props.color}` : '0.25rem solid #4e73df'} ;
  color: #5a5c69;

  height: 100% !important;
  padding-top: 0.5rem !important;

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }

  > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.35rem - 1px);
    border-top-right-radius: calc(0.35rem - 1px);
  }

  > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.35rem - 1px);
    border-bottom-left-radius: calc(0.35rem - 1px);
  }
`
