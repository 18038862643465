import { GoogleMapViewPhotoOptions } from '@bluebid-sdk/core'
import { api } from 'bb-lib-desktop'

export function googleMapViewApiUrl(options: GoogleMapViewPhotoOptions) {
  return api<string>('/images/mapview/apiurl', {
    method: 'POST',
    body: {
      streetAddress: options.streetAddress,
      lat: options.latitude,
      lng: options.longitude,
      width: options.width,
      height: options.height,
      zoom: options.zoom,
      maptype: options.mapType,
      geoJsonBoundary: options.geoJsonBoundary,
    },
  })
}
