import styled from 'styled-components'
import React from 'react'

export const MessageReadUnread = ({isRead}) => {
    return (<>
      {!isRead && <BidMessageReadOrNot>Not Read</BidMessageReadOrNot>}
    </>)
}

export const BidMessageReadOrNot = styled.span`
  color: #dc3545;
  font-weight: 500;
`
