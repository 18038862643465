type IndexingStatus = {
  [id: string]: string | null
}

type IndexTimesFetcher = (ids: string[]) => Promise<{ [id: string]: string }>

export const waitForIndexing = (deps: { maxRetries: number, pollingInterval: number, fetchIndexTimes: IndexTimesFetcher }) => {
  const { maxRetries, pollingInterval, fetchIndexTimes } = deps

  const wait = async (args: { ids: string[], threshold: string, tries?: number }): Promise<IndexingStatus> => {
    const { ids, threshold, tries = 0 } = args

    if (tries >= maxRetries)
      throw new Error('Indexing timeout exceeded')

    try {
      const res = await fetchIndexTimes(ids)

      const done = ids.every(id =>
        res[id] && new Date(res[id]) >= new Date(threshold)
      )

      if (done)
        return res
    } catch (error) {
      console.error('Error fetching indexing status:', error)
    }

    await new Promise(resolve => setTimeout(resolve, pollingInterval))
    return wait({ ids, threshold, tries: tries + 1 })
  }

  return wait
}
