import React, { useEffect, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { BlockUI, NewPrimaryButton } from 'bb-lib-desktop'
import Loading from '../../components/Loading'
import { createRevision, deleteRevision, deployRevision, getDeployedRevision, getRevisions, getTemplate } from '../../lib/data/templates'
import { GridListHeader } from './GridListHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTrash, faClone, faRocket } from '@fortawesome/free-solid-svg-icons'
import { errorToast, successToast } from '../../utils/common'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef } from '@ag-grid-community/core'
import { DateValueFormatter } from '../../components/grid/GridRenderers'
import { TemplateRevisionCloneModal } from './TemplateRevisionCloneModal'
import { Badge } from 'reactstrap'

type TemplateRevision = {
  id: string,
  label: string
  deployed: boolean
}

const ListTemplateRevisions = () => {
  const navigate = useNavigate()
  const { templateKey } = useParams()

  const [isWorking, setIsWorking] = useState(false)
  const [isCloning, setIsCloning] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [templateName, setTemplateName] = useState<string>()
  const [revisions, setRevisions] = useState<TemplateRevision[]>([])
  const [sourceRevisionId, setSourceRevisionId] = useState<string>()

  const load = () => {
    if (!templateKey) {
      return
    }

    setIsWorking(true)
    Promise.allSettled([
      getRevisions({ templateKey }),
      getDeployedRevision({ templateKey }),
      getTemplate({ templateKey })
    ]).then(([v, d, t]) => {
      const [revisions, deployedRevision, template] = [
        (v.status === 'fulfilled') ? v.value : undefined,
        (d.status === 'fulfilled') ? d.value : undefined,
        (t.status === 'fulfilled') ? t.value : undefined
      ]

      if (revisions) {
        const revs = revisions.map(rev => ({
          ...rev,
          deployed: deployedRevision && deployedRevision.id === rev.id
        }))
        
        setRevisions(revs)
      }

      setTemplateName(template.name)

      setIsWorking(false)
    })
  }

  // fetch revisions of the given template and the deployed version
  useEffect(() => {
    load()
  }, [templateKey])

  const toggleIsCloning = (revisionId?: string) => {
    if (revisionId) {
      setSourceRevisionId(revisionId)
    }
    setIsCloning(!isCloning)
  }


  const deploy = (revisionId: string) => {
    // eslint-disable-next-line
    const cnf = confirm(`Are you sure you want to deploy this revision?`)
    if (!cnf) {
      return
    }

    deployRevision({ revisionId })
      .then(() => {
        setTimeout(() => {
          load()
        }, 1000)
        successToast(`Deployed revision: ${revisionId}`)
      }).catch(e => {
        console.error(e)
        errorToast(`Failed to deploy revision: ${revisionId}`)
      })
  }

  const remove = (revisionId: string) => {
    // eslint-disable-next-line
    const cnf = confirm(`Are you sure you want to delete revision ${revisionId}?`)
    if (!cnf) {
      return
    }

    deleteRevision({ revisionId }).then(() => {
      setTimeout(() => {
        load()
        successToast(`Deleted revision: ${revisionId}`)
      }, 1000)
    })
  }

  const back = () => {
    navigate('/admin/templates')
  }

  const create = () => {
    setIsCreating(true)
    createRevision({
      templateKey,
      source: '---',
      label: `New ${templateKey} Revision`
    }).then(revResult => {
      setTimeout(() => {
        navigate(`/admin/templates/${templateKey}/${revResult._id}`)
      }, 4000)
    })
  }

  const RevisionRenderer = (params: { data: { id: string, label: string, deployed: boolean } }) => (
    <>
      {params.data?.deployed && <Badge className="mr-1" color="success">Deployed</Badge>}
      <Link to={`${params.data?.id}`}>{params.data?.label}</Link>
    </>
  )

  const ActionsRenderer = ({ data: { id, deployed } }) => (<>
    <FontAwesomeIcon title="Clone" icon={faClone} className="clickable mr-1" onClick={() => toggleIsCloning(id)} />
    { !deployed && <FontAwesomeIcon title="Delete" icon={faTrash} className="clickable mr-1" onClick={() => remove(id)} /> }
    { !deployed && <FontAwesomeIcon title="Deploy" icon={faRocket} className="clickable mr-1" onClick={() => deploy(id)} /> }
  </>)

  const columnDefs: ColDef<TemplateRevision & { actions: any, modifiedAt: string, createdAt: string }>[] = [
    { field: 'id', headerName: 'ID', width: 200, hide: true },
    { field: 'label', headerName: 'Label', width: 300, cellRenderer: RevisionRenderer },
    { field: 'actions', headerName: '', width: 100, cellRenderer: ActionsRenderer },
    { field: 'modifiedAt', headerName: 'Modified At', width: 210, valueFormatter: DateValueFormatter },
    { field: 'createdAt', headerName: 'Created At', width: 210, valueFormatter: DateValueFormatter }
  ]

  return (
    <>
      <BlockUI blocking={isWorking} />

      <GridListHeader title={`Revisions: ${templateName}`}>
          <span className="fs-5 clickable" onClick={back}>
            <FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> 
            Back to Templates
          </span>
        </GridListHeader>

      <div className='ag-theme-balham' style={{ height: 400 }}>
        <AgGridReact
          columnDefs={columnDefs as any}
          rowData={revisions}
        />
      </div>

      <NewPrimaryButton className="mt-2" onClick={create} disabled={isCreating}>
        { isCreating && <>Creating...</> }
        { !isCreating && <>New Blank Revision</> }
      </NewPrimaryButton>

      <TemplateRevisionCloneModal
        isOpen={isCloning}
        templateKey={templateKey}
        sourceRevisionId={sourceRevisionId}
        onClose={toggleIsCloning}
      />
    </>
  )
}

export default withAuthenticationRequired(ListTemplateRevisions, {
  onRedirecting: () => <Loading />,
})
