import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import { AgGridReact } from '@ag-grid-community/react'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { ColDef, ColGroupDef, ModuleRegistry } from '@ag-grid-community/core'

import { User, Property, MLS_STATUS_LABELS, MlsStatuses } from '@bluebid-sdk/core'
import { Datasource } from 'bb-lib-desktop'
import {
  ArchitectureTypes,
  HeatingFuelTypes,
  HeatingTypes,
  LAND_USE_CATEGORY,
  RoofStyles,
  STATE_CODES,
  STD_LAND_USE_TYPES,
  WaterTypes,
} from '../../lib/data/data'
import {
  AddressLinkRenderer,
  CommaNumRenderer,
  CurrencyRenderer,
  DateValueFormatter,
  LocationValueGetter,
  OwnerFieldLinkRenderer, PhoneNumberRendererFromResultField,
  restoreGridState,
  ResultFieldRenderer,
} from '../../components/grid/GridRenderers'
import { UserSearch } from '../../components/UserSearch'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import { faFileArchive, faList } from '@fortawesome/free-solid-svg-icons'
import { getApiHost } from '../../config'
import { useNavigate } from 'react-router-dom'
import { PropertySearchWidget } from './PropertySearchWidget'
import { useSessionStorage } from '../../lib/utils'
import { GridListHeader, HeaderActionIcon } from './GridListHeader'
import { AuthContext } from '../../constants/context'
import { useProperty } from './hooks/useProperty'
import { downloadExport } from '../../lib/data/exporting'
import { PropertyEditModal } from './propertyedit/PropertyEditModal'

ModuleRegistry.registerModules([
  InfiniteRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY)

export const cols: (ColDef<any, any> | ColGroupDef<any>)[] = [
  // { field:'propertyId', headerName:'Id', cellRenderer:LinkRenderer, cellRendererParams:{ prefix:'/admin/propertyedit'} },
  // { field:'address', width:350, filter:'agTextColumnFilter' },
  {
    field: 'address',
    headerName: 'Address',
    width: 300,
    cellRenderer: AddressLinkRenderer,
    cellRendererParams: { prefix: '/admin/propertyedit' },
    filter: 'agTextColumnFilter',
    checkboxSelection: true,
  },
  {
    field: 'detail.metadata.publishing_date',
    headerName: 'ATOM date',
    valueFormatter: DateValueFormatter,
    hide: true,
    filter: 'agDateColumnFilter',
  },
  {
    field: 'mlsStatus',
    headerName: 'MLS Status',
    cellRenderer: (params) => {
      // @todo: combine to use the same as inquiries-grid-component.tsx
    const value = params.value || params.value === '' ? params.value : params?.data?._fields?.mls?.mlsStatus
    const edited = params?.data?._fields?.mls?.mlsEdited

    const colorByStatus = (status) => {
      switch (status) {
        case MlsStatuses.OffMarket:
          return 'green'
        case MlsStatuses.Listed:
          return 'red'
        case MlsStatuses.NoMatch:
          return '#baba3d'
        default:
          return 'black'
      }
    }
     return (
        <Row>
          <Col style={{ fontWeight: value ? 'bold' : 'normal', color: colorByStatus(value) }}>
            {value ? `${MLS_STATUS_LABELS[value]}${edited ? ' (edited)' : ''}` : ''}
          </Col>
        </Row>
     )
    }
  },
  {
    field: 'mlsCheckedAt',
    headerName: 'MLS Last Checked',
    valueFormatter: DateValueFormatter,
    // sortable: false
  },
  {
    headerName: 'Created / Modified',
    children: [
      {
        field: 'createdAt',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
      {
        field: 'modifiedAt',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      {
        field: 'refreshedAt',
        columnGroupShow: 'open',
        headerName: 'Last Data Refresh',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      {
        field: 'lastCheckedAt',
        columnGroupShow: 'open',
        headerName: 'Last Refresh Request',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      {
        field: 'cmaRequestedAt',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
      {
        field: 'omSellerDate',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
      {
        field: 'omBuyerDate',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
    ],
  },
  {
    headerName: 'User Info',
    children: [
      { field: 'ownerId', headerName: 'User', cellRenderer: OwnerFieldLinkRenderer, sortable: false },
      {
        field: 'Email',
        cellRenderer: ResultFieldRenderer,
        cellRendererParams: { object: 'owner', field: 'email' },
        sortable: false,
      },
      {
        field: 'Phone',
        cellRenderer: PhoneNumberRendererFromResultField,
        cellRendererParams: { object: 'owner', field: 'phone' },
        sortable: false,
      },
    ],
  },
  {
    headerName: 'Flags',
    children: [
      { field: 'contactRequested', headerName: 'Contact Requested', width: 80 },
      {
        field: 'contactReqDate',
        headerName: 'Contact req date',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      { field: 'cma', headerName: 'CMA', columnGroupShow: 'closed', width: 80 },
      {
        field: 'cmaRequestedAt',
        headerName: 'CMA request date',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      { field: 'omSeller', headerName: 'Off market Seller', columnGroupShow: 'open', width: 80 },
      {
        field: 'omSellerDate',
        headerName: 'OM Seller date',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      { field: 'omBuyer', headerName: 'Off market Buyer', columnGroupShow: 'open', width: 80 },
      {
        field: 'omBuyerDate',
        headerName: 'OM Buyer date',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
    ],
  },
  {
    field: 'location',
    headerName: 'Location',
    valueGetter: LocationValueGetter,
    hide: true,
    sortable: false,
    filter: false,
  },
  {
    headerName: 'Valuation',
    children: [
      {
        field: 'detail.valuation.value',
        headerName: 'Value',
        cellRenderer: CurrencyRenderer,
        width: 120,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'detail.valuation.date',
        columnGroupShow: 'open',
        headerName: 'Date',
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      {
        field: 'detail.valuation.low',
        columnGroupShow: 'open',
        headerName: 'Low',
        cellRenderer: CurrencyRenderer,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'detail.valuation.high',
        columnGroupShow: 'open',
        headerName: 'High',
        cellRenderer: CurrencyRenderer,
        filter: 'agNumberColumnFilter',
      },
    ],
  },
  {
    field: 'detail.owner_sell_price',
    headerName: 'Price to move',
    cellRenderer: CurrencyRenderer,
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'detail.minimum_offer',
    headerName: 'Minimum Bluebid',
    cellRenderer: CurrencyRenderer,
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'detail.bluebid_estimate.value',
    headerName: 'Bluebid Estimate',
    cellRenderer: CurrencyRenderer,
    filter: 'agNumberColumnFilter',
  },
  { field: 'detail.buyers_agent_commission', headerName: "Buyer's Agent Fee" },
  {
    headerName: 'Owner Info',
    children: [
      { field: 'detail.owner.name', headerName: 'Owner', filter: 'agTextColumnFilter', valueFormatter: params => params.value?.toUpperCase() },
      {
        field: 'detail.owner.formatted_street_address',
        columnGroupShow: 'open',
        headerName: 'Address',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'detail.owner.city',
        columnGroupShow: 'open',
        headerName: 'City',
        filter: 'agTextColumnFilter',
        width: 150,
      },
      {
        field: 'detail.owner.state',
        columnGroupShow: 'open',
        headerName: 'State',
        width: 80,
        filter: 'agSetColumnFilter',
        filterParams: { values: STATE_CODES },
      },
      {
        field: 'detail.owner.zip_code',
        columnGroupShow: 'open',
        headerName: 'Zip',
        filter: 'agTextColumnFilter',
        width: 100,
      },
    ],
  },
  {
    headerName: 'Parcel Info',
    columnGroupShow: 'open',
    children: [
      { field: 'detail.parcel.county_name', headerName: 'County', width: 150, filter: 'agTextColumnFilter' },
      {
        field: 'detail.parcel.municipality',
        columnGroupShow: 'open',
        headerName: 'Municipality',
        width: 150,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Size',
        columnGroupShow: 'open',
        children: [
          { field: 'detail.parcel.area_acres', headerName: 'Area acres', width: 120, filter: 'agNumberColumnFilter' },
          {
            field: 'detail.parcel.frontage_ft',
            columnGroupShow: 'open',
            headerName: 'Frontage',
            width: 100,
            filter: 'agNumberColumnFilter',
          },
          {
            field: 'detail.parcel.area_sq_ft',
            columnGroupShow: 'open',
            headerName: 'Area Sq Ft',
            width: 120,
            filter: 'agNumberColumnFilter',
          },
        ],
      },
      {
        headerName: 'FIPS / APN',
        columnGroupShow: 'open',
        children: [
          { field: 'detail.parcel.fips_code', headerName: 'FIPS', width: 100, filter: 'agTextColumnFilter' },
          {
            field: 'detail.parcel.apn_unformatted',
            columnGroupShow: 'open',
            headerName: 'APN (unformatted)',
            filter: 'agTextColumnFilter',
          },
          {
            field: 'detail.parcel.apn_original',
            columnGroupShow: 'open',
            headerName: 'APN (original)',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      {
        field: 'detail.parcel.legal_description',
        columnGroupShow: 'open',
        headerName: 'Legal Description',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'detail.parcel.standardized_land_use_type',
        columnGroupShow: 'open',
        headerName: 'Land Use Type',
        filter: 'agSetColumnFilter',
        filterParams: { values: STD_LAND_USE_TYPES },
      },
      {
        field: 'detail.parcel.standardized_land_use_category',
        columnGroupShow: 'open',
        headerName: 'Land Use Category',
        filter: 'agSetColumnFilter',
        filterParams: { values: LAND_USE_CATEGORY },
      },
      {
        field: 'detail.parcel.county_land_use_code',
        columnGroupShow: 'open',
        headerName: 'County Land Use Code',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'detail.parcel.county_land_use_description',
        columnGroupShow: 'open',
        headerName: 'County Land Use Desc.',
        filter: 'agTextColumnFilter',
      },

      {
        field: 'detail.parcel.lot_number',
        columnGroupShow: 'open',
        headerName: 'Lot #',
        width: 100,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'detail.parcel.lot_code',
        columnGroupShow: 'open',
        headerName: 'Lot Code',
        width: 100,
        filter: 'agTextColumnFilter',
      },
      {
        field: 'detail.parcel.subdivision',
        columnGroupShow: 'open',
        headerName: 'Subdivision',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'detail.parcel.tax_account_number',
        columnGroupShow: 'open',
        headerName: 'Tax Acct #',
        filter: 'agTextColumnFilter',
      },
      // { field:'detail.parcel.', columnGroupShow:'open', headerName:''},
    ],
  },
  {
    headerName: 'Structure',
    children: [
      { field: 'detail.structure.beds_count', width: 100, headerName: 'Beds', filter: 'agNumberColumnFilter' },
      { field: 'detail.structure.baths', width: 100, headerName: 'Baths', filter: 'agNumberColumnFilter' },
      {
        field: 'detail.structure.partial_baths_count',
        width: 100,
        headerName: 'Partial Baths',
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'detail.structure.total_area_sq_ft',
        width: 100,
        headerName: 'Sq Ft',
        cellRenderer: CommaNumRenderer,
        filter: 'agNumberColumnFilter',
      },
      { field: 'detail.structure.fireplaces', width: 100, headerName: 'Fireplaces', columnGroupShow: 'open' },
      { field: 'detail.structure.pool_type', width: 100, headerName: 'Pool Type', columnGroupShow: 'open' },
      { field: 'detail.structure.air_conditioning_type', width: 100, headerName: 'A/C Type', columnGroupShow: 'open' },
      {
        headerName: 'Parking',
        children: [
          {
            field: 'detail.structure.parking_spaces_count',
            width: 100,
            headerName: '#',
            filter: 'agNumberColumnFilter',
          },
          {
            field: 'detail.structure.parking_type',
            width: 100,
            headerName: 'Type',
            columnGroupShow: 'open',
            filter: 'agTextColumnFilter',
          },
        ],
      },
      {
        headerName: 'More...',
        children: [
          { field: 'detail.structure.stories', width: 100, headerName: 'Stories', filter: 'agNumberColumnFilter' },
          {
            field: 'detail.structure.heating_fuel_type',
            width: 110,
            headerName: 'Heat Fuel',
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
            filterParams: { values: HeatingFuelTypes },
          },
          {
            field: 'detail.structure.heating_type',
            width: 150,
            headerName: 'Heat Type',
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
            filterParams: { values: HeatingTypes },
          },
          {
            field: 'detail.structure.year_built',
            width: 100,
            headerName: 'Year Built',
            columnGroupShow: 'open',
            filter: 'agNumberColumnFilter',
          },
          {
            field: 'detail.structure.effective_year_built',
            width: 100,
            headerName: 'Effective Year Built',
            columnGroupShow: 'open',
            filter: 'agNumberColumnFilter',
          },
          {
            field: 'detail.structure.roof_style_type',
            width: 100,
            headerName: 'Roof Style',
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
            filterParams: { values: RoofStyles },
          },
          {
            field: 'detail.structure.water_type',
            width: 100,
            headerName: 'Water Type',
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
            filterParams: { values: WaterTypes },
          },
          {
            field: 'detail.structure.architecture_type',
            width: 100,
            headerName: 'Architecture Type',
            columnGroupShow: 'open',
            filter: 'agSetColumnFilter',
            filterParams: { values: ArchitectureTypes },
          },
        ],
      },
    ],
  },
]

const datasource = new Datasource({
  index: 'properties',
  hideUnclaimed: true,
  fields: 'owner',
  adminSearch: true,
})

const ListProperties = () => {
  const gridRef = useRef<AgGridReact>()
  const { getToken } = useContext(AuthContext)
  const [user, setUser] = useState<User>()
  const [showUnclaimed, setShowUnclaimed] = useState(false)
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([])
  const navigate = useNavigate()
  const [colState, setColState] = useSessionStorage('properties', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => {
    restoreGridState(colState, gridRef)
    gridRef.current.api.updateGridOptions({ serverSideDatasource: datasource })
  }, [])
  const onBtnRefresh = useCallback(() => {
    gridRef.current.api.refreshServerSide()
  }, [])
  const [editPropertyModal, setEditPropertyModal] = useState(false)
  const [editProperty, setEditProperty] = useState<Property>()
  const { owner, refetchProperty } = useProperty(editProperty?.propertyId)

  const toggle = () => setEditPropertyModal(!editPropertyModal)

  const toggleUnclaimed = () => {
    setShowUnclaimed(!showUnclaimed)
    let ds = new Datasource({
      index: 'properties',
      userId: user?.id,
      hideUnclaimed: !showUnclaimed,
      fields: 'owner',
      adminSearch: true,
    })
    gridRef.current.api.updateGridOptions({ serverSideDatasource: ds })
  }
  const selectUser = (sel) => {
    setUser(sel?.data)
    let ds = new Datasource({
      index: 'properties',
      userId: sel?.data?.id,
      hideUnclaimed: !showUnclaimed,
      fields: 'owner',
      adminSearch: true,
    })
    gridRef.current.api.updateGridOptions({ serverSideDatasource: ds })
  }

  const selectProperty = (sel) => navigate(`/admin/propertyedit/${sel.value}`)

  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const getRowId = useCallback(function (params) {
    return params.data.propertyId
  }, [])

  const getRowStyle = useCallback(function (params) {
    if (params?.data?.status === 'Unclaimed') {
      return { color: 'lightgrey' }
    }
  }, [])

  const onBtExport = useCallback(() => {
    try {
      downloadExport({
        getToken,
        exportUrl: `${getApiHost()}/properties/export?${datasource.exportQueryParams}`
      })
    } catch (error) {
      console.error('error downloading file:', error)
    }
  }, [])

  const onBtBulk = () => navigate(`/admin/propertyedit-bulk`, { state: { selectedPropertyIds } })

  const onSelectionChanged = () => {
    const selected = gridRef.current.api.getSelectedNodes()
    setSelectedPropertyIds(selected.map((node) => node.data.propertyId))
  }

  // change first column to trigger edit modal
  const modifiedColumns = [...cols]

  // it seems like AgGrid unions the column types without a discriminating key??
  // for now this extra conditional narrows the type correctly to one of the two in the column type union
  if ('cellRenderer' in modifiedColumns[0]) {
    modifiedColumns[0].cellRenderer = (params) => {
      return (
        <div>
          <Button
            color="link"
            size="sm"
            style={{ fontSize: '12px' }}
            onClick={() => {
              setEditProperty(params.data)
              toggle()
            }}
          >
            {params.value}
          </Button>
        </div>
      )
    }
  }
  const columnDefs = useMemo(() => modifiedColumns, [])

  return (
    <>
      <GridListHeader title={'Properties'} onRefresh={onBtnRefresh}>
        <ChildContainer>
          <FormGroup switch>
            <Input type="switch" onChange={toggleUnclaimed} checked={!showUnclaimed} />
            <Label check onClick={toggleUnclaimed}>
              {showUnclaimed ? 'Excluding unclaimed' : 'Including unclaimed'}
            </Label>
          </FormGroup>

          <UserSearch onSelect={selectUser} label={'Find claims for user'} />

          <PropertySearchWidget onSelect={selectProperty} />

          <HeaderActionIcon icon={faFileArchive} onClick={() => onBtExport()} />
          <HeaderActionIcon icon={faList} onClick={() => onBtBulk()} />
        </ChildContainer>
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            ref={gridRef}
            animateRows={true}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            onSelectionChanged={onSelectionChanged}
            getRowId={getRowId}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection={true}
            rowSelection="multiple"
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            suppressRowClickSelection={true}
            sideBar={sideBar}
            getRowStyle={getRowStyle}
          />
        </div>
      </div>

      <PropertyEditModal
        isOpen={editPropertyModal}
        onClose={toggle}
        onEdit={refetchProperty}
        property={editProperty}
        owner={owner}
      />
    </>
  )
}

export default withAuthenticationRequired(ListProperties, {
  onRedirecting: () => <Loading />,
})

const ChildContainer = styled.div`
  margin-left: 20px;
  display: flex;
`
