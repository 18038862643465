import React from 'react'
import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import { MessageType } from '../../constants/messsages'
import { GREEN, LightGrey } from '../../constants/colors'
import { address } from '../../utils/common'
import { DateValueFormatter, LinkRenderer } from '../grid/GridRenderers'
import MessageCellEditor from '../../components/grid/MessageCellEditor'

const getAllMessageTypeTitles = () => {
  const messageTypes = Object.keys(MessageType.Offer)
  let messageTypeTitles = []
  if (messageTypes && messageTypes?.length) {
    messageTypes.forEach((msgType) => {
      messageTypeTitles.push(MessageType.Offer[msgType].title)
    })
    return messageTypeTitles
  } else {
    return []
  }
}

const MessageDataRenderer = (params) => {
  let content = JSON.stringify(params.value)
  if (!params.data) return content || 'No Data'

  switch (params.data.messageType) {
    case 'Message':
      content = params.data.data.message
      break

    case 'OfferPlaced:':
    case 'OfferCountered:':
      content = params.data.data.bidAmount
      break

    default:
      content = params.data.subject
  }
  return (
    <Row>
      <Col xs="12" md="12">
        <div>{content}</div>
      </Col>
    </Row>
  )
}

const ThreadLinkRenderer = (params) => {
  if (!params?.data?.threadId && !params?.data?.recipientId) {
    return ''
  }
  return (
    <div>
      <Button
        style={{ fontSize: '12px' }}
        color="link"
        onClick={() => params?.openThreadView?.(params.data.recipientId, params.data.threadId)}
      >
        {address(params)}
      </Button>
    </div>
  )
}

const PublishRenderer = (params) => {
  const { id: messageId, staging } = params.data

  const label = staging ? 'Publish' : 'Unpublish'

  return (
    <div id={`staging-toggle-${messageId}`}>
      <UncontrolledTooltip placement="left" target={`staging-toggle-${messageId}`}>
        {label}
      </UncontrolledTooltip>
      {staging && (
        <div
          style={{ margin: 'auto', width: '50%' }}
          onClick={() => params?.setStagingStatus?.({ messageId, staging: false })}
          className="clickable"
        >
          <FontAwesomeIcon icon={faPaperPlane} style={{ color: GREEN }} />
        </div>
      )}
      {!staging && (
        <div
          style={{ margin: 'auto', width: '50%' }}
          onClick={() => params?.setStagingStatus?.({ messageId, staging: true })}
          className="clickable"
        >
          <FontAwesomeIcon icon={faPaperPlane} style={{ color: LightGrey }} />
        </div>
      )}
    </div>
  )
}

const AnnotationRenderer = (params) => {
  const { id: messageId, note } = params.data
  return (
    <div>
      <FontAwesomeIcon
        // @ts-ignore
        icon={faPenToSquare}
        className="clickable"
        onClick={() => params?.openAnnotationModal({ messageId, note })}
      />{' '}
      {note}
    </div>
  )
}

export const messagesGridColumns = ({
  setStagingStatus,
  openAnnotationModal,
  openThreadView,
}): (ColDef | ColGroupDef)[] => [
  { field: 'id', headerName: 'Id', width: 300, hide: true },
  {
    field: 'staging',
    headerName: 'Published',
    width: 100,
    cellRenderer: PublishRenderer,
    cellRendererParams: { setStagingStatus },
  },
  {
    field: 'messageType',
    headerName: 'Message Type',
    width: 150,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: getAllMessageTypeTitles(),
    },
  },
  {
    field: 'data',
    headerName: 'Message Data',
    width: 400,
    cellRenderer: MessageDataRenderer,
    cellEditor: MessageCellEditor,
    editable: (params) => params.data.messageType === 'Message',
  },

  {
    field: 'threadId',
    headerName: 'Address',
    width: 300,
    cellRenderer: ThreadLinkRenderer,
    cellRendererParams: { openThreadView },
  },

  {
    field: 'note',
    headerName: 'Admin Annotation',
    width: 300,
    cellRenderer: AnnotationRenderer,
    cellRendererParams: { openAnnotationModal },
  },

  {
    headerName: 'Created / Modified',
    children: [
      {
        field: 'createdAt',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
      {
        field: 'modifiedAt',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
      {
        field: 'expiredAt',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
    ],
  },

  {
    field: 'propertyId',
    headerName: 'Property',
    width: 300,
    cellRenderer: LinkRenderer,
    cellRendererParams: { prefix: '/admin/propertyedit' },
    filter: 'agTextColumnFilter',
  },

  {
    field: 'senderId',
    headerName: 'Sender Id',
    cellRenderer: LinkRenderer,
    cellRendererParams: { prefix: '/admin/userprofile' },
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    field: 'recipientId',
    headerName: 'Recipient Id',
    cellRenderer: LinkRenderer,
    cellRendererParams: { prefix: '/admin/userprofile' },
    filter: 'agTextColumnFilter',
    hide: true,
  },
]
