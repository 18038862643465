import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Property, User, MessageThread } from '@bluebid-sdk/core'

import { getClaimedPropertyInfo, getThreadChildren, getUser } from '../lib/data/bluebidData'
import { MessageType } from '../views/admin/OfferHistory'
import { OfferPlacedBubble } from './offers/OfferPlacedBubble'
import { OfferCounteredBubble } from './offers/OfferCounteredBubble'
import { MessageNoteBubble } from './offers/MessageNoteBubble'
import { OfferFinalBubble } from './offers/OfferFinalBubble'
import { ThreadHistoryBlock } from './offers/ChildMessageList'
import { MessageImagesBubble } from './offers/MessageImagesBubble'
import { getUserDisplayName } from '../utils/common'

export const ThreadConversation: React.FC<{ userId?: string; threadId?: string }> = ({ userId, threadId }) => {
  const [messages, setMessages] = useState([])
  const [parent, setParent] = useState<MessageThread>()
  const [property, setProperty] = useState<Property>()
  const [sender, setSender] = useState<User>()
  const [receiver, setReceiver] = useState<User>()

  const getChildMessages = () => {
    return getThreadChildren(userId, threadId).then((children) => {
      if (children.status === 'error') {
        console.error(children)
      } else {
        setMessages(children)
        setParent(children?.[0] || null)
      }
    })
  }
  const getThreadData = async (thread) => {
    let p = await getClaimedPropertyInfo(thread.propertyId)
    setProperty(p)
    let o = await getUser(thread.recipientId)
    setReceiver(o)
    let b = await getUser(thread.senderId)
    setSender(b)
  }

  useEffect(() => {
    getChildMessages()
  }, [threadId])

  useEffect(() => {
    if (parent?.propertyId) {
      getThreadData(parent)
    }
  }, [parent?.propertyId])

  const onUpdateMessageList = () => {
    getChildMessages()
  }

  return (
    <>
      <div>
        <InfoLine>
          <InfoLabel>Address: </InfoLabel>
          <InfoData>
            <Link to={`/admin/propertyedit/${property?.propertyId}`}>{property?.address || ''}</Link>
          </InfoData>
        </InfoLine>
        <InfoLine>
          <InfoLabel>Originator: </InfoLabel>
          <InfoData>
            <Link to={`/admin/userprofile/${sender?.id}`}>{getUserDisplayName(sender)}</Link>
          </InfoData>
        </InfoLine>
        <InfoLine>
          <InfoLabel>Recipient: </InfoLabel>
          <InfoData>{getUserDisplayName(receiver)}</InfoData>
        </InfoLine>
      </div>
      <ThreadHistoryBlock>
        {messages.length && (
          <RenderMessageThread
            messages={messages}
            sender={sender}
            receiver={receiver}
            parent={parent}
            property={property}
            update={onUpdateMessageList}
          />
        )}
        {!messages.length && <div>No Messages.</div>}
      </ThreadHistoryBlock>
    </>
  )
}

const RenderMessageThread = ({ messages, sender, receiver, parent, property, update }) => {
  return messages?.map((entry, i) => {
    // let isLast = (i === childThreads.length - 1) && entry.messageType !== MessageType.Offer.Message.title
    // const isLast = lastmessageActionDetail?.id === entry.id
    const isLast = false
    let messageSender = entry?.senderId === sender?.id ? sender : receiver
    let isMessageSender = entry?.senderId === sender?.id
    const key = `message-${i}`
    switch (entry.messageType) {
      case MessageType.Offer.Placed.title:
        return (
          // @ts-ignore
          <OfferPlacedBubble
            key={key}
            parent={parent}
            onUpdateMessageList={update}
            message={entry}
            isCurrent={isLast}
            sender={messageSender}
            isSender={isMessageSender}
          />
        )

      case MessageType.Offer.Countered.title:
        return (
          <OfferCounteredBubble
            key={key}
            parent={parent}
            property={property}
            msg={entry}
            onUpdateMessageList={update}
            isCurrent={isLast}
            sender={messageSender}
            isSender={isMessageSender}
          />
        )

      case MessageType.Offer.Message.title:
        return (
          <MessageNoteBubble
            key={key}
            parent={parent}
            msg={entry}
            first={i === 0}
            sender={messageSender}
            isSender={isMessageSender}
          />
        )

      case MessageType.Offer.Images.title:
        return (
          <MessageImagesBubble
            key={key}
            isSender={isMessageSender}
            msg={entry}
            sender={messageSender}
            onUpdateMessageList={update}
          />
        )
      // case MessageType.Offer.Expired.title:
      //     return <OfferExpireBubble key={`detail-${i}`} msg={entry} property={parent.data.property}/>
      case MessageType.Offer.Rejected.title:
      case MessageType.Offer.Accepted.title:
      case MessageType.Offer.Revoked.title:
        return (
          <OfferFinalBubble
            key={key}
            parent={parent}
            msg={entry}
            property={property}
            sender={messageSender}
            isSender={isMessageSender}
          />
        )
      default:
        return (
          <div>
            Unknown message type {entry.messageType}. ID: {entry.id}
          </div>
        )
    }
  })
}

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center;
  padding: 10px;
  font-size: 14px;
`

const InfoLabel = styled.div`
  font-weight: bold;
  padding-right: 10px;
`
const InfoData = styled.div``
