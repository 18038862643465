import React from 'react'
import styled from 'styled-components'

import { Branding, formatPhoneNumber, Property } from '@bluebid-sdk/core'

import { sizes } from '../utils/responsive'
import { LightGrey } from '../constants/colors'

/**
 * @deprecated use AgentBranding
 */
export const GroupBranding: React.FC<{
  branding: Branding
  title?: string
  address?: string
  width?: number
  property: Property
  threadView?: boolean
  contactAgentMessage?: string
  contactAgentSubmitter?: (args: {
    name: string
    phone: string
    email: string
    message: string
  }) => Promise<any>
  asEmailLink?: boolean
}> = ({
  branding,
  title,
  address,
  width,
  property,
  threadView,
  contactAgentMessage,
  contactAgentSubmitter,
  asEmailLink,
}) => {
  if (!branding) {
    return <React.Fragment></React.Fragment>
  }

  return (
    <Component width={width}>
      <div className="d-flex">
        <ImgCol>
          <BrandedPhoto src={branding.photoUrl} />
        </ImgCol>
        <ContentCol>
          <div className="h-100 text-center">
            <div className="h-100 d-flex flex-column justify-content-center">
              {!!title && <Title threadView={threadView}>{title}</Title>}
              <InfoLine>{branding.label}</InfoLine>
              {branding.phone && (
                <InfoLine>
                  Text or call{' '}
                  <PhoneNumber href={`tel:+${branding.phone}`}>
                    {formatPhoneNumber(branding.phone)}
                  </PhoneNumber>
                </InfoLine>
              )}
              <InfoLine>
                {asEmailLink && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${
                      branding.email
                    }?&cc=support@bluebidhomes.com&subject=BlueBid Inquiry:%20${
                      address ? encodeURIComponent(address) : ''
                    }`}
                  >
                    Contact Agent
                  </a>
                )}

                {!!branding.websiteUrl && (
                  <React.Fragment>
                    {' '}
                    <span style={{ color: LightGrey }}>|</span>{' '}
                    <a target="_blank" rel="noopener noreferrer" href={branding.websiteUrl}>
                      {branding.websiteUrlLabel || 'View Website'}
                    </a>
                  </React.Fragment>
                )}
              </InfoLine>
            </div>
          </div>
        </ContentCol>
      </div>
    </Component>
  )
}

const Component = styled(styled.div.attrs({ className: 'group-branding' })``)<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
`

const Title = styled.p.attrs({ className: 'mx-2 mb-3 fw-bold fs-8' })<{ threadView?: boolean }>`
  max-width: ${(props) => (props.threadView ? '' : '250px')};
`

const ImgCol = styled.div`
  flex: 0 0 auto;
  width: 30%;

  @media (max-width: ${sizes.mobileXL}) {
    width: 25%;
  }
`

const ContentCol = styled.div`
  flex: 0 0 auto;
  width: 80%;

  @media (max-width: ${sizes.mobileXL}) {
    width: 75%;
  }
`

const InfoLine = styled.div`
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
  }
`

const PhoneNumber = styled.a`
  color: inherit;
`

const BrandedPhoto = styled.img.attrs({ className: 'w-100' })`
  min-height: 100px;
`