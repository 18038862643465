import { BodyWrapper, CardContainer } from './AdminStyles'
import { Col, Container, Row } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { getDataMetrics } from '../../lib/data/bluebidData'
import { Histogram } from './Histogram'
import moment from 'moment-timezone'
import { ItemCounts } from './ItemCounts'
import { RecentClaims } from '../../components/RecentClaims'
import { useNavigate } from 'react-router-dom'

export const AdminHomePanel = () => {
  const [dailyClaims, setDailyClaims] = useState([])
  const [weeklyClaims, setWeeklyClaims] = useState([])
  const [dailyUsers, setDailyUsers] = useState([])
  const [weeklyUsers, setWeeklyUsers] = useState([])
  const navigate = useNavigate()
  const navigateEdit = (sel) => navigate(`/admin/propertyedit/${sel}`)

  const UTCDate = (dte) => moment.utc(dte).format('MMM DD')

  const parseAggs = (result, field) => {
    return result.aggregations[field].buckets.map(b => {
      return {
        date: UTCDate(b.key_as_string),
        count: b.doc_count,
      }
    })
  }
  const refreshMetrics = async () => {
    const newMetrics = await getDataMetrics()
    setDailyClaims(parseAggs(newMetrics.properties, 'by_day'))
    setWeeklyClaims(parseAggs(newMetrics.properties, 'by_week'))
    setDailyUsers(parseAggs(newMetrics.users, 'by_day'))
    setWeeklyUsers(parseAggs(newMetrics.users, 'by_week'))
  }

  useEffect(() => {
    refreshMetrics()
    let to = setInterval(refreshMetrics, 90000)
    return () => {
      clearInterval(to)
    }
  }, [])

  const PropertyBarColor = 'rgba(53, 162, 235, 0.5)'
  const UserBarColor = 'rgba(63,201,116,0.5)'

  return (
    <BodyWrapper>
      <Container>
        <Row className={'mt-2'}>
          <Col xs={12}>
            <h3>Counts</h3>
          </Col>
        </Row>
        <Row className={'mb-2 mt-2'}>
          <ItemCounts />
        </Row>

        <Row className={'mt-4 mb-2'}>
          <Col xs={12}>
            <h3>Recent Claims</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CardContainer>
              <RecentClaims onPropertyClick={navigateEdit} />
            </CardContainer>
          </Col>
        </Row>


        <Row className={'mt-4 mb-2'}>
          <Col xs={12}>
            <h3>Claims and Signups</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={'mb-2'}>
            <CardContainer>
              <Histogram title={'Daily Claims'} dataset={dailyClaims} barColor={PropertyBarColor} />
            </CardContainer>
          </Col>
          <Col xs={6} className={'mb-2'}>
            <CardContainer>
              <Histogram title={'Weekly Claims'} dataset={weeklyClaims} barColor={PropertyBarColor} />
            </CardContainer>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className={'mb-2'}>
            <CardContainer>
              <Histogram title={'Daily Signups'} dataset={dailyUsers} barColor={UserBarColor} />
            </CardContainer>
          </Col>
          <Col xs={6} className={'mb-2'}>
            <CardContainer>
              <Histogram title={'Weekly Signups'} dataset={weeklyUsers} barColor={UserBarColor} />
            </CardContainer>
          </Col>
        </Row>
      </Container>
    </BodyWrapper>
  )
}

