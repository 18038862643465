import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { debounce, set } from 'lodash'
import { BlockUI, NewPrimaryButton, NewSecondaryButton } from 'bb-lib-desktop'
import Loading from '../../components/Loading'
import { Alert, Badge, Col, Input, Row, Table } from 'reactstrap'
import { deleteRevision, deployRevision, describeContextSchema, getTemplate, getDeployedRevision, getRevision, preview, saveRevision } from '../../lib/data/templates'
import { Revision } from '../../lib/templates'
import { GridListHeader } from './GridListHeader'
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { faArrowLeft, faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { errorToast, successToast } from '../../utils/common'
import { PropertySearchByAddress } from '../../components/PropertySearchByAddress'
import { UserSearch } from '../../components/UserSearch'
import { TemplateRevisionCloneModal } from './TemplateRevisionCloneModal'
import { getUser } from '../../lib/data/bluebidData'

/**
 * Takes a Joi schema description and renders it as a list of keys and types.
 * Recursively renders nested objects.
 */
const ResolverContextDescription = ({ data }) => {
  const renderValue = (key, value) => {
    const type = value && value.type ? ` (${value.type})` : ''
    const label = value && value.flags?.label ? ` - ${value.flags.label}` : ''
    
    if (value && typeof value === 'object' && value.keys) {
      return (
        <div key={key}>
          <div className="mb-1"><kbd>{key}.*</kbd>{label}</div>
          <div className="ml-3">
            {Object.entries(value.keys).map(([nestedKey, nestedValue]) =>
              renderValue(nestedKey, nestedValue)
            )}
          </div>
        </div>
      );
    }

    return (<div key={key} className="mb-1"><kbd>{key}</kbd>{type} {label}</div>)
  };

  return (
    <div>
      {Object.entries(data.keys).map(([key, value]) => renderValue(key, value))}
    </div>
  )
}

const TemplateRevision = () => {
  const navigate = useNavigate()

  const [isWorking, setIsWorking] = useState(false)
  const { templateKey, revisionId } = useParams()

  const [templateName, setTemplateName] = useState<string>('')
  const [hasChanges, setHasChanges] = useState(false)
  const [templateRevision, setTemplateRevision] = useState<Revision>()
  const [updatedSource, setUpdatedSource] = useState<string>()
  const [previewSource, setPreviewSource] = useState<string>()
  const [previewError, setPreviewError] = useState<string>()
  const [resolverDescription, setResolverDescription] = useState()
  const [isDeployedRevision, setIsDeployedRevision] = useState<boolean>(false)
  const [isDeploying, setIsDeploying] = useState<boolean>(false)
  const [isCloning, setIsCloning] = useState<boolean>(false)
  const [revisionLabel, setRevisionLabel] = useState<string>('')
  const [contextArgNames, setContextArgNames] = useState<string[]>([])
  const [viewContext, setViewContext] = useState(false)
  const [createdByName, setCreatedByName] = useState<string>()
  const [modifiedByName, setModifiedByName] = useState<string>()
  const [clonedFromRevision, setClonedFromRevision] = useState<string>()
  const [channel, setChannel] = useState<'email' | 'sms'>('email')

  const [claimedPropertyId, setClaimedPropertyId] = useState<string>()
  const [recipientId, setRecipientId] = useState<string>()
  const [otp, setOtp] = useState<string>()
 
  const loadTemplateRevision = () => {
    if (!templateKey || !revisionId) {
      return
    }

    setIsWorking(true)

    return Promise.all([
      getRevision({ revisionId }),
      getTemplate({ templateKey }),
      describeContextSchema({ templateKey }),
      getDeployedRevision({ templateKey })
    ]).then(([rev, t, d, deployed]) => {
      setTemplateRevision(rev)
      setRevisionLabel(rev.label)
      setUpdatedSource(rev.source)
      setContextArgNames(t.contextArgs)
      setTemplateName(t.name)
      setResolverDescription(d)
      setChannel(t.channel)

      if (rev.clonedFrom) {
        getRevision({ revisionId: rev.clonedFrom }).then(originalRev => {
          setClonedFromRevision(originalRev && originalRev.id ? rev.clonedFrom : null)
        }).catch(() => {
          setClonedFromRevision(null)
        })
      }

      if (deployed && deployed.id) {
        setIsDeployedRevision(deployed.id === revisionId)
      }

      if (rev.createdBy) {
        getUser(rev.createdBy).then(u => setCreatedByName(`${u.firstName} ${u.lastName}`))
      }

      if (rev.modifiedBy) {
        getUser(rev.modifiedBy).then(u => setModifiedByName(`${u.firstName} ${u.lastName}`))
      }

      setIsWorking(false)
    })
  }

  useEffect(() => {
    loadTemplateRevision()
  }, [templateKey, revisionId])

  // update the source code component with the revision source
  const onSourceChange = useCallback((src: string) => {
    if (templateRevision) {
      setHasChanges(templateRevision.source !== src)
      setUpdatedSource(src)
    }
  }, [templateKey, templateRevision])

  // only emit source code changes max once every 500ms
  const debouncedOnSourceChange = useMemo(
    () => debounce(onSourceChange, 500),
    [onSourceChange]
  )

  // update the preview when the source changes or the test arguments change
  useEffect(() => {
    const args = {
      ...contextArgNames.includes('claimedPropertyId') ? { claimedPropertyId } : undefined,
      ...contextArgNames.includes('recipientId') ? { recipientId } : undefined,
      ...contextArgNames.includes('otp') ? { otp } : undefined
    }

    const hasArgs = Object.keys(args).length > 0
    if (!hasArgs) {
      setPreviewError('Missing test parameters.')
      return
    }

    preview({ templateKey, source: updatedSource, args })
      .then(source => {
        if (source.status === 'error') {
          const msg = source.errorMessage?.includes('ExpressionAttributeValues must not be empty') ?
            'Missing test parameters.' : source.errorMessage
          setPreviewError(msg || 'Cannot fetch preview.')
        } else {
          setPreviewError(undefined)

          if (channel === 'sms') {
            setPreviewSource(`<pre style="white-space:break-spaces">${source}</pre>`)
          } else {
            setPreviewSource(source)
          }
        }
      })
  }, [channel, updatedSource, claimedPropertyId, recipientId, otp])

  // saves the revision
  const save = () => {
    saveRevision({ revisionId, source: updatedSource, label: revisionLabel })
      .then(() => {
        successToast('Saved revision')
        setHasChanges(false)
      }).catch(e => {
        errorToast('Failed to save revision')
        console.error(e)
      })
  }

  // test argument handling: claimed property (property selector)
  const onClaimedPropertySelect = (property: { value: string }) => {
    setClaimedPropertyId(property.value)
  }

  // test argument handling: recipient ID (user selector)
  const onRecipientSelect = (s: { data: string }) => setRecipientId(s.data)

  // test argument handling: OTP number (number input)
  const onOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value)
  }

  // goes back to the revision list, but warns if there are unsaved changes
  const back = () => {
    // eslint-disable-next-line no-restricted-globals
    const cnf = (hasChanges) ? confirm('You have unsaved changes. Are you sure you want to leave?') : true
    
    if (cnf) {
      navigate(`/admin/templates/${templateKey}`)
    }
  }

  // saves and deploys a revision after asking the user to confirm
  const deploy = () => {
    // eslint-disable-next-line
    const cnf = confirm(`Are you sure you want to save and deploy revision ${revisionId}?`)
    if (!cnf) {
      return
    }

    setIsDeploying(true)
    saveRevision({ revisionId, source: updatedSource, label: revisionLabel })
      .then(() => deployRevision({ revisionId }))
      .then(() => {
        setTimeout(() => {
          successToast(`Deployed this revision`)
          setIsDeploying(false)
          loadTemplateRevision()
        }, 1000)
      }).catch(e => {
        console.error(e)
        setIsDeploying(false)
        errorToast('Failed to deploy')
      })
  }

  const toggleIsCloning = () => {
    setIsCloning(!isCloning)
  }

  // deletes this revision after confirming with the user
  const remove = () => {
    // eslint-disable-next-line
    const cnf = confirm(`Are you sure you want to delete revision ${revisionId}?`)
    if (!cnf) {
      return
    }

    deleteRevision({ revisionId }).then(() => {
      setTimeout(() => {
        navigate(`/admin/templates/${templateKey}`)
      }, 1000)
    })
  }

  // adjusts the width of the preview iframe
  const changePreviewSize = (size: string) => {
    const el = document.getElementById('previewContainer')
    if (el) {
      el.style.width = size
    }
  }

  const onRevisionLabelChange = event => {
    setHasChanges(true)
    setRevisionLabel(event.target.value)
  }

  const toggleViewContext = () => {
    setViewContext(!viewContext)
  }

  return (
    <>
      <BlockUI blocking={isWorking} />

      { !isWorking && templateKey && (<>
        <GridListHeader title={`Modify Template Revision`}>
          <span className="fs-5 clickable" onClick={back}>
            <FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> 
            Back to Revisions
          </span>
        </GridListHeader>

        <Row>
          <Col>
            <Table>
              <tbody>
                <tr>
                  <th style={{ width: 100 }} scope="row">
                    Template
                  </th>
                  <td>
                    { templateName }
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Revision
                  </th>
                  <td>
                    <div>{ revisionId }</div>
                    
                    { templateRevision?.clonedFrom && clonedFromRevision && <div>
                      <strong>Cloned from:</strong> <Link to={`/admin/templates/${templateRevision.templateKey}/${clonedFromRevision}`}>
                        { clonedFromRevision }
                      </Link>
                    </div> }

                    { templateRevision?.clonedFrom && clonedFromRevision === null && <div>
                      <strong>Cloned from:</strong> <em>(base revision deleted)</em>
                    </div> }
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Label
                  </th>
                  <td>
                    <input
                      type="text"
                      className='form-control'
                      value={revisionLabel}
                      onChange={onRevisionLabelChange}
                      disabled={isDeployedRevision}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Deployment
                  </th>
                  <td>
                    { isDeployedRevision && <Badge color="success">Deployed Revision</Badge> }
                    { !isDeployedRevision && <Badge color="secondary">Not Deployed Revision</Badge> }
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Channel
                  </th>
                  <td>
                    {channel}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Context
                  </th>
                  <td>
                    <NewPrimaryButton onClick={toggleViewContext}>
                      { viewContext ? 'Hide' : 'Show' }
                    </NewPrimaryButton>

                    { viewContext && resolverDescription && <div className="mt-3">
                      <ResolverContextDescription data={resolverDescription} />
                    </div> }
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col className="text-right pb-3">
            <div>
              <NewPrimaryButton className="mr-1" onClick={save} disabled={isDeployedRevision}>Save</NewPrimaryButton>
              <NewPrimaryButton className="mr-1" onClick={toggleIsCloning}>Clone</NewPrimaryButton>
              <NewPrimaryButton className="mr-1" onClick={deploy} disabled={isDeployedRevision || isDeploying}>Save & Deploy</NewPrimaryButton>
              <NewSecondaryButton onClick={remove} disabled={isDeployedRevision}>Delete</NewSecondaryButton>
            </div>
            <div className="text-right mt-2">
              { isDeployedRevision && (<div className="mb-4">
                This revision is currently <strong>deployed and cannot be modified</strong>.
                <br />To make changes, clone to a new revision.
              </div>) }
              { templateRevision && templateRevision.createdAt && <div>
                <em>Created by:</em> {createdByName} @ {new Date(templateRevision.createdAt).toLocaleString()}
              </div> }
              { templateRevision && templateRevision.modifiedAt && <div>
                <em>Modified by:</em> {modifiedByName} @ {new Date(templateRevision.modifiedAt).toLocaleString()}
              </div> }
            </div>
          </Col>
        </Row>

        <CodeMirror
          theme={'dark'}
          value={updatedSource}
          height="500px"
          extensions={[html()]}
          onChange={debouncedOnSourceChange}
          readOnly={isDeployedRevision}
        />
        
        <Row className="mt-3 mb-5 pb-5">
          <Col md={3}>
            <h5>Test Parameters</h5>

            {contextArgNames.includes('claimedPropertyId') && (<div className="mb-3">
              <div>Claimed Property:</div>
              <PropertySearchByAddress onSelect={onClaimedPropertySelect} />
            </div>)}

            {contextArgNames.includes('recipientId') && (<div className="mb-3">
              <div>Recipient:</div>
              <UserSearch onSelect={onRecipientSelect} label={undefined} />
            </div>)}

            {contextArgNames.includes('otp') && (<div className="mb-3">
              <div>OTP:</div>
              <Input type="text" maxLength={4} onChange={onOtpChange} />
            </div>)}
          </Col>
          <Col>
            <Row className="mb-2">
              <Col md={1}>
                <h5>Preview</h5>
              </Col>
              <Col>
                <NewPrimaryButton className="mr-2" color="primary" onClick={() => changePreviewSize('100%')}>
                  <FontAwesomeIcon icon={faDesktop} />
                </NewPrimaryButton>
                <NewPrimaryButton className="mr-2" color="primary" onClick={() => changePreviewSize('400px')}>
                  <FontAwesomeIcon icon={faMobileAlt} />
                </NewPrimaryButton>
              </Col>
            </Row>

            {typeof previewSource === 'string' && !previewError && (
              <div id="previewContainer" style={{ resize: 'horizontal', overflow: 'auto', maxWidth: '100%', width: '100%' }}>
                <iframe srcDoc={previewSource} width={channel === 'sms' ? '400px' : '100%'} height="700px" className="border" />
              </div>
            )}

            {previewError && (
              <Alert color="danger" className="w-50">{ previewError }</Alert>
            )}
          </Col>
        </Row>
      </>)}

      <TemplateRevisionCloneModal
        isOpen={isCloning}
        templateKey={templateKey}
        sourceRevisionId={revisionId}
        onClose={toggleIsCloning}
      />
    </>
  )
}

export default withAuthenticationRequired(TemplateRevision, {
  onRedirecting: () => <Loading />,
})
