import { AddressManager } from '../../components/AddressManager'
import { Container, Row } from 'reactstrap'

export const ManageAddresses = () => {

    return (
        <>
            <Container>
                <Row>
                    <h3>Address Management</h3>
                </Row>
            </Container>
            <AddressManager />
        </>
    )
}

