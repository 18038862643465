import React, { useEffect, useState } from 'react'

import { PropertyInvite } from '@bluebid-sdk/core'
import { getOwnerInvite } from 'bb-lib-desktop'

export const useAgentReferral = (property) => {
  const [propertyInvite, setPropertyInvite] = useState<PropertyInvite>()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (property?.propertyId && property?.ownerId) {
      setLoading(true)
      getOwnerInvite(property.propertyId, property.ownerId)
        .then(setPropertyInvite)
        .catch(setError)
        .finally(() => setLoading(false))
    }
  }, [property?.propertyId, property?.ownerId])

  return {
    propertyInvite, error, loading,
  }
}
