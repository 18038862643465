import React, { useRef } from 'react'
import { FileDrop } from 'react-file-drop'
import styled from 'styled-components'

export const FileDropHandler = ({accept, handler}) => {
  const inputRef = useRef();

  const filePicker = () => {
    // @ts-ignore
    inputRef.current.click();
  };

  return (
    <Container>
      <FileDrop onTargetClick={filePicker}
                onDrop={(files, event) => handler(files, event)}>
        <p className="placeholder">
          DRAG XLS / XLSX FILE HERE <br /> OR <span>BROWSE</span>
        </p>
        <input
          value=""
          style={{ visibility: "hidden", opacity: 0 }}
          accept={accept}
          ref={inputRef}
          type="file"
          onChange={(e) => handler(e.target.files, e)}
        />
      </FileDrop>
    </Container>
  );
}

const Container = styled.div`
    width: 320px;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 3px rgba(51, 46, 58, 0.1));
    background-color: #ffffff;
    //padding: 24px;
    display: flex;
    flex-direction: column;
    
    h3 {
      font-size: 14px;
      letter-spacing: 3px;
      line-height: 26px;
      color: #3d4852;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }

    .file-drop {
      background-color: aliceblue;
      width: 100%;
      height: 60px;
      border-radius: 20px;
      filter: drop-shadow(0px 4px 3px rgba(51, 46, 58, 0.1));
      margin-top: auto;
      border: 1px dashed #688ee8;

      & .file-drop-target {
        height: 100%;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        flex-direction: column;

        &.file-drop-dragging-over-target {
          opacity: 0.3;
        }

        input[type="file"] {
          width: 0;
          height: 0;
        }
      }

      .placeholder {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 26px;
        background-color: aliceblue;
        color: #3d4852;
        font-weight: 400;
        text-align: center;
          margin-top: 4px;

        span {
          color: #688ee8;
          cursor: pointer;
        }
      }
    }
  }
`
