import React from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'

import { AgentServices } from '../../components'

export const DefaultAgentServicesView: React.FC = () => {
  return (
    <Container fluid={true} className="mt-3">
      <h4>Default Agent Services</h4>

      <Row className="my-3">
        <Col xs={3}>
          <Card>
            <CardBody style={{ minHeight: '470px' }}>
              <AgentServices />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
