import { withAuthenticationRequired } from '@auth0/auth0-react'
import { UserActionsList } from '../../components/user/UserActionsList'
import Loading from '../../components/Loading'

// actions history for all users
const ListActionsHistory = () => <UserActionsList title={'User Actions Log'}/>

export default withAuthenticationRequired(ListActionsHistory, {
  onRedirecting: () => <Loading />,
})
