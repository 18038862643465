import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import React, { useState } from 'react'
import apiCall from '../lib/data/apiCall'
import styled from "styled-components";

const statesList = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID',
    'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS',
    'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK',
    'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV',
    'WI', 'WY'
];

export const StateCitySearch = ({onSelectState, onSelectCity, initialState, label}) => {
    const [curState, setCurState] = useState(initialState)
    const [, setCurCity] = useState('')

    const searchFn = async (input) => {
        console.log(input)
        let qry = `state_id.keyword:${curState} AND city_ascii:${input}*`
        let result = await apiCall(`/search/uscities?q=${qry}`)
        if (result && result.count) {
            return result.items.map(r => {
                return {
                    value: r.id,
                    label: `${r?.city_ascii || ''}`,
                    data: r?.city_ascii
                }
            })
        }
        return []
    }

    const onCityChange = (selected) => {
        onSelectCity(selected.data)
    }
    const onStateChange = (state) => {
        setCurState(state)
        setCurCity('')
        onSelectState(state)
    }

    return <>
        <SearchContainer>
            <Row>
                { label && <Label>{label}</Label> }
                <Col sm={6}>
                    <FormGroup>
                        <Label for="formAssignCityState">State</Label>
                        <Input
                            type="select"
                            id="formAssignCityState"
                            value={curState}
                            onChange={(e) => onStateChange(e.target.value)}
                        >
                            {statesList.map((stateCode, index) => (
                                <option key={index} value={stateCode}>
                                    {stateCode}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="formAssignCity">City</Label>
                        <AsyncSelect
                            id="formAssignCity"
                            cacheOptions defaultOptions
                            loadOptions={searchFn}
                            isClearable={true}
                            placeholder={'Search'}
                            onChange={onCityChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </SearchContainer>
    </>
}

const SearchContainer = styled(Container)`
    border: 1px solid lightgrey;
    padding: 5px;
    border-radius: 4px;
`
