import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Chance } from 'chance'
import { getSignedUrl, s3upload } from '../../lib/data/bluebidData'

/**
 * @deprecated use ChangeBrandingPhotoModal
 */
const ChangeTeamBrandingPhotoModal = ({ isOpen, toggleModal, license, onUploadSuccess, onUploadError }) => {
  const chance = new Chance()

  const [selectedImage, setSelectedImage] = useState(undefined)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }

  const fileExt = file => file.type.split('/').pop().trim()

  const assetName = (file) => {
    const ext = fileExt(file)
    const uuid = chance.guid({ version: 4 })
    const assetName = `branding_${license.groupId}_${uuid}.${ext}`
    return assetName
  }

  const doUpload = (file) => new Promise((resolve, reject) => {
    const name = assetName(file)
    
    getSignedUrl(file.name, file.type, name)
      .then((data) => {

        if (!data.signedUrl) {
          reject(data)
          return
        }

        return s3upload(data.signedUrl, file.type, file, () => {})
      }).then(res => {
        if (res) {
            resolve(res)
        } else {
            reject(res)
        }
      })
  })

  const handleUpload = () => {
    doUpload(selectedImage)
      .then(res => {
        setSelectedImage(undefined)
        toggleModal()
        onUploadSuccess(res)
      })
      .catch(err => {
        onUploadError(err)
      })
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Change Team Branding Photo</ModalHeader>
      <ModalBody>
        <input type="file" onChange={handleFileChange} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        <Button color="primary" disabled={!selectedImage} onClick={handleUpload}>Upload</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangeTeamBrandingPhotoModal