import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { getPropertyById } from '../lib/data/bluebidData'
import { getEnv } from '../config'

type Property = {
    propertyId: string
    address: string
} | null

type PropertySummaryCardProps = {
    property?: Property
    propertyId?: string
}

const PropertySummaryCard: React.FC<PropertySummaryCardProps> = ({ property, propertyId }) => {
    const [fetchedProperty, setFetchedProperty] = useState<Property>(null)
    
    useEffect(() => {
        if (!property && propertyId) {
            getPropertyById(propertyId).then(property => {
                setFetchedProperty(property);
            });
        } else if (property) {
            setFetchedProperty(property)
        }
    }, [property, propertyId])


    return fetchedProperty && (
    <Card>
        <CardBody>
            <CardTitle tag="h5">{fetchedProperty.address}</CardTitle>
            <a href={`${getEnv().appHost}/claimedProperty/${propertyId}`} target="_blank" rel="noreferrer" className="text-decoration-none">
                <FontAwesomeIcon icon={faExternalLinkAlt} /> Open (Bluebid)
            </a>
            <a href={`/admin/propertyedit/${propertyId}`} target="_blank" rel="noreferrer" className="text-decoration-none ml-3">
                <FontAwesomeIcon icon={faExternalLinkAlt} /> Edit (Admin)
            </a>
            <Table striped className="mt-3">
                <tbody>
                    <tr>
                        <td width="200"><strong>Property ID</strong></td>
                        <td>{propertyId}</td>
                    </tr>
                </tbody>
            </Table>
        </CardBody>
    </Card>
  );
}

export default PropertySummaryCard;