// Autocomplete.js
import React, { Component } from 'react';
import styled from 'styled-components';

class GoogleAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this.clearSearchBox.bind(this);
        this.setSearchText = this.setSearchText.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
    }

    componentDidMount({ map, mapApi, initialAddress } = this.props) {
        const options = {
            componentRestrictions: { country: ["us"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
        };
        this.autoComplete = new mapApi.places.Autocomplete(
            this.searchInput,
            options,
        );
        this.autoComplete.addListener('place_changed', this.onPlaceChanged);
        this.autoComplete.bindTo('bounds', map);
        this.searchInput.value = initialAddress || ''
    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }

    onPlaceChanged = ({ map, onSelect } = this.props) => {
        const place = this.autoComplete.getPlace();

        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        onSelect(place);
        this.searchInput.blur();
    }

    setSearchText(text) {
        this.searchInput.value = text;
    }

    clearSearchBox() {
        this.searchInput.value = '';
    }

    render() {
        return (
            <Wrapper>
                <input
                    className="form-control search-input"
                    ref={(ref) => {
                        this.searchInput = ref;
                    }}
                    type="text"
                    onFocus={this.clearSearchBox}
                    placeholder="Enter an address"
                />
            </Wrapper>
        );
    }
}

export default GoogleAutoComplete;

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align:center;
`;
