import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, CardTitle, Col, Container, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { User } from '@bluebid-sdk/core'
import { NewPrimaryButton, NewSecondaryButton, BlockUI, PhoneInput, DividerLine } from 'bb-lib-desktop'

import {
  deleteAccount,
  getAvailableRoles,
  getUser,
  getUserProperties,
  otpCheck,
  otpOverride,
  toggleBlockUser,
  updateProfileAPI,
} from '../lib/data/bluebidData'
import { errorToast, successToast } from '../utils/common'
import { TagManager } from '../lib/gtm/TagManager'
import confirm from './Confirm'
import { ProfileComponent } from '../views/Profile'
import { AdminToggleActionButton, AdminWarningActionButton } from './adminActions/common'
import { UserActionsList } from './user/UserActionsList'
import { UserReferrals } from './user/UserReferrals'
import { UserMessages } from './user/UserMessages'
import { UserNotifications } from './user/UserNotifications'
import { UserAssets } from './user/UserAssets'
import { UserRoles } from './UserRoles'
import { AuthContext } from '../constants/context'
import { EditAgentContentModal } from './EditAgentContentModal'
import { ListUserListingAlerts } from '../views/admin/adminComponents/ListUserListingAlerts'
import { GREEN, LightGrey } from '../constants/colors'
import { CollapseableCard } from './CollapseableCard'
import UserClaimsList from './user/UserClaimsList'
import { DateValueFormatter, getSubscriberLogo } from './grid/GridRenderers'
import { relativeDate } from '../lib/utils'
import moment from 'moment'
import { InquiriesGridComponent } from './inquiries'

/**
 * @todo import from bb-lib-desktop once updated with lib/utils.ts functions
 */
const isAgent = (user: User) => hasRole(user, 'agent') || hasRole(user, 'teamOwner')
const hasRole = (user: User, role: string) => (user?.roles ?? []).includes(role)

enum CollapseKeys {
  UserActionHistory = 'UserActionHistory',
  ListingAlerts = 'ListingAlerts',
  Referrals = 'Referrals',
  Claims = 'Claims',
  Messages = 'Messages',
  Notifications = 'Notifications',
  Assets = 'Assets',
  Inquiries = 'Inquiries',
}

const initialState = {
  [CollapseKeys.UserActionHistory]: false,
  [CollapseKeys.ListingAlerts]: true,
  [CollapseKeys.Referrals]: true,
  [CollapseKeys.Claims]: true,
  [CollapseKeys.Messages]: true,
  [CollapseKeys.Notifications]: true,
  [CollapseKeys.Assets]: true,
  [CollapseKeys.Inquiries]: true,
}

const shortDate = (dte) => {
  const tz = moment.tz.guess()
  const d = moment.tz(dte, tz)
  return d.format('MM DD YY hh:mm:ss a')
}


export const UserProfile = () => {
  const { userId } = useParams()

  const { bluebidUser } = useContext(AuthContext)
  const editRoles = bluebidUser?.roles?.includes('admin')

  const [user, setUser] = useState<any>()
  const [, setUserPhoto] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState<any>()
  const [otpRecord, setOtpRecord] = useState<{ id: string, verified: boolean }>()
  const [validate, setValidate] = useState({ firstName: '', lastName: '', phone: '' })
  const [image, setImage] = useState<any>()
  const [first, setFirst] = useState(true)
  const [roles, setRoles] = useState([])
  const [userBlockState, setUserBlockState] = useState({ value: undefined, label: 'Checking...' })
  const [availableRoles, setAvailableRoles] = useState([])
  const [editAgentModal, setEditAgentModal] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState<{ [key: string]: boolean }>(initialState)
  const navigate = useNavigate()

  const toggleCollapse = (key: CollapseKeys) => {
    setIsCollapsed((prevState) => ({ ...prevState, [key]: !prevState[key] }))
  }

  const editAgentModalToggle = () => {
    setEditAgentModal(!editAgentModal)
  }

  const updateUserBlockState = (value, label?: any) => {
    setUserBlockState({ value, label: label || (value ? 'Unblock user' : 'Block user') })
  }

  useEffect(() => {
    if (first) {
      setFirst(false)
    }

    getAvailableRoles().then(setAvailableRoles)

    if (userId) {
      getUserProperties(userId, ['blocked']).then((result) => updateUserBlockState(result?.blocked === true))

      getUser(userId, ['roles']).then((user) => {
        setUser(user)
        setFirstName(user?.firstName)
        setLastName(user?.lastName)
        setEmail(user?.email)
        setPhone(user?.phone)
        setUserPhoto({ preview: user?.picture || 'https://images.bluebid.io/profile_picture.png', raw: undefined })
        setImage({ preview: user?.picture || 'https://images.bluebid.io/profile_picture.png', raw: undefined })
        setRoles(user?.roles)
        setIsLoading(false)

        if (user?.phone) {
          otpCheck({ userId, phone: user?.phone }).then((res) => {
            setOtpRecord(res)
          })
        }
      })
    }
  }, [userId])

  const getUserFullName = (user) =>
    `${user?.firstName ? user.firstName : 'Bluebid'} ${user?.lastName ? user.lastName : 'User'}`

  const validateFirstName = (firstName) => {
    setFirstName(firstName)
    let checkValidation = { ...validate }
    if (!(firstName.length > 2)) {
      checkValidation.firstName = 'has-danger'
      setValidate(checkValidation)
    } else {
      checkValidation.firstName = ''
      setValidate(checkValidation)
    }
  }

  const validateLastName = (lastName) => {
    setLastName(lastName)
    let checkValidation = { ...validate }
    if (!(lastName.length > 2)) {
      checkValidation.lastName = 'has-danger'
      setValidate(checkValidation)
    } else {
      checkValidation.lastName = ''
      setValidate(checkValidation)
    }
  }

  const validateMobile = (value) => {
    const mobile = value.replace(/\D/g, '')
    setPhone(mobile)
    setValidate((state) => ({
      ...state,
      phone: mobile.length === 0 || mobile.length === 10 ? '' : 'has-danger',
    }))
  }

  const submitForm = (e) => {
    e.preventDefault()
    TagManager.dataLayer({
      dataLayer: {
        event: 'profile_updated',
        category: 'Profile',
        action: 'User updated profile',
        label: 'profile-updated',
      },
    })

    setIsLoading(true)
    const userData = { roles } as any

    if (firstName !== user.firstName) {
      userData.firstName = firstName
    }

    if (lastName !== user.lastName) {
      userData.lastName = lastName
    }

    if (phone !== user.phone) {
      if (phone.length === 0) {
        if (user.phone) userData.clear = 'phone'
      } else {
        userData.phone = phone
      }
    }

    updateProfileAPI(user.id, userData).then((user) => {
      if (user) {
        successToast(`profile updated successfully!`)
      } else {
        errorToast(`something went wrong!`)
      }
      setIsLoading(false)
    })
  }

  const handleUserBlockToggle = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (user?.sub && userBlockState.value != null) {
      const newUserBlockState = !userBlockState.value
      updateUserBlockState(undefined, newUserBlockState ? 'Blocking...' : 'Unblocking...')

      toggleBlockUser({ sub: user.sub, block: newUserBlockState }).then((res) => {
        // TODO Investigate further: it seems that Auth0 needs more time to update the user's blocked property
        // if (res.statusCode === 200) setTimeout(updateUserBlockedProperty, 500)

        updateUserBlockState(newUserBlockState)
      })
    }
  }

  const handleRoleChange = (roleName) => (checked) => {
    if (checked) {
      setRoles((prevRoles) => [...prevRoles, roleName])
    } else {
      setRoles((prevRoles) => prevRoles.filter((role) => role !== roleName))
    }
  }

  const togglePhoneVerification = () => {
    const toggledValue = otpRecord?.verified ? false : true

    otpOverride({
      userId: user.id,
      phone: user.phone,
      verified: toggledValue
    })
    .then(setOtpRecord)
  }

  return (
    <>
      <BlockUI blocking={isLoading} />

      {!isLoading && (
        <ProfileContainer>
          <Card className="mb-4">
            <CardTitle style={{ padding: '10px 0 0 10px' }}>
              <h3>User Info</h3>
            </CardTitle>
            <div>
              <Col md>
                <Form onSubmit={(e) => submitForm(e)}>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ProfileInput
                          type="email"
                          name="email"
                          id="email"
                          disabled
                          value={email}
                          placeholder="Email Address"
                        />
                      </FormGroup>
                      <FormGroup>
                        <ProfileInput
                          type="text"
                          name="firstname"
                          id="firstname"
                          onChange={(e) => validateFirstName(e.target.value)}
                          value={firstName}
                          placeholder="First Name"
                          invalid={validate.firstName === 'has-danger'}
                        />
                        <FormFeedback>First name required at least 2 characters!</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <ProfileInput
                          type="text"
                          name="lastname"
                          id="lastname"
                          onChange={(e) => validateLastName(e.target.value)}
                          value={lastName}
                          placeholder="Last Name"
                          invalid={validate.lastName === 'has-danger'}
                        />
                        <FormFeedback>Last name required at least 2 characters!</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <PhoneInput
                            className={validate.phone === 'has-danger' ? 'is-invalid' : ''}
                            style={{ flexGrow: 1 }}
                            name="phone"
                            id="phone"
                            initial={phone}
                            changehandler={(e) => validateMobile(e.phone)}
                          />
                          <div>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className={'ml-2 clickable'}
                              style={{
                                fontSize: '25px',
                                color: otpRecord?.verified ? GREEN : LightGrey,
                              }}
                              onClick={togglePhoneVerification}
                            />
                          </div>
                        </div>
                        <FormFeedback>Phone number required only 10 digits!</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      {/* NOTE: only difference below is the mr-0 (margin right) */}
                      {/*<NewSecondaryButton style={{ minWidth: '120px', marginBottom: '5px' }} onClick={() => close()}>*/}
                      {/*  Close*/}
                      {/*</NewSecondaryButton>*/}
                      {/*<NewSecondaryButton*/}
                      {/*  style={{ minWidth: '120px', marginBottom: '5px' }}*/}
                      {/*  onClick={() => gotoClaims()}*/}
                      {/*>*/}
                      {/*  Show Claims*/}
                      {/*</NewSecondaryButton>*/}

                      <DeleteAccount user={user} />

                      <AdminToggleActionButton
                        style={{ minWidth: '120px', marginBottom: '5px' }}
                        onClick={handleUserBlockToggle}
                        disabled={userBlockState.value === undefined}
                        type={userBlockState.value ? 'secondary' : ''}
                      >
                        {userBlockState.label}
                      </AdminToggleActionButton>

                      <DividerLine height={'60px'} color={'#fff'} />

                      <NewPrimaryButton style={{ minWidth: '120px', marginBottom: '5px' }}>
                        Save Profile
                      </NewPrimaryButton>
                    </Col>

                    <Col md={2}>
                      <UserRoles
                        allRoles={availableRoles}
                        userRoles={roles}
                        editable={editRoles}
                        onChange={handleRoleChange}
                      />
                    </Col>

                    <Col md={2}>
                      <NewSecondaryButton
                        style={{ minWidth: '120px', marginBottom: '5px' }}
                        disabled={!isAgent(user)}
                        onClick={editAgentModalToggle}
                      >
                        Edit Agent Info
                      </NewSecondaryButton>
                      <EditAgentContentModal isOpen={editAgentModal} toggle={editAgentModalToggle} userId={userId} />
                    </Col>

                    <Col md={3}>
                      <ProfileImageContainer>
                        <img src={getSubscriberLogo(user.sub)} width={32} height={32} />
                      </ProfileImageContainer>
                      <br/>

                      <div>
                        <GeoLabel>Created:</GeoLabel>
                        <GeoData>{shortDate(user.createdAt)}</GeoData>
                      </div>
                      <div>
                        <GeoLabel>Last Update:</GeoLabel>
                        <GeoData>{shortDate(user.modifiedAt)}</GeoData>
                      </div>
                      <div>
                        <GeoLabel>Last Login:</GeoLabel>
                        <GeoData>{shortDate(user.lastLoginAt)}</GeoData>
                      </div>
                      <div>
                        <GeoLabel>City:</GeoLabel>
                        <GeoData>{user?.geoip?.cityName}</GeoData>
                      </div>
                      <div>
                        <GeoLabel>Country:</GeoLabel>
                        <GeoData>{user?.geoip?.countryName}</GeoData>
                      </div>
                      <div>
                        <GeoLabel>Location:</GeoLabel>
                        <GeoData>({user?.geoip?.latitude}, {user?.geoip?.longitude})</GeoData>
                      </div>

                    </Col>

                  </Row>
                </Form>
              </Col>
            </div>
          </Card>

          <CollapseableCard
            title="User Action History"
            isCollapsed={isCollapsed[CollapseKeys.UserActionHistory]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.UserActionHistory)}
          >
            <UserActionsList userId={userId} title="" />
          </CollapseableCard>

          <CollapseableCard
            title="Claims"
            isCollapsed={isCollapsed[CollapseKeys.Claims]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.Claims)}
          >
            <UserClaimsList userId={user.id} />
          </CollapseableCard>

          <CollapseableCard
            title="Referrals"
            isCollapsed={isCollapsed[CollapseKeys.Referrals]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.Referrals)}
          >
            <UserReferrals user={user} />
          </CollapseableCard>

          <CollapseableCard
            title="Messages"
            isCollapsed={isCollapsed[CollapseKeys.Messages]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.Messages)}
          >
            <UserMessages user={user} />
          </CollapseableCard>

          <CollapseableCard
            title="Notifications"
            isCollapsed={isCollapsed[CollapseKeys.Notifications]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.Notifications)}
          >
            <UserNotifications user={user} />
          </CollapseableCard>

          <CollapseableCard
            title="Listing Alerts"
            isCollapsed={isCollapsed[CollapseKeys.ListingAlerts]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.ListingAlerts)}
          >
            <ListUserListingAlerts userId={userId} header={undefined} />
          </CollapseableCard>

          <CollapseableCard
            title="Assets"
            isCollapsed={isCollapsed[CollapseKeys.Assets]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.Assets)}
          >
            <UserAssets user={user} />
          </CollapseableCard>

          <CollapseableCard
            title="Inquiries"
            isCollapsed={isCollapsed[CollapseKeys.Inquiries]}
            toggleCollapse={() => toggleCollapse(CollapseKeys.Inquiries)}
          >
            <InquiriesGridComponent
              hideHeader={true}
              query={`contactSheet.userId.keyword:${user.id}`}
              showReferralInfo={true}
            />
          </CollapseableCard>
        </ProfileContainer>
      )}
    </>
  )
}

export default withAuthenticationRequired(ProfileComponent, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (
    <div>
      <BlockUI blocking={true} />
    </div>
  ),
})

const DeleteAccount = ({ user }) => {
  const navigate = useNavigate()
  const onActionClick = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    let result = await confirm({
      title: 'Are you sure you want to delete this account?',
      message: 'All outstanding Bluebids will be revoked.\nAny claimed homes will become UNCLAIMED.',
      cancelText: 'Cancel',
      cancelColor: 'primary',
      confirmColor: 'danger',
      confirmText: 'Yes, delete',
    })
    if (result) {
      await deleteAccount(user.id)
      successToast('Account has been deleted')
      navigate('/admin/list-users')
    }
  }

  return (
    <AdminWarningActionButton style={{ minWidth: '120px', marginBottom: '5px' }} onClick={(e) => onActionClick(e)}>
      Delete account
    </AdminWarningActionButton>
  )
}

const ProfileInput = styled(Input)`
  font-size: 10pt;
`

const ProfileImageContainer = styled.div`
  padding: 0;
  border-radius: 5px;
`

const ProfilePicture = styled.img`
  width: 60px;
  height: 100%;
  border-radius: 5px;
`

const ProfileContainer = styled(Container)`
  margin-top: 20px;
`

const GeoLabel = styled.span`
    width: 75px;
    font-weight: bold;
    display: inline-block;
`
const GeoData = styled.span`
  
`
