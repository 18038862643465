import React, { useMemo } from 'react'
import styled from 'styled-components'

import { GridListHeader } from '../../views/admin/GridListHeader'
import { GridContainerStyle, GridStyle } from '../../components/CommonGrid'
import { GridContainerProps } from './grid-container-props.type'
import { GridComponent } from './grid.component'

export const GridContainerComponent: React.FC<GridContainerProps> = (props) => {
  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])

  return (
    <GridContainer className="grid-container">
      {!!props?.title && <GridListHeader title={props.title}></GridListHeader>}
      {props?.subHeader}

      <div style={props?.title ? containerStyle : gridStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          <GridComponent {...props} />
        </div>
      </div>
    </GridContainer>
  )
}

const GridContainer = styled.div`
  height: 100%;
`
