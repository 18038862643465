import React, { useContext } from 'react'
import { AuthContext } from '../../constants/context'
import styled from 'styled-components'
import { MessageBubble } from './MessageBubble'
import { MessageType } from '../../constants/messsages'
import { MessageDate } from './MessageDate'
import { sizes } from '../../utils/responsive'
import { OfferDetailsLabel, OfferLine } from '../detail/common'
import { Col, Row } from 'reactstrap'
import { BubbleParagraph } from './MessageNoteBubble'
import { MessageReadUnread } from './MessageReadUnread'

export const OfferFinalBubble = ({ parent, property, msg, sender, isSender}) => {
    const { bluebidUser: user } = useContext(AuthContext)
    const isPropertyOwner = property?.ownerId === user?.id

    const actionResponse = (msgType) => {
        let actor = isPropertyOwner ? (isSender ? 'you' : 'buyer') : (isSender ? 'you' : 'owner')
        switch (msgType) {
            case MessageType.Offer.Rejected.title:
                return <p>Bluebid was rejected by {actor}</p>

            case MessageType.Offer.Accepted.title:
                return <p>Moving forward! A licensed real estate agent will contact you to begin a private sale
                            process.</p>

            case MessageType.Offer.Revoked.title:
                return <p>Bluebid was revoked by {actor}</p>
        }
    }

    return (
        <FinalMessageContainer>
          <MessageBubble>
            <FinalAction className={`${isSender ? 'from-me' : 'from-them'} bubble-div`}>
                <Row>
                  <Col xs>
                    <BubbleParagraph>
                      <OfferLine>
                          <OfferDetailsLabel><strong>{`${sender?.firstName} ${sender?.lastName} <${sender?.email}>`}</strong></OfferDetailsLabel>
                      </OfferLine>
                      {actionResponse(msg.messageType)}
                      <DateStamp isSender={isSender}>
                        <MessageDate msg={msg} />
                        <MessageReadUnread isRead={msg?.isRead} />
                      </DateStamp>
                    </BubbleParagraph>
                  </Col>
                </Row>
            </FinalAction>
          </MessageBubble>
        </FinalMessageContainer>
    )
}

const DateStamp = styled.p`
  color: ${(props) => props.isSender ? 'white' : 'black'};
  margin-bottom: 0;
`
const FinalMessageContainer = styled.div`
  .from-me::before {
    //border-right: 1rem solid #FF1716 !important;
  }
`
const FinalAction = styled.div`
  width: 40% !important;

  @media (max-width: ${sizes.tablet}) {
    width: 45% !important;
  }
  @media (max-width: ${sizes.mobileXL}) {
    width: 68% !important;
  }
  @media (max-width: ${sizes.mobileM}) {
    width: 78% !important;
  }
`
