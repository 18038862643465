import React, { useContext, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import { AgGridReact } from '@ag-grid-community/react'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons'

import { Property, License } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { Datasource, NewPrimaryButton, api, errorPopup, getProperty, successPopup } from 'bb-lib-desktop'

import { getAllLicenses, deleteInquiry, publishInquiry, restoreInquiry } from '../../lib/data/bluebidData'
import { getApiHost } from '../../config'
import { downloadExport } from '../../lib/data/exporting'
import { AuthContext } from '../../constants/context'
import { errorToast, successToast } from '../../utils/common'
import { GridContainerStyle, GridNoHeaderContainerStyle, GridStyle } from '../CommonGrid'
import { GridListHeader, HeaderActionIcon } from '../../views/admin/GridListHeader'
import { SendInquiryModal } from '../adminActions/SendInquiryModal'
import { InquiryResultsModal } from './../adminActions/InquiryResultsModal'
import { GridComponent } from '../grid'
import { inquiriesGridColumns } from './inquiries-grid-columns.fn'
import { useProperty } from '../../views/admin/hooks/useProperty'
import { PropertyEditModal } from '../../views/admin/propertyedit/PropertyEditModal'

/**
 * Used for delayed data refreshes to allow data to sync
 */
const delayAction = (fn: () => void, delay = 2000) => {
  setTimeout(fn, delay)
}

export const InquiriesGridComponent: React.FC<{
  title?: string
  licenseId?: string
  query?: string
  showReferralInfo?: boolean
  agentName?: string
  disableExport?: boolean
  condensed?: boolean
  hideHeader?: boolean
  onSend?: () => void
}> = ({
  title,
  licenseId,
  query: queryOverride,
  showReferralInfo,
  agentName,
  disableExport,
  condensed,
  hideHeader,
  onSend,
}) => {
  const { getToken } = useContext(AuthContext)
  const gridRef = useRef<AgGridReact>()

  const containerStyle = useMemo(() => (condensed ? GridNoHeaderContainerStyle : GridContainerStyle), [])
  const gridStyle = useMemo(() => GridStyle, [])

  const [loaded, setLoaded] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [updatingMlsStatuses, setUpdatingMlsStatuses] = useState(false)
  const [showDeleted, setShowDeleted] = useState(false)
  const [licenseLookup, setLicenseLookup] = useState<License[]>([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [rowsChecked, setRowsChecked] = useState<{ [inquiryId: string]: boolean }>({})
  const [showSendInquiryModal, setShowSendInquiryModal] = useState(false)
  const [showResultsModal, setShowResultsModal] = useState(false)
  const [selectedInquiryId, setSelectedInquiryId] = useState('')
  const [selectedPropertyId, setSelectedPropertyId] = useState('')
  const [selectedAddress, setSelectedAddress] = useState('')

  const [selectedResults, setSelectedResults] = useState({})

  const [showEditPropertyModal, setShowEditPropertyModal] = useState(false)
  const [editProperty, setEditProperty] = useState<Property>()
  const { owner, refetchProperty } = useProperty(editProperty?.propertyId)

  const toggleEditPropertyModal = () => setShowEditPropertyModal(!showEditPropertyModal)

  const getCheckedInquiryIds = () => Object.keys(rowsChecked).filter((inquiryId) => rowsChecked[inquiryId])

  const query = queryOverride
    ? `${queryOverride}${showDeleted ? ` AND status.keyword:deleted` : ` AND status.keyword:(NOT deleted)`}`
    : showDeleted
      ? `status.keyword:deleted`
      : `status.keyword:(NOT deleted)`
  const disableMlsAction = !getCheckedInquiryIds().length

  const datasource = useMemo(
    () =>
      new Datasource<{ id: string }>({
        index: 'inquiries',
        adminSearch: true,
        fields: 'mls,detail',
        query,
      }),
    [query]
  )

  useEffect(() => {
    getAllLicenses().then((allLicenses) => {
      setLicenseLookup(allLicenses)
      setLoaded(true)
    })
  }, [])

  /**
   * Effect to keep "Select All" checkbox in sync with checked rows
   */
  useEffect(() => {
    if (!datasource?.loadedRowData?.length) {
      return
    }

    const rows = datasource?.loadedRowData

    const checkedRows = rows.filter((row) => rowsChecked[row.id])

    if (rows.length === checkedRows.length && !selectAllChecked) {
      setSelectAllChecked(true)
    } else if (rows.length !== checkedRows.length && selectAllChecked) {
      setSelectAllChecked(false)
    }
  }, [datasource?.loadedRowData, rowsChecked])

  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const publish = (inquiryId: string, propertyId: string, address: string) => {
    if (propertyId) {
      getProperty(propertyId).then((property) => {
        if (property.status === 'error') {
          errorPopup('Could not find property; may have been deleted or unclaimed.')
          return
        }
        setSelectedInquiryId(inquiryId)
        setSelectedPropertyId(propertyId)
        setSelectedAddress(address)
        setShowSendInquiryModal(true)
      })
    } else {
      setSelectedInquiryId(inquiryId)
      setSelectedPropertyId(undefined)
      setSelectedAddress(address)
      setShowSendInquiryModal(true)
    }
  }

  const toggleShowDeleted = () => {
    setShowDeleted(!showDeleted)
    gridRef.current.api.refreshServerSide()
  }

  const handleContactSheetUpdated = () => {
    delayAction(onBtnRefresh)
  }

  const toggleSelectAllCheckbox = (checked: boolean) => {
    const newRowChecks = {}

    if (datasource?.loadedRowData?.length && checked) {
      datasource.loadedRowData.forEach((row) => {
        newRowChecks[row.id] = true
      })
    }

    setSelectAllChecked(checked)
    setRowsChecked(newRowChecks)
  }

  const toggleRowCheckbox = (inquiryId: string, checked: boolean) => {
    setRowsChecked((prevState) => {
      const newState = { ...prevState }

      newState[inquiryId] = checked

      return newState
    })
  }

  const onBtnExport = async () => {
    let exportUrl = `${getApiHost()}/inquiries/export?${datasource.exportQueryParams}`

    if (licenseId) {
      exportUrl = `${exportUrl}&licenseId=${licenseId}&showReferralInfo=${!!showReferralInfo}`
    }

    try {
      downloadExport({
        getToken,
        exportUrl,
      })
    } catch (error) {
      console.error('error downloading file:', error)
    }
  }

  const del = (id: string) =>
    deleteInquiry({ id })
      .then(() => {
        successToast('Inquiry deleted')
        delayAction(onBtnRefresh)
      })
      .catch((e) => {
        errorToast('Error deleting inquiry')
        console.error('error deleting inquiry', e)
      })

  const restore = (id: string) =>
    restoreInquiry({ id })
      .then(() => {
        successToast('Inquiry restored')
        delayAction(onBtnRefresh)
      })
      .catch((e) => {
        errorToast('Error deleting inquiry')
        console.error('error deleting inquiry', e)
      })

  const showResults = (results) => {
    console.log('setting to', results)
    setSelectedResults(results)
    setShowResultsModal(true)
  }

  const columnDefs = useMemo(
    () =>
      inquiriesGridColumns({
        setEditProperty,
        toggleEditPropertyModal,
        publish,
        showResults,
        restore,
        del,
        onBtnRefresh,
        updatingMlsStatuses,
        licenseLookup,
        selectAllChecked,
        rowsChecked,
        toggleRowCheckbox,
        toggleSelectAllCheckbox,
        agentName,
        showReferralInfo,
        setUpdatingMlsStatuses,
      }),
    [licenseLookup, updatingMlsStatuses, rowsChecked, selectAllChecked, agentName]
  )

  const handleOnPropertyEdit = async () => {
    refetchProperty()
    setTimeout(() => {
      onBtnRefresh()
    }, 2000)
  }

  return (
    <>
      {/*property edit page view*/}
      {!hideHeader && condensed && (
        <GridListHeader size={'sm'} title={title || 'Inquiries'} onRefresh={onBtnRefresh}></GridListHeader>
      )}

      {/*inquiries list view*/}
      {!hideHeader && !condensed && (
        <GridListHeader title={title || 'Inquiries'} onRefresh={onBtnRefresh}>
          {!disableExport && <HeaderActionIcon icon={faFileArchive} onClick={() => onBtnExport()} />}
        </GridListHeader>
      )}

      {!hideHeader && !condensed && (
        <div className="mb-3">
          <NewPrimaryButton
            className="float-end"
            disabled={disableMlsAction || updatingMlsStatuses}
            onClick={async () => {
              setUpdatingMlsStatuses(true)

              const response = await api('/inquiries/updateMlsStatuses', {
                method: 'post',
                body: {
                  ids: getCheckedInquiryIds(),
                  forceUpdate,
                },
              })

              if (!isSuccessResponse(response)) {
                errorToast(response.errorMessage)

                return
              }

              successPopup('MLS statuses updated!')
              setRowsChecked({})

              delayAction(() => {
                setUpdatingMlsStatuses(false)
                onBtnRefresh()
              })
            }}
          >
            Check MLS Status
          </NewPrimaryButton>
          <FormGroup switch className="float-end mt-2 mr-2" style={{ minHeight: 'auto' }}>
            <Input
              type="switch"
              onChange={() => setForceUpdate(!forceUpdate)}
              checked={forceUpdate}
              disabled={disableMlsAction || updatingMlsStatuses}
            />
            <Label check onClick={() => !disableMlsAction && !updatingMlsStatuses && setForceUpdate(!forceUpdate)}>
              Force update
            </Label>
          </FormGroup>
          <FormGroup switch className="float-start mt-2" style={{ minHeight: 'auto' }}>
            <Input type="switch" onChange={toggleShowDeleted} checked={showDeleted} />
            <Label check onClick={toggleShowDeleted}>
              Show deleted inquiries
            </Label>
          </FormGroup>
          <div style={{ clear: 'both' }} />
        </div>
      )}
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          {loaded && (
            <GridComponent
              gridRef={gridRef}
              sessionKey="inquiries"
              columnDefs={columnDefs}
              serverSideDatasource={datasource}
              suppressRowClickSelection={true}
            />
          )}
        </div>
      </div>
      <SendInquiryModal
        isOpen={showSendInquiryModal}
        propertyId={selectedPropertyId}
        address={selectedAddress}
        inquiryId={selectedInquiryId}
        onSend={async () => {
          return publishInquiry({ id: selectedInquiryId }).then(() => {
            const refresh = new Promise((r) => {
              setTimeout(() => {
                getAllLicenses().then(setLicenseLookup).then(onBtnRefresh).then(r)
                if (onSend) {
                  onSend()
                }
              }, 2000)
            })

            return refresh
          })
        }}
        onClose={() => setShowSendInquiryModal(false)}
        onContactSheetUpdated={handleContactSheetUpdated}
      />

      <InquiryResultsModal
        isOpen={showResultsModal}
        results={selectedResults}
        onClose={() => setShowResultsModal(false)}
      />

      <PropertyEditModal
        isOpen={showEditPropertyModal}
        onClose={toggleEditPropertyModal}
        onEdit={handleOnPropertyEdit}
        property={editProperty}
        owner={owner}
      />
    </>
  )
}
