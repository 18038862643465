import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import { successToast, errorToast } from '../utils/common'
import { EditAgentContent } from './EditAgentContent'

export const EditAgentContentModal: React.FC<{
  isOpen: boolean
  toggle: () => void
  userId: string
}> = ({ isOpen, toggle, userId }) => {
  const onUserError = (errorMessage: string) => {
    errorToast(errorMessage)
    toggle()
  }

  const onSaveError = (errorMessage: string) => {
    errorToast(errorMessage)
  }

  const onSaveSuccess = () => {
    successToast('Agent info saved!')
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>Edit Agent Info</ModalHeader>
      <ModalBody>
        <EditAgentContent
          userId={userId}
          onUserError={onUserError}
          onSaveSuccess={onSaveSuccess}
          onSaveError={onSaveError}
        />
      </ModalBody>
    </Modal>
  )
}
