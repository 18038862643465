import React from 'react'
import { faAddressCard, faBell, faCommentDots, faDollarSign, faHome, faShareAlt, faUser, faUsers, faEnvelope, faSearch, faCog } from '@fortawesome/free-solid-svg-icons'
import { Nav } from 'reactstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { SubMenu, TopMenu } from './SubMenu'
import classNames from 'classnames'
import { ADMIN_ID } from '../../constants/context'

const AdminSideBarMenu = ({ sidebarIsOpen, toggle }) => {

    return (
        <SideBar className={classNames({ "is-open": sidebarIsOpen })}>
            <SidebarHeader>
                <HomeLink to={'/'}>
                    <AdminLabel>Bluebid Admin</AdminLabel>
                </HomeLink>
            </SidebarHeader>

            <SideMenu>
                <Nav vertical className='list-unstyled pb-3'>
                    <SubMenu title='Search' icon={faSearch} items={SearchMenu} />

                    <SubMenu title='Homes' icon={faHome} items={HomesMenu} />

                    <TopMenu title='Users' icon={faUser} target={'/admin/list-users'} />

                    <TopMenu title='Inquiries' icon={faEnvelope} target={'/admin/list-inquiries'} />

                    <TopMenu title='Licenses' icon={faUsers} target={'/admin/list-groups'} />

                    <TopMenu title='Messages' icon={faUser} target={'/admin/list-messages'} />

                    <TopMenu title='Zip Codes' icon={faUsers} target={'/admin/list-zip-licenses'} />

                    <TopMenu title='Invitations' icon={faShareAlt} target={'/admin/list-invites'} />

                    {/*<SubMenu title='Bluebids' icon={faDollarSign} items={OffersMenu} />*/}

                    <SubMenu title='System' icon={faCog} items={SystemMenu} />

                    <SubMenu title='Address Management' icon={faAddressCard} items={AddressMenu} />
                </Nav>
            </SideMenu>
        </SideBar>
    )
}

const SearchMenu = [{
    title: 'Search Properties',
    target: '/admin/search-properties',
    }, {
      title: 'Search Users',
      target: '/admin/search-users',
  }
]

const HomesMenu = [{
    title: 'All Homes',
    target: '/admin/list-properties',
    }, {
        title: 'Claim a Property',
        target: '/admin/claim-property'
    }, {
        title: 'Claim Errors',
        target: '/admin/list-claim-errors'
    }, {
        title: 'Unclaimed Homes',
        target: `/admin/userhomes/${ADMIN_ID}`,
    }, {
        title: 'All Assets',
        target: '/admin/list-assets',
  }
]

const AddressMenu = [{
    title: 'All Addresses',
    target: '/admin/list-addresses',
}, {
    title: 'Manage Addresses',
    target: '/admin/addresses',

}]

const OffersMenu = [{
    title: 'Active Bluebids',
    target: '/admin/active-offers',
}, {
    title: 'Inactive Bluebids',
    target: '/admin/inactive-offers',

},]

const SystemMenu = [{
  title: 'Templates',
  target: '/admin/templates',
    },{
      title: 'Default Agent Services',
      target: '/admin/default-agent-services',
    }, {
      title: 'Mobile Verifications',
      target: '/admin/list-otps',
    }, {
      title: 'Notifications Log',
      target: '/admin/list-notifications',
    }, {
      title: 'Admin Change Log',
      target: '/admin/list-admin-history',
    },{
        title: 'User Actions Log',
        target: '/admin/list-actions-history',
    },{
        title: 'Claim Alerts',
        target: '/admin/list-claim-alerts',
}]

const HomeLink = styled(Link)`
  color: white;
  display: inline-block;
  font-size: 1rem;
  margin-left: 10px;
  top: 3px;
  position: relative;
  :hover {
    text-decoration: none;
    color: darkgrey;
  }
`

const MENU_WIDTH = 250

const AdminLabel = styled.div`
  display: inline-block;
  font-size: 1rem;
  top: 3px;
  position: relative;
  height: 39px;
`

const SideBar = styled.div.attrs({
    className: 'side-bar'
})`
  min-width: ${MENU_WIDTH}px;
  max-width: ${MENU_WIDTH}px;
  background: #3c6dd9;
  color: #fff;
  margin-left: -${MENU_WIDTH}px;
  transition: 0.5s;

  &.is-open {
    margin-left: 0;
    transition: all 0.5s;
  }
  
  ul p {
    color: #fff;
    padding: 10px;
  }

  > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
`
const SidebarHeader = styled.div.attrs({
    className: 'sidebar-header'
})`
  background: #3c6dd9;

  h3 {
    color: #fff;
    padding: 1em;
  }
`
const SideMenu = styled.div.attrs({
    className: 'side-menu'
})`
  height: calc(100vh - 130px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
  }
`

export default AdminSideBarMenu

