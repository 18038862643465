import { Col, Row } from 'reactstrap'
import { currencyFormatter } from '../../lib/utils'
import styled from 'styled-components'
import { DisclaimerText, OfferDetailsAmount, OfferDetailsLabel, OfferLine } from '../detail/common'
import { MessageDate } from './MessageDate'
import { OfferCounteredBubble } from './OfferCounteredBubble'
import { OFFERS_MSG_TEXT } from '../../constants/messsages'
import React from 'react'
import { MessageBubble } from './MessageBubble'
import { MessageActionsOrStatus } from '../actions/MessageActionsOrStatus'
import { BubbleParagraph } from './MessageNoteBubble'
import { ContingencyList } from './ContingencyList'
import moment from 'moment'
import { MessageReadUnread } from './MessageReadUnread'

/**
 * Display the details and actions based on the selected
 * @param user
 * @param message
 * @returns {JSX.Element|null}
 * @constructor
 */
export const OfferPlacedBubble = ({ parent, message, isCurrent, onUpdateMessageList, sender, isSender }) => {
    let { offer, property } = message?.data

    if (!offer) {
        return null
    }
    let offerHistory = offer?.history
    let currentDateTime = moment(new Date());
    currentDateTime.set({hour:20,minute:0,second:0,millisecond:0})

    // extract the contingent from list

    const financingKeyToText = (finance) => {
        switch (finance) {
            case 'preApproved':
                return 'Buyer is pre-approved'
            case 'needApproval':
                return 'Buyer needs financing approval'
            case 'cash':
                return 'Cash'
            default:
                return ''
        }
    }

    const DisclaimerAndDate = () => {
        return (<>
            <OfferLine>
                <DisclaimerText>{OFFERS_MSG_TEXT}</DisclaimerText>
            </OfferLine>
            <br />
            <MessageDate msg={message} isCurrent={isCurrent} />
            <MessageReadUnread isRead={message?.isRead} />
        </>)
    }

    // TODO: offer status will have a new value 'Expired'. If the pointed to Offer is in this state then
    //   do not allow any actions and display an 'Offer has expired' message bubble instead
    // UI is TBD
    // if (isCurrent && (offer.status === OfferStatus.Expired || isBluebidExpired)) {
    //     return <MessageBubble>
    //         <div className={`${isOwner ? 'from-them' : 'from-me'} bubble-div`}>
    //             <Row>
    //                 <Col xs>
    //                     This Bluebid has expired.
    //                 </Col>
    //             </Row>
    //         </div>
    //     </MessageBubble>
    // }

    return (<>
        <MessageBubble>
            <div className={`${isSender ? 'from-me' : 'from-them'} bubble-div`}>
                <Row>
                    <Col xs>
                        <BubbleParagraph>
                            <OfferLine>
                                <OfferDetailsLabel><strong>{`${sender?.firstName} ${sender?.lastName} <${sender?.email}>`}</strong></OfferDetailsLabel>
                            </OfferLine>
                            <OfferLine>
                                <OfferDetailsLabel>Bluebid:</OfferDetailsLabel>
                                <OfferDetailsAmount>{currencyFormatter.format(offer?.bidAmount || message?.data?.bidAmount || 0)}</OfferDetailsAmount>
                            </OfferLine>

                            <OfferLine>
                                <OfferDetailsLabel>Financing:</OfferDetailsLabel>
                                <OfferDetailsAmount>{financingKeyToText(offer?.financing)}</OfferDetailsAmount>
                            </OfferLine>

                            <OfferLine>
                                <OfferDetailsLabel>Contingencies:</OfferDetailsLabel>
                                <ContingencyList list={offer?.contingencies || []} />
                            </OfferLine>

                            {!isCurrent && <DisclaimerAndDate />}
                        </BubbleParagraph>
                    </Col>

                    {isCurrent && <>
                        <Col xs={5} md={4} className='pl-0'>
                            <MessageActionsOrStatus
                                messageDetail={message}
                                id={parent?.id}
                                showMessageList={onUpdateMessageList}
                            />
                        </Col>
                        <OfferLine>
                            <Col xs>
                                <DisclaimerAndDate />
                            </Col>
                        </OfferLine>
                    </>}
                </Row>
            </div>
        </MessageBubble>

        {offerHistory && offerHistory.length ? <>
            <h5>History</h5>
            <MessageHistoryBlock>
                {offerHistory?.map((entry, i) => {
                    return <OfferCounteredBubble key={`detail-${i}`}
                                                 property={property}
                                                 msg={entry} />
                })}
            </MessageHistoryBlock>
        </> : null}
    </>)
}

export const MessageHistoryBlock = styled.div`
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
`
export const ThreadSequenceTitleBlock = styled.div`
  font-size: 1.1em;
  padding-bottom: 10px;
`
