import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Link } from 'react-router-dom'
import { ColDef, ColGroupDef, ICellRendererParams } from '@ag-grid-community/core'
import { Container, Card, CardBody, Row, Col, ListGroup, ListGroupItem, CardHeader, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { ClaimDetail, ClaimError, ClaimErrorStatuses, PropertyDetail } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'

import { updateClaimError } from '../../lib/api'
import { FipsApnClaimDialog } from '../../components'
import { DateValueFormatter, LocationRenderer } from '../grid/GridRenderers'
import { successToast, errorToast } from '../../utils/common'

const StatusLabels = {
  [ClaimErrorStatuses.ToDo]: 'To-Do',
  [ClaimErrorStatuses.Done]: 'Done',
}

type ToggleClaimModal = (args?: {
  claimError?: ClaimError
  claimDetail?: Partial<ClaimDetail>
  fipsApnPropertyDetail?: PropertyDetail
}) => void

export const RowDetailRender: React.FC<ICellRendererParams<ClaimError>> = ({ data: claimError, ...params }) => {
  const fipsApnOptions: { fips: string; apn: string }[] = []
  const toggleClaimModal: ToggleClaimModal =
    typeof (params as any)?.toggleClaimModal === 'function' ? (params as any).toggleClaimModal : () => {}

  // get fips/apn values from "multiple properties error"
  claimError.rawError?.warnings?.forEach(
    (warning: { code: string; metadata: { properties: { fips: string; apn: string }[] } }) => {
      if (warning.code === 'PW02') {
        warning.metadata.properties.forEach((p) => {
          fipsApnOptions.push({ fips: p.fips, apn: p.apn })
        })
      }
    }
  )

  return (
    <Container className="py-3 h-100 overflow-auto">
      <Row className="h-100">
        <Col xs={6}>
          <Card>
            <CardHeader>Data Provider Errors</CardHeader>
            <ListGroup flush>
              {claimError.dataProviderErrors.map((dataProviderError, index) => (
                <ListGroupItem key={`data-provider-error-${index}`} className={index > 0 ? 'border-top' : ''}>
                  {dataProviderError}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Card>
          {!!fipsApnOptions.length && (
            <Row>
              {fipsApnOptions.map((o) => (
                <Col key={`fips-apn-option-${o.fips}-${o.apn}`} xs={4} className="mt-3">
                  <Card>
                    <CardBody>
                      <div>FIPS: {o.fips}</div>
                      <div>APN: {o.apn}</div>
                      <FipsApnClaimDialog
                        fips={o.fips}
                        apn={o.apn}
                        buttonProps={{ style: { marginTop: '10px', width: '100%' } }}
                        onSelect={(propertyDetail) => {
                          const address = claimError?.address
                          const email = (claimError as any)?._fields?.user?.email

                          const claimDetail: Partial<ClaimDetail> = {
                            email,
                            address,
                            latitude: claimError?.location?.lat,
                            longitude: claimError?.location?.lng,
                            createUser: false,
                          }

                          toggleClaimModal({ claimError, claimDetail, fipsApnPropertyDetail: propertyDetail })
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Col>
        <Col xs={6}>
          <Card className="h-100 overflow-auto">
            <CardHeader>Raw Error</CardHeader>
            <CardBody>
              <pre>{JSON.stringify(claimError.rawError, undefined, 2)}</pre>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const StatusCellEditor = forwardRef<any, any>((props, ref) => {
  const initialValue = props.value
  const [value, setValue] = useState(initialValue)
  const refInput = useRef(null)

  useEffect(() => {
    const eInput = refInput.current
    eInput.focus()
  }, [])

  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },

    isCancelAfterEnd() {
      return false
    },
  }))

  const handleChange = (event) => {
    setValue(event.target.value)
    setTimeout(() => props.api.stopEditing(), 0)
  }

  return (
    <select ref={refInput} value={value} onChange={handleChange} style={{ width: '100%' }}>
      <option value={ClaimErrorStatuses.ToDo}>{StatusLabels[ClaimErrorStatuses.ToDo]}</option>
      <option value={ClaimErrorStatuses.Done}>{StatusLabels[ClaimErrorStatuses.Done]}</option>
    </select>
  )
})

const NoteCellRenderer = (props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [originalNote, setOriginalNote] = useState(props.value)
  const [note, setNote] = useState(props.value)

  const handleDoubleClick = () => {
    setOriginalNote(props.value)
    setIsEditing(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)

  const commit = async () => {
    setIsEditing(false)

    const rowNode = props.api.getRowNode(props.node.id)

    if (rowNode) {
      rowNode.setDataValue(props.colDef.field, note)
    }

    const response = await updateClaimError(props.data.id, { note })

    if (!isSuccessResponse(response)) {
      errorToast('Error updating note')

      console.log('error updating note', response.errorMessage)

      if (rowNode) {
        rowNode.setDataValue(props.colDef.field, originalNote)
      }
    }

    successToast('Note updated')
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() === 'enter') {
      commit()
    }
  }

  return isEditing ? (
    <input
      type="text"
      value={note}
      onChange={handleChange}
      onBlur={commit}
      onKeyDown={handleKeyDown}
      autoFocus
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none',
        padding: 0,
        fontSize: 'inherit',
        fontFamily: 'inherit',
        color: 'inherit',
        backgroundColor: 'transparent',
      }}
    />
  ) : (
    <div style={{ width: '100%', height: '100%' }} onDoubleClick={handleDoubleClick}>
      <span style={{ lineHeight: 'normal', verticalAlign: 'middle' }}>{props.value}</span>
    </div>
  )
}

export const claimErrorsGridColumns = ({
  refresh,
  toggleClaimModal,
}: {
  toggleClaimModal?: ToggleClaimModal
  refresh?: () => void
}): (ColDef<ClaimError> | ColGroupDef<ClaimError>)[] => [
  {
    width: 350,
    field: 'address',
    cellRenderer: (params) => {
      const data = params.data as ClaimError
      const address = data?.address
      const email = params?.data?._fields?.user?.email

      const claimDetail: Partial<ClaimDetail> = {
        email,
        address,
        latitude: data?.location?.lat,
        longitude: data?.location?.lng,
        createUser: false,
      }

      return (
        <>
          <a target="_blank" rel="noreferrer" href={`https://www.google.com/search?q=${encodeURIComponent(address)}`}>
            <FontAwesomeIcon icon={faSearch} />
          </a>
          <Button
            style={{ fontSize: '12px', marginLeft: '5px' }}
            size="sm"
            color="link"
            onClick={() => {
              toggleClaimModal({ claimError: data, claimDetail })
            }}
          >
            {address}
          </Button>
        </>
      )
    },
  },
  {
    field: 'location',
    cellRenderer: LocationRenderer,
  },
  {
    field: 'userId',
    headerName: 'User',
    filter: false,
    sortable: false,
    cellRenderer: (params) => {
      if (!params?.value) {
        return
      }
      const userId = params.value
      const name =
        `${params?.data?._fields?.user?.firstName} ${params?.data?._fields?.user?.lastName}`.trim() || '(no name)'

      return <Link to={`/admin/userprofile/${userId}`}>{name}</Link>
    },
  },
  {
    field: 'dataProviderErrors',
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    field: 'rawError',
    valueFormatter: (params) => JSON.stringify(params?.data?.rawError || []),
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    field: 'status',
    editable: true,
    valueFormatter: (params) => StatusLabels[params.value],
    cellEditor: StatusCellEditor,
    onCellValueChanged: async (params) => {
      const status = params.newValue

      const response = await updateClaimError(params.data.id, { status })

      if (!isSuccessResponse(response)) {
        errorToast(response.errorMessage)

        return
      }

      successToast('Claim error updated')
      setTimeout(() => {
        refresh?.()
      }, 1000)
    },
  },
  {
    field: 'createdAt',
    valueFormatter: DateValueFormatter,
    filter: 'agDateColumnFilter',
    sort: 'desc',
  },
  { field: 'note', headerName: 'Note', width: 300, cellRenderer: NoteCellRenderer },
]
