import React, { useState, useEffect } from 'react'
import { useParams, useOutletContext, Link } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { User, Group, removeArrayItem } from '@bluebid-sdk/core'
import { isErrorResponse } from '@bluebid-sdk/api-client'
import { BlockUI } from 'bb-lib-desktop'

import { getGroupUsers, updateGroup, assignUserToGroup, unassignUserFromGroup } from '../../../lib'
import { successToast, errorToast } from '../../../utils/common'
import { UserSearch } from '../../../components/UserSearch'
import { EditAgentContentModal } from '../../../components/EditAgentContentModal'

export const GroupMembersView: React.FC = () => {
  const { groupId } = useParams()
  const { group } = useOutletContext<{ group: Group }>()
  const [isLoading, setIsLoading] = useState(true)
  const [groupMembers, setGroupMembers] = useState<User[]>()
  const [groupOwnerId, setGroupOwnerId] = useState<string>()
  const [selectedUser, setSelectedUser] = useState<User>()
  const [editAgentModal, setEditAgentModal] = useState(false)
  const [editAgentId, setEditAgentId] = useState<string>()

  const editAgentModalToggle = () => {
    setEditAgentModal(!editAgentModal)
  }

  const openEditAgentModal = (agentId: string) => {
    setEditAgentId(agentId)
    setEditAgentModal(true)
  }

  useEffect(() => {
    if (groupId) {
      setIsLoading(true)
      ;(async () => {
        const response = await getGroupUsers(groupId)

        if (isErrorResponse(response)) {
          errorToast(response.errorMessage)
        } else {
          setGroupMembers(response.data)
          setGroupOwnerId(group?.ownerId)
        }

        setIsLoading(false)
      })()
    }
  }, [groupId])

  const onSelectUser = (user: User) => {
    setSelectedUser(user)
  }

  const handleAssignUser = async () => {
    if (!selectedUser?.id) {
      return
    }

    setIsLoading(true)

    const response = await assignUserToGroup(groupId, selectedUser.id)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      setSelectedUser(undefined)
      setGroupMembers([...groupMembers, selectedUser])
      successToast('User assigned to group')
    }

    setIsLoading(false)
  }

  const handleUnassignUser = async (userId: string) => {
    setIsLoading(true)

    const response = await unassignUserFromGroup(groupId, userId)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      const updatedGroupMembers = removeArrayItem(groupMembers, (groupMember) => groupMember.id === userId)

      setGroupMembers(updatedGroupMembers)
      successToast('User unassigned from group')
    }

    setIsLoading(false)
  }

  const handleSetGroupOwner = async (ownerId: string) => {
    setIsLoading(true)

    const response = await updateGroup(groupId, { ownerId })

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      setGroupOwnerId(ownerId)
      successToast('Updated group details')
    }

    setIsLoading(false)
  }

  const teamMembers = groupMembers?.filter((m) => !!m)?.filter((m) => m.id !== groupOwnerId) || []
  const teamOwner = groupMembers?.find((m) => m.id === groupOwnerId)
  const ownerName = (u) =>
    u?.firstName || u?.lastName ? `${u.firstName?.toUpperCase()} ${u.lastName?.toUpperCase()}` : '(name not set)'

  return (
    <div className="group-members-view">
      <BlockUI blocking={isLoading} />

      <Row className="mt-4">
        <Col md={6}>
          <Card className="mb-5" color="light">
            <CardBody>
              {teamOwner && (
                <Row>
                  <Col md={4}>
                    <Label>License owner: </Label>
                  </Col>
                  <ItemTextCol>
                    <Link to={`/admin/userprofile/${teamOwner?.id}`}>{ownerName(teamOwner)}</Link>
                  </ItemTextCol>
                  <ItemTextCol>
                    <span className="text-nowrap">
                      <Link to={`/admin/userprofile/${teamOwner?.id}`}>{teamOwner.email}</Link>{' '}
                      <a href="#" onClick={() => openEditAgentModal(teamOwner?.id)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </a>
                    </span>
                  </ItemTextCol>
                </Row>
              )}

              <Row>
                <Col md={4}>
                  <Label>License members:</Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    {teamMembers.map((member, index) => {
                      return (
                        <ListItem key={index}>
                          <Row>
                            <Col md={1}>
                              <Button color="grey" size="sm" onClick={() => handleSetGroupOwner(member.id)}>
                                <FontAwesomeIcon icon={faCrown} />
                              </Button>
                            </Col>
                            <Col md={1}>
                              <Button color="danger" size="sm" onClick={() => handleUnassignUser(member.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Col>
                            <ItemTextCol>
                              <Link to={`/admin/userprofile/${member.id}`}>{ownerName(member)}</Link>
                            </ItemTextCol>
                            <ItemTextCol>
                              <span className="text-nowrap">
                                <Link to={`/admin/userprofile/${member.id}`}>{member.email}</Link>{' '}
                                <a href="#" onClick={() => openEditAgentModal(member.id)}>
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </a>
                              </span>
                            </ItemTextCol>
                          </Row>
                        </ListItem>
                      )
                    })}
                  </ul>
                </Col>
              </Row>
              <EditAgentContentModal isOpen={editAgentModal} toggle={editAgentModalToggle} userId={editAgentId} />
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="mb-5" color="light">
            <CardBody>
              <CardTitle tag="h6">Add Agent to License</CardTitle>

              {/** @todo replace this with new AdminSearch component */}
              <UserSearch label="" onSelect={({ data }) => onSelectUser(data)} />

              <div className="mt-3 text-right">
                <Button color="primary" disabled={!selectedUser?.id} type="submit" onClick={handleAssignUser}>
                  Add
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const Label = styled.div`
  font-size: 10pt;
  font-weight: bold;
`

const ListItem = styled.li`
  padding: 5px;
  list-style: none;

  :nth-child(odd) {
    background: #efefef;
  }
`

const ItemTextCol = styled(Col)`
  font-size: 10pt;
`
