import styled from 'styled-components'
import { Button } from 'reactstrap'
import { sizes } from '../../utils/responsive'
import { Black, BluebidBlue, DarkDarkGrey, DarkGrey, White } from '../../constants/colors'

/**
 * Common styling and constants for Action buttons and Icons*
 */

const HOVER_ACTION_COLOR = '#0066F9'

export const vdoStyle = {
  display: 'block',
  width: '100%',
  height: '100%'
};

/**
 * Any / all property images being displayed
 * @type {StyledComponent<"img", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const PropertyImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 4px;
    max-height: 455px;
`
export const PropertyVideo = styled.video`
    height: 100%;
    width: 100%;
    border-radius: 4px;
    max-height: 455px;
    background: ${Black};
`
/**
 * Primary action button
 * @type {StyledComponent<Button, AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const PrimaryButton = styled(Button).attrs({
  className: 'bb-primary-button'
})`
    padding: 10px 25px;
    border-radius: 2px;
    backdrop-filter: blur(32px);
    background-color: ${BluebidBlue};
    border: 1px solid ${BluebidBlue};
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;

  :hover, :active {
    border-color: ${DarkGrey};
    background-color: ${DarkGrey} !important;
    color: ${White} !important;
    svg {
      color: ${White};
    }
  }

    @media (max-width: ${sizes.mobileXL}) {
      width: 100%;
      margin-bottom: 10px;
    }
`
/**
 * Secondary action button
 * @type {StyledComponent<StyledComponent<Button, AnyIfEmpty<goober.DefaultTheme>, {}, never>, AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const SecondaryButton = styled(PrimaryButton).attrs({
  className: 'secondary-button'
})`
  background-color: ${White};
  color: ${BluebidBlue};
  min-width: ${(props) => props?.minWidth || '180px'};
`

/**
 * An icon that is clickable
 * @type {StyledComponent<Button, AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const IconButton = styled(Button).attrs({
  className: 'icon-button'
})`
    background-color: transparent;
    color: gray;
    border: none;
    font-size: 1.5rem;
    padding: 0 10px 0 5px;

  :hover, :active {
    border-color: ${BluebidBlue};
    background-color: transparent;
    color: ${BluebidBlue} !important;
    svg {
      color: ${BluebidBlue};
    }
  }

  // &:hover {
  //       background-color: transparent;
  //       color: ${HOVER_ACTION_COLOR};
  //   }

    &:focus {
      box-shadow: none;
      outline: none;
    }
`

export const PropertyDetailButton = styled(SecondaryButton)`
  padding: 10px 20px;
  min-width: 145px;
`
export const CustomButton = styled(Button)`
    padding: 10px 25px;
    border-radius: 5px;
    backdrop-filter: blur(32px);
    font-size: 0.9rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    margin-right: 10px;

    @media (max-width: ${sizes.mobileXL}) {
      width: 100%;
    }
`

export const OfferAdminButton = styled(Button)`
  padding: 10px 25px;
  border-radius: 0;
  line-height: 1.14;
  text-align: center;
`

/**
 * An action 'button' that has no styling.
 *
 * @type {StyledComponent<Button, AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const PlainActionButton = styled(PrimaryButton).attrs({
  className: 'pa-button'
})`
    background-color: transparent;
    color: ${BluebidBlue};

  :hover, :active {
    border-color: ${DarkGrey};
    background-color: ${DarkGrey} !important;
    color: ${White} !important;
    svg {
      color: ${White};
    }
  }
`;

export const FilterButton = styled(PrimaryButton).attrs({
  className: 'filter-button'
})`
  background-color: ${White};
  border-color: ${BluebidBlue};
  color: ${BluebidBlue};
  padding: 11px 10px;
  min-width: 145px;
  font-size: 0.85rem;
  position: relative;
  top: 1px;

  :hover, :active {
    border-color: ${DarkGrey} !important;
    background-color: ${DarkGrey} !important;
    color: ${White} !important;
    svg {
      color: ${White};
    }
  }

  @media (max-width: ${sizes.tabletL}) {
    display: ${props => (props.isrequired === "true") ? '' : 'none'};
  }
  @media (max-width: ${sizes.tabletL}) {
    width: unset;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  @media (max-width: ${sizes.mobileXL}) {
    min-width: 100px;
    font-size: 0.75rem;
  }

`

/**
 * A div that looks like a button (no on-Click and suitable for having an href inside)
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
export const FakeButton = styled.div`
  display: inline-block;
  font-weight: 400;
  color: ${DarkDarkGrey};
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`

export const FakePlainButton = styled(FakeButton)`
  padding: 10px 25px;
  border-radius: 2px;
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  background-color: ${BluebidBlue};
  border: 1px solid ${BluebidBlue};
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;

  @media (max-width: ${sizes.mobileXL}) {
    width: 100%;
  }
`

export const FakeTransparentButton = styled(FakePlainButton).attrs({
  className: 'ftrans-button'
})`
  background-color: transparent;
  color: ${BluebidBlue};
  
  :hover, :active {
    border-color: ${DarkGrey};
    background-color: ${DarkGrey} !important;
    color: ${White} !important;
    svg, a {
      color: ${White};
    }
  }

`
export const UnstlyedLink = styled.a`
    text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

export const IconActionButton = styled(SecondaryButton)`
  min-width: revert;
  border: none;
  padding: 0;
  position: relative;
  top: 10px;
`
