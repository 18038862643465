import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { Card, Col, Container, Row, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { Datasource } from 'bb-lib-desktop'

import { formatDate } from '../../lib/utils'
import { GridContainerStyle, GridStyle } from '../../components/CommonGrid'
import { notificationsGridColumns } from './notifications-grid-columns.fn'
import { GridListHeader } from '../../views/admin/GridListHeader'
import { GridComponent } from '../grid'

const isProbablyHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str)

const DetailLabel = styled.td`
  font-weight: bold;
`

const DetailValue = styled.td<{ highlight?: any }>`
  color: ${(props) => (props.highlight ? 'red !important' : 'inherit')};
`

const DetailRenderer = (params) => {
  const { body, channel, recipientName, recipientPhone, recipientEmail, dateSent, sent, overLimit, optedOut } =
    params.data

  const content = isProbablyHTML(body) ? params.data.body : params.data.body.replace(/\\n/g, '<br>')

  return (
    <Container className="py-3">
      <Row>
        <Col xs="4">
          {channel === 'sms' && (
            <h3>
              <FontAwesomeIcon icon={faMobileAlt} fixedWidth /> SMS
            </h3>
          )}
          {channel === 'email' && (
            <h3>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth /> EMail
            </h3>
          )}
          <Table>
            <tbody>
              <tr>
                <DetailLabel>To:</DetailLabel>
                <DetailValue>{recipientName}</DetailValue>
              </tr>
              <tr>
                <DetailLabel></DetailLabel>
                <DetailValue>{recipientPhone ?? recipientEmail}</DetailValue>
              </tr>
              <tr>
                <DetailLabel>Attempted:</DetailLabel>
                <DetailValue>{formatDate(dateSent)}</DetailValue>
              </tr>
              <tr>
                <DetailLabel>Sent:</DetailLabel>
                <DetailValue highlight={!sent}>{sent ? 'Yes' : 'No'}</DetailValue>
              </tr>
              <tr>
                <DetailLabel>Rate Limited:</DetailLabel>
                <DetailValue highlight={overLimit}>{overLimit ? 'Yes' : 'No'}</DetailValue>
              </tr>
              <tr>
                <DetailLabel>Opted Out:</DetailLabel>
                <DetailValue highlight={optedOut}>{optedOut ? 'Yes' : 'No'}</DetailValue>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <Card className="p-3">
            <iframe
              title="Detail Content"
              srcDoc={content}
              style={{ width: '100%', height: '400px', border: 'none' }}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export const NotificationsGridComponent: React.FC<{
  title?: string
  userId?: string
  overrideColumnDefs?: (ColDef | ColGroupDef)[]
  sessionKey?: string
}> = ({ title = 'Notifications', userId, overrideColumnDefs, sessionKey = 'notifications' }) => {
  const gridRef = useRef<AgGridReact>()
  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])

  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])
  const [query, setQuery] = useState<string>()

  const datasource = useMemo(() => new Datasource({ index: 'notifications', adminSearch: true, query }), [query])

  useEffect(() => {
    setQuery(userId ? `(senderId:"${userId}" OR recipientId:"${userId}")` : undefined)
  }, [userId])

  const columnDefs = useMemo(() => notificationsGridColumns(), [])

  return (
    <>
      {!!title && <GridListHeader title={title} onRefresh={onBtnRefresh} />}

      <div style={title ? containerStyle : gridStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          <GridComponent
            gridRef={gridRef}
            sessionKey={sessionKey}
            columnDefs={overrideColumnDefs || columnDefs}
            serverSideDatasource={datasource}
            cacheBlockSize={20}
            masterDetail={true}
            detailCellRenderer={DetailRenderer}
            detailRowAutoHeight={true}
          />
        </div>
      </div>
    </>
  )
}
