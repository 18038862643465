import apiCall from '../lib/data/apiCall'
import AsyncSelect from 'react-select/async'
import React from 'react'

export const PropertySearchByAddress = ({onSelect}) => {
    const searchFn = async (input) => {
        let result = await apiCall(`/search/properties?q=address:${input}* AND NOT status:unclaimed`)
        if (result && result.count) {
            return result.items.map(r => {
                return {
                    value: r.propertyId,
                    label: `${r.address}`,
                    data: r
                }
            })
        }
        return []
    }

    return (<>
        <AsyncSelect
            menuPosition={'fixed'}
            styles={customStyles}
            cacheOptions defaultOptions
            loadOptions={searchFn}
            placeholder={'Address'}
            onChange={onSelect}
        />
    </>)
}

const customStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 5 }),
}
