import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { MessagesGridComponent } from '../../components'
import Loading from '../../components/Loading'

const ListMessages = () => {
  return <MessagesGridComponent />
}

export default withAuthenticationRequired(ListMessages, {
  onRedirecting: () => <Loading />,
})
