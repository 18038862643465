import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDollar } from '@fortawesome/free-solid-svg-icons'
import { Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import BlockUI from "../BlockUI";
import { errorToast, successToast } from "../../utils/common";
import { ModalSubHeader } from '../actions/ManagePhotosAction'
import { AdminActionButton, AdminSecondaryActionButton, AdminWarningActionButton } from './common'
import { NumericFormat } from 'react-number-format';
import { updateBluebidEstimate } from "../../lib/data/bluebidData";
import { CenteredModalFooter, DialogFooterBlock } from './completeOfferAction'
import { PrimaryButton, SecondaryButton } from "../actions/common";
import { PropertyPriceInfo } from "../detail/PropertyPriceInfo";

export const UpdateBluebidEstimate = ({ property, shouldUpdateProfile }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [potentialEstimate, setPotentialEstimate] = useState(property.detail?.bluebid_estimate?.value)
    const [modal, setModal] = useState(false)

    const toggle = () => setModal(!modal)

    const handleEstimateChange = (values) => {
        const { floatValue } = values
        setPotentialEstimate(floatValue)
    }

    const handleSubmitNewEstimate = (event) => {
        event.preventDefault()
        updateEstimate(potentialEstimate)
    }

    const handleEstimateClear = () => {
        setPotentialEstimate(undefined)
        updateEstimate(undefined)
    }

    const updateEstimate = (newEstimate) => {
        setIsLoading(true)

        updateBluebidEstimate(property?.propertyId, newEstimate)
            .then(result => {
                setIsLoading(false)

                if (result.statusCode && result.statusCode >= 400) {
                    console.error({ result })
                    errorToast(result.errorMessage)
                } else {
                    toggle()
                    successToast(newEstimate !== undefined ? `Updated Bluebid estimate to ${newEstimate}` : `Cleared Bluebid estimate`)
                    setPotentialEstimate(newEstimate)
                    shouldUpdateProfile(newEstimate)
                }
            }).catch(error => {
                console.log('error', error)
                errorToast(`something went wrong!`)
                setIsLoading(false)
            })
    }

    return (
        <>
            <AdminSecondaryActionButton onClick={() => toggle()} >
                <FontAwesomeIcon icon={faCommentDollar} /> Bluebid Estimate
            </AdminSecondaryActionButton>

            <Modal size="md" isOpen={modal} backdrop="static"
                toggle={toggle}
                modalTransition={{ timeout: 200 }}>
                <ModalHeader>
                    <div>
                        <h3>Update Bluebid Estimate</h3>
                        <ModalSubHeader>
                            {property?.address}
                        </ModalSubHeader>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <BlockUI blocking={isLoading} />

                    <Container>
                        <PropertyPriceInfo property={property} />
                        <hr />
                        <Form onSubmit={handleSubmitNewEstimate}>
                            <FormGroup>
                                <Col md={6} className={'offset-md-3'}>
                                    <Label for="estimate">Bluebid Estimate:</Label>
                                    <NumericFormat
                                        value={potentialEstimate}
                                        onValueChange={handleEstimateChange}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        allowNegative={false}
                                        customInput={Input}
                                        autoFocus
                                        id="estimate"
                                    />
                                </Col>
                            </FormGroup>

                            <CenteredModalFooter>
                                <DialogFooterBlock>
                                    <PrimaryButton type="submit" className={"btn-margin"} style={{width: '120px'}} disabled={isLoading}>Update</PrimaryButton>
                                    <AdminWarningActionButton className={"btn-margin"} style={{minWidth: '120px'}} disabled={isLoading} onClick={() => handleEstimateClear()}>Clear</AdminWarningActionButton>
                                    <SecondaryButton className={"btn-margin"} style={{minWidth: '120px'}} disabled={isLoading} onClick={() => toggle()}>Cancel</SecondaryButton>
                                </DialogFooterBlock>
                            </CenteredModalFooter>
                        </Form>
                    </Container>
                </ModalBody>
            </Modal>
        </>
    )
}
