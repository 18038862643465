import ReactGoogleAutocomplete from 'react-google-autocomplete'
import React from 'react'
import styled from "styled-components";

export const GoogleAutoComplete = ({onSelect}) => {

    return (
        <Searcher
            className={'form-control'}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(place) => onSelect(place)}
            options={{
                types: ['street_address','premise','locality','sublocality','postal_code'],
                componentRestrictions: { country: "us" },
            }} />
    )
}

const Searcher = styled(ReactGoogleAutocomplete).attrs({
    className: 'google-autocomplete'
})`
    margin-left: 10px;
  margin-right: 10px;
`
