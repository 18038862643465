/**
 * In future these may be controlled via software,
 * for now just enable as needed for development
 *
 */
export const Features = {
    HelpMenu: false,
    MessagesMenu: true,
    AlertsMenu: false,
    FavoritesMenu: true,
    FavoritesAction: true,
    AdminUse: true
}
