import styled from "styled-components";
import React from "react";

const ErrorContainer = styled.div`
  background: url('https://images.bluebid.io/error-image.jpeg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
`

const CenteredErrorText = styled.div`
  font-family: 'Montserrat', serif, sans-serif;
  font-size: 1.8rem;
  position:absolute;
  top:25%;
  left:50%;
  transform:translate(-50%,-50%);
  color:#fff;
`

const ErrorScreen = ({error, message}) => {
    if (error) {
        console.error(error)
        return (
            <ErrorContainer>
                <CenteredErrorText>
                    { message || 'Oh no, it looks like something went wrong!'}
                </CenteredErrorText>
            </ErrorContainer>
        )
    }
    return <></>
}

export default ErrorScreen
