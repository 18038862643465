import { PropertySearchByAddress } from '../../components/PropertySearchByAddress'
import { ActionButton, BodyWrapper, FlexContainer, FlexItem, FlexTitle, FlexWrap } from './AdminStyles'
import { Col, Container, Row } from 'reactstrap'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../../components/Loading'

const SearchProperties = () => {
    const navigate = useNavigate()
    const [property, setProperty] = useState()
    const selectProperty = (s) => setProperty(s.data)
    const gotoProperty = () => navigate(`/admin/propertyedit/${property.propertyId}`)
    const gotoPropertyOffers = () => navigate(`/admin/propertyoffers/${property.propertyId}`)

    return (
        <BodyWrapper>
            <Container>
                <Row>
                    <Col xs={12}>
                        <FlexWrap>
                            <FlexContainer>
                                <FlexTitle>
                                    <h4 className='display-6'>Search by Address
                                    </h4>
                                </FlexTitle>
                                <FlexItem className='fill'>
                                    <PropertySearchByAddress onSelect={selectProperty} />
                                </FlexItem>
                                <FlexItem>
                                    <ActionButton className='btn' color='primary'
                                                  disabled={property === undefined}
                                                  onClick={gotoProperty}>Show Detail</ActionButton>
                                    <ActionButton className='btn' color='primary'
                                                  disabled={property === undefined}
                                                  onClick={gotoPropertyOffers}>Show Bluebids</ActionButton>
                                    {/*<ActionButton className='btn float-right mr-0' color='primary'*/}
                                    {/*              onClick={goToPropertiesList}>List Properties</ActionButton>*/}
                                </FlexItem>
                            </FlexContainer>
                        </FlexWrap>
                    </Col>
                </Row>
            </Container>
        </BodyWrapper>)
}

export default withAuthenticationRequired(SearchProperties, {
    onRedirecting: () => <Loading/>,
})
