import { Container, Card, CardBody, CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { getEnv } from '../../../config';

export const HomeSearchedFor = (userAction) => {
    const { address, propertyId, isClaimed } = userAction.data

    return (
        <Container>
            <Card>
                <CardBody>
                    <CardTitle tag="h5">{address}</CardTitle>
                    
                    { propertyId && <>
                        <a href={`${getEnv().appHost}/claimedProperty/${propertyId}`} rel="noreferrer" target="_blank" className="text-decoration-none">
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Open (Bluebid)
                        </a>
                        <a href={`/admin/propertyedit/${propertyId}`} target="_blank" rel="noreferrer" className="text-decoration-none ml-3">
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Edit (Admin)
                        </a>
                    </>}

                    { !propertyId && 
                        <a href={`https://google.com/maps/place/${encodeURIComponent(address)}`} rel="noreferrer" target="_blank" className="text-decoration-none">
                            <FontAwesomeIcon icon={faExternalLinkAlt} /> Open (Google Maps)
                        </a>
                    }

                    <Table striped className="mt-3">
                        <tbody>
                            <tr>
                                <td width="200"><strong>Property ID</strong></td>
                                <td>{propertyId || '-'}</td>
                            </tr>
                            <tr>
                                <td><strong>Claimed?</strong></td>
                                <td>{isClaimed ? 'Yes' : 'No'}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Container>
    )
}