import React, {useState} from "react";
import styled from 'styled-components';

import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {PrimaryButton} from "./common";
import {AuthContext} from "../../constants/context";
import {resendEmailVerification} from "../../lib/data/bluebidData";

export const VerifyEmailModal = ({show, toggle, title, dialogContent, path}) => {
    const { bluebidUser } = React.useContext(AuthContext)
    const [wasSent, setWasSent] = useState(false)

    if (!show) return <></>

    const verifyEmailAction = async () => {
        await resendEmailVerification(bluebidUser, path)
        setWasSent(true)
    }

    return <>
        <Modal isOpen={show} toggle={toggle}>
            <VerifyHeader toggle={() => toggle()}>
                {title}
            </VerifyHeader>
            <ModalBody>
                <Row>
                    <Col>
                        {dialogContent(!wasSent)}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Row className={"mt-3"}>
                    <Col className={"text-center"}>
                        <PrimaryButton onClick={wasSent ? toggle : verifyEmailAction}>
                            {wasSent ? 'OK' : 'Resend Verification Email'}
                        </PrimaryButton>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    </>
}

const VerifyHeader = styled(ModalHeader)`
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
`
