import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'

import { getGroupById } from '../../lib'
import { InquiriesGridComponent } from '../../components'
import Loading from '../../components/Loading'
import { getLicenseByGroupId } from '../../lib/data/bluebidData'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])

const ListLicenseInquiries = () => {
  const { groupId } = useParams()

  const [licenseId, setLicenseId] = useState<string>()
  const [zipcodes, setZipcodes] = useState<string[]>([])
  const [showReferralInfo, setShowReferralInfo] = useState(false)
  const [agentName, setAgentName] = useState<string>()

  useEffect(() => {
    getLicenseByGroupId(groupId).then((licenses) => {
      const license = licenses[0]
      setLicenseId(license.id)
      setZipcodes(license.zipcodes)
      setShowReferralInfo(license?.channels?.includes('anywhere'))
    })

    getGroupById(groupId).then((group) => {
      setAgentName(group.data?.name)
    })
  }, [groupId])

  return (
    <InquiriesGridComponent
      licenseId={licenseId}
      title={`License Inquiries: ${agentName || ''}`}
      query={`staging:false AND licenseId.keyword:${licenseId}`}
      showReferralInfo={showReferralInfo}
      agentName={agentName}
      disableExport={!zipcodes.length}
    />
  )
}

export default withAuthenticationRequired(ListLicenseInquiries, {
  onRedirecting: () => <Loading />,
})
