import { useState } from "react"
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import styled from "styled-components"
import BlockUI from "../BlockUI";
import { offerExpireApi, offerUpdatedApi } from '../../lib/data/bluebidData'
import { errorToast, successToast } from "../../utils/common"
import { OfferAdminButton, PrimaryButton } from '../actions/common'
import confirm from '../Confirm'

export const CenteredModalFooter = styled(ModalFooter)`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
`
export const DialogFooterBlock = styled.div`
  justify-content: center;
  padding-bottom: 20px;
`
export const OfferModalBodyContainer = styled(ModalBody)`
  .form-check-input {
    margin-top: 0;
  }
`
export const OfferModal = styled(Modal)`
    .btn-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        color: #000;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        border: 0;
        border-radius: 0.25rem;
        opacity: .5;
        padding: 0.5rem 0.5rem;
        margin: -0.5rem -0.5rem -0.5rem auto;
    }
`

const REVOKE = 'Revoked'

export const CompleteOfferActionModal = ({ offerId, ownerId, bidderId, lastAction, reloadData }) => {
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [note, setNote] = useState('')
    const [channels, setChannels] = useState({ sms: true, push: true, email: true })

    const toggle = () => {
        setNote('')
        setShowModal(!showModal)
    }

    let acceptLabel = (lastAction === 'Offer' || lastAction === 'BuyerCountered') ? 'Owner Accepts' : 'Buyer Accepts'
    let rejectLabel = (lastAction === 'Offer' || lastAction === 'BuyerCountered') ? 'Owner Rejects' : 'Buyer Rejects'
    let revokeLabel = (lastAction === 'Offer' || lastAction === 'BuyerCountered') ? 'Buyer Revokes' : 'Owner Revokes'
    let actionText
    switch (lastAction) {
        default:
        case 'Offer':
            actionText = 'Bidder sent an Bluebid to the owner'
            break;
        case 'BuyerCountered':
            actionText = 'Bidder responded with a counter back to the owner'
            break
        case 'OwnerCountered':
            actionText = 'Owner responded with a counter back to the bidder'
            break
    }

    // call offer api
    const offerAPICall = async (status) => {
        setIsLoading(true)

        // set ownerId, based on inPlay last Action value:
        //
        // lastAction = 'OwnerCountered'
        //      Accept: bidderId
        //      Reject: bidderId
        //      Revoke: property.ownerId
        // lastAction = 'BuyerCountered'
        //      Accept: property.ownerId
        //      Reject: property.ownerId
        //      Revoke: bidderId
        // lastAction = 'Offer'
        //      Accept: property.ownerId
        //      Reject: property.ownerId
        //      Revoke: bidderId
        //
        let actionUserId
        switch (lastAction) {
            case 'OwnerCountered':
                actionUserId = status === REVOKE ? ownerId : bidderId
                break;
            case 'BuyerCountered':
                actionUserId = status === REVOKE ? bidderId : ownerId
                break;
            case 'Offer':
            default:
                actionUserId = status === REVOKE ? bidderId : ownerId
                break;
        }

        await offerUpdatedApi({
            offerId: offerId,
            actorId: actionUserId,
            note: note && note.length ? note : undefined,
            channels: channels
        }, status).then(result => {
            if ((result.statusCode && result.statusCode >= 400) || (result.status && result.status === 'error')) {
                console.error({result});
                setIsLoading(false);
                errorToast(result.errorMessage);
            } else {
                toggle()
                setChannels({ sms: true, push: true, email: true })
                successToast(`Bluebid ${status} Successfully!`)
                reloadData()
            }
            setIsLoading(false)
        })
    }

    const ExpireDialogBody = function() {
        return <p>Are you sure you want to expire this Bluebid?</p>
    }

    const expireOffer = async () => {
        let result = await confirm({
            title: 'Expire this Bluebid?',
            bodyComponent: ExpireDialogBody,
            cancelText: 'Cancel',
            confirmColor: 'danger',
            confirmText: 'Expire'
        });
        if (result) {
            offerExpireApi({
                offerId: offerId,
                note: note && note.length ? note : undefined,
                channels: channels
            }).then(result => {
                if ((result.statusCode && result.statusCode >= 400) || (result.status && result.status === 'error')) {
                    console.error({result});
                    setIsLoading(false);
                    errorToast(result.errorMessage);
                } else {
                    toggle()
                    setChannels({ sms: true, push: true, email: true })
                    successToast(`Bluebid expired Successfully!`)
                    reloadData()
                }
                setIsLoading(false)
            })
        }
    }

    const onChannelToggle = (key) => {
        const updatedChannels = {...channels}
        updatedChannels[key] = !updatedChannels[key]
        setChannels(updatedChannels)
    }

    return (<>
    <CloseOfferButton className={"btn-margin m-auto"} onClick={toggle}>Close Bluebid</CloseOfferButton>

    <OfferModal size="lg" isOpen={showModal} backdrop="static" toggle={toggle} modalTransition={{ timeout: 200 }}>
        <ModalHeader toggle={toggle}>Close this Bluebid</ModalHeader>
        <OfferModalBodyContainer>
            <BlockUI blocking={isLoading} />
            <Row>
                <Col md={12}>
                    <DlgLabel>Last action:</DlgLabel>
                    <p>{actionText}</p>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="m-auto">
                    <DlgLabel check>Admin annotation</DlgLabel>
                    <Input type="textarea" onChange={(e) => setNote(e.target.value)}
                        value={note}
                        placeholder={'Annotation...'} />
                </Col>
            </Row>
        </OfferModalBodyContainer>
        <CenteredModalFooter>
            <Row>
                <Col md={12} >
                    <ChannelGroup className="mx-4 mt-2">
                        Send notifications to:   <FormGroup check inline>
                        <Label check>
                                <Input type="checkbox" onChange={() => onChannelToggle('sms')} checked={channels.sms}/>
                                SMS
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input type="checkbox" onChange={() => onChannelToggle('email')} checked={channels.email}/>
                                Email
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input type="checkbox" onChange={() => onChannelToggle('push')} checked={channels.push}/>
                                Push
                            </Label>
                        </FormGroup>
                    </ChannelGroup>
                </Col>
            </Row>
            <DialogFooterBlock>
                <Row>
                    <Col className={'col-md-6 offset-md-2'}>
                        <AdminActionButton className={"btn-success btn-margin m-1 px-3"} disabled={isLoading}
                                      onClick={() => offerAPICall("Accepted")}>{acceptLabel}</AdminActionButton>
                        <AdminActionButton className={"btn-danger btn-margin m-1 px-3"} disabled={isLoading}
                                      onClick={() => offerAPICall("Rejected")}>{rejectLabel}</AdminActionButton>
                        <RevokeButton className={"btn-danger btn-margin m-1 px-3"} disabled={isLoading}
                                      onClick={() => offerAPICall("Revoked")}>{revokeLabel}</RevokeButton>
                        <ExpireButton className={"btn-danger btn-margin m-1 px-3"} disabled={isLoading}
                                      onClick={expireOffer}>Expire Bluebid</ExpireButton>
                    </Col>
                    <Col className={'col-md-4 my-auto'}>
                        <PrimaryButton className={"btn-margin m-auto mt-2"} onClick={toggle}>Cancel</PrimaryButton>
                    </Col>
                </Row>
            </DialogFooterBlock>

        </CenteredModalFooter>
    </OfferModal>
    </>)
}

const AdminActionButton = styled(OfferAdminButton)`
    min-width: 200px;
`

const CloseOfferButton = styled(AdminActionButton)`
  min-width: 80px;
  max-width: 120px;
  font-size: 8pt;
  background-color: #027ffe;
`

const RevokeButton = styled(AdminActionButton)`
  background-color: #166cc5;
  border-color: #166cc5;
`

const ExpireButton = styled(AdminActionButton)`
  background-color: #c2c5c5;
  border-color: #c2c5c5;
`

export const DlgLabel = styled(Label)`
    padding-top: 10px;
    font-weight: bold;
`
export const ChannelGroup = styled.div`
    .form-check-input[type=checkbox] {
        margin-top: 4px;
    }
`
