import React, { useMemo } from 'react'

import { User } from '@bluebid-sdk/core'

import { InvitesGridComponent, invitesGridColumns } from '../invites'

export const UserReferrals: React.FC<{ user: User }> = ({ user }) => {
  const columnDefs = useMemo(() => {
    const columns = invitesGridColumns({
      onToggleDelete: () => {},
      onToggleAdmin: () => {},
    })
    const getColumnByHeader = (header: string) => columns.find((column) => column.headerName === header)

    return [
      getColumnByHeader('Recipient'),
      getColumnByHeader('Unclaimed Address'),
      getColumnByHeader('Claimed Address'),
      getColumnByHeader('Claimed Property'),
      getColumnByHeader('Created / Modified'),
    ].filter((column) => !!column)
  }, [])

  if (!user?.email) {
    return
  }

  return (
    <InvitesGridComponent
      sessionKey="userreferrals"
      overrideColumnDefs={columnDefs}
      senderEmail={user.email}
      enableSearch={false}
      title=""
    />
  )
}
