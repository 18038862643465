import styled from 'styled-components'

export const AlignRight = styled.div`
    text-align: right;
`

export const GridHeaderRow = styled.div`
    height: 80px;
`;

