import { FormGroup, Input, Label } from 'reactstrap'
import React, { useState } from 'react'
import styled from 'styled-components'
import { enableGoogleImages } from '../../lib/data/bluebidData'

export const ToggleGoogleImagesAction = ({property, shouldUpdateProfile}) => {
    const [excludeImages, setExcludeImages] = useState(property?.omitGoogleImages || false)

    const toggleUnclaimed = () => {
        const enabled = !excludeImages
        enableGoogleImages(property.propertyId, enabled)
            .then((updatedProperty) => {
                console.log(updatedProperty)
                setExcludeImages(enabled)
                shouldUpdateProfile(updatedProperty)
            })
    }

    return (
        <SwitchContainer>
            <Label><b>Google Map Images</b></Label>
            <FormGroup switch>
                <Input type="switch" onChange={toggleUnclaimed} checked={!excludeImages} />
                <Label check onClick={toggleUnclaimed}>{excludeImages ? 'are excluded' : ' are included'}</Label>
            </FormGroup>
        </SwitchContainer>
    )
}

const SwitchContainer = styled.div`
    //font-size: 1.3em;
`
