import React from 'react'
import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt, faTachometerAlt, faUserSlash } from '@fortawesome/free-solid-svg-icons'

import { formatPhoneNumber } from '@bluebid-sdk/core'

import { address } from '../../utils/common'
import { DateValueFormatter, LinkRenderer } from '../../components/grid/GridRenderers'

const ChannelRenderer = ({ data: { channel } }) => (
  <>
    {channel === 'sms' && <FontAwesomeIcon fixedWidth icon={faMobileAlt} />}
    {channel === 'email' && <FontAwesomeIcon fixedWidth icon={faEnvelope} />}
    {channel === 'sms' ? ' SMS' : ' EMail'}
  </>
)

const SentRenderer = ({ data: { sent, overLimit, optedOut } }) =>
  sent ? (
    <>Yes</>
  ) : (
    <>
      No&nbsp;
      {overLimit && <FontAwesomeIcon icon={faTachometerAlt} title="Rate Limit Exceeded" />}
      {optedOut && <FontAwesomeIcon icon={faUserSlash} title="Opted Out" />}
    </>
  )

const ThreadLinkRenderer = (params) => {
  if (!params?.data?.threadId && !params?.data?.recipientId) {
    return ''
  }
  return (
    <div>
      <Link to={`/admin/view-thread/${params.data.recipientId}/${params.data.threadId}`}>{address(params)}</Link>
    </div>
  )
}

export const notificationsGridColumns = (): (ColDef | ColGroupDef)[] => [
  { field: 'id', headerName: 'Id', width: 300, hide: true },
  {
    field: 'messageType',
    headerName: 'Type',
    width: 150,
    cellRenderer: 'agGroupCellRenderer',
    valueFormatter: ({ value }) => value || 'Unknown',
  },
  {
    field: 'channel',
    headerName: 'Channel',
    width: 100,
    filter: 'agTextColumnFilter',
    cellRenderer: ChannelRenderer,
  },
  {
    field: 'dateSent',
    headerName: 'Date Sent',
    width: 170,
    valueFormatter: DateValueFormatter,
    filter: 'agDateColumnFilter',
    sort: 'desc',
  },
  {
    field: 'sent',
    headerName: 'Sent',
    width: 100,
    cellRenderer: SentRenderer,
  },
  {
    field: 'senderName',
    headerName: 'Sender Name',
    width: 300,
    valueFormatter: (params) => params.value?.toUpperCase()
  },
  {
    field: 'recipientName',
    headerName: 'Recipient Name',
    width: 200,
    valueFormatter: (params) => params.value?.toUpperCase(),
  },
  {
    field: 'recipientContact',
    headerName: 'Recipient Contact',
    width: 200,
    valueGetter: (params) =>
      params.data.channel === 'sms' ? formatPhoneNumber(params.data.recipientPhone) : params.data.recipientEmail,
  },
  {
    field: 'propertyId',
    headerName: 'Property',
    width: 300,
    cellRenderer: LinkRenderer,
    cellRendererParams: { prefix: '/admin/propertyedit' },
    filter: 'agTextColumnFilter',
  },
  {
    field: 'senderId',
    headerName: 'Sender Id',
    cellRenderer: LinkRenderer,
    cellRendererParams: { prefix: '/admin/userprofile' },
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    field: 'recipientId',
    headerName: 'Recipient Id',
    cellRenderer: LinkRenderer,
    cellRendererParams: { prefix: '/admin/userprofile' },
    filter: 'agTextColumnFilter',
    hide: true,
  },
  { field: 'threadId', headerName: 'Address', width: 300, cellRenderer: ThreadLinkRenderer },
]
