import React from "react";
import {useNavigate} from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "../config";

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();
// console.log({config}, process.env)

// Add any other providers here...
const Providers = ({children}) => {
    const navigate = useNavigate()

    const onRedirectCallback = (appState) => {
        // console.log('appState Data', appState);
        navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
    }

    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            useRefreshTokens
            cacheLocation={'localstorage'}
            onRedirectCallback={onRedirectCallback}
            // there is apparently some issue with Firefox/Android using localStorage for
            //  storing transaction state, so we are using cookies
            // ref: https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html#useCookiesForTransactions
            useCookiesForTransactions
            authorizationParams={{
                redirect_uri: window.location.origin,
                ...(config.audience ? { audience: config.audience } : null),
                ...(config.scope ? { scope: config.scope } : null),
            }}
        >
            {children}
        </Auth0Provider>
    )
}

export default Providers;
