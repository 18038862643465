import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'

// AG Grid modules
import { ModuleRegistry } from '@ag-grid-community/core'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'

// fontawesome
import initFontAwesome from './utils/initFontAwesome'
import { Toaster } from 'react-hot-toast'
import { findUser, getUser, getUserByEmail, getUserByPhone, getUserBySub } from './lib/data/bluebidData'

import { AuthContext, MapContext, PropertyContext } from './constants/context'

// styles
import './App.scss'
import styled from 'styled-components'

import { useSessionStorage } from './lib/utils'
import { getConfig } from './config'

import BlockUI from './components/BlockUI'

import AdminSideBarMenu from './views/admin/AdminSideBarMenu'
import AdminContent from './views/admin/AdminContent'
import { LoginHero } from './components/adminActions/LoginHero'

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig()

initFontAwesome()

ModuleRegistry.registerModules([
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  MasterDetailModule,
  SetFilterModule,
  ExcelExportModule,
  MenuModule,
])

const toastOpts = {
  // Define default options
  className: '',
  duration: 5000,
  style: {
    background: '#363636',
    color: '#fff',
  },
  // Default options for specific types
  success: {
    duration: 3000,
    theme: {
      primary: 'green',
      secondary: 'black',
    },
  },
}

const AdminApp = () => {
  const { user, error, loginWithRedirect, logout, getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  const [sidebarIsOpen, setSidebarOpen] = useState(true)
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen)

  const [accessToken, setAccessToken] = useState(null)
  const [bluebidUser, setbluebidUser] = useState(undefined)
  const [mapInstance, setMapInstance] = useState(undefined)
  const [mapApi, setMapApi] = useState(undefined)
  const [mapHoverPropertyId, setMapHoverPropertyId] = useState(undefined)
  const [mapW, setMapW] = useState(0)
  const [mapH, setMapH] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isShowPropertiesFilters, setShowPropertiesFilters] = useState(false)
  const [propertiesFilters, setPropertiesFilters] = useState(undefined)

  const [properties, setProperties] = useState([])
  const navigate = useNavigate()
  const [lastPage, setLastPage] = useSessionStorage('lastPage', '')

  const propertyContext = useMemo(
    () => ({
      properties,
      isShowPropertiesFilters,
      propertiesFilters,
      updateProperties: (p) => {
        setProperties(p)
      },
      togglePropertiesFilters: () => setShowPropertiesFilters(!isShowPropertiesFilters),
      openPropertiesFilters: () => setShowPropertiesFilters(true),
      closePropertiesFilters: () => setShowPropertiesFilters(false),
      updatePropertiesFilters: (filters) => {
        // console.log('filters222', filters)
        setPropertiesFilters(filters)
      },
    }),
    [properties, propertiesFilters, isShowPropertiesFilters]
  )

  const mapContext = useMemo(
    () => ({
      // google map value
      mapInstance,
      mapApi,
      mapH,
      mapW,
      mapHoverPropertyId,
      updateMapHoverProperty: (propertyId) => setMapHoverPropertyId(propertyId),
      updateMapDims: (h, w) => {
        setMapW(w)
        setMapH(h)
      },
      updateMapInstance: (i) => {
        setMapInstance(i)
      },
      updateMapApi: (a) => {
        setMapApi(a)
      },
    }),
    [mapInstance, mapApi, mapH, mapW, mapHoverPropertyId]
  )

  // This context provides global access to the user profile (if logged in), as well
  // as the login and logout actions
  const authContext = useMemo(
    () => ({
      // the user object, if logged in
      bluebidUser,

      // access token, if logged in. (We will use this later)
      accessToken,

      // Call this if the bluebid user profile is updated.
      updateUser: (user) => setbluebidUser(user),

      refetchUser: async () => {
        getUser(bluebidUser.id, 'properties,favorites,mapImages&height=474').then((usr) => setbluebidUser(usr))
      },

      // login
      signIn: () => loginWithRedirect(),

      // logout
      signOut: () => {
        setAccessToken(null)
        setbluebidUser(undefined)
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      },
      getToken: () => {
        return getAccessTokenSilently({
          // @ts-ignore
          audience: config.audience,
          scope: config.scope,
        })
      },
    }),
    [bluebidUser, accessToken, loginWithRedirect, logout]
  )

  const getBluebidUser = async (user) => {
    // First attempt to get bluebid user from auth0 idToken custom claims
    // (custom claims are added by our custom auth0 login hook the runs post login)
    const claims = await getIdTokenClaims()
    // const userId = claims['https://bluebid.app/id']
    // if (userId) {
    //   return {
    //     id: userId,
    //     email: claims.email,
    //     firstName: claims.given_name,
    //     lastName: claims.family_name,
    //     picture: claims.picture,
    //     sub: claims.sub,
    //     emailVerified: true,
    //   }
    // }
    // claim not found on idToken, fallback to calling API
    // (this is only needed if a user has not logged out/back in recently)
    return findUser(claims?.email, claims?.phone, claims?.sub)
  }

  const getBBUser = (user) => {
    if (user.email) return getUserByEmail(user.email)
    if (user.phone) return getUserByPhone(user.phone)
    return getUserBySub(user.sub)
  }

  const isAdmin = (user) => user.roles?.includes('admin')

  useEffect(() => {
    if (user) {
      setLoading(true)
      authContext
        .getToken()
        .then((token) => {
          // now make a call to Auth0 to get the user's access token. This will be set to
          // localStorage to make available for all apiCall invocations.
          localStorage.setItem('token', token as any)
          getBluebidUser(user).then((u) => {
            if (!isAdmin(u)) {
              console.log('user was not an admin')
              authContext.signOut()
              return
            }

            setLoading(false)
            setbluebidUser(u)
          })
        })
        .catch((e) => {
          console.error(e)
          setLoading(false)
        })
    } else {
      setbluebidUser(undefined)
    }
  }, [user, lastPage, getAccessTokenSilently, navigate, setLastPage])

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  // if we had a login interstitial then lastPage will be in the session. This prevents the
  // home page from rendering before we redirect to the previous location the user was on
  if (lastPage !== '') {
    return <></>
  }

  return (
    <AuthContext.Provider value={authContext}>
      <MapContext.Provider value={mapContext}>
        <PropertyContext.Provider value={propertyContext}>
          <AppContainer id="app">
            <BlockUI blocking={loading} />

            <Toaster position="top-center" reverseOrder={false} gutter={8} toastOptions={toastOpts} />
            {!bluebidUser && <LoginHero />}
            {bluebidUser && (
              <>
                <AdminSideBarMenu toggle={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
                <AdminContent toggle={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
              </>
            )}
          </AppContainer>
        </PropertyContext.Provider>
      </MapContext.Provider>
    </AuthContext.Provider>
  )
}

export default AdminApp

const AppContainer = styled.div.attrs({
  className: 'app-container',
})`
  display: flex;
  width: 100%;
  align-items: stretch;
  font-family: Montserrat, serif, sans-serif;
  font-size: 11px;
  height: 100%;
`
