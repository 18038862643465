import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Container, Card, CardBody, Row, Col, CardHeader, CardText, CardImg } from 'reactstrap'

import { License, formatPhoneNumber } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { getLicensesByZipcode, BlockUI } from 'bb-lib-desktop'

import { AuthContext } from '../../constants/context'
import Loading from '../../components/Loading'
import { ZipDetailProperties } from './ZipDetailProperties'
import { ZipDetailInvites } from './ZipDetailInvites'

const LicenseCard: React.FC<{
  license: License
}> = ({ license }) => {
  return (
    <Card className="mb-4">
      <CardHeader>
        <Link to={`/admin/group/${license.group?.id}/zipcodes`}>License: {license.group?.name || '(Untitled)'}</Link>
      </CardHeader>
      <CardBody>
        <Row className="mb-3">
          {license.branding?.photoUrl && (
            <Col md="4">
              <CardImg
                src={license.branding.photoUrl}
                alt="Branding Photo"
                className="img-fluid"
                style={{ width: '150px' }}
              />
            </Col>
          )}
          <Col>
            <CardText>
              <strong>Display Label:</strong> {license.branding?.label || 'None'}
            </CardText>
            <CardText>
              <strong>Phone:</strong> {formatPhoneNumber(license.branding?.phone)}
            </CardText>
            <CardText>
              <strong>Email:</strong> {license.branding?.email || 'None'}
            </CardText>
            <CardText>
              <strong>Website:</strong> {license.branding?.websiteUrl || 'None'}
            </CardText>
            <CardText>
              <strong>Website Label:</strong> {license.branding?.websiteUrlLabel || 'None'}
            </CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const ZipDetail: React.FC = () => {
  const { bluebidUser } = useContext(AuthContext)
  const { zipcode } = useParams()
  const [licenses, setLicenses] = useState<License[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const refresh = async () => {
    setIsLoading(true)

    if (!zipcode) {
      return
    }

    const response = await getLicensesByZipcode(zipcode)

    if (isSuccessResponse(response)) {
      setLicenses(response.data || [] as any)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    refresh()
  }, [bluebidUser])

  return (
    <>
      <BlockUI blocking={isLoading} />

      {!isLoading && (
        <>
          <Container>
            <h3 className="my-3">{zipcode}</h3>
            <Row>
              {(licenses || []).map((license) => (
                <Col xs={6}>
                  <LicenseCard key={license.groupId} license={license} />
                </Col>
              ))}
            </Row>
            <Row>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Card className="mb-4">
                  <CardBody>
                    <ZipDetailProperties zipcode={zipcode} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="mb-4">
                  <CardHeader>
                    <strong>Invites</strong>
                  </CardHeader>
                  <CardBody>
                    <ZipDetailInvites zipcode={zipcode} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  )
}

export default withAuthenticationRequired(ZipDetail, {
  onRedirecting: () => <Loading />,
})
