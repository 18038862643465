import React, { useState } from 'react'
import classNames from 'classnames'
import { Collapse, NavItem, NavLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DarkDarkGrey } from '../../constants/colors'

export const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true)
  const toggle = () => setCollapsed(!collapsed)
  const { icon, title, items } = props

  return (
    <MenuGroup>
      <MenuNavItem onClick={toggle} className={classNames({ 'menu-open': !collapsed })}>
        <NavLink className="dropdown-toggle bb-navlink" style={{ cursor: 'pointer' }}>
          <MenuIcon>
            <FontAwesomeIcon icon={icon} className="mr-2" />
          </MenuIcon>
          {title}
        </NavLink>
      </MenuNavItem>
      <CollapseGroup isOpen={!collapsed} navbar className={classNames('items-menu', { 'mb-1': !collapsed })}>
        <div className="collapse-inner">
          {items.map((item, index) => (
            <MenuNavItem key={index} className="pl-4">
              <MenuNavLink tag={Link} to={item.target}>
                {item.title}
              </MenuNavLink>
            </MenuNavItem>
          ))}
        </div>
      </CollapseGroup>
    </MenuGroup>
  )
}

export const TopMenu = (props) => {
  const { icon, title, target } = props

  return (
    <MenuGroup>
      <MenuNavItem >
        <TopNavContainer className="bb-navlink" style={{ cursor: 'pointer' }}>
          <MenuIcon>
            <FontAwesomeIcon icon={icon} className="mr-2" />
          </MenuIcon>
          <TopNavItem tag={Link} to={target}>
            {title}
          </TopNavItem>
        </TopNavContainer>
      </MenuNavItem>
    </MenuGroup>
  )
}

const TopNavContainer = styled.div`
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    > span {
        color: white;
    }
`

const MenuGroup = styled.div.attrs({ className: 'menu-group' })``

const CollapseGroup = styled(Collapse).attrs({ className: 'collapse-group' })`
  margin: 0 1em;
  border-radius: 4px;
  background-color: white;
  color: #0066f9;
`

const TopNavItem = styled(NavItem)`
    font-family: 'Montserrat', serif, sans-serif;
    font-size: 13px;
    color: white;
    text-decoration: none;
    &:hover {
      font-weight: bold;
      color: white;
      text-decoration: none;
    }
`
const MenuNavItem = styled(NavItem)`
    font-family: 'Montserrat', serif, sans-serif;
    font-size: 13px;
    &:hover {
      font-weight: bold;
      color: white;
      text-decoration: none;
    }

    a {
        &.dropdown-toggle::after {
            display: inline-flex;
            float: right;
            top: 4px;
            position: relative;
        }
    }    
`

const MenuNavLink = styled(NavLink)`
  color: ${DarkDarkGrey};

  :hover,
  :active {
    font-weight: bold;
  }
  &.dropdown-toggle::after {
    display: inline-flex;
    float: right;
    top: 4px;
    position: relative;
  }
`

const MenuIcon = styled.span`
  min-width: 25px;
  display: inline-block;
  text-align: center;
`

// export default SubMenu
