import React, { useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { InputGroup, Input, ListGroup, ListGroupItem, Button } from 'reactstrap'
import { onlyText } from 'react-children-utilities'
import styled from 'styled-components'

import { AdminSearchTypes, SearchResults } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'

import { adminSearch } from '../lib'

type AdminSearchProps<T> = {
  searchType: AdminSearchTypes
  renderSearchResult: (searchResult: T) => React.JSX.Element
  searchTransformFn?: (search: string) => string
  searchResultsFilterFn?: (searchResults: SearchResults<T>) => SearchResults<T>
  onSearchResultSelect?: (searchResult: T) => void
  extraSearchParams?: string[]
  placeholder?: string
  enableAdhoc?: boolean
  onAdhoc?: (value: string) => void
}

export const AdminSearch = <T,>({
  searchType,
  renderSearchResult,
  searchTransformFn,
  searchResultsFilterFn,
  onSearchResultSelect,
  extraSearchParams,
  placeholder,
  enableAdhoc,
  onAdhoc,
}: AdminSearchProps<T>) => {
  const [value, setValue] = useState<string>()
  const [searchResults, setSearchResults] = useState<SearchResults<T>>()
  const [selectedSearchResult, setSelectedSearchResult] = useState<T>()
  const [adhocValue, setAdhocValue] = useState<string>()
  const [showSearchResults, setShowSearchResults] = useState(false)
  const charCountThreshold = 1

  const handleOnFocus = () => {
    setShowSearchResults(value?.length > charCountThreshold)
  }

  const handleOnBlur = () => {
    setTimeout(() => setShowSearchResults(false), 300)
  }

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const search = e.target.value || ''

    setValue(search)

    if (search.length > charCountThreshold) {
      const searchStr = searchTransformFn ? searchTransformFn(search) : search

      const response = await adminSearch<T>(searchType, searchStr, extraSearchParams, 1000)

      if (isSuccessResponse(response)) {
        const results = searchResultsFilterFn ? searchResultsFilterFn(response.data) : response.data

        setSearchResults(results)
        setShowSearchResults(true)
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // ENTER
    if (e.keyCode === 13) {
      if (enableAdhoc) {
        setAdhocValue(value)
        onAdhoc?.(value)
      }
    }
  }

  const handleSelectSearchResult = (searchResult: T) => {
    setSelectedSearchResult(searchResult)
    onSearchResultSelect?.(searchResult)
  }

  const handleClearSelectedSearchResult = () => {
    setSearchResults(undefined)
    setValue(undefined)
    setSelectedSearchResult(undefined)
    onSearchResultSelect?.(undefined)
  }

  const handleClearAdhocValue = () => {
    setSearchResults(undefined)
    setValue(undefined)
    setAdhocValue(undefined)
    onAdhoc?.(undefined)
  }

  return (
    <Component>
      {!!selectedSearchResult && (
        <InputGroup>
          <SearchInput value={onlyText(renderSearchResult(selectedSearchResult))} disabled={true} />
          <Button onClick={handleClearSelectedSearchResult}>X</Button>
        </InputGroup>
      )}
      {!!adhocValue && (
        <InputGroup>
          <SearchInput value={adhocValue} disabled={true} />
          <Button onClick={handleClearAdhocValue}>X</Button>
        </InputGroup>
      )}
      {!selectedSearchResult && !adhocValue && (
        <React.Fragment>
          <DebounceInput
            element={SearchInput}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            value={value}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
          {showSearchResults && (
            <SearchResultsContainer>
              {!searchResults?.items?.length && (
                <SearchResult key="admin-search-no-results" disabled>
                  <i className="text-center">(no results)</i>
                </SearchResult>
              )}
              {!!searchResults?.items?.length &&
                searchResults?.items?.map((searchResult, index) => (
                  <SearchResult
                    key={`admin-search-result-${index}`}
                    onClick={() => handleSelectSearchResult(searchResult)}
                  >
                    {renderSearchResult(searchResult)}
                  </SearchResult>
                ))}
            </SearchResultsContainer>
          )}
        </React.Fragment>
      )}
    </Component>
  )
}

const Component = styled.div.attrs({ className: 'admin-search' })`
  position: relative;
`

const SearchInput = styled(Input)``

const SearchResultsContainer = styled(ListGroup)`
  position: absolute;
  z-index: 1000;
`

const SearchResult = styled(ListGroupItem).attrs({ action: true, tag: 'button' })``
