import { GoogleMapType } from '@bluebid-sdk/core'

import { api } from 'bb-lib-desktop'

export function updateMapViewPhoto(
  propertyId: string,
  options: {
    url: string
    mapType: GoogleMapType
    lat: number
    lon: number
    replaceMainImage?: boolean
  }
) {
  return api<string>(`/properties/${propertyId}/mapView`, {
    method: 'POST',
    body: options,
  })
}
