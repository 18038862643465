import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CardBody, CardTitle, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faDollarSign, faHome } from '@fortawesome/free-solid-svg-icons'

import { Property } from '@bluebid-sdk/core'

import { AdminCard, AdminLabel, AdminValue } from './Styles'
import { currencyOrNone, pctOrNone, relativeDate } from '../../../lib/utils'
import { getEnv } from '../../../config'
import { useAgentReferral } from '../hooks/useAgentReferral'
import { EditFactsAction } from '../../../components/actions/EditFactsAction'
import { UpdateBluebidEstimate } from '../../../components/adminActions/UpdateBluebidEstimate'
import { AdminSecondaryActionButton } from '../../../components/adminActions/common'
import { SetLocationAction } from '../../../components/adminActions/SetLocationAction'
import { UnclaimPropertyAction } from '../../../components/adminActions/UnclaimPropertyAction'
import { DeletePropertyAction } from '../../../components/adminActions/DeletePropertyAction'
import { MarketStatus } from './MarketStatus'
import { ClipboardCopy } from '../../../components/adminActions/CopyClipboard'
import { getUniqueAddressViews } from '../../../lib/data/bluebidData'
import { UniqueViewsModal } from '../UniqueViewsModal'

export const AdminPropertyInfo: React.FC<{ property: Property; onEditComplete?: () => void }> = ({ property, onEditComplete }) => {
  const { propertyInvite } = useAgentReferral(property)
  const navigate = useNavigate()
  const [detail, setDetail] = useState(property?.detail)
  const [uniqueViews, setUniqueViews] = useState([])
  const [showUniqueViews, setShowUniqueViews] = useState(false)

  useEffect(() => {
    getUniqueAddressViews(property.address).then(uv => {
      setUniqueViews(uv)
    })
  }, [])

  if (!property) return <></>

  const Unavailable = 'unavailable'
  const deeds = detail?.deeds
  const latestDeed = deeds?.[0]
  let dateStr = latestDeed?.recording_date
  if (dateStr) {
    let parts = dateStr.split('-')
    let date = new Date(+parts[0], +parts[1] - 1, +parts[2])
    dateStr = date.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
  }

  const toggleUniqueViews = () => {
    setShowUniqueViews(!showUniqueViews)
  }

  return <AdminCard>
    <CardBody>
      <CardTitle tag={'h5'}>
        Property Info
        <span style={{ float: 'right' }}>
          <Link style={{paddingLeft: '10px'}} to={`${getEnv().appHost}/claimedProperty/${property.propertyId}`} target={'_blank'}>
            <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
          </Link>
        </span>
      </CardTitle>
      <Row>
        <Col sm={8}>
          <Row>
            <AdminLabel md={4}>Address</AdminLabel>
            <AdminValue md={7}>
              <ClipboardCopy copyText={property.address}>{property.address}</ClipboardCopy>
            </AdminValue>
          </Row>
          <Row>
            <AdminLabel md={4}>County</AdminLabel>
            <AdminValue md={7}>{detail?.parcel?.county_name}</AdminValue>
          </Row>
          <Row>
            <AdminLabel md={4}>FIPS</AdminLabel>
            <AdminValue md={7}>{detail?.parcel?.fips_code}</AdminValue>
          </Row>
          <Row>
            <AdminLabel md={4}>APN</AdminLabel>
            <AdminValue md={7}>{detail?.parcel?.apn_original}</AdminValue>
          </Row>
          <br />

          <Row>
            <AdminLabel md={4}>Owner's Price</AdminLabel>
            <AdminValue md={7}>{currencyOrNone(detail?.owner_sell_price, Unavailable)}</AdminValue>
          </Row>
          <Row>
            <AdminLabel md={4}>B.A.C.</AdminLabel>
            <AdminValue md={7}>{pctOrNone(detail?.buyers_agent_commission, Unavailable)}</AdminValue>
          </Row>
          <br />

          <Row>
            <AdminLabel md={4}>Bluebid Estimate</AdminLabel>
            <AdminValue md={7}>{currencyOrNone(detail?.bluebid_estimate?.value, Unavailable)}</AdminValue>
          </Row>
          <br />

          <Row>
            <AdminLabel md={4}>Market Estimate</AdminLabel>
            <AdminValue md={7}>{currencyOrNone(detail?.valuation?.value, Unavailable)}</AdminValue>
          </Row>
          <Row>
            <AdminLabel md={4}> Market Range</AdminLabel>
            <AdminValue md={7}>{currencyOrNone(detail?.valuation?.low)} - {currencyOrNone(detail?.valuation?.high, Unavailable)}</AdminValue>
          </Row>
          <Row>
            <AdminLabel md={4}>Last Sale Price</AdminLabel>
            <AdminValue md={7}>{currencyOrNone(latestDeed?.sale_price, Unavailable)}</AdminValue>
          </Row>
          <Row>
            <AdminLabel md={4}>Last Sale Date</AdminLabel>
            <AdminValue md={7}>{dateStr}</AdminValue>
          </Row>
          <br />

          <Row>
            <AdminLabel md={4}>Unique Views</AdminLabel>
            <AdminValue md={7}>
              <a href="#" onClick={() => toggleUniqueViews()}>{uniqueViews.length}</a>
            </AdminValue>
          </Row>
          <br />

          <Row>
            <AdminLabel md={4}>Referral Info</AdminLabel>
            <AdminValue md={7}>
              {propertyInvite && <>
                <Link to={`/admin/userprofile/${propertyInvite.sender}`}>{propertyInvite.sender}</Link>
                <br />
                {relativeDate(propertyInvite.createdAt)}
              </>}
            </AdminValue>
          </Row>
        </Col>

        <Col sm={3}>
          <EditFactsAction property={property} onUpdate={(detail) => {
            property.detail = detail
            setDetail(detail)
          }} />
          <UpdateBluebidEstimate property={property} shouldUpdateProfile={(newEstimate) => {
            setDetail({
              ...property.detail,
              bluebid_estimate: {
                value: newEstimate
              }
            })
            onEditComplete()
          }} />
          <SetLocationAction location={property.location} propertyId={property.propertyId} />

          <br />
          <br />
          <AdminSecondaryActionButton onClick={() => navigate(`/admin/propertyoffers/${property.propertyId}`)}>
            <FontAwesomeIcon icon={faDollarSign} /> Show Bluebids
          </AdminSecondaryActionButton>
          <AdminSecondaryActionButton
            onClick={() => navigate(`/admin/list-admin-history/${property.propertyId}`)}>
            <FontAwesomeIcon icon={faClock} /> Admin History
          </AdminSecondaryActionButton>
          <br />
          <br />

          <UnclaimPropertyAction propertyId={property.propertyId} redirect={'/'} afterAction={undefined} />
          <DeletePropertyAction propertyId={property.propertyId} redirect={'/'} afterAction={undefined} iconOnly={undefined} />

        </Col>
      </Row>

      <Row>
        <AdminLabel md={4}>Listing Status</AdminLabel>
        <Col xs={12}>
          <MarketStatus property={property} />
        </Col>
      </Row>

    </CardBody>

    <UniqueViewsModal
      isOpen={showUniqueViews}
      onClose={toggleUniqueViews}
      address={property.address}
    />
  </AdminCard>
}
