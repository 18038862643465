import { ActionButton, BodyWrapper, FlexContainer, FlexItem, FlexTitle, FlexWrap } from './AdminStyles'
import { Col, Container, Row } from 'reactstrap'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserSearch } from '../../components/UserSearch'
import { VPad } from '../../components/EditDetails'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../../components/Loading'

const SearchUsers = () => {
    const selectEmail = (s) => setUser(s.data)
    const [user, setUser] = useState()
    const navigate = useNavigate()

    const gotoUser = () => navigate(`/admin/userprofile/${user.id}`)

    return (
        <BodyWrapper>
            <Container>
                 <Row>
                     <Col xs={12}>
                         <FlexWrap>
                             <FlexContainer>
                                 <FlexTitle>
                                     <h4 className='display-6'>Search for User
                                     </h4>
                                 </FlexTitle>
                                 <FlexItem className='fill'>
                                     <UserSearch onSelect={selectEmail} />
                                 </FlexItem>
                                 <FlexItem>
                                     <VPad height={40} />
                                     <ActionButton className='btn' color='primary'
                                                   disabled={user === undefined}
                                                   onClick={gotoUser}>Edit User</ActionButton>
                                     {/*<ActionButton className='btn float-right mr-0' color='primary'*/}
                                     {/*              onClick={goToUsersList}>List Users</ActionButton>*/}
                                 </FlexItem>
                             </FlexContainer>
                         </FlexWrap>
                     </Col>
                 </Row>
            </Container>
        </BodyWrapper>
    )
}

export default withAuthenticationRequired(SearchUsers, {
    onRedirecting: () => <Loading/>,
})

