import React, { useCallback, useMemo, useRef } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ModuleRegistry } from '@ag-grid-community/core'

import { Datasource } from 'bb-lib-desktop'
import { GridContainerStyle, GridStyle, StandardColumnDefs } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import { useSessionStorage } from '../../lib/utils'
import { cols } from './ListProperties'
import { restoreGridState } from '../../components/grid/GridRenderers'
import { GridListHeader } from './GridListHeader'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])

/**
 * @deprecated use ZipDetailProperties
 */
const CityDetailProperties = ({ state, city }) => {
  const gridRef = useRef()

  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])
  const datasource = useMemo(() => {
      return new Datasource({
        index: 'properties',
        query: `detail.address.city:${city} AND detail.address.state:${state}`,
        fields: 'owner',
        adminSearch: true,
      })
    }
    , [city, state])

  const [colState, setColState] = useSessionStorage('cityProperties', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef),[])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(),[])

  const getRowId = useCallback(function(params) {
    return params.data.propertyId
  }, [])

  const columnDefs = useMemo(() => cols, [])

  return (
      <div style={{ ...containerStyle, height: '300px' }}>
        <GridListHeader title={'City Properties'} onRefresh={onBtnRefresh} />
        <div style={{ ...gridStyle, height: 'calc(100% - 70px)'}} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            getRowId={getRowId}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection='true'
            rowSelection='multiple'
            rowModelType='serverSide'
            serverSideDatasource={datasource}
            serverSideInfiniteScroll={true}
            suppressRowClickSelection='true'
          />
        </div>
      </div>
  )
}

export default withAuthenticationRequired(CityDetailProperties, {
  onRedirecting: () => <Loading />,
})
