import { Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { AddressSearcher } from './AddressSearcher'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { relativeDate } from '../lib/utils'
import { addAddress, addAddressAlias, deleteAddressById, excludeAddress, getAddress, getAddressAliases } from '../lib/data/bluebidData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faPlus, faQuestion, faQuestionCircle, faSync, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { AdminActionButton } from './adminActions/common'
import styled from 'styled-components'
import { IconActionButton } from './actions/common'
import confirm from './Confirm'
import { ModalSubHeader } from './actions/ManagePhotosAction'
import { ReactTooltip } from './detail/commonStyles'
import { GetDataPopup } from '../views/admin/CreateClaim'
import { VPad } from './EditDetails'
import { MapAddressBrowser } from './MapAddressBrowser'
import { successToast } from '../utils/common'
import AutoComplete from './AutoComplete'
import { GoogleAutoComplete } from './GoogleAutoComplete'

const AddressContext = React.createContext()

export const AddressManager = () => {
    // the master address in the DB - could be different from above or the same
    const [master, setMaster] = useState()
    // any aliases found
    const [aliases, setAliases] = useState([])

    const addressContext = useMemo(() => ({
        master,
        aliases,
        updateMaster: async (master) => {
            if (master) {
                await getAddressAndAliases(master)
            } else {
                setMaster(undefined)
                setAliases([])
            }
        },
        updateAliases: async (aliases) => {
            setAliases([])
            setAliases(aliases || [])
        },
    }), [master, aliases])

    const getAddressAndAliases = (address) => {
        if (address.aliasForId) {
            // find and show the parent of this alias
            getAddress(address.aliasForId)
                .then(itemParent => {
                    console.log('master', itemParent)
                    setMaster(itemParent)
                    getAddressAliases(itemParent.id)
                        .then(result => {
                            console.log('aliases', result)
                            setAliases(result?.items || [])
                        })
                })
        } else {
            // see if there are any children
            setMaster(address)
            getAddressAliases(address.id)
                .then(result => {
                    console.log('aliases', result)
                    setAliases(result?.items || [])
                })
        }
    }

    // user selected item in pull-down
    const selectAddress = async (item) => {
        await getAddressAndAliases(item)
    }

    const onStartAddMasterAddress = () => {
        setMaster(undefined)
    }

    // user selected item in site select - ignore
    const handleSiteSelect = (item) => console.log(item)

    return <Container>
            <AddressContext.Provider value={addressContext}>
                <VPad height={20} />
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={10}>
                                <Label>Address Database</Label>
                                <AddressSearcher onSelect={selectAddress} />
                            </Col>
                            <Col md={2}>
                                <VPad height={24} />
                                <AddMasterAddressAction onStartAddAddress={onStartAddMasterAddress}/>
                            </Col>
                        </Row>
                        <Row>
                            <VPad height={20} />
                            <AddressInfo />
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Label>Google Search</Label>
                            <Row>
                                <GoogleAutoComplete onSelect={(place) => console.log(place)} />
                            </Row>
                        </Row>
                        <VPad height={20} />
                        <Row>
                            <Label>Test Site Search</Label>
                            <AutoComplete onSelect={handleSiteSelect}/>
                        </Row>
                    </Col>
                </Row>
            </AddressContext.Provider>
        </Container>
}

const HelpText = () => {
    return <HelpContainer>
        <p>This manages addresses used for searching and claiming:</p>
        <p>
            These addresses are combined with Google results. For any duplicates this address will be used.
            <ul>
                <li>Correcting Google lat/lng values for an address</li>
                <li>Adding addresses that Google does not include</li>
                <li>Excluding results from Google</li>
            </ul>
        </p>
        <p>Procedure:</p>
        <ol>
            <li>Search for Bluebid addresses in the search box<br/>
                <b>- OR -</b>
                <br/>
                Use the Plus button to add a new master address</li>
            <li>Manage address aliases after finding or creating a master address</li>
            <li>Use the Exclude button to prevent the address from appearing</li>
        </ol>
        {/*<p style={{color: 'red', textAlign: 'center', fontWeight: 'bold'}}>*/}
        {/*    Changes are made immediately!*/}
        {/*</p>*/}
    </HelpContainer>
}
const HelpContainer = styled.div`
    font-size: 10pt;
`

const AddressInfo = () => {
    const { master, aliases, updateMaster, updateAliases } = useContext(AddressContext)
    const [excluded, setExcluded] = useState(master?.exclude || false)

    if (!master) return <></>

    const onGetData = (data) => {
        console.log(data)
    }

    const onMasterDeleted = async () => {
        await updateMaster(undefined)
        successToast('Master address was deleted')
    }
    const onChildDeleted = () => {
        successToast('Alias address was deleted')
        setTimeout(updateAddresses, 2000)
    }
    const onAdded = () => {
        successToast('Item was added')
        setTimeout(updateAddresses, 2000)
    }

    const toggleMasterExclude = async () => {
        if (master) {
            let state = !excluded
            let updated = await excludeAddress(master.id, state)
            console.log('exclude master', updated)
            setExcluded(state)
        }
    }

    const toggleAliasExclude = async (alias) => {
        let state = !alias.exclude
        let updated = await excludeAddress(alias.id, state)
        alias.exclude = state
        updateAliases(aliases)
        console.log('exclude alias', updated)
    }

    const updateAddresses = () => {
        updateMaster(master)
    }

    return (
        <>
            <ReactTooltip multiline={true} type='success' effect='solid' place='top' />
            <Row>
                <Col md={12}>
                    <SectionLabel
                        data-tip={'The master address is used to Claim and obtain ATOM data. Any aliases added below will resolve to this address.'}>
                        Master Address <FontAwesomeIcon icon={faQuestion} />
                    </SectionLabel>
                </Col>
            </Row>
            <Row>
                <Col md={10}>
                    <MainAddress type='text' value={master?.address || ''} readOnly />
                </Col>
                <Col md={2}>
                    <DeleteAddressAction address={master} onDelete={onMasterDeleted} disabled={!!aliases?.length} />
                    <GetDataPopup address={master?.address} onSelect={onGetData}
                                  enabled={!!master}
                                  iconOnly={true}
                                  checkOnly={true}
                    />
                    <IconActionButton disabled={!master?.address} onClick={updateAddresses}>
                        <FontAwesomeIcon icon={faSync} />
                    </IconActionButton>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <SwitchContainer>
                        <FormGroup switch>
                            <Input type='switch' onChange={toggleMasterExclude} checked={excluded} className={'form-control'} />
                            <SwitchLabel check onClick={toggleMasterExclude}>Exclude this address from Search</SwitchLabel>
                        </FormGroup>
                    </SwitchContainer>
                </Col>
            </Row>
            <Row>
                <Col md={4} className={'offset-md-1'}>
                    <InfoLabel>Latitude</InfoLabel>
                    <InfoNumber type='text' value={master?.location?.lat || ''} readOnly />
                </Col>
                <Col md={4}>
                    <InfoLabel>Longitude</InfoLabel>
                    <InfoNumber type='text' value={master?.location?.lon || ''} readOnly />
                </Col>
            </Row>
            <Row>
                <Col md={4} className={'offset-md-1'}>
                    <InfoLabel>Created</InfoLabel>
                    <InfoNumber type='text' value={master?.createdAt ? relativeDate(master.createdAt) : ''} readOnly />
                </Col>
                <Col md={4}>
                    <InfoLabel>Updated</InfoLabel>
                    <InfoNumber type='text' value={master?.modifiedAt ? relativeDate(master.modifiedAt) : ''} readOnly />
                </Col>
            </Row>

            <VPad height={20} />
            <Row>
                <Col md={12}>
                    <SectionLabel
                        data-tip={'Each address below will resolve to the master address. This allows users to search for multiple address variants for a single address'}>
                        Address Aliases <FontAwesomeIcon icon={faQuestion} />
                    </SectionLabel>
                </Col>
            </Row>

            <>
                {
                    aliases.map((entry, ndx) => {
                        return (<>
                            <Row ndx={`alias-${ndx}`}>
                                <Col md={10}>
                                    <ItemLabel>Alias {ndx + 1}</ItemLabel>
                                    <Input type='text' value={entry?.address || ''} readOnly />
                                </Col>
                                <Col md={1}>
                                    <VPad height={35} />
                                    <DeleteAddressAction address={entry} onDelete={onChildDeleted} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <SwitchContainer>
                                        <FormGroup switch>
                                            <Input type='switch' onChange={e => toggleAliasExclude(entry)} checked={entry.exclude} className={'form-control'} />
                                            <SwitchLabel check >Exclude this address from Search</SwitchLabel>
                                        </FormGroup>
                                    </SwitchContainer>
                                </Col>
                            </Row>
                        </>)
                    })
                }
            </>
            <Row>
                <Col md={12}>
                    <VPad height={20} />
                    <AddChildAddressAction master={master} onAdd={onAdded} />
                </Col>
            </Row>
        </>
    )
}

const SwitchLabel = styled(Label)`
  font-size: 10pt;
  position: relative;
  top: 2px;
`

const InfoLabel = styled(Label)`
  margin-top: 10px;
  font-size: 9pt;
`
const InfoNumber = styled(Input)`
  font-size: 9pt;
  border-radius: 0;
`

const ItemLabel = styled(Label)`
  font-weight: bold;
  margin-top: 10px;
`

const SectionLabel = styled(Label)`
  font-weight: bold;
  font-size: 14px;
`

const MainAddress = styled(Input)`
  font-weight: bold;
`

const DeleteAddressAction = ({ address, onDelete, disabled }) => {

    // TODO: handle alias delete vs master delete
    const deleteAction = async (address) => {
        console.log('delete', address)
        let isAlias = !!address.aliasFor
        let title = isAlias ? `Delete alias: ${address.address}?` : `Delete address at: ${address.address}?`

        let result = await confirm({
            message: 'The address will not be deleted if it is a claimed property. Once deleted, the address will no longer be findable for unless Google has the address.',
            title: title,
            cancelText: 'Cancel',
            cancelColor: 'primary',
            confirmColor: 'danger',
            confirmText: 'Yes, delete',
        })
        if (result) {
            let delResult = await deleteAddressById(address.id)
            console.log(delResult)
            if (delResult.status === 'error') {
                await confirm({
                    title: 'Error occurred',
                    message: delResult.errorMessage,
                    confirmText: 'Ok',
                })
            } else {
                onDelete(delResult)
            }
        }
    }

    return (
        <DeleteActionButton disabled={disabled} onClick={e => deleteAction(address)}>
            <FontAwesomeIcon icon={faTrashAlt} />
        </DeleteActionButton>
    )
}

const PickAddress = ({ onAction }) => {
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)

    const pickAddress = (data) => {
        toggle()
        onAction(data)
    }
    return (
        <>
            <IconActionButton onClick={(e) => setModal(true)}>
                <FontAwesomeIcon icon={faGlobeAmericas} />
            </IconActionButton>

            <Modal isOpen={modal} size={'xl'}>
                <ModalBody>
                    <MapAddressBrowser onUseAddress={pickAddress} onCancel={toggle} />
                </ModalBody>
            </Modal>
        </>
    )
}

const AddMasterAddressAction = ({onStartAddAddress}) => {
    const { updateMaster } = useContext(AddressContext)
    const [modal, setModal] = useState(false)
    const [address, setAddress] = useState('')
    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)
    const [exclude, setExclude] = useState(false)
    const [, setHasData] = useState(false)

    let isValid = address.length > 0
    const toggle = () => setModal(!modal)
    const toggleExclude = () => setExclude(!exclude)

    useEffect(() => {
        return () => {
            setAddress('')
            setLatitude(0)
            setLongitude(0)
            setExclude(false)
            setHasData(false)
        }
    }, [modal])

    const updateAddress = (a) => {
        setAddress(a)
        setHasData(false)
    }
    const onGetData = (data) => {
        console.log(data)
    }

    const useAddress = (data) => {
        console.log(data)
        setAddress(data.address)
        setLatitude(data.latitude)
        setLongitude(data.longitude)
        setExclude(data?.exclude || false)
    }

    const startAddMasterAddress = () => {
        onStartAddAddress()
        setModal(true)
    }

    const addMasterAddress = () => {
        toggle()
        console.log('add address')
        addAddress(address, latitude, longitude, exclude)
            .then(result => {
                console.log(result)
                updateMaster(result)
            })
    }

    const onHelp = () => {
        confirm({
            title: 'Managing addresses',
            cancelText: '',
            bodyComponent: HelpText,
        })
    }


    return (
        <>
            <IconActionButton onClick={(e) => startAddMasterAddress()}>
                <FontAwesomeIcon icon={faPlus} />
            </IconActionButton>
            <IconActionButton onClick={onHelp}>
                <FontAwesomeIcon icon={faQuestionCircle} />
            </IconActionButton>

            <Modal isOpen={modal} toggle={toggle} size={'md'}>
                <ModalHeader toggle={toggle}>
                    <h3>Add address to search database</h3>
                </ModalHeader>
                <ModalBody>
                    <InfoRow>
                        <ol>
                            <li>
                                Use the Map browser to get initial location / address
                            </li>
                            <li>
                                Edit the address as needed below
                            </li>
                            <li>
                                Optionally check if ATOM data exists for the address
                            </li>
                            <li>
                                Check 'Exclude' to exclude this address from the search result
                            </li>
                            <li>
                                Click 'Add Address' to add the new address
                            </li>
                        </ol>
                    </InfoRow>
                    <Row>
                        <Col md={10}>
                            <Label>Address</Label>
                            <input className={'form-control'}
                                   value={address}
                                   onChange={(e) => updateAddress(e.target.value)}
                                   type='text' name='address' id='address' placeholder={'Address'}
                            />
                        </Col>
                        <Col md={2}>
                            <VPad height={30}></VPad>
                            <PickAddress onAction={useAddress} />
                            <GetDataPopup address={address} onSelect={onGetData}
                                          enabled={address.length > 0}
                                          iconOnly={true}
                                          checkOnly={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <SwitchContainer>
                                <FormGroup switch>
                                    <Input type='switch' onChange={toggleExclude} checked={exclude} className={'form-control'} />
                                    <Label check onClick={toggleExclude}>Exclude this address from Search</Label>
                                </FormGroup>
                            </SwitchContainer>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <AdminActionButton onClick={toggle}>Cancel</AdminActionButton>

                    <AdminActionButton disabled={!isValid} onClick={addMasterAddress}>
                        Add Address
                    </AdminActionButton>
                </ModalFooter>
            </Modal>
        </>
    )
}


const SwitchContainer = styled.div`
  padding: 10px;
`

const InfoRow = styled(Row)`
  ol {
    padding: 20px;
    margin-left: 10px;

    li {
      font-size: 12pt;
      margin-bottom: 5px;
    }
  }
`

const AddChildAddressAction = ({ onAdd }) => {
    const { master } = useContext(AddressContext)

    const [modal, setModal] = useState(false)
    const [childAddress, setChildAddress] = useState(master?.address || '')
    const [exclude, setExclude] = useState(false)

    const toggle = () => {
        setModal(!modal)
    }

    const startAddAlias = () => {
        setChildAddress(master?.address || '')
        setModal(true)
    }

    const toggleExclude = () => setExclude(!exclude)

    const addChildAddress = () => {
        addAddressAlias(master.id, childAddress, exclude)
            .then(result => {
                if (result.status !== 'error') {
                    onAdd(result)
                    setModal(false)
                } else {
                    confirm({
                        title: 'Error occurred',
                        message: result.errorMessage,
                        confirmText: 'Ok',
                    })
                }
            })
    }

    return (<>
            <AdminActionButton onClick={(e) => startAddAlias()}>
                <FontAwesomeIcon icon={faPlus} /> Add Alias
            </AdminActionButton>

            <Modal size='lg' isOpen={modal} backdrop='static'>
                <ModalHeader toggle={toggle}>
                    <div>
                        <h3>Add Alias Address For</h3>
                        <ModalSubHeader>
                            {master?.address}
                        </ModalSubHeader>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <ItemLabel>Enter the alias address:</ItemLabel>
                            <Input type='text' value={childAddress}
                                   onChange={(e) => setChildAddress(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <SwitchContainer>
                                <FormGroup switch>
                                    <Input type='switch' onChange={toggleExclude} checked={exclude} className={'form-control'} />
                                    <Label check onClick={toggleExclude}>Exclude this address from Search</Label>
                                </FormGroup>
                            </SwitchContainer>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <AdminActionButton onClick={toggle}>
                        Cancel
                    </AdminActionButton>

                    <AdminActionButton onClick={addChildAddress}>
                        Add Alias
                    </AdminActionButton>
                </ModalFooter>
            </Modal>
        </>
    )
}


export const DeleteActionButton = styled(IconActionButton)`
  min-width: revert;
  color: red;
`
