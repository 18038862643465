import styled from 'styled-components'
import { Button } from 'reactstrap'

export const AdminTitle = styled.h4`
margin-top: 20px;

`

export const AdminButton = styled(Button)`
    margin-top: 5px;
    border-radius: 4px;
    margin-right: 10px;
`
