import React, { useContext, useEffect, useState } from 'react'

import { useParams, Link } from 'react-router-dom'

import { AuthContext } from '../../constants/context'
import { addCityLicense, getAllGroups, getCityLicenses, prioritizeGroupForCity, removeCityLicense, saveCityGroupBranding } from '../../lib/data/bluebidData'
import ErrorScreen from '../../components/ErrorScreen'
import { ErrorBoundary } from 'react-error-boundary'
import { FormGroup, Input, Label, Form, Container, Button, Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, CardText, CardImg } from 'reactstrap'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { successToast, errorToast } from '../../utils/common'
import BlockUI from '../../components/BlockUI'
import { startCase } from 'lodash'
import ChangeTeamBrandingPhotoModal from '../../components/actions/ChangeTeamBrandingPhoto'
import CityDetailProperties from './CityDetailProperties'
import CityDetailInvites from './CityDetailInvites'

const LicenseCard = ({ license, onRemove, onModifyBranding, onModifyPhoto }) => {
    return (
        <Card className="mb-4">
            <CardHeader>
                <Link to={`/admin/group/${license.group?.id}`}>
                    Team Name: {license.group?.name || '(Untitled)'}
                </Link>
            </CardHeader>
            <CardBody>
                <Row className="mb-3">
                    {license.teamRep?.photoUrl && <Col md="2">
                        <CardImg
                            src={license.teamRep.photoUrl}
                            alt="Branding Photo"
                            className="img-fluid"
                            style={{ width: '150px' }}
                        />
                    </Col>}
                    <Col>
                        <CardText><strong>Display Label:</strong> {license.teamRep?.label || 'None'}</CardText>
                        <CardText><strong>Phone:</strong> {license.teamRep?.phone || 'None'}</CardText>
                        <CardText><strong>Email:</strong> {license.teamRep?.email || 'None'}</CardText>
                    </Col>
                </Row>
                <Button size="sm" color="danger" onClick={() => onRemove(license)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button size="sm" color="primary" onClick={() => onModifyBranding(license)} className="ml-3">
                    Modify Branding
                </Button>
                <Button size="sm" color="primary" onClick={() => onModifyPhoto(license)} className="ml-3">
                    Change Photo
                </Button>
            </CardBody>
        </Card>
  );
}

/**
 * @deprecated use ZipDetail
 */
const CityDetail = () => {
    const { bluebidUser } = useContext(AuthContext)
    const { state, city } = useParams()

    const [brandingModalOpen, setBrandingModalOpen] = useState(false)
    const [editedTeamBranding, setEditedTeamBranding] = useState({})

    const [photoModalOpen, setPhotoModalOpen] = useState(false)
    const [photoLicense, setPhotoLicense] = useState({})

    const [licenses, setLicenses] = useState([])
    const [teams, setTeams] = useState([])
    const [selectedTeam, setSelectedTeam] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const toggleBrandingModal = () => {
        setBrandingModalOpen(!brandingModalOpen)
    }

    const togglePhotoModal = (license) => {
        setPhotoLicense(license)
        setPhotoModalOpen(!photoModalOpen)
    }

    const handleBrandingInputChange = (event) => {
        const { name, value } = event.target;
        setEditedTeamBranding((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleModifyBranding = (license) => {
        setSelectedTeam(license.groupId)
        setEditedTeamBranding(license.teamRep)
        toggleBrandingModal()
    }

    const handlePhotoUploadSuccess = async (res) => {
        saveCityGroupBranding({
            groupId: photoLicense.groupId,
            city,
            state,
            branding: {
                ...photoLicense.teamRep,
                photoUrl: res.url
            }
        })
        .then(() => {
            refresh()
            successToast('Changed team branding photo')
            togglePhotoModal()
        })
        .catch(handlePhotoUploadError)
    }

    const handlePhotoUploadError = (err) => {
        console.error(err)
        errorToast('Could not change team branding photo')
        togglePhotoModal()
    }

    const requestFailed = (result) => result && result.status && result.status === 'error'

    const getFailureError = (result) => {
        if (result.errorMessage instanceof Array) {
            return new Error(result.errorMessage.join(', '))
        }

        if (result.errorMessage) {
            return new Error(result.errorMessage)
        }

        return new Error(result.statusCode || 'unknown error')
    }

    const handleSaveBranding = async (event) => {
        event.preventDefault()

        try {
            const s = await saveCityGroupBranding({
                groupId: selectedTeam,
                city,
                state,
                branding: editedTeamBranding
            })

            if (requestFailed(s)) {
                throw getFailureError(s)
            }

            refresh()
            successToast('Saved team branding')
            toggleBrandingModal();
        } catch (error) {
            errorToast(`Could not save branding: ${error.message}`)
        }
    }

    const handleAddLicense = () => {
        setIsLoading(true)

        addCityLicense({ groupId: selectedTeam, state, city })
            .then(res => {
                if (requestFailed(res)) {
                    throw getFailureError(res)
                }

                refresh()
                successToast('Added license')
            })
            .catch(err => {
                console.log('err', err)
                setIsLoading(false)
                errorToast('Could not add license')
            })
    };

    const handleRemoveLicense = (license) => {
        setIsLoading(true)
        const { groupId, city, state } = license

        removeCityLicense({ groupId, state, city })
            .then(res => {
                if (requestFailed(res)) {
                    throw getFailureError(res)
                }

                refresh()
                successToast('Removed license')
            })
            .catch(err => {
                console.log('err', err)
                setIsLoading(false)
                errorToast('Could not remove license')
            })
    };

    const handlePrioritizeLicense = (license) => {
        setIsLoading(true)
        const { groupId, city, state } = license

        prioritizeGroupForCity({ groupId, state, city })
            .then(res => {
                if (requestFailed(res)) {
                    throw getFailureError(res)
                }

                refresh()
                successToast('Prioritized team for city')
            })
            .catch(err => {
                console.log('err', err)
                setIsLoading(false)
                errorToast('Could not prioritize team for city')
            })
    };

    const refresh = () => {
        setIsLoading(true)

        getCityLicenses({ state, city })
            .then(res => {
                setLicenses(res || [])
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
            })

        getAllGroups()
            .then(res => {
                setTeams(res || [])
                setIsLoading(false)
            })
            .catch(err => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        refresh()
    }, [bluebidUser])

    return (<>
        <BlockUI blocking={isLoading} />

        { !isLoading &&
            <ErrorBoundary FallbackComponent={ErrorScreen}>
                <Container>
                    <h3 className="my-3">{ startCase(city) }, { state.toUpperCase() }</h3>
                    <Row>
                        <Col md={3}>
                            <Card className="mb-4">
                                <CardHeader><strong>Assign Licenses</strong></CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleAddLicense}>
                                        <FormGroup>
                                            <Label for="selectedTeam">Select a Team</Label>
                                            <Input
                                                type="select"
                                                id="selectedTeam"
                                                value={selectedTeam}
                                                onChange={(e) => setSelectedTeam(e.target.value)}
                                            >
                                            <option value="">Select...</option>
                                                {teams.map((team) => (
                                                    <option key={team.id} value={team.id}>
                                                        {team.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                        <Button color="primary" type="submit">
                                            Assign License
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col md={9}>
                            <Card className="mb-4">
                                <CardHeader><strong>Licenses</strong> ({licenses.length} of 2)</CardHeader>
                                <CardBody>
                                    <Row>
                                        {(licenses || []).map((license) => (
                                            <Col>
                                                <LicenseCard
                                                    key={license.groupId}
                                                    license={license}
                                                    onRemove={handleRemoveLicense}
                                                    onPrioritize={handlePrioritizeLicense}
                                                    onModifyBranding={handleModifyBranding}
                                                    onModifyPhoto={togglePhotoModal}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-4">
                                {/*<CardHeader><strong>Properties</strong></CardHeader>*/}
                                <CardBody>
                                    <CityDetailProperties city={city} state={state} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="mb-4">
                                {/*<CardHeader><strong>Invites</strong></CardHeader>*/}
                                <CardBody>
                                    <CityDetailInvites city={city} state={state} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Modal isOpen={brandingModalOpen} toggle={toggleBrandingModal}>
                    <ModalHeader toggle={toggleBrandingModal}>Modify Team Branding</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={handleSaveBranding}>
                            <FormGroup>
                                <Label for="photoUrl">Photo URL</Label>
                                <Input type="text" name="photoUrl" id="photoUrl" value={editedTeamBranding?.photoUrl} onChange={handleBrandingInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="label">Label</Label>
                                <Input type="text" name="label" id="label" value={editedTeamBranding?.label} onChange={handleBrandingInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" value={editedTeamBranding?.email} onChange={handleBrandingInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input type="text" name="phone" id="phone" value={editedTeamBranding?.phone} onChange={handleBrandingInputChange} />
                            </FormGroup>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggleBrandingModal}>Cancel</Button>{' '}
                                <Button color="primary" type="submit">Save</Button>
                            </ModalFooter>
                        </Form>
                    </ModalBody>
                </Modal>

                <ChangeTeamBrandingPhotoModal
                    license={photoLicense}
                    isOpen={photoModalOpen}
                    toggleModal={togglePhotoModal}
                    onUploadSuccess={handlePhotoUploadSuccess}
                    onUploadError={handlePhotoUploadError}
                />
            </ErrorBoundary>
        }
    </>)
}

export default withAuthenticationRequired(CityDetail, {
    onRedirecting: () => <Loading/>,
})
