import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const Histogram = ({title, dataset, barColor}) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };
    const labels = dataset.map(d => d.date)
    const dataVals = dataset.map(d => d.count)
    const data = {
        labels,
        datasets: [
            {
                label: title,
                data: dataVals,
                backgroundColor: barColor,
            },
        ],
    };

    return (
        <Bar options={options} data={data} />
    )
}
