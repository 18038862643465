import { Container, Card, CardBody, CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export const OtpPrompted = (userAction) => {
    const { address, location, phone } = userAction.data

    const lat = location.lat ?? '-'
    const lng = location.lng ?? '-'

    return (
        <Container>
            <Card>
                <CardBody>
                    <CardTitle tag="h5">{address}</CardTitle>
                    <a href={`https://google.com/maps/place/${encodeURIComponent(address)}`} rel="noreferrer" target="_blank" className="text-decoration-none">
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Open (Google Maps)
                    </a>
                    <Table striped className="mt-3">
                        <tbody>
                            <tr>
                                <td><strong>Location</strong></td>
                                <td>
                                    ({lat}, {lng})
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Phone</strong></td>
                                <td>
                                    {phone}
                                </td>
                            </tr>
                        </tbody>
                    </Table>            
                </CardBody>
            </Card>
        </Container>
    )
}