import React from 'react'
import { Container, Card, CardBody, CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

export const ZipcodeSearchedFor = (userAction) => {
  const { zipcode, location } = userAction.data

  const lat = location.lat ?? '-'
  const lng = location.lng ?? '-'

  return (
    <Container>
      <Card>
        <CardBody>
          <CardTitle tag="h5">{zipcode}</CardTitle>
          <a
            href={`https://google.com/maps/place/${zipcode}`}
            rel="noreferrer"
            target="_blank"
            className="pl-3 text-decoration-none"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} /> Open (Google Maps)
          </a>
          <Table striped className="mt-3">
            <tbody>
              <tr>
                <td>
                  <strong>Location</strong>
                </td>
                <td>
                  ({lat}, {lng})
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  )
}
