/**
 * Format property address from property detail info. This has to handle two cases:
 *    a. claimed properties - we have full address info within property.detail
 *    b. unclaimed properties - property.address contains the street address string
 *
 * @param property
 * @return {string}
 */
import {booleanPointInPolygon, point, polygon} from "./data/geom";
import {useEffect, useState} from "react";
import moment from 'moment-timezone'
import { EXPIRATION_DAYS } from '../constants/messsages'

export const GUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const addressFromDetail = (property) => {

    if (property?.address?.formatted_street_address) {
        let streetPart = `${property.address.formatted_street_address}, ${property.address.city}`
        return `${capitalizeEveryFirst(streetPart)}, ${property.address.state.toUpperCase()} ${property.address.zip_code}`
    }
    return formatPropertyAddress(property.address || property.id)
}

// capitalize all first letters in words
export const capitalizeEveryFirst = (words) => {
    if (words) {
        return words.toLowerCase().split(' ').map((word) => {
            return word ? word[0].toUpperCase() + word.substring(1) : word
        }).join(' ')
    }
    return ''
}

/**
 * Formats property address by
 *  1. Capitalizing first letter of each word
 *  2. Ensuring the State is CAPS
 *
 * @param address - address. Must be in format: '1 Main Street, Anytown, ST'
 *
 * @returns {string} - formatted address
 */
export const formatPropertyAddress = (address) => {
    if (address) {
        // capitalize all first letters in words
        let parts = address.toLowerCase().split(' ')
        let capWords = parts.map((word) => {
            return word ? word[0].toUpperCase() + word.substring(1) : word
        });

        // sometimes the address contains the zipcode at the end
        let stateNdx = parts.length-1
        let lastItem = parts[stateNdx]
        if (lastItem.length === 5 && lastItem.match(/\b\d{5}\b/g))  {
            stateNdx = stateNdx - 1
        }
        // ensure state is in caps
        capWords[stateNdx] = capWords[stateNdx].toUpperCase()
        return capWords.join(" ")
    }
    return ''
}

const getSessionStorageOrDefault = (key, defaultValue) => {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

export const useSessionStorage = (key, defaultValue) => {
    const [value, setValue] = useState(
        getSessionStorageOrDefault(key, defaultValue)
    );

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}

export const getKeyValue = async (key) => {
    try {
        const jsonValue = localStorage.getItem(key)
        const result = jsonValue != null ? JSON.parse(jsonValue) : null;
        // console.log(result)
        return Promise.resolve(result)
    } catch(e) {
        // error reading value - use Promise.reject??
        console.error(e)
        return Promise.resolve(undefined)
    }
}
export const saveKeyValue = async (k, v) => {
    try {
        localStorage.setItem(k, JSON.stringify(v))
    } catch (e) {
        // saving error
        console.error(e)
        return Promise.reject(e)
    }
    return Promise.resolve(v)
}

// refer: https://react-native-async-storage.github.io/async-storage/docs/usage
const MAP_LOCATION_KEY = "@mapLocation"
export const getLastMapLocation = async () => getKeyValue(MAP_LOCATION_KEY)
export const saveMapLocation = async (location) => saveKeyValue(MAP_LOCATION_KEY, location)
const MAP_TYPE_KEY = `@mapTypeId`
export const getLastMapType = async () => getKeyValue(MAP_TYPE_KEY)
export const saveMapType = async (type) => saveKeyValue(MAP_TYPE_KEY, type)


/**
 * After a user logs in their userId is saved. This method is used to retrieve the userId
 *  and also the full user profile
 * @return {Promise<string>}
 */
//  export const getUserId = () => {
//     const id = localStorage.getItem(USERID_KEY);
//     console.log('User Id: ', id);
//     return id;
// };

/**
 * Look in local property list to see if the passed location is within the boundary of one of the properties within
 *
 * @param address - optional
 * @param location - lat/lng
 * @param properties - list of properties
 * @return {*}
 */
export const locateExisting = (address, location, properties) => {
    return properties.find(p => {
        // we can only test property boundary if this is a claimed property
        if (p.detail && p.detail.boundary) {
            // this property has detail info and we need to check if the user clicked on it
            return booleanPointInPolygon(point([location.longitude || location.lng, location.latitude || location.lat]),
                polygon(p.detail.boundary.geojson.coordinates[0]))
        }

        // if property has 'address' field then it was added because the user clicked on the map, and we've used
        //   Google Maps reverse-geo to find the nearest address, but the user has not asked for more detail.
        // in this case we want to see if the passed address matches
        if (p.address && location) {
            return p.address === address
        }

        return false
    })
}


export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

export const currencyOrNone = (value, alternate) => value ? currencyFormatter.format(value) : alternate || '-'
export const pctOrNone = (value, alternate) => value ? `${value}%` : alternate || '-'

export const numberFormatter = (n) => {
    if (n && n !== '') {
        if (typeof n == 'string') {
            return parseInt(n).toLocaleString('en-US')
        }
        return n.toLocaleString('en-US')
    }
    return ''
}

/**
 * Return the list of assets for display in an image carousel
 *
 * @param property
 * @param isOwner
 * @returns {[{id: string, mimeType: string, url: *},{id: string, mimeType: string, url: any}]}
 * Rules:
 *  - if owner has uploaded assets:
 *      if streetView image is the default 'no image found'
 *          remove the street view image
 *   - if no uploaded assets:
 *      if streetView image is the default 'no image found'
 *          substitute it with the 'add images' photo
 */
export const getAssetsFromProperty = (property, isOwner) => {
    let propertyImage = property.expanded.mapImages.streetViewUrl
    let hasUploadedAssets = property.expanded.assets && property.expanded.assets.length > 0

    let assets = [{
        id: `${property.propertyId}-streetView`,
        mimeType: 'image/jpeg',
        url: propertyImage
    }, {
        id: `${property.propertyId}-satView`,
        mimeType: 'image/jpeg',
        url: property.expanded.mapImages.satViewUrl
    }]

    /**
     * If user has uploaded any assets they will appear first in the carousel.
     * User may re-arrange the order of those assets.
     *
     * The end of the carousel will always show the satView and the mapView images
     */
    if (hasUploadedAssets) {
        assets = property.expanded.assets.concat(assets)
    }

    if (!isOwner) {
        return assets
    }

    if (hasUploadedAssets) {
        // only remove the streetview image if it is the default 'no image found'
        assets = assets.filter(a => a.url !== 'https://assets.bluebid.io/NoStreetViewDefault.png')
    } else {
        assets = assets.map(a => {
            if (a.url === 'https://assets.bluebid.io/NoStreetViewDefault.png') {
                a.url = 'https://assets.bluebid.io/AddHomePhoto.png'
            }
            return a
        })
    }
    return assets
}

/**
 * This changes an image url to include a size specifier. The image handler will automatically
 * resize and optimize the image the first time it is requested. Subsequent calls will return the
 * previously optimized and resized image.
 *
 *  eg: https://assets.bluebid.io/someimage.jpg => https://assets.bluebid.io/1024x683/someimage.jpg
 *
 * @param url - the image url
 * @param rez - '1024x683'
 * @returns {*}
 */
export const imageAtRez = (url, rez) => {
    if (url.indexOf(rez) > -1) {
        let parts = url.split('/')
        parts.splice(parts.length - 1, 0, rez)
        return parts.join('/')
    }
    return url
}

/**
 * This returns all assets for a given property into an array. It does not fetch any assets, it
 * simply parses out the assets from the 'expanded' fields into an array suitable for a carousel, etc.
 *
 * @param expandedPropertyObject - the property object which has 'expanded' fields present
 * @param size - image size desired
 *
 * @returns {*[]}
 */
export const getPropertyAssetsArray = (expandedPropertyObject, size) => {
    const mimeType = 'image/jpeg';

    // these are assets we always show
    let fixedAssets = [
        {url: expandedPropertyObject?.expanded?.mapImages?.satViewUrl, mimeType: mimeType},
        {url: expandedPropertyObject?.expanded?.mapImages?.mapViewUrl, mimeType: mimeType}
    ]

    // only show the StreetView image:
    //  IF the user has not uploaded any images
    //  AND the StreetView image is not the 'NoStreetViewDefault' image
    let userHasAddedAssets = expandedPropertyObject?.expanded?.assets?.length > 0
    let isStreetViewMissing = expandedPropertyObject?.expanded?.mapImages?.streetViewUrl === 'https://assets.bluebid.io/NoStreetViewDefault.png'
    if (!userHasAddedAssets && !isStreetViewMissing) {
        fixedAssets.splice(0, 0,
            {url: expandedPropertyObject?.expanded?.mapImages?.streetViewUrl, mimeType: mimeType})
    }
    const fullAssetsArray = [...expandedPropertyObject?.expanded?.assets || [], ...fixedAssets];

    // now we will go through them and mark any that are video items
    // so that the carousel can display the proper tile type
    // Possible mime-types that may be uploaded are:
    //   ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif', 'video/mp4', 'video/wav'];
    const isVideo = (a) => {
        return (a.mimeType === 'video/mp4' || a.mimeType === 'video/wav')
    }

    // sweep through and:
    //  - assign a unique Id
    //  - set the image size prefix (image handler will automatically resize)
    //  - set isImage flag
    fullAssetsArray.forEach(a => {
        a.isImage = !isVideo(a.mimeType)
        a.id = a.url
        a.url = imageAtRez(a.url, size)
    })

    return fullAssetsArray
}

export const parseSubscriber = (sub) => {
    if (!sub) {
        return 'unknown'
    }

    let parts = sub.split('|')
    return parts.length > 1 ? parts[0] : 'unknown'
}

/**
 * Formats a UTC date relative to the user's current timezone
 * @param dte
 * @returns {string}
 */
export const relativeDate = (dte) => {
    const tz = moment.tz.guess()
    const d = moment.tz(dte, tz)
    return d.format('MMM DD, YYYY hh:mm a');
}

export const formatDate = (date, formatStr = 'MMM DD, YYYY hh:mm a') => {
    if (!date) return ''

    const tz = moment.tz.guess()
    const d = moment.tz(date, tz)
    return d.format(formatStr);
}

export const expirationDate = (date) => {
    let expireDateTime = moment(date).add(EXPIRATION_DAYS, 'days')
    return expireDateTime.format('MMM DD, YYYY hh:mm a')
}

export const formatPhoneNumber = (phoneNumberString) => {
    if (!phoneNumberString)
        return ''

    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
}

export const variableToSentenceTitleCase = (variableName) =>
  variableName
    .replace(/_+/g, ' ')
    .replace(/([A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|$))/g, ' $1')
    .trim()
    .split(' ')
    .map(w => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ')

export const sanitizeHtmlId = (input) =>
    input.replace(/(^[0-9-]+|[^a-z0-9-_])/gi, '_')
