import { Container, Label, Row } from 'reactstrap'
import { PropertySearchByAddress } from '../../components/PropertySearchByAddress'
import styled from 'styled-components'
import React from 'react'

export const PropertySearchWidget = ({onSelect}) => {
    return <PropertySearchContainer>
        <Row>
            <Label>Search Claims</Label>
            <PropertySearchByAddress onSelect={onSelect} />
        </Row>
    </PropertySearchContainer>
}
const PropertySearchContainer = styled(Container)`
    border: 1px solid lightgrey;
    padding: 5px;
    border-radius: 4px;
`
