import React, { useContext, useEffect, useState } from 'react'
import confirm from '../Confirm'
import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'
import { offerUpdatedApi } from '../../lib/data/bluebidData'
import { errorToast, successToast } from '../../utils/common'
import { CustomButton, UnstlyedLink } from './common'
import { CounterOfferAction } from './CounterOfferAction'
import { TagManager } from '../../lib/gtm/TagManager'
import { AuthContext, MessageContext } from '../../constants/context'
import { sizes } from '../../utils/responsive'
import { v4 as uuidv4 } from 'uuid'
import { MessageFontSize, MessageType } from '../../constants/messsages'

const AcceptDialogBody = function() {
    return <>
        <DialogParagraph>
            When you move forward, a licensed real estate agent will contact you directly to begin a
            private sale process. You should not move forward unless you are genuinely interested in
            selling the property based on the terms of this Bluebid. By moving forward, you agree to
            Bluebid's <UnstlyedLink target="_blank" href={`https://www.bluebidhomes.com/terms-of-service`}>Terms of Service</UnstlyedLink>.
        </DialogParagraph>
    </>
}
const RejectDialogBody = function() {
    return <>
        <DialogParagraph>
            Are you sure you want to reject this Bluebid?
        </DialogParagraph>
    </>
}
const RevokeDialogBody = function() {
    return <>
        <DialogParagraph>
            Are you sure you want to revoke this Bluebid?
        </DialogParagraph>
    </>
}

const DialogParagraph = styled.p`
  line-height: 20px;
`

export const MessageActionsOrStatus = ({ messageDetail, showMessageList }) => {
    const { curThread, pushEnabled, fetchChildThreads, fetchParentThreads } = useContext(MessageContext)
    const [showAccept, setShowAccept] = useState(false)
    const [, setShowReject] = useState(false)
    const [showCounter, setShowCounter] = useState(false)
    const [showRevoked, setShowRevoked] = useState(false)
    const [, setIsLoading] = useState(false)

    const {bluebidUser: user} = useContext(AuthContext)
    const isPropertyOwner = curThread?.data?.property?.ownerId === user?.id
    const isActionSender = messageDetail?.senderId === user?.id

    useEffect(() => {
        if (!isActionSender) {
            switch (messageDetail.messageType) {
                case MessageType.Offer.Placed.title:
                    return getActionDetails(MessageType.Offer.Placed);
                case MessageType.Offer.Countered.title:
                    return getActionDetails(MessageType.Offer.Countered);
                case MessageType.Offer.Revoked.title:
                    return getActionDetails(MessageType.Offer.Revoked);
                case MessageType.Offer.Accepted.title:
                    return getActionDetails(MessageType.Offer.Accepted);
                case MessageType.Offer.Rejected.title:
                    return getActionDetails(MessageType.Offer.Rejected);
                case "default":
                    return null
            }
        } else {
            setShowAccept(false);
            setShowReject(false);
            setShowCounter(false);
            setShowRevoked(messageDetail.messageType === MessageType.Offer.Placed.title || messageDetail.messageType === MessageType.Offer.Countered.title);
        }
    }, [messageDetail])

    if (!messageDetail) return <></>

    const getActionDetails = (offerType) => {
        let showAccept = offerType.actions.includes("Accept");
        let showReject = offerType.actions.includes("Reject");
        let showCounter = offerType.actions.includes("Counter");

        setShowAccept(showAccept);
        setShowReject(showReject);
        setShowCounter(showCounter);
        setShowRevoked(false);
    }

    const checkingStatus = (status) => {
        switch (status) {
            case 'Accepted':
            case 'Rejected':
            case 'Revoked':
            case 'Countered':
                return false;
            default: return true
        }
    }

    //call offer api
    const offerAPICall = async (status) => {
        setIsLoading(true)
        await offerUpdatedApi({
            offerId: messageDetail.data.offer?.id || messageDetail.data.offer.toString(),
            ownerId: user.id,
            bidderId: messageDetail.data.offer.bidderId || (isPropertyOwner ? messageDetail?.recipientId : messageDetail?.senderId)
        }, status).then(result => {
            // console.log('Result details', result);
            if ((result.statusCode && result.statusCode >= 400) || (result.status && result.status === 'error')) {
                console.error({result});
                setIsLoading(false);
                errorToast(result.errorMessage);
            } else {
                if (pushEnabled && messageDetail) {
                    let newMessageDetails = {
                        ...messageDetail,
                        id: result?.id || uuidv4(),
                        messageType: MessageType.Offer[status].title,
                        sender: user,
                        senderId: user?.id,
                        recipient: messageDetail?.recipientId === user.id ? messageDetail?.sender : messageDetail?.recipient,
                        recipientId: messageDetail?.recipientId === user.id ? messageDetail?.senderId : messageDetail?.recipientId,
                        createdAt: new Date(),
                        modifiedAt: new Date(),
                        hasOffer: status === 'Accepted' ? true : false
                    }
                    showMessageList(newMessageDetails)
                    setIsLoading(false);
                } else {
                    setTimeout(() => {
                        fetchChildThreads(curThread)
                        fetchParentThreads()
                        setIsLoading(false)
                    }, 600);
                }
                if (status === 'Accepted') {
                    successToast(`You're moving forward!`);
                } else {
                    successToast(`Bluebid ${status} Successfully!`);
                }
            }
        })
    }

    const onAcceptActionClick = async () => {
        let result = await confirm({
            title: 'Move forward?',
            bodyComponent: AcceptDialogBody,
            cancelText: 'Cancel',
            confirmColor: 'success',
            confirmText: 'Move forward'
        });
        if (result) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'offer_accepted',
                    category: 'Bluebid',
                    action: `${isPropertyOwner ? 'Owner' : 'Buyer'} accepted Bluebid!`,
                    label: messageDetail?.address
                }
            })
            offerAPICall("Accepted")
        }
    }

    const onRejectActionClick = async () => {
        let result = await confirm({
            title: 'Reject Bluebid?',
            bodyComponent: RejectDialogBody,
            cancelText: 'Cancel',
            confirmColor: 'danger',
            confirmText: 'Reject'
        });
        if (result) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'offer_rejected',
                    category: 'Bluebid',
                    action: `${isPropertyOwner ? 'Owner' : 'Buyer'} rejected Bluebid!`,
                    label: messageDetail?.address
                }
            })
            offerAPICall("Rejected")
        }
    }

    const onRevokedActionClick = async () => {
        let result = await confirm({
            title: 'Revoke Bluebid?',
            bodyComponent: RevokeDialogBody,
            cancelText: 'Cancel',
            confirmColor: 'danger',
            confirmText: 'Revoke'
        });
        if (result) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'offer_revoked',
                    category: 'Bluebid',
                    action: `${isPropertyOwner ? 'Owner' : 'Buyer'} revoked Bluebid!`,
                    label: messageDetail?.address
                }
            })
            offerAPICall("Revoked")
        }
    }

    return (
        <>
            {messageDetail.data.offer &&
                <MessageActionContainer id="current-offer-action">
                    {showAccept && checkingStatus(messageDetail.data.offer?.status) && <ActionCustomButton className={"btn-success btn-margin m-2 w-100"} onClick={onAcceptActionClick}>Move forward!</ActionCustomButton> }
                    {showAccept && checkingStatus(messageDetail.data.offer?.status) && <ActionCustomButton className={"btn-danger btn-margin m-2 w-100"} onClick={onRejectActionClick}>Reject</ActionCustomButton> }
                    {showRevoked && checkingStatus(messageDetail.data.offer?.status) && <ActionCustomButton className={"btn-danger btn-margin m-2 w-100"} onClick={onRevokedActionClick}>Revoke</ActionCustomButton> }
                    {showCounter && checkingStatus(messageDetail.data.offer?.status) && <CounterOfferAction messageDetail={messageDetail} user={user} showMessageList={showMessageList}/> }
                </MessageActionContainer>
            }

            {/* <OfferMessageStatus messageDetail={messageDetail} /> */}
            {/* <OfferFinalStateInfo property={messageDetail?.data?.property} msg={messageDetail}/> */}
        </>
    )
}

const MessageActionContainer = styled.div`
    // padding: ${isDesktop ? '' : '20px 20px 0 20px'};
    text-align: center;

    .btn {
        @media (max-width: ${sizes.mobileXL}) {
            width: 85%;
            font-size: 0.75rem;
        }
    }
`


export const ActionCustomButton = styled(CustomButton)`
    font-size: ${MessageFontSize};
    padding: 11px 8px;

    @media (max-width: 1078px) {
        font-size: 0.8rem;
    }
`
