import styled from 'styled-components'
import { Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import React from 'react'

import { formatPhoneNumber } from '@bluebid-sdk/core'

const OwnerContainer = styled(Container)`
  font-size: 10pt;
`

const Label = styled.span`
  font-weight: bold;
  margin-right: 10px;
`
const Value = styled.span`
`

export const OwnerDetail = ({owner, title}) => {
    if (!owner) {
        return null
    }
    return <OwnerContainer>
        <Row>
            <Col md={12}>
                <Row className={'mr-0'}>
                    <Col md={6} className={'form-group mb-0'}>
                        <Label>Property Owner</Label>
                        <Value><Link to={`/admin/userprofile/${owner?.id}`}>{owner?.firstName?.toUpperCase() || 'Not Set'} {owner?.lastName?.toUpperCase()}</Link></Value>
                    </Col>
                    <Col md={6} className={'form-group mb-0'}>
                        <Label>User Id</Label>
                        <Value><Link to={`/admin/userprofile/${owner.id}`}>{owner.id}</Link></Value>
                    </Col>
                </Row>
                <Row className={'mr-0'}>
                    <Col md={6} className={'form-group mb-0'}>
                        <Label>Email:</Label>
                        <Value><Link to={`/admin/userprofile/${owner?.id}`}>{owner?.email}</Link></Value>
                    </Col>

                    <Col md={6} className={'form-group mb-0'}>
                        <Label>Email Verified?:</Label>
                        <Value>{owner?.emailVerified ? 'true' : 'false'}</Value>
                    </Col>
                </Row>
                <Row className={'mr-0'}>
                    <Col md={6} className={'form-group mb-0'}>
                        <Label>Phone:</Label>
                        <Value>{formatPhoneNumber(owner?.phone)}</Value>
                    </Col>
                    <Col md={6} className={'form-group mb-0'}>
                        <Label>Signup Source:</Label>
                        <Value><SignupSource sub={owner?.sub}/></Value>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2>{title}</h2>
            </Col>
        </Row>
    </OwnerContainer>
}

const parseSubscriber = (sub) => {
    if (!sub) {
        return 'unknown'
    }
    let parts = sub.split('|')
    return parts.length > 1 ? parts[0] : 'unknown'
}
const subScriberToEnglish = (sub) => {
    let subscriber = parseSubscriber(sub)
    switch (subscriber) {
        case 'auth0':
            return 'Username / Password'
        case 'facebook':
            return 'Facebook'
        case 'linkedin':
            return 'LinkedIn'
        case 'google-oauth2':
            return 'Google'
        case 'twitter':
            return 'Twitter'
        case 'apple':
            return 'Apple'
        default:
            return `Unknown: ${sub}`
    }
}

export const SignupSource = ({sub}) => {
    let text = sub ? subScriberToEnglish(sub) : ''
    return <span>{text}</span>
}
