/**
 * Auth0 configuration for prod
 */
export const getConfig = () => getEnv().auth
export const getApiHost = () => getEnv().host

export const getEnv = () => {
    const ProdConfig = {
        scope: 'browse',  // same for all
        domain: 'auth.bluebid.app',
        clientId: 'ULncnsNIZ9JItyaJoSVx4YjNyWjEvuXt',
        audience: 'https://api.bluebid.io',
        appOrigin: 'https://admin.bluebid.app',
    }
    return {
        desc: 'Prod API',
        auth: ProdConfig,
        host: ProdConfig.audience,
        appHost: 'https://bluebid.app'
    }
}
