import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Card, CardBody, CardTitle, Col, Form, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap'
import BlockUI from '../BlockUI'
import styled from 'styled-components'
import { errorToast, successToast } from '../../utils/common'
import { UserSearch } from '../UserSearch'
import { PrimaryButton, SecondaryButton } from '../actions/common'
import { AdminActionButton } from './common'
import apiCall from '../../lib/data/apiCall'
import { StateCitySearch } from '../StateCitySearch'
import { InitialState } from '../../constants/constants'

export const CreateAlertAction = ({onComplete}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [city, setCity] = useState('')
    const [state, setState] = useState(InitialState)
    const [userId, setUserId] = useState()
    const toggle = () => setModal(!modal)
    const openDialog = () => {
        setCity('')
        setState(InitialState)
        toggle()
    }
    const closeDialog = () => toggle()

    const handleCreateAlert = (event) => {
        event.preventDefault()
        setIsLoading(true)
        const alertName = `${city}, ${state}`

        apiCall(`/claimAlerts`, {
            method: 'POST',
            body: {
                userId: userId,
                name: alertName,
                methods: [{
                    type: 'byCity',
                    values: [city]
                }],
                filter: {
                    city: city,
                    state: state
                },
            }
        }).then(res => {
            setIsLoading(false)
            if (res.status && res.status === 'error') {
                errorToast(res.errorMessage?.[0] || 'Error creating Alert!')
                console.error(res.errorMessage)
            } else {
                setCity('')
                setState(InitialState)
                successToast(`Created Alert ${alertName}`)

                // @todo: do this more gracefully, without a setTimeout;
                // takes a moment for ES to index
                setTimeout(() => {
                    setIsLoading(false)
                    onComplete()
                    closeDialog()
                }, 1000)
            }
        }).catch(err => {
            console.log('err', err)
            errorToast('Could not create Alert')
        })
    };

    const enabled = !!city && !!state && !!userId
    const canCreateTeam = enabled && !isLoading

    return (
        <>
            <AdminActionButton onClick={openDialog} >
                <FontAwesomeIcon icon={faUser} /> Create Alert
            </AdminActionButton>

            <Modal size="lg" isOpen={modal} backdrop="static"
                   toggle={toggle}
                   modalTransition={{ timeout: 200 }}>
                <ModalBody>
                    <BlockUI blocking={isLoading} />
                    <Card className="mb-4" color="light">
                        <CardBody>
                            <CardTitle tag="h6">
                                Create Claim Alert
                            </CardTitle>
                            <Form className="mt-1">
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="formCreateGroupName">User</Label>
                                            <UserSearch label={'Owner'} onSelect={(d) => setUserId(d.data.id)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="formCreateGroupName">City and State</Label>
                                            <StateCitySearch onSelectState={setState} onSelectCity={setCity} initialState={InitialState}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </ModalBody>
                <DialogFooter>
                    <SecondaryButton className={"btn-margin"} disabled={isLoading} onClick={toggle}>Cancel</SecondaryButton>
                    <PrimaryButton className={"btn-margin"} disabled={!canCreateTeam} onClick={handleCreateAlert}>Create</PrimaryButton>
                </DialogFooter>
            </Modal>
        </>
    )
}

const DialogFooter = styled.div`
    text-align: right;
`
