import React from 'react'
import styled from 'styled-components'
import { getEnv } from '../../config'
import { Button } from 'reactstrap'
import { useApiVersion } from './hooks/useApiVersion'

const environment = getEnv().desc

export const AdminHeader = () => {
  const {apiVersion, coreVersion} = useApiVersion()

  return (
    <>
      <CountContainer>
        <ItemContainer>
          <Item>
            <ItemLabel>Environment</ItemLabel>
            <ItemValue>{environment}</ItemValue>
          </Item>
          <Item>
            <ItemLabel>API</ItemLabel>
            <ItemValue>{apiVersion}</ItemValue>
          </Item>
          <Item>
            <ItemLabel>Core</ItemLabel>
            <ItemValue>{coreVersion}</ItemValue>
          </Item>
        </ItemContainer>
        <AdminButton onClick={() => sessionStorage.clear()}>Reset sorting</AdminButton>
      </CountContainer>
    </>
  )
}

const CountContainer = styled.div`
  display: inline-block;
`
const ItemContainer = styled.ul`
  display: inline;
  color: black;
  font-size: 12px;
`

const Item = styled.li`
  display: inline;
  margin-right: 10px;
  border-radius: 4px;
  padding: 5px;
  background: #C5CBD3;
`

const ItemLabel = styled.div`
  display: inline;
  margin: 0 10px;
`
const ItemValue = styled.div`
  display: inline;
  margin-right: 10px;
  font-weight: bold;
`
export const AdminButton = styled(Button)`
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  padding: 0 10px;
  color: black;
  background: #C5CBD3
`
