import { Property } from '@bluebid-sdk/core'
import { api } from 'bb-lib-desktop'

import { Ok } from '../../outcome'

export async function updateMlsStatuses(propertyIds: string[]) {
  return api<{ results: { [propertyId: string]: Ok<Property> } }>('/properties/updateMlsStatuses', {
    method: 'post',
    body: {
      ids: propertyIds,
    },
  })
}
