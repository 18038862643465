import { Route, Routes, Navigate } from 'react-router-dom'
import Profile from '../Profile'
import EditFacts2 from '../EditFacts2'
import SearchProperties from './SearchProperties'
import SearchUsers from './SearchUsers'
import CreateClaim from './CreateClaim'
import PropertyEditBulk from './PropertyEditBulk'
import UserHomes from '../UserHomes'
import PropertyOfferHistory from './PropertyOfferHistory'
import NotFound from '../NotFound'
import { AdminHomePanel } from './AdminHomePanel'
import ListActiveOffers from './ListActiveOffers'
import ListInactiveOffers from './ListInactiveOffers'
import ListProperties from './ListProperties'
import ListUsers from './ListUsers'
import { UserProfile } from '../../components/UserProfile'
import ListAddresses from './ListAddresses'
import ListAssets from './ListAssets'
import { ManageAddresses } from './ManageAddresses'
import ListMessages from './ListMessages'
import ListClaimErrors from './ListClaimErrors'
import ThreadView from './ThreadView'
import ListInvites from './ListInvites'
import ListGroups from './ListGroups'
import ListClaimAlerts from './ListClaimAlerts'
import GroupDetail from './GroupDetail'
import ImportInvites from './ImportInvites'
import ListCityLicenses from './ListCityLicenses'
import ListZipLicenses from './ListZipLicenses'
import CityDetail from './CityDetail'
import ZipDetail from './ZipDetail'
import { EditAgentInfoView } from './EditAgentInfoView'
import { GroupMembersView, GroupOpportunitiesView, GroupZipcodesView } from './group-detail'
import { CreditAgentView } from './CreditAgentView'
import ListUserActions from './ListActionsHistory'
import AdminHistoryList from '../../components/adminHistory/AdminHistoryList'
import ListNotifications from './ListNotifications'
import PropertyEditNew from './propertyedit/PropertyEditNew'
import PropertyGooglePhotos from './PropertyGooglePhotos'
import ListInquiries from './ListInquiries'
import ListTemplates from './ListTemplates'
import ListTemplateRevisions from './ListTemplateRevisions'
import TemplateRevision from './TemplateRevision'
import ListOtps from './ListOtps'
import ListLicenseInquiries from './ListLicenseInquiries'
import { DefaultAgentServicesView } from './DefaultAgentServicesView'

export const AdminRoutes = () => {
    return (
        <Routes>
            <Route path='/profile' element={<Profile />} />
            {/*<Route path="/external-api" element={ExternalApi}/>*/}

            {/*<Route path='/offers/' element={<Offers />}>*/}
            {/*    <Route path=':offerId' element={<Offers />} />*/}
            {/*</Route>*/}
            {/*<Route path='/help' element={<HelpPage />} />*/}
            {/*<Route path='/editfacts/:propertyId' element={<EditFacts2 />} />*/}

            {/* Admin paths */}
            <Route path='/' exact element={<AdminHomePanel />} />

            <Route path='/admin/list-properties' exact element={<ListProperties />} />
            <Route path='/admin/search-properties' exact element={<SearchProperties />} />
            <Route path='/admin/claim-property' exact element={<CreateClaim />} />
            <Route path='/admin/list-claim-errors' exact element={<ListClaimErrors />} />

            <Route path='/admin/list-addresses' exact element={<ListAddresses />} />
            <Route path='/admin/addresses' exact element={<ManageAddresses />} />

            <Route path='/admin/list-users' exact element={<ListUsers />} />
            <Route path='/admin/search-users' exact element={<SearchUsers />} />
            <Route path='/admin/list-claim-alerts' exact element={<ListClaimAlerts />} />

            <Route path='/admin/active-offers' element={<ListActiveOffers />} />
            <Route path='/admin/inactive-offers' element={<ListInactiveOffers />} />

            <Route path='/admin/list-assets' exact element={<ListAssets />} />
            <Route path='/admin/list-users' exact element={<ListUsers />} />
            <Route path='/admin/userprofile/:userId' element={<UserProfile />} />
            <Route path='/admin/list-otps' exact element={<ListOtps />} />

            <Route path='/admin/propertyedit-bulk' element={<PropertyEditBulk />} />
            <Route path='/admin/propertyedit/:propertyId' element={<PropertyEditNew />} />
            <Route path='/admin/propertyedit/:propertyId/google-photos' element={<PropertyGooglePhotos />} />

            <Route path='/admin/editfacts/:propertyId' element={<EditFacts2 />} />

            <Route path='/admin/propertyoffers/:propertyId' element={<PropertyOfferHistory />} />

            <Route path='/admin/userhomes/:userId' element={<UserHomes />} />

            <Route path='/admin/list-messages' exact element={<ListMessages />} />
            <Route path='/admin/view-thread/:userId/:threadId' exact element={<ThreadView />} />
            <Route path='/admin/list-notifications' exact element={<ListNotifications />} />
            <Route path='/admin/templates' exact element={<ListTemplates />} />
            <Route path='/admin/templates/:templateKey' element={<ListTemplateRevisions />} />
            <Route path='/admin/templates/:templateKey/:revisionId' element={<TemplateRevision />} />

            <Route path='/admin/list-invites' exact element={<ListInvites />} />
            <Route path='/admin/credit-agent' exact element={<CreditAgentView />} />
            <Route path='/admin/import-invites' exact element={<ImportInvites />} />

            <Route path='/admin/list-groups' exact element={<ListGroups />} />
            <Route path='/admin/group/:groupId' element={<GroupDetail />}>
                <Route path="members" element={<GroupMembersView />} />
                <Route path="zipcodes" element={<GroupZipcodesView />} />
                <Route path="opportunities" element={<GroupOpportunitiesView />} />
                {/*<Route path="roles" element={<GroupRolesView />} />*/}
                <Route index element={<Navigate to='members' replace />} />
            </Route>
            <Route path='/admin/list-licenses' exact element={<ListCityLicenses />} />
            <Route path='/admin/list-zip-licenses' exact element={<ListZipLicenses />} />
            <Route path='/admin/default-agent-services' exact element={<DefaultAgentServicesView />} />
            <Route path='/admin/city/:state/:city' exact element={<CityDetail />} />
            <Route path='/admin/zipcode/:zipcode' exact element={<ZipDetail />} />
            <Route path='/admin/users/:userId/edit-agent-info' exact element={<EditAgentInfoView />} />

            <Route path='/admin/list-actions-history' exact element={<ListUserActions />} />
            <Route path='/admin/list-admin-history/:propertyId' element={<AdminHistoryList />} />
            <Route path='/admin/list-admin-history' element={<AdminHistoryList />} />

            <Route path='/admin/list-inquiries' exact element={<ListInquiries />} />
            <Route path='/admin/list-license-inquiries/:groupId' exact element={<ListLicenseInquiries />} />

            <Route path='*' element={<NotFound />} />
        </Routes>

    )
}
