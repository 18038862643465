import React, { useCallback, useMemo, useRef } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { DateValueFormatter, IpAddressRenderer, ResultFieldLinkRenderer, ResultFieldRenderer, restoreGridState } from '../../components/grid/GridRenderers'
import { Datasource, NewSecondaryButton } from 'bb-lib-desktop'
import { AgGridReact } from '@ag-grid-community/react'

export const UniqueViewsModal: React.FC<{
  isOpen: boolean,
  onClose: () => void,
  address: string
}> = ({ isOpen, onClose, address }) => {
  const datasource = new Datasource({
    index: 'userActions',
    sort: '-createdAt',
    userIdField: 'userId',
    adminSearch: true,
    fields: 'user',
    query: `(action.keyword:HomeViewed OR action.keyword:AddressViewed) AND data.address:"${address}"`,
  })

  const columnDefs = [
    { field: 'createdAt', headerName: 'Timestamp', width: 170, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
    { field: 'first', headerName: 'First',
      sortable: false, width: 140,
      cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'user', field: 'firstName'}
    },
    { field: 'last', headerName: 'Last',
      sortable: false, width: 140,
      cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'user', field: 'lastName'}
    },
    { field: 'email', headerName: 'EMail',
      sortable: false, width: 230,
      cellRenderer: ResultFieldLinkRenderer,
      cellRendererParams: {
        prefix: '/admin/userprofile',
        object: 'user',
        field: 'email',
        linkField: 'userId'
      }
    },
    { field: 'ipAddr', headerName: 'IP Address', width: 200, filter: 'agTextColumnFilter', cellRenderer: IpAddressRenderer, },
    { field: 'action', headerName: 'Action', width: 120, filter: 'agTextColumnFilter' },
    { hide: true, field: 'id', headerName: 'UserAction Id', width: 300, filter: 'agTextColumnFilter' }
  ]

  return (
    <Modal size='lg' isOpen={isOpen} toggle={() => onClose()}>
      <ModalHeader>Unique Address Views:<br />{address}</ModalHeader>
      <ModalBody>
        <div style={{height: 400}} className='ag-theme-balham'>
          <AgGridReact
            animateRows={true}
            masterDetail={true}
            columnDefs={columnDefs as any[]}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <NewSecondaryButton color="secondary" onClick={() => onClose()}>Close</NewSecondaryButton>
      </ModalFooter>
    </Modal>
  )
}