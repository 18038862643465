import React, { useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'

import Loading from '../../components/Loading'
import { Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from 'reactstrap'
import { AdminTitle } from '../../components/AdminStyles'
import BlockUI from '../../components/BlockUI'
import { errorToast } from '../../utils/common'
import { getSignedUrl, importInvites, s3upload } from '../../lib/data/bluebidData'


const ImportInvites = () => {
    const [isWorking, setIsWorking] = useState(false)
    const [selectedFile, setSelectedFile] = useState(undefined)
    const [, setUploadProgress] = useState(undefined)
    const [showingResults, setShowingResults] = useState(false)
    const [fatalError, setFatalError] = useState(undefined)
    const [fatalErrorModalOpen, setFatalErrorModalOpen] = useState(false)
    const [oks, setOks] = useState([])
    const [notOks, setNotOks] = useState([])
    const [skipped, setSkipped] = useState([])
    const [activeTab, setActiveTab] = useState('notes');

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const toggleFatalErrorModal = () => {
      setFatalErrorModalOpen(!fatalErrorModalOpen)
    }

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0])
    }

    const upload = async ({ file }) => {
        const { signedUrl } = await getSignedUrl(file.name, file.type, `invites-${uuidv4()}.csv`)

        const { origin, pathname } = new URL(signedUrl)
        const downloadUrl = `${origin}${pathname}`

        await s3upload(signedUrl, file.type, file, ({ progress }) => {
            setUploadProgress(progress)
        })

        const results = await importInvites(downloadUrl)
        console.log(results)

        return results
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            errorToast('No file selected for upload')
            return Promise.reject()
        }

        if (selectedFile.type !== 'text/csv') {
            errorToast('Only CSV files are supported')
            return Promise.reject()
        }

        setIsWorking(true)

        try {
            const results = await upload({ file: selectedFile })
            console.log('res', results)

            if (results.status === 'error') {
                setFatalError(results.errorMessage || results.statusCode)
                setFatalErrorModalOpen(true)
            } else {
                setOks(results.attempted.success)
                setNotOks(results.attempted.fail)
                setSkipped(results.skipped)
                setShowingResults(true)
            }
        } catch (error) {
            setFatalError(error.errorMessage || error)
            setFatalErrorModalOpen(true)
        }

        setIsWorking(false)
    }

    const handleBack = () => {
        setShowingResults(false)
    }

    return (<>
        <BlockUI blocking={isWorking} />

        <AdminTitle>Import Invites</AdminTitle>

        <Modal isOpen={fatalErrorModalOpen} toggle={toggleFatalErrorModal}>
            <ModalHeader toggle={toggleFatalErrorModal}>Fatal Error</ModalHeader>
            <ModalBody>
                <div className="mb-2">Unable to process this CSV file due to a non-recoverable error.</div>
                <Alert color="danger">
                    <div>{fatalError || "Unknown error."}</div>
                </Alert>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggleFatalErrorModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>

        {showingResults &&
            <div>
                <Row className="mt-4">
                    <Col><h5>Results</h5></Col>
                    <Col md="2" className="text-right">
                        <Button color="secondary" size="sm" onClick={handleBack}>Back</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="mt-3">
                            <CardHeader>Successful Imports ({oks.length})</CardHeader>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Recipient E-Mail</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {oks.map(({ invite }, index) => (
                                            <tr key={index}>
                                                <td>{invite.recipient}</td>
                                                <td>{invite.firstName}</td>
                                                <td>{invite.lastName}</td>
                                                <td>{invite.address}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        <Card className="mt-3">
                            <CardHeader>Unsuccessful Imports ({notOks.length})</CardHeader>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Recipient E-Mail</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Address</th>
                                            <th>Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notOks.map(({ invite, error }, index) => (
                                            <tr key={index}>
                                                <td>{invite.recipient}</td>
                                                <td>{invite.firstName}</td>
                                                <td>{invite.lastName}</td>
                                                <td>{invite.address}</td>
                                                <td>
                                                    <Alert color="danger">
                                                        {error.errorDetail?.message}
                                                    </Alert>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                        <Card className="mt-3">
                            <CardHeader>Skipped Imports ({skipped.length})</CardHeader>
                            <CardBody>
                                <p>
                                    Invites are deduplicated and skipped based off the combination of the <strong>recipient</strong>, <strong>firstName</strong>, <strong>lastName</strong>, and <strong>address</strong>.
                                </p>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Recipient E-Mail</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {skipped.map((invite, index) => (
                                            <tr key={index}>
                                                <td>{invite.recipient}</td>
                                                <td>{invite.firstName}</td>
                                                <td>{invite.lastName}</td>
                                                <td>{invite.address}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        }

        {!showingResults &&
            <Row className="mt-3">
                <Col md="4">
                    <Form>
                        <FormGroup>
                            <Label for="fileInput">Choose CSV File</Label>
                            <Input type="file" id="fileInput" onChange={handleFileChange} />
                        </FormGroup>
                        <Button color="primary" onClick={handleUpload}>Import</Button>
                    </Form>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h5">
                                Invite CSVs
                            </CardTitle>

                            Required column format:

                            <Table className="mt-2">
                                <thead>
                                    <tr>
                                        <th width="70px">Position</th>
                                        <th>Name</th>
                                        <th>Required</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><code>recipient</code></td>
                                        <td>Yes</td>
                                        <td>Potential invitee's email address</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td><code>firstName</code></td>
                                        <td>No</td>
                                        <td>Potential invitee's first name</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td><code>lastName</code></td>
                                        <td>No</td>
                                        <td>Potential invitee's last name</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td><code>phone</code></td>
                                        <td>No</td>
                                        <td>Potential invitee's phone number</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td><code>address</code></td>
                                        <td>Yes</td>
                                        <td>Property address of the potential invite. Must include the city and state.</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <ul>
                                <li>Do not include column headers</li>
                                <li><strong>Separator:</strong> <code>,</code> (comma separates values)</li>
                                <li><strong>Text Qualifier:</strong> <code>"</code> (double-quotes encapsulate values that may contain the separator)</li>
                            </ul>

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === 'notes' ? 'active' : ''}
                                        onClick={() => toggleTab('notes')}
                                    >
                                        Notes
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={activeTab === 'example' ? 'active' : ''}
                                        onClick={() => toggleTab('example')}
                                    >
                                        Example
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="notes">
                                    <ul className="mt-2">
                                        <li>
                                            This tool is <strong>idempotent</strong>. Running it multiple times will not result in duplicate entries.
                                        </li>
                                        <li>
                                            Invites are deduplicated based off the combination of the <strong>recipient</strong>, <strong>firstName</strong>, <strong>lastName</strong>, and <strong>address</strong>.
                                        </li>
                                        <li>
                                            Invites are routed based off of the address; If a CSV for <strong>Team X</strong> contains invites for a city owned by <strong>Team Y</strong>, then <strong>Team Y</strong> gets those invites.
                                        </li>
                                    </ul>
                                </TabPane>
                                <TabPane tabId="example">
                                    <pre className="mt-3 pl-2">a@bluebid.io,A_first,A_last,1111111111,"88 Turnpike Street, Easton MA 02375"<br />
b@bluebid.io,B_first,B_last,2222222222,"100 Some Street, Apt 2, Springfield MA 11111"<br />
c@bluebid.io,C_first,C_last,3333333333,"5 Any Street, Wakefield MA 22222"</pre>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        }
    </>)
}

export default withAuthenticationRequired(ImportInvites, {
    onRedirecting: () => <Loading/>,
})
