import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import BlockUI from '../BlockUI'
import styled from 'styled-components'
import { errorToast, successToast } from '../../utils/common'
import { UserSearch } from '../UserSearch'
import { PrimaryButton, SecondaryButton } from '../actions/common'
import { AdminActionButton } from './common'
import apiCall from '../../lib/data/apiCall'

export const CreateTeamAction = ({onComplete}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [teamName, setTeamName] = useState('')
    const [teamOwner, setTeamOwner] = useState('')
    const toggle = () => setModal(!modal)
    const openDialog = () => {
        setTeamName('')
        setTeamOwner('')
        toggle()
    }
    const closeDialog = () => toggle()

    const handleCreateTeam = (event) => {
        event.preventDefault()
        setIsLoading(true)

        apiCall(`/groups`, {
            method: 'POST',
            body: {
                name: teamName,
                ownerId: teamOwner.data.id
            }
        }).then(res => {
            setIsLoading(false)
            if (res.status && res.status === 'error') {
                errorToast(res.errorMessage?.[0] || 'Error creating Team!')
                console.error(res.errorMessage)
            } else {
                setTeamName('')
                setTeamOwner('')
                successToast(`Created Team ${teamName}`)

                // @todo: do this more gracefully, without a setTimeout;
                // takes a moment for ES to index
                setTimeout(() => {
                    setIsLoading(false)
                    onComplete()
                    closeDialog()
                }, 1000)
            }
        }).catch(err => {
            console.log('err', err)
            errorToast('Could not create Team')
        })
    };

    const enabled = teamName.length >= 4 && teamOwner.length!==0
    const canCreateTeam = enabled && !isLoading

    return (
        <>
            <AdminActionButton className="mr-2" onClick={openDialog} >
                <FontAwesomeIcon icon={faUser} /> Create Team
            </AdminActionButton>

            <Modal size="lg" isOpen={modal} backdrop="static"
                   toggle={toggle}
                   modalTransition={{ timeout: 200 }}>
                <ModalBody>
                    <BlockUI blocking={isLoading} />
                    <Card className="mb-4" color="light">
                        <CardBody>
                            <CardTitle tag="h6">
                                Create Team
                            </CardTitle>
                            <Form className="mt-1">
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="formCreateGroupName">Name</Label>
                                            <Input
                                                type="text"
                                                id="formCreateGroupName"
                                                value={teamName}
                                                onChange={(e) => setTeamName(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <UserSearch label={'Owner'} onSelect={setTeamOwner} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>


                </ModalBody>
                <DialogFooter>
                    <SecondaryButton className={"btn-margin"} disabled={isLoading} onClick={toggle}>Cancel</SecondaryButton>
                    <PrimaryButton className={"btn-margin"} disabled={!canCreateTeam} onClick={handleCreateTeam}>Create</PrimaryButton>
                </DialogFooter>
            </Modal>
        </>
    )
}

const DialogFooter = styled.div`
    text-align: right;
`
