import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Card, CardBody, CardTitle, CardText, Alert, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import { AdminSearchTypes } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { BlockUI, errorPopup, NewPrimaryButton, NewSecondaryButton } from 'bb-lib-desktop'

import { adminSearch, sendUpdatePhotosEmail } from '../../lib'
import { findTargetLicense, getInquiryById, syncContactSheet, transferZipcode, verifyContactSheet } from '../../lib/data/bluebidData'
import LoadingInline from '../LoadingInline'
import { formatPhoneNumber } from '../../lib/utils'
import { errorToast } from '../../utils/common'

type ContactSheet = {
  userId: string;
  name: string;
  email: string;
  phone: string;
}

type SendInquiryModalProps = {
  isOpen: boolean;
  propertyId: string;
  address: string;
  inquiryId?: string;
  contactSheet?: ContactSheet;
  onSend: (contactSheet: ContactSheet) => Promise<unknown>;
  onClose: () => void;
  onContactSheetUpdated?: (contactSheet: ContactSheet) => void;
}

export const SendInquiryModal: React.FC<SendInquiryModalProps> = ({ isOpen, propertyId, address, inquiryId, contactSheet, onSend, onClose, onContactSheetUpdated }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [targetZip, setTargetZip] = useState('')
  const [selectedLicense, setSelectedLicense] = useState(null)
  const [initialTargetLicense, setInitialTargetLicense] = useState(null)
  const [transferFailed, setTransferFailed] = useState(false)
  const [inquiryContactSheet, setInquiryContactSheet] = useState<ContactSheet>(null)
  const [isContactSheetOutdated, setIsContactSheetOutdated] = useState(false)
  const [isUpdatingContactSheet, setIsUpdatingContactSheet] = useState(false)
  const [liveContactSheet, setLiveContactSheet] = useState<ContactSheet>(null)
  const [unknownContactSheet, setUnknownContactSheet] = useState(false)
  const [contactSheetMissingName, setContactSheetMissingName] = useState(false)
  const [willTransfer, setWillTransfer] = useState(false)
  const [marketReportEmail, setMarketReportEmail] = useState(true)

  useEffect(() => {
    if (!isOpen) {
      return
    } else {
      setIsSending(false)
      setIsLoading(true)
      setTransferFailed(false)
    }

    // if an inquiryId was provided, automatically grab the contact sheet from the inquiry
    // if there was no inquiryId (inquiry might not exist yet), use the provided contact sheet
    if (inquiryId) {
      getInquiryById({ id: inquiryId })
        .then(inquiry => {
          setInquiryContactSheet(inquiry.contactSheet)
          setMarketReportEmail(inquiry.type !== 'buyerInquiry')
        })
    } else {
      setInquiryContactSheet(contactSheet)
    }

    findTargetLicense({ propertyId, address })
      .then(({ license, zip }) => {
        if (license) {
          const option = { value: license.id, label: license.branding.label }
          setSelectedLicense(option)
          setInitialTargetLicense(option)
        }

        if (zip) {
          setTargetZip(zip)
        }

        setIsLoading(false)
      })
  }, [isOpen, propertyId, address, inquiryId])

  useEffect(() => {
    if (!selectedLicense) {
      setWillTransfer(false)
      return
    }

    if (!initialTargetLicense) {
      setWillTransfer(false)
      return
    }

    setWillTransfer(selectedLicense.value !== initialTargetLicense.value)
  }, [selectedLicense, initialTargetLicense])

  const checkContactSheet = async (args: { contactSheet: ContactSheet }): Promise<{ verified: boolean, contactSheet: ContactSheet }> => {
    setUnknownContactSheet(false)
    return verifyContactSheet({ contactSheet: args.contactSheet })
      .then(result => {
        if (result.status === 'error') {
          setUnknownContactSheet(true)
        } else {
          setIsContactSheetOutdated(!result.verified)
          setLiveContactSheet(result.contactSheet)
          setContactSheetMissingName(isMissingName(result.contactSheet.name))
        }
        return result
      })
  }


  useEffect(() => {
    if (!inquiryContactSheet) {
      return
    }

    checkContactSheet({ contactSheet: inquiryContactSheet })
  }, [inquiryContactSheet])

  if (!isOpen) {
    return null
  }

  const searchLicensesFn = async (input: string): Promise<{ value: string; label: string }[]> => {
    const response = await adminSearch(
      'licenses' as AdminSearchTypes,
      `branding.label:${input}*`
    )

    if (isSuccessResponse(response)) {
      const items = response.data?.items

      if (!items) {
        return []
      }

      return items.map((item) => {
        return {
          value: item.id,
          label: item.branding.label,
        }
      })
    }

    return []
  }

  const onLicenseChange = (selected) => {
    setSelectedLicense(selected)
  }

  const splitFullName = (fullName: string) => {
    const [firstName, ...lastName] = fullName.trim().split(/\s+/)
  
    return {
      firstName,
      lastName: lastName.join(' '),
    }
  }

  const isMissingName = (name: string) => {
    const { firstName, lastName } = splitFullName(name)
    return !firstName || firstName === '' || !lastName || lastName === ''
  }
  
  const handleSend = async () => {

    // if the contact sheet is outdated, cancel this send to show the outdated sheet warning
    // for the rest of this function use the verified contactSheet out of the checker
    const { verified, contactSheet } = await checkContactSheet({ contactSheet: inquiryContactSheet })
    if (verified === false || contactSheetMissingName === true) {
      return
    }

    setIsSending(true)
    setTransferFailed(false)
    setContactSheetMissingName(false)

    const licenseId = selectedLicense.value
    const initialLicenseId = initialTargetLicense.value
    const isReassignment = licenseId !== initialLicenseId

    if (isReassignment) {
      const transfer = await transferZipcode({
        toLicenseId: licenseId,
        zipcode: targetZip
      })

      // transfer undefined when successful
      if (transfer && !isSuccessResponse(transfer)) {
        setIsSending(false)
        setTransferFailed(true)

        // leave the modal open and stop the process
        return
      }
    }

    await onSend(contactSheet)

    if (marketReportEmail && propertyId) {
      const photosResponse = await sendUpdatePhotosEmail(propertyId)

      if (!isSuccessResponse(photosResponse)) {
        errorToast(photosResponse.errorMessage || 'Photos email could not be sent.')
      }
    }

    setIsSending(false)
    onClose()
  }

  const handleReset = () => {
    setSelectedLicense(initialTargetLicense)
  }

  const handleUpdateContactSheet = () => {

    // if no inquiryId, there's no inquiry to update; just update the contact sheet with what
    // we know is the correct sheet
    if (!inquiryId) {
      onContactSheetUpdated(liveContactSheet)
      setInquiryContactSheet(liveContactSheet)
      return
    }

    setIsUpdatingContactSheet(true)

    syncContactSheet({ inquiryId })
      .then(updatedContactSheet => {
        if (updatedContactSheet.status === 'error') {
          throw updatedContactSheet.errorMessage
        }

        setInquiryContactSheet(updatedContactSheet)
        onContactSheetUpdated(updatedContactSheet)
      })
      .catch((e) => {
        errorPopup('Error updating contact sheet.')
        console.error(e)
      })
      .finally(() => {
        setIsUpdatingContactSheet(false)
      })
  }

  const handleMarketReportEmailChange = () => setMarketReportEmail(!marketReportEmail)

  return (
    <Modal isOpen={isOpen}>
      <BlockUI blocking={isLoading} />

      <ModalHeader>Send Inquiry</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={3}><Label for="zipcode"><strong>Address:</strong></Label></Col>
          <Col>{address}</Col>
        </Row>
        <Row>
          <Col md={3}><Label for="zipcode"><strong>Zip Code:</strong></Label></Col>
          <Col>{targetZip}</Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <FormGroup>
              <Label for="license"><strong>License:</strong></Label>
              <Row>
                <Col>
                  <AsyncSelect
                    id="license"
                    cacheOptions
                    defaultOptions
                    loadOptions={searchLicensesFn}
                    isClearable={false}
                    placeholder={'License'}
                    onChange={onLicenseChange}
                    value={selectedLicense}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                  />
                </Col>
                <Col md={3}>
                  <NewSecondaryButton
                    style={{ height: 38, width: '100%' }}
                    disabled={isSending || !willTransfer}
                    onClick={handleReset}
                  >
                    Reset
                  </NewSecondaryButton>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        { inquiryContactSheet && <Row className="mt-1">
          <Col>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="mb-3">Inquirer Contact Sheet</CardTitle>
                <Row>
                    <Col md={3}><Label for="contactId"><strong>User ID:</strong></Label></Col>
                    <Col style={{ fontSize: '80%' }}>
                      <a href={`/admin/userprofile/${inquiryContactSheet.userId}`} target="_blank">
                        <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon> {inquiryContactSheet.userId}
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}><Label for="contactName"><strong>Name:</strong></Label></Col>
                    <Col>{inquiryContactSheet.name}</Col>
                  </Row>
                  <Row>
                    <Col md={3}><Label for="email"><strong>Email:</strong></Label></Col>
                    <Col>{inquiryContactSheet.email}</Col>
                  </Row>
                  <Row>
                    <Col md={3}><Label for="phone"><strong>Phone:</strong></Label></Col>
                    <Col>{inquiryContactSheet.phone && formatPhoneNumber(inquiryContactSheet.phone)}</Col>
                  </Row>
                  { isContactSheetOutdated && <Alert color="danger" className="m-0 mt-3">
                    <Row>
                      <Col style={{ alignSelf: 'center' }}>Contact sheet is outdated.</Col>
                      <Col md={4} className="text-right pr-3">
                        <NewPrimaryButton onClick={handleUpdateContactSheet} disabled={isUpdatingContactSheet}>
                        {isUpdatingContactSheet ? <LoadingInline /> : null}{' '}Update
                        </NewPrimaryButton>
                      </Col>
                    </Row>
                  </Alert>}
                  { unknownContactSheet && <Alert color="danger" className="m-0 mt-3">
                    <Row>
                      <Col style={{ alignSelf: 'center' }}>Cannot verify contact sheet.</Col>
                    </Row>
                  </Alert>}
                  { contactSheetMissingName && <Alert color="danger" className="m-0 mt-3">
                    <Row>
                      <Col style={{ alignSelf: 'center' }}>Contact name is incomplete. Supply both first and last names on user profile.</Col>
                    </Row>
                  </Alert>}
              </CardBody>
            </Card>
          </Col>
        </Row> }
        { transferFailed && <Row className="mt-1">
          <Col>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="text-danger">Transfer Error</CardTitle>
                <CardText>
                  An error occurred while transferring the zipcode to the new license,
                  and the change was rolled back.
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row> }
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Col xs={5}>
            { propertyId && <FormGroup check>
              <Input id="market-report-email" type="checkbox" checked={marketReportEmail} onChange={handleMarketReportEmailChange} />
              <Label check for="market-report-email">
                Market Report<br />Scheduled
              </Label>
            </FormGroup> }
          </Col>
          <Col xs={7} className="text-right">
            <NewSecondaryButton disabled={isSending} onClick={onClose}>Cancel</NewSecondaryButton>
            {' '}
            <NewPrimaryButton onClick={handleSend} disabled={isSending || isContactSheetOutdated}>
              {isSending ? <LoadingInline /> : null}{' '}
              {selectedLicense && !willTransfer ? 'Send' : 'Transfer & Send'}
            </NewPrimaryButton>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}
