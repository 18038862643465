import React from 'react'

export const Deleted: React.FC = () => {
  return (
    <div>
      Deleted
    </div>
  )
}

export const deletedSummary = () => {
  return `Deleted`
}