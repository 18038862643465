import React from 'react'
import { ColDef } from '@ag-grid-community/core'

import { User } from '@bluebid-sdk/core'

import { MessagesGridComponent } from '../messages'

export const UserMessages: React.FC<{ user: User }> = ({ user }) => {
  const sentReceivedCol: ColDef = {
    headerName: 'Sent/Received',
    valueGetter: (params) => {
      return params?.data?.senderId === user?.id ? 'Sent' : 'Received'
    },
  }

  if (!user?.id) {
    return
  }

  return (
    <MessagesGridComponent
      userId={user.id}
      sessionKey="usermessages"
      newColumns={[sentReceivedCol]}
      columnOrder={['Message Type', 'Message Data', 'Address', 'Created / Modified', 'Property']}
      title=""
    />
  )
}
