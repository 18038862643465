import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { PrimaryButton, SecondaryButton } from '../../../components/actions/common'
import { Col, Container, FormGroup, Input, Label, Row, Form, Alert } from 'reactstrap'
import styled from 'styled-components'
import LoadingInline from '../../../components/LoadingInline'
import { Description, Title } from './shared'

type FormValues = {
  by: string
  value: number
  round: boolean
}

type ChangeBluebidEstimateProps = {
  onApply: (data: FormValues) => void
  onCancel: () => void,
  isWorking: boolean
}

const ChangeBluebidEstimateForm: React.FC<ChangeBluebidEstimateProps> = ({ onApply, onCancel, isWorking }) => {
  const { control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      by: 'amount',
      value: null,
      round: false,
    }
  })

  const by = watch('by')
  const value = watch('value')

  return (
    <Form onSubmit={handleSubmit(onApply)}>
      <Container>
        <Title>Change Bluebid Estimate</Title>

        <Description>
          Changes the selected properties' Bluebid estimates by the specified amount or percentage.
          Values are relative; an amount of $500 will increase the estimate of every property by $500.
        </Description>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>
                <Controller
                  name="by"
                  control={control}
                  render={({ field }) => <Radio
                    {...field}
                    type="radio"
                    value="amount"
                    checked={field.value === 'amount'}
                  />}
                />
                By Amount
              </Label>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>
                <Controller
                  name="by"
                  control={control}
                  render={({ field }) => <Radio
                    {...field}
                    type="radio"
                    value="percent"
                    checked={field.value === 'percent'}
                  />}
                />
                By Percentage
              </Label>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <NumericFormat
                onValueChange={({ value }) => field.onChange(value ? parseFloat(value) : 0)}
                className="form-control"
                thousandSeparator
                prefix={by === 'amount' ? '$' : ''}
                suffix={by === 'percent' ? '%' : ''}
              />
            )}
          />
        </FormGroup>

        {by === 'percent' && (
          <FormGroup check>
            <Label check>
              <Controller
                name="round"
                control={control}
                render={({ field }) => <Input {...field} type="checkbox" checked={field.value} value="" />}
              />
              Round to nearest hundred
            </Label>
          </FormGroup>
        )}

        <FormGroup row className="mt-5 text-center">
          <Col>
            <PrimaryButton type="submit" disabled={!value || isWorking}>
              {isWorking ? <LoadingInline /> : null} Apply
            </PrimaryButton>
            <SecondaryButton type="button" onClick={onCancel} style={{ minWidth: 0 }}>Cancel</SecondaryButton>
          </Col>
        </FormGroup>
      </Container>
    </Form>
  )
}

const Radio = styled(Input)`
  position: inherit;
  margin-left: 0;
  margin-right: 5px;
`

export default ChangeBluebidEstimateForm
