import React from 'react'
import { CardBody, CardTitle, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { AdminCard, AdminLabel, AdminValue } from './Styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { AssignOwnerAction } from '../../../components/adminActions/AssignOwnerAction'
import { SendEstimationEmail } from '../../../components/adminActions/SendEstimationEmail'
import { UpdatePhotosEmail } from '../../../components/adminActions/UpdatePhotosEmail'
import { NumericFormat, PatternFormat } from 'react-number-format'
import styled from 'styled-components'

export const AdminOwnerInfo = ({ owner, property, onEditComplete }) => {
  if (!owner) {
    return null
  }
  const ownerName = `${owner?.firstName?.toUpperCase() || 'Not Set'} ${owner?.lastName?.toUpperCase()}`

  return <AdminCard>
    <CardBody>
      <CardTitle tag={'h5'}>
        Owner Info
        <span style={{ float: 'right' }}>
          <Link to={`/admin/userprofile/${owner?.id}`}>
            <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
          </Link>
        </span>
      </CardTitle>
      <Row>
        <Col sm={8}>
          <Row>
            <AdminLabel sm={4}>Claim Name</AdminLabel>
            <AdminValue sm={8}>{ownerName}</AdminValue>
          </Row>

          <Row>
            <AdminLabel md={4}>Deed Name</AdminLabel>
            <AdminValue md={8}>{property?.detail?.owner?.name}</AdminValue>
          </Row>

          <Row>
            <AdminLabel md={4}>Email:</AdminLabel>
            <AdminValue md={8}>{owner?.email}</AdminValue>
          </Row>

          {/*<Row>*/}
          {/*  <AdminLabel md={5}>Email Verified?:</AdminLabel>*/}
          {/*  <AdminValue md={5}>{owner?.emailVerified ? 'true' : 'false'}</AdminValue>*/}
          {/*</Row>*/}

          <Row>
            <AdminLabel md={4}>Phone:</AdminLabel>
            <AdminValue md={8}>
              {owner?.phone && <MobileNumberFormat value={owner.phone}
                             type={"tel"} disabled
                             valueIsNumericString
                             format="(###) ###-####"
                             mask="_"
              />}
            </AdminValue>
          </Row>

          <Row>
            <AdminLabel md={4}>Source:</AdminLabel>
            <AdminValue md={8}><SignupSource sub={owner?.sub} /></AdminValue>
          </Row>

        </Col>
        <Col sm={3}>
            <AssignOwnerAction property={property} shouldUpdateProfile={onEditComplete} />
            <SendEstimationEmail property={property} />
            <UpdatePhotosEmail property={property} />
        </Col>
      </Row>

    </CardBody>
  </AdminCard>
}

const MobileNumberFormat = styled(PatternFormat)`
  border: none;
  background-color: transparent;
`

const parseSubscriber = (sub) => {
  if (!sub) {
    return 'unknown'
  }
  let parts = sub.split('|')
  return parts.length > 1 ? parts[0] : 'unknown'
}
const subScriberToEnglish = (sub) => {
  let subscriber = parseSubscriber(sub)
  switch (subscriber) {
    case 'auth0':
      return 'Username / Password'
    case 'facebook':
      return 'Facebook'
    case 'linkedin':
      return 'LinkedIn'
    case 'google-oauth2':
      return 'Google'
    case 'twitter':
      return 'Twitter'
    case 'apple':
      return 'Apple'
    default:
      return `Unknown: ${sub}`
  }
}

const SignupSource = ({ sub }) => {
  let text = sub ? subScriberToEnglish(sub) : ''
  return <span>{text}</span>
}
