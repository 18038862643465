import React from 'react'
import { Container, FormGroup, Label } from 'reactstrap'
import AsyncSelect from 'react-select/async'

import { AdminSearchTypes } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'

import { adminSearch } from '../lib'

export const ZipCodeSearch: React.FC<{
  onSelect: (selected: { zip: string; cities: string[]; state: string }) => void
  label?: string
}> = ({ onSelect, label }) => {
  const searchFn = async (
    input
  ): Promise<Array<{ value: string; label: string; data: { zip: string; cities: string[]; state: string } }>> => {
    if (input === '') {
      return []
    }

    const qry = isNaN(input) ? `cityState:${input}*` : `zips:*${input}*`
    const response = await adminSearch(AdminSearchTypes.USCities, qry)

    if (isSuccessResponse(response)) {
      // consolidate cities/towns with the same zip code
      const zipCodesData: { [zip: string]: { zip: string; cities: string[]; state: string } } = {}

      // allow this fake zip code as the default agent
      // (putting this here to not require USCities index patching)
      if (input === '00000') {
        return [{
          value: '00000',
          label: '00000 (Default Agent)',
          data: { zip: '00000', cities: [], state: '' },
        }]
      }

      response.data?.items?.forEach((item) => {
        const zips = item.zips.split(' ')

        zips.forEach((zip) => {
          if (!zipCodesData[zip]) {
            zipCodesData[zip] = { zip, cities: [], state: '' }
          }

          if (!zipCodesData[zip].cities.includes(item.city_ascii)) {
            zipCodesData[zip].cities.push(item.city_ascii)
          }

          zipCodesData[zip].state = item.state_id
        })
      })
      return Object.keys(zipCodesData).map((zip) => {
        const zipCodeData = zipCodesData[zip]

        return {
          value: zipCodeData.zip,
          label: `${zipCodeData.zip} (${zipCodeData.cities
            .map((city) => `${city}, ${zipCodeData.state}`)
            .join(' / ')})`,
          data: zipCodeData,
        }
      })
    } else {
      return []
    }
  }

  const onZipChange = (selected) => {
    onSelect(selected?.data)
  }

  return (
    <>
      <Container className="p-0">
        {label && <Label>{label}</Label>}
        <FormGroup>
          <Label for="formAssignCity">Zip Code</Label>
          <AsyncSelect
            id="formAssignCity"
            cacheOptions
            defaultOptions
            loadOptions={searchFn}
            isClearable={true}
            placeholder={'Zip Code, City, Town'}
            onChange={onZipChange}
          />
        </FormGroup>
      </Container>
    </>
  )
}
