import apiCall from './apiCall'
import axios from 'axios'

const Chance = require('chance')
let chance = new Chance()

export const getDistanceInMeters = (location1, location2) => {
  let lat1 = location1.lat()
  let lon1 = location1.lng()

  let lat2 = location2.lat()
  let lon2 = location2.lng()

  let R = 6371 // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1)
  let dLon = deg2rad(lon2 - lon1)
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  let d = R * c // Distance in km
  return d * 1000

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
}

export const radiusFromLocation = (center, zoom) => {
  let radius = Math.floor(((38000 / Math.pow(2, zoom - 3)) * Math.cos((center.lat * Math.PI) / 180) * 700) / 2)
  // console.log(`Radius from loc: ${radius}`)
  return radius
}
/**
 * Get the Bluebid API to return the list of claimed properties at the given location
 * @param center
 * @param zoom
 */
export const getPropertyData = async (center, zoom, propertyFilter) => {
  let meters = radiusFromLocation(center, zoom)
  return apiCall(`/properties/claimed/radius`, {
    method: 'POST',
    body: {
      lat: center.lat,
      lng: center.lng,
      radius: meters,
      maxCount: 300,
      filter: propertyFilter,
    },
  })
}

export const findClaimedInRect = async (topLeft, bottomRight, propertyFilter) => {
  return apiCall(`/properties/claimed/area`, {
    method: 'POST',
    body: {
      topLeft: { latitude: topLeft.lat(), longitude: topLeft.lng() },
      bottomRight: { latitude: bottomRight.lat(), longitude: bottomRight.lng() },
      maxCount: 300,
      filter: propertyFilter,
    },
  })
}

export const getClosestProperty = async (location) => {
  let distance = 500
  return apiCall(`/properties/closest`, {
    method: 'POST',
    body: {
      location: { latitude: location.lat, longitude: location.lng },
      distance: distance,
    },
  })
}

/**
 * Construct a new property object representing something a user clicked on that has yet to be claimed.
 * This property object may or may not contain detail information.
 * - if a user clicked on a map location, we construct a property object with no detail and just an address
 * - if a user directly entered an address, we construct a property object that has full detail information
 *
 *  Only if a user 'claims' the address, will this transition to a claimed property, at which time:
 *      a. a real propertyId will be assigned
 *      b. an ownerId will be assigned
 */
export const createPropertyObject = (detail, address, show) => {
  let newProp = {
    // ownerId:
    // ID of the owner of this property. The absence of this field
    // means the property is an unclaimed property in our data list

    // propertyID. This is a temporary ID that is used so the client-side
    // may render properly, etc. If this property object is saved (claimed)
    // then the ID should be omitted from sending to the API. A real propertyId will
    // be generated on the server-side when it is claimed. The client must update the
    // ID in the list accordingly
    propertyId: chance.guid(),

    // react state info - not persisted
    address: address,
    show: show,
  }

  if (detail) {
    newProp.detail = detail.data
    newProp.location = {
      lat: detail.data.address.latitude,
      lng: detail.data.address.longitude,
    }
    newProp.address = detail.data.address.formatted_street_address
  }
  return newProp
}

export const IMAGE_SIZE_PARAMS = 'width=640&height=426'

/**
 * Add any API calls below using the same format. If you need to debug the api call, just add an entry in your .env file:
 *
 * LOG_API_CALLS=true
 */
export const getPropertyById = async (propertyId, include) => {
  const inc = include ? `?include=${include}` : ''
  return apiCall(`/properties/id/${propertyId}${inc}`)
}

export const getPropertiesByIds = async (propertyIds) =>
  apiCall(`/search/resourcesByIds`, {
    method: 'POST',
    body: {
      resource: 'properties',
      ids: propertyIds,
      limit: 200
    }
  })

export const getPropertyBulkActions = async () =>
  apiCall(`/properties/bulk`, {
    method: 'GET'
  })

export const applyBulkPropertyAction = async ({ propertyIds, functionKey, params }) =>
  apiCall(`/properties/bulk/${functionKey}`, {
    method: 'POST',
    body: {
      propertyIds,
      params
    }
  })

export const getPropertiesIndexTimes = async ({ propertyIds }) =>
  apiCall(`/properties/indexedAt`, {
    method: 'POST',
    body: {
      propertyIds
    }
  })

export const getMyFavorites = async (userId) => {
  return apiCall(`/users/id/${userId}?include=favorites`)
}
export const getPropertyByIdAPI = async (propertyId) => {
  return apiCall(`/properties/id/${propertyId}`)
}
export const getPropertyAtAddress = async (address) => apiCall(`/properties/address/${encodeURIComponent(address)}`)

export const getClaimAttempts = async (userId, address) =>
  apiCall(`/users/claimAttempts/${userId}/${encodeURIComponent(address)}`)

export const getClaimedPropertyAtAddress = async (address) =>
  apiCall(`/properties/address/${encodeURIComponent(address)}`)

export const claimProperty = async (body) =>
  apiCall(`/properties`, {
    method: 'POST',
    body: body,
  })

export const addPropertyAssets = async (body) =>
  apiCall('/assets/property', {
    method: 'POST',
    body: body,
  })
export const getClaimedPropertyInfo = async (propertyId) =>
  apiCall(`/properties/id/${propertyId}?include=assets,mapImages&${IMAGE_SIZE_PARAMS}`)

// the only info we can get about unclaimed properties are the images
export const getUnclaimedPropertyInfo = async (property) =>
  apiCall(
    `/properties/images/${encodeURIComponent(property.address)}/${
      property.detail.latitude || property.detail.address.latitude
    }/${property.detail.longitude || property.detail.address.longitude}?${IMAGE_SIZE_PARAMS}`
  )

export const getOwnerNames = async (address, userId) => {
  let usr = ''
  if (userId) {
    usr = `/${userId}`
  }
  return apiCall(`/properties/ownerNames/${encodeURIComponent(address)}${usr}`)
}

export const uploadAssets = async (body) =>
  apiCall('/assets', {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const setAssetOrder = async (body) =>
  apiCall('/assets/order', {
    method: 'PATCH',
    body: body,
  })

export const sendBid = async (body) =>
  apiCall(`/offers`, {
    method: 'POST',
    body: body,
  })
export const counterOffer = async (body) =>
  apiCall(`/offers/counter`, {
    method: 'POST',
    body: body,
  })
export const getMyProperties = async (userId) => apiCall(`/properties/owner/${userId}?include=properties`)
export const favoriteProperty = async (body) =>
  apiCall(`/users/addFavorite`, {
    method: 'POST',
    body: body,
  })
export const removeFavoriteProperty = async (body) =>
  apiCall(`/users/removeFavorite`, {
    method: 'POST',
    body: body,
  })

export const getAvailableRoles = async () => {
  return apiCall(`/roles`)
}

export const getUser = async (userId, include) => {
  let exp = include ? `?include=${include}` : ''
  return apiCall(`/users/id/${userId}${exp}`)
}
export const getUserByEmail = async (email) => {
  // return apiCall(`/users/email/${email}?include=properties,favorites,mapImages&${IMAGE_SIZE_PARAMS}`)
  return apiCall(`/users/email/${email}?include=properties,favorites`)
}
export const getUserByPhone = async (phone) => {
  return apiCall(`/users/phone/${phone}?include=properties,favorites`)
  // return apiCall(`/users/phone/${phone}?include=properties,favorites,mapImages&${IMAGE_SIZE_PARAMS}`)
}
export const getUserBySub = async (sub) => {
  return apiCall(`/users/sub/${sub}?include=properties,favorites`)
  // return apiCall(`/users/sub/${sub}?include=properties,favorites,mapImages&${IMAGE_SIZE_PARAMS}`)
}
export const findUser = async (email, phone, sub) => {
  return apiCall(`/users/find`, {
    // return apiCall(`/users/find?include=properties,favorites`, {
    method: 'POST',
    body: {
      email: email,
      phone: phone,
      sub: sub,
    },
  })
}
export const getAssetWithId = async (id) => {
  return apiCall(`/assets/property/${id}?include=assets,mapImages&${IMAGE_SIZE_PARAMS}`)
}
export const getPropertyAssets = async (propertyIdentifier) => {
  if (propertyIdentifier.ownerId) {
    return getClaimedPropertyInfo(propertyIdentifier.propertyId, 300)
  }
  propertyIdentifier.expanded = await getUnclaimedPropertyInfo(propertyIdentifier)
  return propertyIdentifier
}
export const getMessageById = async (id) => {
  return apiCall(`/messages/${id}`)
}

export const getMessageThreads = async (userId) => apiCall(`/messages/threads/${userId}`)

export const getThreadChildren = async (userId, threadId) => apiCall(`/messages/children/${userId}/${threadId}`)

export const offersListAPI = async (userId) => {
  return apiCall(`/users/messages/${userId}`)
}
export const offersStatusAPI = async (offerId, body) => {
  apiCall(`/offers/status/${offerId}`, {
    method: 'POST',
    body: body,
  })
}

export const offerUpdatedApi = async ({ offerId, actorId, channels, note }, offerType) => {
  let url = ''
  let body = {}
  if (offerType === 'Accepted') {
    url = '/offers/accept'
    body = {
      offerId: offerId,
      ownerId: actorId,
      channels,
      note,
    }
  } else if (offerType === 'Rejected') {
    url = '/offers/reject'
    body = {
      offerId: offerId,
      ownerId: actorId,
      channels,
      note,
    }
  } else if (offerType === 'Revoked') {
    url = '/offers/revoke'
    body = {
      offerId: offerId,
      bidderId: actorId,
      channels,
      note,
    }
  }

  return apiCall(`${url}`, {
    method: 'POST',
    body: body,
  })
}

export const offerExpireApi = async ({ offerId, note, channels }) =>
  apiCall('/offers/expire', {
    method: 'POST',
    body: {
      offerId: offerId,
      note: note,
      channels: channels,
    },
  })

/**
 * Admin specific functions go below here only
 * @param address
 * @returns {Promise<Response>}
 */

export const getPropertyDetail = async (address) => {
  return apiCall(`/properties/detail/${address}`)
}

export const getPropertyOffers = async (propertyId) => apiCall(`/offers/property/${propertyId}`)
export const getUsersProperties = async (userId) =>
  apiCall(`/properties/owner/${userId}?include=properties,assets,mapImages&height=474`)
// export const getMessageById = async (id) => {
//     return apiCall(`/messages/${id}`);
// }

export const updateProfileAPI = async (userId, body) => {
  return apiCall(`/users/${userId}`, {
    method: 'PATCH',
    body: body,
  })
}
export const clearNewUser = async (userId) => {
  return apiCall(`/users/${userId}`, {
    method: 'PATCH',
    body: { isNewSignup: false },
  })
}

export const getPropertyByAddress = async (address, lat, lng) => {
  return apiCall(`/properties/images/${encodeURIComponent(address)}/${lat}/${lng}`)
}

// { { stageAPI } } /properties/images /: address /: lat /: lng /: mapType
export const updateProperty = async (propertyId, body) => {
  return apiCall(`/properties/${propertyId}`, {
    method: 'PATCH',
    body: body,
  })
}
export const getSignedUrl = async (fileName, fileType, assetName) => {
  return apiCall('/sign', {
    method: 'POST',
    body: {
      fileName,
      fileType,
      assetName,
    },
  })
}
export const s3upload = async (url, contentType, file, onProgress) => {
  const myHeaders = new Headers({ 'Content-Type': contentType })
  return axios
    .request({
      method: 'put',
      url: url,
      data: file,
      headers: myHeaders,
      onUploadProgress: (p) => {
        let pr = {
          total: p.total,
          progress: Math.round((p.loaded * 100) / p.total),
          timeStamp: p.timeStamp,
        }
        onProgress(pr)
      },
    })
    .then((response) => {
      if (response.status === 200) {
        let urlParts = response.config.url
          .replace(/s3.[\w-]+.amazonaws.com\//, '') // aws v3 now includes region
          .replace('s3.amazonaws.com', '')
          .replace(`dev.`, 'dev')
          .replace(`stage.`, 'stage')
          .replace(`prod.`, '')
          .split('?')
        return {
          url: urlParts[0],
        }
      } else {
        console.error(response)
        return undefined
      }
    })
}

export const propertySearch = async (session, search) =>
  apiCall(`/properties/search?session=${session}&search=${search}`)

export const propertyInfo = async (session, item) => {
  let override = item.is_override ? '&override=true' : ''
  return apiCall(`/properties/info/${item.place_id}?session=${session}${override}&d=${item.description}`)
}

export const sharePropertyEmail = async (sender, recipient, message, property, location, action) => {
  return apiCall(`/email/share`, {
    method: 'POST',
    body: {
      sender: sender,
      recipient: recipient,
      message: message,
      propertyId: property,
      location: location,
      action: action,
    },
  })
}

export const resendEmailVerification = async (user, returnTo) => {
  return apiCall('/resend-email-verification', {
    method: 'POST',
    body: {
      user_id: user.id,
      user_sub: user.sub,
      returnTo: returnTo,
    },
  })
}

export const deleteProperty = async (propertyId) => {
  return apiCall(`/properties/${propertyId}`, {
    method: 'DELETE',
  })
}
export const unclaimProperty = async (propertyId) => {
  return apiCall(`/properties/unclaim`, {
    method: 'POST',
    body: { propertyId: propertyId },
  })
}
export const getUserFavorites = async (userId) => apiCall(`/users/favorites/${userId}`)

export const assignPropertyOwner = async (propertyId, body) => {
  return apiCall(`/properties/assignowner/${propertyId}`, {
    method: 'PATCH',
    body: body,
  })
}

export const updateBluebidEstimate = async (propertyId, newBluebidEstimate) => {
  return apiCall(`/properties/${propertyId}/bluebidEstimate`, {
    method: 'POST',
    body: { bluebidEstimate: newBluebidEstimate },
  })
}

export const deleteAccount = async (id) => apiCall(`/users/id/${id}`, { method: 'DELETE' })

export const getUserProperties = (userId, fields) =>
  apiCall(`/users/properties/${encodeURIComponent(userId)}?fields=${fields.map(encodeURIComponent).join(',')}`, {
    method: 'GET',
  })

export const toggleBlockUser = ({ sub, block }) => apiCall(`/users/block`, { method: 'POST', body: { sub, block } })

export const getDataCounts = async () => apiCall('/search/counts')

export const getDataMetrics = async () => apiCall('/search/metrics')

export const getOfferHistory = async (offerId) => apiCall(`/offers/history/${offerId}`)

export const updateLocation = async (propertyId, location) => {
  return apiCall(`/properties/location`, {
    method: 'PATCH',
    body: {
      propertyId: propertyId,
      location: location,
    },
  })
}

export const addressSuggest = async (search) => apiCall(`/address/suggest?address=${search}`)

export const getAddressAliases = async (parentId) => apiCall(`/address/aliases/${parentId}`)

export const getAddress = async (id) => apiCall(`/address/${id}`)

export const deleteAddressById = async (id) =>
  apiCall(`/address/${id}`, {
    method: 'DELETE',
  })

export const addAddressAlias = async (parentId, address) =>
  apiCall('/address/alias', {
    method: 'POST',
    body: {
      parentId,
      address,
    },
  })

export const addAddress = async (address, latitude, longitude, exclude) =>
  apiCall('/address', {
    method: 'POST',
    body: {
      address,
      latitude,
      longitude,
      exclude,
    },
  })

export const excludeAddress = async (id, exclude) =>
  apiCall('/address/exclude', {
    method: 'POST',
    body: {
      id,
      exclude,
    },
  })

export const enableGoogleImages = async (propertyId, enabled) =>
  apiCall(`/properties/enableGoogleImages`, {
    method: 'POST',
    body: {
      propertyId,
      enabled,
    },
  })

// Property Invitations
export const getPropertyInvitations = async (propertyId) => apiCall(`/invites/${propertyId}`)

export const expirationReminderEmail = async (offerId, channels) =>
  apiCall(`/offers/sendExpirationReminder`, {
    method: 'POST',
    body: {
      offerId,
      channels,
    },
  })

export const getOffer = async (offerId) => apiCall(`/offers/${offerId}`)

export const updateMessageNote = async (messageId, message) =>
  apiCall(`/messages/note/`, {
    method: 'PATCH',
    body: {
      messageId,
      message,
    },
  })

export const deleteImages = async (messageId, imageUrls, fallbackNote) =>
  apiCall(`/messages/deleteimages`, {
    method: 'POST',
    body: {
      messageId,
      imageUrls,
      fallbackNote,
    },
  })

export const getResourceCount = async (resource, query) => {
  return apiCall(`/search/counts/${resource}?q=${query}&limit=0`)
}

export const getAllCounts = async (index, query) => {
  return apiCall(`/search/${index}?q=${query}&limit=0`)
}

export const getAllUserActions = async ({ userId, cursor, limit, propertyId }) => {
  // property Id and limit can be added here in the API call.
  return apiCall(`/users/listAllActions?userId=${userId}${cursor}`)
}

export const getSearchResourceByIds = async (resource, ids) => {
  return apiCall(`/search/resourcesByIds`, {
    method: 'POST',
    body: {
      resource,
      ids,
    },
  })
}

export const sendEstimationChangedEmail = (propertyId) => {
  return apiCall(`/email/homeEstimateChanged/${propertyId}`, {
    method: 'POST',
  })
}

export const getGroupDetailView = async (groupId) => apiCall(`/groups/view/adminGroupDetail/${groupId}`)

export const importInvites = async (s3Url) =>
  apiCall(`/invites/import`, {
    method: 'POST',
    body: {
      url: s3Url,
    },
  })

export const citySearch = async (term) => apiCall(`/address/cities/suggest?term=${term}`)

export const prioritizeGroupForCity = async ({ groupId, state, city }) =>
  apiCall(`/groups/${groupId}/cities/${state.toLowerCase()}/${city.toLowerCase()}/prioritize`, {
    method: 'POST',
  })

export const saveCityGroupBranding = async ({ groupId, city, state, branding }) =>
  apiCall(`/groups/${groupId}/cities/${state.toLowerCase()}/${city.toLowerCase()}`, {
    method: 'POST',
    body: branding,
  })

export const getCityLicenses = async ({ state, city }) =>
  apiCall(`/groups/city/${state.toLowerCase()}/${city.toLowerCase()}/licenses`)

export const removeCityLicense = async ({ groupId, city, state }) =>
  apiCall(`/groups/${groupId}/cities`, {
    method: 'DELETE',
    body: {
      city,
      state,
    },
  })

export const addCityLicense = async ({ groupId, city, state }) =>
  apiCall(`/groups/${groupId}/cities`, {
    method: 'POST',
    body: {
      city,
      state,
    },
  })

export const getAllGroups = async () => apiCall(`/groups`).then((res) => res.items)
export function deleteClaimAlert(claimAlertId) {
  return apiCall(`/claimAlerts/${claimAlertId}`, {
    method: 'DELETE',
  })
}

export function getPropertyChangeHistory({ historyId }) {
  return apiCall(`/properties/changeHistory/${historyId}`, {
    method: 'GET'
  })
}

export function annotateMessage({ messageId, note }) {
  return apiCall(`/messages/${messageId}/annotate`, {
    method: 'POST',
    body: {
      note
    }
  })
}

export const getMessagesIndexTimes = async ({ messageIds }) =>
  apiCall(`/messages/indexedAt`, {
    method: 'POST',
    body: {
      messageIds
    }
  })

export function setStagingFlag({ messageId, staging }) {
  return apiCall(`/messages/staging`, {
    method: 'POST',
    body: {
      messageId,
      staging
    }
  })
}

export function deleteMessage({ messageId }) {
  return apiCall(`/messages`, {
    method: 'DELETE',
    body: {
      messageId
    }
  })
}

export const getLicenseById = async (licenseId) => apiCall(`/licenses/${licenseId}`, {
  method: 'GET'
})

export const findTargetLicense = async ({ propertyId, address }) => apiCall(`/inquiries/findTargetLicense`, {
  method: 'POST',
  body: {
    propertyId,
    address
  }
})

export const verifyContactSheet = async ({ contactSheet }) => apiCall(`/inquiries/verifyContactSheet`, {
  method: 'POST',
  body: {
    contactSheet
  }
})

export const syncContactSheet = async ({ inquiryId }) => apiCall(`/inquiries/${inquiryId}/syncContactSheet`, {
  method: 'POST'
})

export const transferZipcode = async ({ zipcode, toLicenseId }) => apiCall(`/licenses/zipcode/${zipcode}/transfer`, {
  method: 'POST',
  body: {
    toLicenseId
  }
})

export const getInquiryById = async ({ id }) =>
  apiCall(`/inquiries/${id}`, {
    method: 'GET'
  })


export const publishInquiry = async ({ id }) =>
  apiCall(`/inquiries/publish`, {
    method: 'POST',
    body: {
      id
    }
  })

export const deleteInquiry = async ({ id }) =>
  apiCall(`/inquiries`, {
    method: 'DELETE',
    body: {
      id
    }
  })

export const restoreInquiry = async ({ id }) =>
  apiCall(`/inquiries/restore`, {
    method: 'POST',
    body: {
      id
    }
  })

export const annotateInquiry = async ({ id, note }) =>
  apiCall(`/inquiries/annotate`, {
    method: 'POST',
    body: {
      id,
      note
    }
  })

export const otpResend = async ({ id }) =>
  apiCall(`/users/otp/resend/${id}`, {
    method: 'POST',
  })

export const otpOverride = async ({ userId, phone, verified }) =>
  apiCall(`/users/otp/override`, {
    method: 'POST',
    body: {
      userId,
      phone,
      verified
    }
  })

export const otpCheck = async ({ userId, phone }) =>
  apiCall(`/users/otp/check`, {
    method: 'POST',
    body: {
      userId,
      phone
    }
  })

export const getLicenseByGroupId = async (groupId) => apiCall(`/licenses/groups/${groupId}`)

export const getLicenseByZipcode = async (zipcode) => apiCall(`/licenses/zipcodes/${zipcode}`)

export const getAllLicenses = async () => apiCall(`/licenses`)

export const bulkAddZipcodes = async (id, zipcodes) => {
  return apiCall(`/licenses/zipcodes`, {
    method: 'POST',
    body: {
      id,
      zipcodes
    }
  })
}

export const updateLicenseChannels = async (id, channels) => {
  return apiCall(`/licenses/${id}/channels`, {
    method: 'PUT',
    body: {
      channels
    }
  })
}

export const getUniqueAddressViews = async (address) => {
  return apiCall(`/properties/uniqueAddressViews/${address}`, {
    method: 'GET'
  })
}