import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'
import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPaperPlane,
  faShare,
  faTrash,
  faHouseUser,
  faCloudDownloadAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { MLS_STATUS_LABELS, MlsStatuses, InquiryTypes, Branding, AgentServices, Group } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { errorPopup, getProperty, successPopup } from 'bb-lib-desktop'

import { updateMlsStatus, updateMlsStatuses } from '../../lib/api'
import { formatPhoneNumber } from '../../lib/utils'
import { isOk, Outcome } from '../../lib/outcome'
import { GREEN, LightGrey, RED, BLUE, BluebidBlue } from '../../constants/colors'
import LoadingInline from '../LoadingInline'
import { annotateInquiry, syncContactSheet } from '../../lib/data/bluebidData'
import { errorToast, successToast } from '../../utils/common'
import { CurrencyResultFieldRenderer, DateValueFormatter, relativeDate, ResultFieldRenderer } from '../grid/GridRenderers'

/**
 * @todo this was updated, move update @bluebid-sdk/core
 */
interface License {
  id: string
  groupId: string
  /**
   * @deprecated use zipcodes
   */
  zipcode?: string
  zipcodes: string[]
  zipcodeDates: string[]
  branding?: Branding
  agentServices?: AgentServices
  group?: Group
  createdAt?: string
}

/**
 * @todo move to @bluebid-sdk/core
 */
type ContactSheet = {
  userId: string
  name: string
  email: string
  phone: string
}

/**
 * @todo move to @bluebid-sdk/core
 */
interface Inquiry {
  id: string
  status: 'active' | 'deleted'
  type: string
  licenseId?: string
  propertyId?: string
  address?: string
  contactSheet: ContactSheet
  message: string
  subject: string
  agentId?: string
  createdAt: string,
  modifiedAt?: string
  staging?: boolean
  note: string
  results?: {
    email?: Outcome<any, unknown>
    sms?: Outcome<any, unknown>
  }
}

interface EsInquiry extends Inquiry {
  _fields?: {
    mls?: {
      mlsStatus?: string
    }
  }
}

/**
 * @todo move to @bluebid-sdk/core
 */
const getInquiryAgentName = (inquiry: Partial<Inquiry>, licenses: License[]) => {
  if (inquiry?.licenseId) {
    const license = licenses.find((l) => l.id === inquiry.licenseId)

    if (!license) {
      return '(license missing)'
    }

    if (!license.group) {
      return '(agent missing)'
    }

    return license.group.name
  }

  const addr = inquiry?.address || inquiry?.subject?.split(':')?.[1]?.trim()
  const zip = addr?.split(' ')?.pop()

  if (zip) {
    const license = licenses.find((l) =>  l.zipcodes.includes(zip) && l.createdAt && inquiry.createdAt && l.createdAt <= inquiry.createdAt)

    if (license?.group) {
      return license.group.name
    }
  }
  
  const defLicense = licenses.find((l) => l.zipcodes.includes('00000'))

  return defLicense?.group?.name
}

const MlsStatusCellEditor = forwardRef<any, any>((props, ref) => {
  const initialValue = props.value || props?.data?._fields?.mls?.mlsStatus
  const [value, setValue] = useState(initialValue)
  const refInput = useRef(null)

  useEffect(() => {
    const eInput = refInput.current
    eInput.focus()
  }, [])

  useImperativeHandle(ref, () => ({
    getValue() {
      return value
    },

    isCancelAfterEnd() {
      return false
    },
  }))

  const handleChange = (event) => {
    setValue(event.target.value)
    setTimeout(() => props.api.stopEditing(), 0)
  }

  return (
    <select ref={refInput} value={value} onChange={handleChange} style={{ width: '100%' }}>
      <option value="">(not set)</option>
      <option value="listed">Listed</option>
      <option value="off-market">Off-Market</option>
    </select>
  )
})

const PublishRenderer = (params) => {
  const { staging, address, propertyId, id, results } = params.data

  return (
    <>
      {staging && (
        <ColumnButton onClick={() => params?.publish?.(id, propertyId, address)}>
          <FontAwesomeIcon icon={faPaperPlane} style={{ color: GREEN }} />
        </ColumnButton>
      )}

      {!staging && (
        <ColumnButton>
          <FontAwesomeIcon
            onClick={() => params?.showResults?.(results)}
            icon={faInfoCircle}
            style={{ color: BluebidBlue }}
          />{' '}
          <FontAwesomeIcon icon={faPaperPlane} style={{ color: LightGrey }} />
        </ColumnButton>
      )}
    </>
  )
}

const DeleteRenderer = (params) => {
  const { id, status } = params.data

  if (status === 'deleted') {
    return (
      <ColumnButton onClick={() => params?.restore?.(id)} title="Restore">
        <FontAwesomeIcon icon={faShare} style={{ color: GREEN }} />
      </ColumnButton>
    )
  }

  return (
    <ColumnButton onClick={() => params?.del?.(id)} title="Delete">
      <FontAwesomeIcon icon={faTrash} style={{ color: RED }} />
    </ColumnButton>
  )
}

const SyncContactSheetRenderer = (params) => {
  const [isSyncing, setIsSyncing] = useState(false)

  const { id, staging } = params.data

  const handleSyncContactSheet = async (id: string) => {
    setIsSyncing(true)
    await syncContactSheet({ inquiryId: id })
    setTimeout(() => {
      params?.onBtnRefresh?.()
      setIsSyncing(false)
    }, 2000)
  }

  return (
    <>
      {staging && (
        <ColumnButton onClick={() => handleSyncContactSheet(id)} title="Sync with User Profile">
          {!isSyncing && <FontAwesomeIcon icon={faCloudDownloadAlt} style={{ color: GREEN }} />}
          {isSyncing && <LoadingInline />}
        </ColumnButton>
      )}

      {!staging && (
        <ColumnButton>
          <FontAwesomeIcon icon={faCloudDownloadAlt} style={{ color: LightGrey }} />
        </ColumnButton>
      )}
    </>
  )
}

const MLSStatusRenderer = (params) => {
  // !todo: extract to re-usable component
  const value = params.value || params.value === '' ? params.value : params?.data?._fields?.mls?.mlsStatus
  const edited = params?.data?._fields?.mls?.mlsEdited
  const colorByStatus = (status) => {
    switch (status) {
      case MlsStatuses.OffMarket:
        return 'green'
      case MlsStatuses.Listed:
        return 'red'
      case MlsStatuses.NoMatch:
        return '#baba3d'
      default:
        return 'black'
    }
  }

  return (
    <Row>
      <Col xs={1}>
        <FontAwesomeIcon
          onClick={async () => {
            if (params?.updatingMlsStatuses) {
              return
            }

            params?.setUpdatingMlsStatuses?.(true)

            const response = await updateMlsStatuses([params?.data?.propertyId])

            if (!isSuccessResponse(response)) {
              errorToast(response.errorMessage)

              return
            }

            successPopup('MLS status updated!')

            delayAction(() => {
              params?.setUpdatingMlsStatuses?.(false)
              params?.onBtnRefresh?.()
            })
          }}
          icon={faHouseUser}
          style={{ cursor: 'pointer', color: params?.updatingMlsStatuses ? LightGrey : BLUE }}
        />
      </Col>
      <Col style={{ fontWeight: value ? 'bold' : 'normal', color: colorByStatus(value) }}>
        {value ? `${MLS_STATUS_LABELS[value]}${edited ? ' (edited)' : ''}` : ''}
      </Col>
    </Row>
  )
}

const toHumanReadable = (str: string) =>
  str
    ?.replace(/([A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|$))/g, ' $1')
    .trim()
    .replace(/^./, (match) => match.toUpperCase())

const EditableCellRenderer = (props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [originalNote, setOriginalNote] = useState(props.value)
  const [note, setNote] = useState(props.value)

  const handleDoubleClick = () => {
    setOriginalNote(props.value)
    setIsEditing(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setNote(e.target.value)

  const commit = () => {
    setIsEditing(false)
    const rowNode = props.api.getRowNode(props.node.id)
    if (rowNode) {
      rowNode.setDataValue(props.colDef.field, note)
    }

    annotateInquiry({ id: props.data.id, note })
      .then((res) => {
        if (res.status === 'error') {
          throw res.errorMessage
        }
        successToast('Note updated')
      })
      .catch((e) => {
        errorToast('Error updating note')
        console.error('error updating note', e)

        if (rowNode) {
          rowNode.setDataValue(props.colDef.field, originalNote)
        }
      })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() === 'enter') {
      commit()
    }
  }

  return isEditing ? (
    <input
      type="text"
      value={note}
      onChange={handleChange}
      onBlur={commit}
      onKeyDown={handleKeyDown}
      autoFocus
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none',
        padding: 0,
        fontSize: 'inherit',
        fontFamily: 'inherit',
        color: 'inherit',
        backgroundColor: 'transparent',
      }}
    />
  ) : (
    <div style={{ width: '100%', height: '100%' }} onDoubleClick={handleDoubleClick}>
      <span style={{ lineHeight: 'normal', verticalAlign: 'middle' }}>{props.value}</span>
    </div>
  )
}

const delayAction = (fn: () => void, delay = 2000) => {
  setTimeout(fn, delay)
}

const ColumnButton = styled.div`
  text-align: center;
  cursor: pointer;
`

type InquiriesGridColumnsDependencies = {
  setEditProperty: (...args: any) => void,
  toggleEditPropertyModal: (...args: any) => void,
  publish?: (...args: any) => void
  showResults?: (...args: any) => void
  setUpdatingMlsStatuses?: (...args: any) => void
  toggleRowCheckbox?: (...args: any) => void
  toggleSelectAllCheckbox?: (...args: any) => void
  restore?: (...args: any) => Promise<void>
  del?: (...args: any) => Promise<void>
  onBtnRefresh?: () => void
  updatingMlsStatuses?: boolean
  licenseLookup?: License[]
  selectAllChecked?: boolean
  rowsChecked?: any
  agentName?: string
  showReferralInfo?: boolean
}

export const inquiriesGridColumns = ({
  setEditProperty,
  toggleEditPropertyModal,
  publish,
  showResults,
  setUpdatingMlsStatuses,
  toggleRowCheckbox,
  toggleSelectAllCheckbox,
  restore,
  del,
  onBtnRefresh,
  updatingMlsStatuses,
  licenseLookup,
  selectAllChecked,
  rowsChecked,
  agentName,
  showReferralInfo,
}: InquiriesGridColumnsDependencies): (ColDef<EsInquiry> | ColGroupDef<EsInquiry>)[] => {
  return [
    {
      colId: 'selectAll',
      width: 40,
      filter: false,
      sortable: false,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      headerComponent: () => (
        <input
          type="checkbox"
          checked={selectAllChecked}
          onChange={(event) => toggleSelectAllCheckbox(event.target.checked)}
        />
      ),
      cellRenderer: (params) => (
        <input
          type="checkbox"
          checked={rowsChecked[params.data.id]}
          onChange={(event) => toggleRowCheckbox(params.data.id, event.target.checked)}
        />
      ),
    },
    {
      colId: 'agent',
      headerName: 'Agent',
      filter: 'agTextColumnFilter',
      width: 150,
      valueFormatter: (params) => {
        if (agentName) {
          return agentName
        }

        return getInquiryAgentName(params.data, licenseLookup)
      },
    },
    {
      field: 'staging',
      headerName: 'Send',
      width: 70,
      cellRenderer: PublishRenderer,
      cellRendererParams: { publish, showResults },
    },
    {
      colId: 'delete',
      headerName: 'Delete',
      width: 70,
      cellRenderer: DeleteRenderer,
      cellRendererParams: { restore, del },
    },
    {
      colId: 'address',
      headerName: 'Address',
      filter: false,
      sortable: false,
      width: 290,
      valueFormatter: (params) => params?.data?.address || params?.data?.subject?.split(':')?.[1]?.trim(),
      cellRenderer: (params) => (
        <>
          {params?.data?.propertyId && (
            <div>
            <a
              href="#"
              onClick={() => {
                getProperty(params.data.propertyId).then(p => {
                  if (p.status === 'error') {
                    errorPopup('Property not found; may have been deleted or unclaimed.')
                    return
                  }

                  setEditProperty(p.data)
                  toggleEditPropertyModal()
                })
              }}
            >
              {params?.data?.address}
            </a>
          </div>
          )}
          {!params?.data?.propertyId && <span>{params?.valueFormatted}</span>}
        </>
      ),
    },
    {
      headerName: 'Contact',
      children: [
        {
          colId: 'contactSheet.sync',
          headerName: 'Sync',
          width: 80,
          cellRenderer: SyncContactSheetRenderer,
          cellRendererParams: { onBtnRefresh },
        },
        {
          field: 'contactSheet.name',
          width: 170,
          headerName: 'Name',
          filter: 'agTextColumnFilter',
          valueFormatter: (params) => params.value?.toUpperCase(),
        },
        {
          field: 'contactSheet.email',
          width: 170,
          headerName: 'Email',
          filter: 'agTextColumnFilter',
          cellRenderer: (params) => (
            <Link to={`/admin/userprofile/${params.data?.contactSheet?.userId}`}>{params.value}</Link>
          ),
        },
        {
          field: 'contactSheet.phone',
          width: 120,
          headerName: 'Phone',
          filter: 'agTextColumnFilter',
          valueFormatter: (params) => formatPhoneNumber(params.value),
        },
      ],
    },
    {
      field: 'type',
      headerName: 'Type',
      valueFormatter: (params) => toHumanReadable(params.value),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.values(InquiryTypes),
        valueFormatter: (params) => toHumanReadable(params.value),
      },
    },
    { field: 'message', headerName: 'Message', filter: 'agTextColumnFilter' },
    { field: 'subject', headerName: 'Subject', filter: 'agTextColumnFilter' },
    {
      colId: 'mlsStatus',
      headerName: 'MLS Status',
      filter: false,
      sortable: false,
      editable: !updatingMlsStatuses,
      cellRenderer: MLSStatusRenderer,
      cellRendererParams: { onBtnRefresh, updatingMlsStatuses, setUpdatingMlsStatuses },
      cellEditor: MlsStatusCellEditor,
      onCellValueChanged: async (params) => {
        if (params.newValue === params?.data?._fields?.mls?.mlsStatus) {
          return
        }

        const propertyId = params.data.propertyId
        const value = params.newValue

        setUpdatingMlsStatuses(true)

        const response = await updateMlsStatus(propertyId, value)

        if (!isSuccessResponse(response)) {
          errorToast(response.errorMessage)

          return
        }

        delayAction(() => {
          setUpdatingMlsStatuses(false)
          onBtnRefresh?.()
        }, 3000)
      },
    },
    {
      colId: 'mlsCheckedAt',
      headerName: 'MLS Last Checked',
      filter: false,
      sortable: false,
      cellRenderer: (params) => {
        const value = params?.data?._fields?.mls?.mlsCheckedAt

        return value ? relativeDate(value) : ''
      },
    },

    { colId: 'estimate', headerName: 'Estimate',
      sortable: false, width: 110,
      cellRenderer:CurrencyResultFieldRenderer, cellRendererParams:{ object: 'detail', field: 'detail.valuation.value'}
    },
    { colId: 'ownerPrice', headerName: 'Owner Price',
      sortable: false, width: 100,
      cellRenderer:CurrencyResultFieldRenderer, cellRendererParams:{ object: 'detail', field: 'detail.owner_sell_price'}
    },
    { colId: 'county', headerName: 'County',
      sortable: false, width: 140,
      cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'detail', field: 'detail.parcel.county_name'}
    },

    { field: 'id', headerName: 'ID', filter: 'agTextColumnFilter' },
    {
      colId: 'emailSent',
      headerName: 'Email Sent',
      filter: 'agSetColumnFilter',
      width: 110,
      valueFormatter: (params) => `${isOk(params.data.results?.email) || false}`,
      filterParams: {
        values: [true, false],
      },
    },
    {
      colId: 'smsSent',
      headerName: 'SMS Sent',
      filter: 'agSetColumnFilter',
      width: 110,
      valueFormatter: (params) => `${isOk(params.data.results?.sms) || false}`,
      filterParams: {
        values: [true, false],
      },
    },
    ...(showReferralInfo
      ? [
          {
            colId: 'anywhereLeadSent',
            headerName: 'Anywhere Lead Sent',
            filter: 'agTextColumnFilter',
            width: 150,
            valueFormatter: (params) => {
              // backwards compat
              if (params.data.results?.referral) {
                return `${isOk(params.data.results?.referral) || false}`
              }

              return `${isOk(params.data.results?.anywhere) || false}`
            },
          },
          {
            colId: 'anywhereLeadId',
            headerName: 'Anywhere Lead ID',
            filter: 'agTextColumnFilter',
            width: 150,
            valueFormatter: (params) =>
              params.data.results?.referral?.value?.referralId || params.data.results?.anywhere?.value?.referralId,
          },
        ]
      : []),
    {
      field: 'results.email.value.dateSent',
      headerName: 'Timestamp',
      valueFormatter: DateValueFormatter,
      filter: false,
      sortable: false,
    },
    {
      hide: true,
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      field: 'createdAt',
      headerName: 'Created',
      valueFormatter: DateValueFormatter,
      filter: 'agDateColumnFilter',
      sort: 'desc' as any,
    },
    { field: 'note', headerName: 'Note', width: 300, cellRenderer: EditableCellRenderer },
  ]
}
