import { Col, Label, Row } from 'reactstrap'
import { currencyFormatter, expirationDate, formatDate, formatPhoneNumber } from '../../lib/utils'
import { useEffect, useState } from 'react'
import { getOffer, getOfferHistory, getPropertyById, getUser } from '../../lib/data/bluebidData'
import BlockUI from '../BlockUI'
import styled from 'styled-components'

export const OfferDetails = ({ offerId }) => {
    const [, setHistory] = useState([])
    const [owner, setOwner] = useState()
    const [bidder, setBidder] = useState()
    const [, setProperty] = useState(undefined)
    const [lastOffer, setLastOffer] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!offerId)
            return

        setIsLoading(true)
        getOfferHistory(offerId)
            .then(async (hist) => {
                let offer = await getOffer(offerId)
                let property = await getPropertyById(offer.propertyId)
                let owner = await getUser(property.ownerId)

                const firstBid = hist[0]
                const lastBid = hist[hist.length - 1]
                let bidder = await getUser(firstBid.bidderId)
                console.log('last', lastBid)
                // console.log('history', hist)
                // console.log(property, owner, bidder)
                setOwner(owner)
                setBidder(bidder)
                setProperty(property)
                setLastOffer(lastBid)
                hist.forEach(h => {
                    h.bidder = h.bidderId === property.ownerId ? owner : bidder
                    h.owner = owner
                })
                setHistory(hist)
                setIsLoading(false)
            })

        return () => {
        }
    }, [offerId])

    let bidType = 'Bluebid'
    let lastRecipient = owner
    if (lastOffer) {
        if (lastOffer.bidderId === owner.id) {
            bidType = `Counter from owner`
            lastRecipient = bidder
        } else {
            bidType = (lastOffer.type === 'Offer' ? 'Bluebid' : 'Counter') + ' from bidder'
            lastRecipient = owner
        }
    }

    return <>
        <BlockUI loading={isLoading} />
        {lastOffer &&
            <>
                <Row>
                    <Col md={2}>
                        <FieldLabel>Address: </FieldLabel>
                    </Col>
                    <Col md={10}>
                        {lastOffer?.address || ''}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FieldLabel>Owner: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {owner?.email || ''}
                    </Col>
                    <Col md={2}>
                        <FieldLabel>Phone: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {formatPhoneNumber(owner?.phone) || ''}
                    </Col>

                </Row>
                <Row>
                    <Col md={2}>
                        <FieldLabel>Bidder: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {bidder?.email || ''}
                    </Col>
                    <Col md={2}>
                        <FieldLabel>Phone: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {formatPhoneNumber(bidder?.phone) || ''}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FieldLabel>Bid Type: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {bidType}
                    </Col>
                    <Col md={2}>
                        <FieldLabel>Bid Amount: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {currencyFormatter.format(lastOffer?.bidAmount) || ''}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FieldLabel>Created: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {formatDate(lastOffer?.createdAt) || ''}
                    </Col>
                    <Col md={2}>
                        <FieldLabel>Modified: </FieldLabel>
                    </Col>
                    <Col md={4}>
                        {formatDate(lastOffer?.modifiedAt) || ''}
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <FieldLabel>Expires at: </FieldLabel>
                    </Col>
                    <Col md={10}>
                        {expirationDate(lastOffer?.modifiedAt) || ''}
                    </Col>
                </Row>

                {/* TODO: below should be extracted to a 'BidStatus' component probably... */}
                <Row>
                    <Col md={12}>
                        Awaiting response from {(owner.id === lastRecipient.id ? 'owner: ' : 'bidder : ') + lastRecipient.email}
                    </Col>
                </Row>
            </>
        }
    </>
}

const FieldLabel = styled(Label)`
    font-weight: bold;
`
