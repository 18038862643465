import { OfferDetailsValue } from '../detail/common'
import { contingentChoices } from './choices'
import styled from 'styled-components'
import React from 'react'

export const ContingencyList = ({ list }) => {
    if (list?.length === 0)
        return <OfferDetailsValue key={'none'}>None</OfferDetailsValue>

    let contingents = contingentChoices.filter(x => list.includes(x.value))
    if (contingents.length === 0) {
        contingents = [{label: 'None'}]
    }

    return <ContingentContainer> {
        contingents.map((contingent, n) =>
            <ContingentItem key={`ci-${n}`}>
                {contingent.label}
            </ContingentItem>
        )}
    </ContingentContainer>

}

const ContingentContainer = styled.ul`
`
const ContingentItem = styled.li`
    list-style: circle;
    //font-size: 0.9em;
    //font-weight: normal;
    //line-height: 25px;
`
