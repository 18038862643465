import { api } from 'bb-lib-desktop'

export function updateStreetViewPhoto(
  propertyId: string,
  options: {
    url: string
    lat: number
    lon: number
    replaceMainImage?: boolean
  }
) {
  return api<string>(`/properties/${propertyId}/streetview`, {
    method: 'POST',
    body: options,
  })
}
