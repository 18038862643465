import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Rollbar from 'rollbar'

import { Property, User } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { BlockUI, NewPrimaryButton, NewSecondaryButton, setAssetOrder } from 'bb-lib-desktop'

import { getAssetWithId } from '../../lib/data/bluebidData'
import styled from 'styled-components'
import { DragNDropList } from '../detail/DragnDropList'
import { errorToast, successToast } from '../../utils/common'
import { TagManager } from '../../lib/gtm/TagManager'

export const DialogFooterBlock = styled.div`
  justify-content: center;
`

export const ModalSubHeader = styled.h5`
  font-size: 1.1rem;
  font-weight: 400;
`

export const CenteredModalFooter = styled(ModalFooter)`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
`

type ManagePhotosActionProps = {
  bluebidUser: User
  property: Property
  showModal: boolean
  onClose: () => void
  shouldUpdateProfile?: () => void
  rollbar?: Rollbar
}
export const ManagePhotosAction: React.FC<PropsWithChildren<ManagePhotosActionProps>> = ({
  bluebidUser,
  property,
  shouldUpdateProfile = null,
  showModal,
  onClose,
  children,
  rollbar,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [assets, setAssets] = useState([])
  const [orderedAssets, setOrderedAssets] = useState([])
  const loggedIn = bluebidUser !== undefined
  const isClaimed = property?.ownerId !== undefined
  const isOwner = loggedIn && isClaimed && bluebidUser && property?.ownerId === bluebidUser.id

  useEffect(() => {
    // Track showing the modal
    if (showModal) {
      getUpdatedPropertyAssets()

      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          page_location: window.location.pathname + '/manageAssets',
        },
      })
    }
  }, [showModal])

  const resetPhotos = () => {
    onClose()
    setAssets([])
  }

  const getUpdatedPropertyAssets = () => {
    setIsLoading(true)
    getAssetWithId(property?.propertyId)
      .then((result) => {
        if (result.status !== 'error') {
          setAssets(result)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleUpdate = async () => {
    //Function is there to update the asset order of the property
    setIsLoading(true)
    // const orderedAssetList = orderedAssets.map(({ id }) => id);
    await setAssetOrder({
      propertyId: property?.propertyId,
      assetOrder: orderedAssets,
    }).then((response) => {
      if (isSuccessResponse(response)) {
        successToast(`Images updated & re-ordered successfully!`)
        onClose()
        shouldUpdateProfile?.()
      } else {
        errorToast(response.errorMessage || `Oops, something went wrong!`)
        rollbar.error(response.errorMessage, response)
      }
      setIsLoading(false)
    })
  }

  // if (!isOwner) return null

  return (
    <React.Fragment>
      {children}

      <Modal size="l" isOpen={showModal} toggle={onClose} modalTransition={{ timeout: 200 }}>
        <BlockUI blocking={isLoading} />

        <ModalHeader>
          <div>
            <h3>Manage Photos and Videos</h3>
            <ModalSubHeader>
              Click and drag to re-arrange the order of your home images or click X to remove them.
            </ModalSubHeader>
          </div>
        </ModalHeader>
        <ModalBody>
          {assets.length ? (
            <DragNDropList
              assetList={assets}
              updateOrderList={(assets) => {
                setOrderedAssets(assets)
              }}
            />
          ) : (
            ''
          )}
        </ModalBody>
        <CenteredModalFooter>
          <DialogFooterBlock>
            <NewPrimaryButton
              height={38}
              style={{ margin: '0 10px 0 0', minWidth: '140px' }}
              disabled={isLoading}
              onClick={() => handleUpdate()}
            >
              Save Updates
            </NewPrimaryButton>
            <NewSecondaryButton
              height={38}
              style={{ margin: '0 0 0 0', minWidth: '140px' }}
              disabled={isLoading}
              onClick={() => resetPhotos()}
            >
              Cancel
            </NewSecondaryButton>
          </DialogFooterBlock>
        </CenteredModalFooter>
      </Modal>
    </React.Fragment>
  )
}
