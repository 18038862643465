import { GoogleStreetViewPhotoOptions } from '@bluebid-sdk/core'
import { api } from 'bb-lib-desktop'

export function googleStreetViewApiUrl(options: GoogleStreetViewPhotoOptions) {
  return api<string>('/images/streetview/apiurl', {
    method: 'POST',
    body: {
      lat: options.latitude,
      lng: options.longitude,
      width: options.width,
      height: options.height,
      fov: options.fov,
      pitch: options.pitch,
      heading: options.heading,
    },
  })
}
