import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {useLocation} from "react-router-dom";

import {Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import styled from 'styled-components';

import {addressFromDetail, useSessionStorage} from "../../lib/utils";
import { PrimaryButton, UnstlyedLink } from './common'
import {claimProperty, getClaimAttempts, getOwnerNames} from "../../lib/data/bluebidData";
import BlockUI from "../BlockUI";
import Checkbox from "../Checkbox";
import { addressUserToURLParams, errorToast } from '../../utils/common'
import {AuthContext} from "../../constants/context";
import { NOT_A_RESIDENCE_MESSAGE} from "../ErrorHandling";
import {VerifyEmailModal} from "./VerifyEmailModal";
import { TagManager } from '../../lib/gtm/TagManager'
import { LINE_COLOR } from '../../constants/colors'

const MAX_ATTEMPTS = 2

export const ClaimPropertyAction = ({property, isLinkStyle, text, claimType}) => {
    let address = addressFromDetail(property)
    const { bluebidUser, signIn, refetchUser } = React.useContext(AuthContext)

    const [showClaimModal, setShowClaimModal] = React.useState(false)
    const [showVerifyModel, setShowVerifyModal] = React.useState(false)
    const [ownerChoices, setOwnerChoices] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [chosenOwner, setChosenOwner] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [, setLastPage] = useSessionStorage('lastPage', '')
    const [returnToPageFrom, setReturnToPageFrom] = useSessionStorage('setReturnToClaimFrom', '')
    const [, setReturnToClaimSuccess] = useSessionStorage('setReturnToClaimSuccess', '')
    const location = useLocation()
    const navigate = useNavigate();
    const isClaimed = property.ownerId !== undefined
    const isVerified = bluebidUser && bluebidUser.emailVerified === true

    const setOwner = (n) => {
        if (ownerChoices.length > n) {
            setChosenOwner(ownerChoices[n])
        }
    }
    const toggle = () => {
        setShowClaimModal(!showClaimModal)
        if (!showClaimModal) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'claim_cancelled',
                    category: 'Claim',
                    action: 'User cancelled claim',
                    label: 'claim-cancelled'
                }
            })
        }
    }
    const close = () => setShowClaimModal(false)

    useEffect(() => {
        // console.log('returnToPage', claimType, returnToPageFrom);
        if (isVerified && (returnToPageFrom === claimType)) {
            startClaim(claimType);
            setReturnToPageFrom('');
        }
    }, [])

    const startLinkClaim = (e) => {
        e.stopPropagation()
        e.preventDefault()
        startClaim('linkClaim')
    }
    /**
     * Show the Claim Property dialog
     */
    const startClaim = (claimType) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                page_location: window.location.pathname + '/claimDialog',
            }
        })
        if (!bluebidUser) {
            setLastPage(location.pathname)
            setReturnToPageFrom(claimType)
            signIn();
            return
        }

        // user is logged in, make sure they have a verified email
        if (!isVerified) {
            setShowVerifyModal(true)
            return
        }

        setShowClaimModal(true)
        setOwnerChoices([])
        setDisabled(false)
        setErrorMessage("")
        getClaimAttempts(bluebidUser.id, address)
            .then(result => {
                if (result) {
                    if (result?.attempts >= MAX_ATTEMPTS) {
                        setDisabled(true)
                    } else {
                        setErrorMessage("")
                        getOwnerNames(address, bluebidUser.id)
                            .then(result => {
                                if (result && result.status !== 'error') {
                                    // console.log(result)
                                    setOwnerChoices(result.names)
                                } else {
                                    // look at result.errorDetails.reasonCode
                                    // reasonCode: 300 - could not find owner information
                                    // disable further claim actions on this page
                                    console.error({result})
                                    setDisabled(true)
                                    setErrorMessage(NOT_A_RESIDENCE_MESSAGE)
                                }
                            })
                    }
                } else {
                    console.error({result})
                    setDisabled(true)
                    setErrorMessage(result.errorMessage)
                }
            })
    }

    /**
     * Perform the claim property action by calling the back-end Api
     */
    const claimPropertyAction = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'claim_started',
                category: 'Claim Started',
                action: 'User clicked claim',
                label: 'claim-started'
            }
        })
        setIsLoading(true)
        setErrorMessage('')
        claimProperty({
            userId: bluebidUser.id,
            ownerName: chosenOwner.toUpperCase(),
            address: address
        })
        .then(result => {
            // console.log(result)
            if (result && result.propertyId) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'claim',
                        category: 'Claim successfully',
                        action: 'claim',
                        label: 'claim'
                    }
                })
                setIsLoading(false)
                // successToast('Property claimed successfully!');
                setReturnToClaimSuccess('true')
                navigate(`/claimedProperty/${result.propertyId}`, {state:{recentClaimed: true}})
                setShowClaimModal(false)
                refetchUser()
            } else {
                setIsLoading(false)
                let msg = "Please select your name. If you are having trouble, please contact us at support@bluebidhomes.com"
                if (result.errorDetail.reasonCode === 302) { // incorrect choice
                    // ReactGA.event('Claim_incorrect',{
                    //     category: 'Claim',
                    //     action: 'User chose wrong owner',
                    //     label: 'claim-incorrect'
                    // })
                    msg = "Sorry, please try again"
                } else {
                    // error. Look at errorDetail.reasonCode:
                    //  300 - TooManyClaimAttemptsError
                    //  301 - PropertyAlreadyClaimedError
                    //  302 - IncorrectOwnerError
                    // TODO: handle these...
                    switch (result?.errorDetail?.reasonCode) {
                        case 300: // too many attempts
                        case 301: // property is already claimed
                            break;
                        default:
                            break;
                    }
                }
                errorToast(msg);
                setErrorMessage(msg)
            }
        })
    }

    /**
     * List of users to choose from
     * @param props
     * @return {*}
     * @constructor
     */
    let OwnerChoiceList = ({owners}) => {
        return owners.map((owner, ndx) => {
            return (
                <OwnerListItem key={`user-${ndx}`} onClick={(e) => setOwner(ndx)}>
                    <Input name="ownerName" type="radio"
                           value={owner}
                           checked={owner === chosenOwner}
                           id={`user-${ndx}`} onChange={(e) => setChosenOwner(e.currentTarget.value)} />
                    <OwnerChoice for={`user-${ndx}`} >{owner}</OwnerChoice>
                </OwnerListItem>
            )
        })
    }

    /**
     * Build content body of the modal. This will either have a list of users to choose from
     * or it will contain a 'contact support' message if the user has tried too many times
     * @return {JSX.Element}
     */
    const ContentBody = ({owners}) => {
        const [checked, setChecked] = useState(false)

        const toggleAck = (e) => setChecked(e.target.checked)

        const claimIfEnabled = (e) => {
            e.preventDefault()
            if (chosenOwner !== '') {
                setChecked(!checked)
            }
        }
        return (<>
            <BlockUI blocking={owners.length === 0} />

            <DialogHeaderBlock>
                In order to claim this home, please verify that you are the legal owner.
            </DialogHeaderBlock>

            <ClaimErrorMessage errorMsg={errorMessage} />

            <OwnerList>
                <OwnerChoiceList owners={owners} />
            </OwnerList>

            <DialogFooterBlock>
                <Row>
                    <Col className={"col-1"}>
                        <AckLabel>
                            <Checkbox onChange={toggleAck} checked={checked} />
                        </AckLabel>
                    </Col>
                    <AckCol className={"col-10"} onClick={claimIfEnabled}>
                        I am the homeowner or have permission to claim on the homeowner's behalf.
                    </AckCol>
                </Row>
                <Row className={"mt-3"}>
                    <Col className={"text-center"}>
                        <PrimaryButton disabled={!checked && chosenOwner !== ""} onClick={claimPropertyAction}>
                            Verify
                        </PrimaryButton>
                    </Col>
                </Row>
            </DialogFooterBlock>

            </>
        )
    }

    const DisabledContent = () => {
        // TODO: better UI
        return (
            <ModalHeader>
                <DialogHeaderBlock>
                    <ClaimSupportMessage>
                        Our apologies. We are having trouble locating your property. <br/><br/>
                        Please provide a few more details to claim your home <a target="_blank"
                                                                                href={`https://www.bluebidhomes.com/claim-my-home?${addressUserToURLParams(address, bluebidUser)}`}>here</a>.
                    </ClaimSupportMessage>
                </DialogHeaderBlock>

                <CenteredDialogActionButton onClick={close}>Close</CenteredDialogActionButton>
            </ModalHeader>
        )
    }

    if (isClaimed) {
        return <></>
    }

    // Verification dialog content. before - true before email was sent, false after
    const verifyDialogContent = (before) => {
        if (before) {
            return <VerifyContent>
                Your email address has not yet been confirmed.
                Please click below to resend the verification email.
            </VerifyContent>
        }
        return <VerifyContent>
            Please check your email for the verification link.
        </VerifyContent>
    }

    return (
        <>
            {isLinkStyle &&
                <UnstlyedLink href="#" onClick={(e) => startLinkClaim(e)}>{text}</UnstlyedLink>
            }
            {!isLinkStyle &&
                <PrimaryButton className={"btn-primary btn-margin"} onClick={() => startClaim('buttonClaim')}>
                    Claim Ownership
                </PrimaryButton>
            }

            <VerifyEmailModal show={showVerifyModel}
                              title={"Please verify your email address"}
                              toggle={() => setShowVerifyModal(false)}
                              dialogContent={verifyDialogContent}
                              path={location.pathname}
            />

            <Modal isOpen={showClaimModal} toggle={toggle}>
                { disabled && <DisabledContent /> }

                { !disabled &&
                    <>
                        <ModalHeader  toggle={() => setShowClaimModal(false)}>
                            <ConfirmTitle>
                                <h3>Please verify you own</h3>
                                <h3>{address}</h3>
                            </ConfirmTitle>
                        </ModalHeader>
                        <ModalBody>
                            <BlockUI blocking={isLoading} />

                            <ChooseOwnerForm>
                                <ContentBody owners={ownerChoices} />
                            </ChooseOwnerForm>
                        </ModalBody>
                    </>
                }
            </Modal>
        </>
    )
}

export const VerifyContent = styled.div`
  font-family: 'Montserrat', serif, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
`

const ErrorMsg = styled.div`
    color: red;
    margin: 15px 20px;
`

const ClaimErrorMessage = ({errorMsg}) => {

    return <ErrorMsg>{errorMsg}</ErrorMsg>
}

const OwnerList = styled.ul`
    margin: 10px;
    border-radius: 10px;
    border: 1px solid ${LINE_COLOR};
`

const OwnerListItem = styled.li`
    padding-bottom: 11px;
    padding-top: 10px;
    /* margin-left: 40px; */
    border-bottom: 1px solid ${LINE_COLOR};
    margin-right: 30px;
    list-style: none;
`

const OwnerChoice = styled(Label)`
    position: relative;
    top: 2px;
    margin-left: 10px;
`
const DialogHeaderBlock = styled.div`
    margin-left: 10px;
    padding: 10px;    
`
const DialogFooterBlock = styled.div`
    margin-left: 35px;
    margin-bottom: 15px;
    padding: 10px;
`

const CenteredDialogActionButton = styled(Button)`
    width: 50%;
    margin: 0 auto;
    display: block;
    `

export const ActionContainer = styled.span`
    padding: 0 10px;
`
const AckLabel = styled(Label)`
  position:relative;
  top: 4px;
`
const AckCol = styled(Col)`
    line-height: 18px;
`

const ChooseOwnerForm = styled(Form)`
    font-family: 'Montserrat', "Helvetica Neue", serif, sans-serif;
`

const ConfirmTitle = styled.div`
    font-family: "Roboto", "Helvetica Neue", serif, sans-serif;
`

const ClaimSupportMessage = styled.div`
    padding: 2.0rem;
      a {
        font-weight: bold;
      }
`

