import React from "react";
import styled, { keyframes } from 'styled-components'

const BlockUIContainer = styled.div`
    position: absolute;
    z-index: 1010;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 2em;
    cursor: wait;
    overflow: hidden;
`

const BlockUIOverlay = styled.div`
    width: 100%;
    height: 100%;
    opacity: 0.75;
    filter: alpha(opacity=50);
    background-color: rgb(184, 184, 184);
`

const BlockUIMessageContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    z-index: 10001;
`

const BlockUIMessage = styled.div`
    color: #333;
    background: none;
    z-index: 1011;
`

const LoadingIndicator = styled.div`
    text-align: center;
`

const loadingAnimation = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`

const UILoader = styled.div`
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;

    animation-name: ${loadingAnimation};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;

    -webkit-animation-name: ${loadingAnimation};
    -webkit-animation-duration: 1.4s;
    -webkit-animation-iteration-count: infinite;

    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);

    &:before {
        width: 50%;
        height: 50%;
        background: #ffffff;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }
    &:after {
        background: #506a8d;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`

const BlockUI = props => {
    const {blocking, title, message} = props

    if (!blocking) {
        return <></>;
    } else {
        return (
            <BlockUIContainer>
                <BlockUIOverlay />
                <BlockUIMessageContainer>
                    <BlockUIMessage>
                        <h4>{title}</h4>
                        <LoadingIndicator>
                            <UILoader>{message}</UILoader>
                        </LoadingIndicator>
                    </BlockUIMessage>
                </BlockUIMessageContainer>
            </BlockUIContainer>
        );
    }
};

BlockUI.defaultProps = {
    blocking: false,
    title: "Working..",
    message: ''
};

export default BlockUI;
