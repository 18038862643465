import { currencyFormatter } from '../../lib/utils'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { EstimateRow, ReactTooltip, UnderlineTip } from './commonStyles'
import { Col } from 'reactstrap'
import { isMobile } from 'react-device-detect'
import { AuthContext } from '../../constants/context'
import { useNavigate } from 'react-router-dom'

export const PropertyPriceInfo = ({property}) => {
    const { bluebidUser } = useContext(AuthContext)
    const isOwner = bluebidUser && property && bluebidUser.id === property.ownerId

    return <>
        <ReactTooltip multiline={true} type="success" effect="solid" place="right" />
        <PropertyPriceInfoBlock>
            <OwnersPrice property={property} isOwner={isOwner} dataTip={'The asking price specified by the owner of this property.'}/>
            <CurrentEstimate detail={property?.detail}  dataTip={'Bluebid\'s data partners use an automated valuation model (AVM) to produce estimates for homes based on available data and comparable sales. While estimates are data points worth considering, they do not take into account this home\'s unique characteristics. This estimate is not a professional appraisal and should not be viewed as a substitute for expert pricing or offering advice.'}/>
            <BluebidEstimate detail={property?.detail}  dataTip={'Bluebid\'s estimate for the value of this property'}/>
            <CurrentRange detail={property?.detail}  dataTip={'This estimate range is not a professional appraisal and should not be viewed as a substitute for expert advice.'}/>
            <LastSalePrice deeds={property?.detail?.deeds}  dataTip={'This price should not be viewed as a reflection of current market value.'}/>
        </PropertyPriceInfoBlock>
    </>
}

const PriceInfoCol = styled(Col)`
    padding: 0;
    margin: 0;
`

const PriceInfoRow = ({value, children}) => {
    let colWidth = isMobile ? undefined : 6
    return <EstimateRow>
        <PriceInfoCol md={colWidth}>
            {children}
        </PriceInfoCol>
        <PriceInfoCol>
            {value}
        </PriceInfoCol>
    </EstimateRow>
}

const OwnersPrice = ({property, dataTip, isOwner}) => {
    const navigate = useNavigate()

    let detail = property?.detail
    let ownerPrice = detail?.owner_sell_price

    const action = isOwner ?
        <UnderlineHref href={'#'} onClick={() => navigate(`/admin/editfacts/${property.propertyId}`)}>add my price</UnderlineHref> :
        'unavailable'

    let value = ownerPrice ? <><SalePrice>{currencyFormatter.format(ownerPrice)}</SalePrice></> : action

    return <PriceInfoRow value={value}>
        <UnderlineTip data-tip={dataTip}>Owner's estimate</UnderlineTip>
    </PriceInfoRow>
}
const UnderlineHref = styled.a`
    text-decoration: underline;
`

const CurrentEstimate = ({detail, dataTip}) => {
    const estimate = detail?.valuation?.value
    let value = estimate ? <SalePrice>{currencyFormatter.format(estimate)}</SalePrice> : 'unavailable'
    return <PriceInfoRow value={value}>
        <UnderlineTip data-tip={dataTip}>Market estimate</UnderlineTip>
    </PriceInfoRow>
}

const BluebidEstimate = ({detail, dataTip}) => {
    const estimate = detail?.bluebid_estimate?.value
    let value = estimate ? <SalePrice>{currencyFormatter.format(estimate)}</SalePrice> : 'unavailable'
    return <PriceInfoRow value={value}>
        <UnderlineTip data-tip={dataTip}>Bluebid estimate</UnderlineTip>
    </PriceInfoRow>
}

const CurrentRange = ({detail, dataTip}) => {
    const low = detail?.valuation?.low
    const high = detail?.valuation?.high
    let value = (high && low) ?
        <><SalePrice>{currencyFormatter.format(low)}</SalePrice> - <SaleDate>{currencyFormatter.format(high)}</SaleDate></> : 'unavailable'
    return <PriceInfoRow value={value}>
        <UnderlineTip data-tip={dataTip}>Estimated range</UnderlineTip>
    </PriceInfoRow>
}

const LastSalePrice = ({deeds, dataTip}) => {
    let value = 'price unavailable'
    if (deeds && deeds.length > 0) {
        try {
            let deed = deeds[0]
            let price = deed.sale_price ? currencyFormatter.format(deed.sale_price) : undefined
            let dateStr = deed.recording_date

            if (price && dateStr) {
                let parts = dateStr.split('-')
                let date = new Date(parts[0], parts[1] - 1, parts[2]);
                dateStr = date.toLocaleDateString('en-us', { year: "numeric", month: "short", day: 'numeric' })
                value = <><SalePrice>{price}</SalePrice> on <SaleDate>{dateStr}</SaleDate></>
            }
        } catch (e) {
        }
    }
    return <PriceInfoRow value={value}>
        <UnderlineTip data-tip={dataTip}>Last sold for</UnderlineTip>
    </PriceInfoRow>
}

const SalePrice = styled.span`
    font-weight: bold;
`

const SaleDate = styled(SalePrice)`
`

const PropertyPriceInfoBlock = styled.div`
    padding: 10px 0 15px 0;
    font-family: Montserrat, sans-serif
`

