import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import { formatPhoneNumber } from '@bluebid-sdk/core'

export type User = {
  id: string,
  picture: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string
}

type UserCardProps = {
  user: User
}

const UserCard: React.FC<UserCardProps> = ({ user }: UserCardProps) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs="auto">
            <img src={user.picture} width="50" height="50" />
          </Col>
          <Col>
            <div><strong>{`${user.firstName?.toUpperCase()} ${user.lastName?.toUpperCase()}`}</strong></div>
            <div>
              <small>
                (ID: <a href={`/userprofile/${user.id}`} target="_blank" rel="noopener noreferrer">{user.id}</a>)
              </small>
            </div>
            <div className="mt-2">
              <FontAwesomeIcon className="mr-2" icon={faEnvelope} fixedWidth />
              {user.email
                ? <a href={`mailto:${user.email}`}>{user.email}</a>
                : " -"}
            </div>
            <div className="mt-1">
              <FontAwesomeIcon className="mr-2" icon={faPhone} fixedWidth />
              {user.phone
                ? <a href={`tel:${user.phone}`}>{formatPhoneNumber(user.phone)}</a>
                : " -"}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default UserCard
