import { Container, Card, CardBody, CardTitle, Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faAndroid, faApple, faChrome, faEdge, faFacebook, faFirefox, faInternetExplorer, faLinux, faOpera, faSafari, faUbuntu, faWindows } from '@fortawesome/free-brands-svg-icons';

export const UserLogin = (userAction) => {
    const userId = userAction.userId
    const { ipAddr, userAgent, os, device, browser, firstName, lastName, location, city, state, sub } = userAction.data

    const lat = location?.lat
    const lng = location?.lng

    const browserIcon = browser.includes('Safari') ? <FontAwesomeIcon icon={faSafari} /> :
        browser.includes('Chrome') ? <FontAwesomeIcon icon={faChrome} /> :
        browser.includes('Firefox') ? <FontAwesomeIcon icon={faFirefox} />:
        browser.includes('Edge') ? <FontAwesomeIcon icon={faEdge} /> :
        browser.includes('Opera') ? <FontAwesomeIcon icon={faOpera} /> :
        browser.includes('Facebook') ? <FontAwesomeIcon icon={faFacebook} /> :
        browser.includes('IE') ? <FontAwesomeIcon icon={faInternetExplorer} /> :
        ''

    const osIcon = os.includes('Mac OS') ? <FontAwesomeIcon icon={faApple} /> :
        os.includes('Windows') ? <FontAwesomeIcon icon={faWindows} /> :
        os.includes('Android') ? <FontAwesomeIcon icon={faAndroid} /> :
        os.includes('iOS') ? <FontAwesomeIcon icon={faApple} /> :
        os.includes('Ubuntu') ? <FontAwesomeIcon icon={faUbuntu} /> :
        os.includes('Chromium') ? <FontAwesomeIcon icon={faChrome} /> :
        os.includes('Linux') ? <FontAwesomeIcon icon={faLinux} /> :
        ''

    const lastLoc = city && state && lat && lng ? `${city}, ${state} (${lat}, ${lng})` : 'unknown'

    const hasNoName = !firstName && !lastName || (firstName === '' && lastName === '')
    const isUserPassSignup = sub && sub.split('|')[0] === 'auth0'

    return (
        <Container>
            <Card>
                <CardBody>
                    <CardTitle tag="h5">{
                        hasNoName ?
                            isUserPassSignup ? 'No Name (Username/Password Signup)' : ''
                            : `${firstName} ${lastName}`
                    }
                    </CardTitle>
                    <a href={`/admin/userprofile/${userId}`} rel="noreferrer" target="_blank" className="text-decoration-none">
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> Open Profile
                    </a>
                    <Table striped className="mt-3">
                        <tbody>
                            <tr>
                                <td><strong>Sub ID</strong></td>
                                <td>
                                    {sub}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Location</strong></td>
                                <td>
                                    {lastLoc}

                                    { lat && lng &&
                                        <a href={`https://google.com/maps/place/${lat},${lng}`} rel="noreferrer" target="_blank" className="pl-3 text-decoration-none">
                                            <FontAwesomeIcon icon={faExternalLinkAlt} /> See in Google Maps
                                        </a>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td><strong>IP</strong></td>
                                <td>
                                    {ipAddr}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Browser</strong></td>
                                <td>
                                    {browserIcon} {browser}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>OS</strong></td>
                                <td>
                                    {osIcon} {os}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Device</strong></td>
                                <td>
                                    {device}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>User Agent</strong></td>
                                <td>
                                    {userAgent}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Container>
    )
}
