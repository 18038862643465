import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { ClaimErrorsGridComponent } from '../../components'
import Loading from '../../components/Loading'

const ListClaimErrors = () => {
  return <ClaimErrorsGridComponent />
}

export default withAuthenticationRequired(ListClaimErrors, {
  onRedirecting: () => <Loading />,
})
