import React from 'react'

const tagStyles: Record<string, React.CSSProperties> = {
  added: {
    backgroundColor: 'lightgreen',
    color: 'darkgreen',
    padding: '2px 5px',
  },
  removed: {
    backgroundColor: 'lightsalmon',
    color: 'darkred',
    padding: '2px 5px',
  },
  field: {
    backgroundColor: 'lightgray',
    color: 'black',
    padding: '2px 5px',
    marginRight: '5px'
  }
}

type TagProps = {
  text: string
  variant: keyof typeof tagStyles
}

export const Tag: React.FC<TagProps> = ({ text, variant }) => (
  <span style={tagStyles[variant]}>{text}</span>
)