import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Button, Card, CardBody, CardTitle, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { Roles, removeArrayItem } from '@bluebid-sdk/core'
import { isErrorResponse } from '@bluebid-sdk/api-client'
import { BlockUI } from 'bb-lib-desktop'

import { assignRoleToGroup, unassignRoleFromGroup, getGroupRoles, getRoles } from '../../../lib'
import { successToast, errorToast } from '../../../utils/common'

export const GroupRolesView: React.FC = () => {
  const { groupId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [roles, setRoles] = useState<Roles[]>()
  const [groupRoles, setGroupRoles] = useState<Roles[]>()
  const [selectedRole, setSelectedRole] = useState<Roles>()

  useEffect(() => {
    ;(async () => {
      const response = await getRoles()

      if (isErrorResponse(response)) {
        errorToast(response.errorMessage)
      } else {
        setRoles(response.data)
      }
    })()
  }, [])

  useEffect(() => {
    if (groupId) {
      setIsLoading(true)
      ;(async () => {
        const response = await getGroupRoles(groupId)

        if (isErrorResponse(response)) {
          errorToast(response.errorMessage)
        } else {
          setGroupRoles(response.data)
        }

        setIsLoading(false)
      })()
    }
  }, [groupId])

  const handleAssignRole = async () => {
    if (!selectedRole) {
      return
    }

    setIsLoading(true)

    const response = await assignRoleToGroup(groupId, selectedRole)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      setSelectedRole(undefined)
      setGroupRoles([...groupRoles, selectedRole])
      successToast('Role assigned to group')
    }

    setIsLoading(false)
  }

  const handleUnassignRole = async (removeRole: Roles) => {
    setIsLoading(true)

    const response = await unassignRoleFromGroup(groupId, removeRole)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      const updatedGroupRoles = removeArrayItem(groupRoles, (groupRole) => groupRole === removeRole)

      setGroupRoles(updatedGroupRoles)
      successToast('Role unassigned from group')
    }

    setIsLoading(false)
  }

  return (
    <div className="group-roles-view">
      <BlockUI blocking={isLoading} />
      <Alert color="primary" className="mt-4">
        <Notes className="mb-0">
          <li>
            Adding a roles <strong>added</strong> team will <strong>automatically add that role</strong> to all team
            members.
          </li>
          <li>
            Likewise, <strong>removing</strong> a role will remove the role from all team members.
          </li>
          <li>
            The <strong>agent</strong> role may not be removed; this is the default role for team members.
          </li>
        </Notes>
      </Alert>

      <Row>
        <Col md={6}>
          <Card className="mt-4 mb-5" color="light">
            <CardBody>
              <CardTitle tag="h6">Assign Role to Team</CardTitle>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="selectedRole">Role</Label>
                    <Input
                      type="select"
                      id="selectedRole"
                      onChange={(e) => {
                        setSelectedRole(e.target.value as Roles)
                      }}
                      value={selectedRole}
                    >
                      <option value="">Select Role</option>
                      {roles?.map((role) => (
                        <option key={role} value={role} selected={selectedRole === role}>
                          {role}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="d-flex align-items-end">
                  <FormGroup>
                    <Button color="primary" type="submit" disabled={!selectedRole} onClick={handleAssignRole}>
                      Assign
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <br />
          <h5>Assigned roles</h5>

          <ul>
            {groupRoles?.map((role, index) => (
              <ListItem key={index}>
                <Row>
                  <Col md={1}>
                    <Button
                      color="danger"
                      size="sm"
                      disabled={role === 'agent'}
                      onClick={() => handleUnassignRole(role)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col>
                  <ItemTextCol>{role}</ItemTextCol>
                </Row>
              </ListItem>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  )
}

export const Notes = styled.ul`
  list-style: circle;
  font-size: 12pt;
  li {
    padding-bottom: 4px;
  }
`

const ListItem = styled.li`
  padding: 5px;
  list-style: none;

  :nth-child(odd) {
    background: #efefef;
  }
`

const ItemTextCol = styled(Col)`
  padding: 6px;
`
