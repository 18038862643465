import { SignInAction } from './SignIn'
import styled from 'styled-components'

export const LoginHero = () => {
    return (
        <BGImage className="p-3 text-center bg-image">
            <LoginContainer>
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="text-white">

                        <h1 className="mb-3">Bluebid Homes</h1>
                        <h4 className="mb-3">Admin Login</h4>

                        <SignInAction />
                    </div>
                </div>
            </LoginContainer>
        </BGImage>
    )
}

const BGImage = styled.div`
  //background-image: url('https://static.wixstatic.com/media/5bb21d_f3eb08b43be14b88ba93ccdd306b9472~mv2.jpg/v1/fill/w_1596,h_687,al_c,q_85,enc_auto/5bb21d_f3eb08b43be14b88ba93ccdd306b9472~mv2.jpg');
  height: 686px;
`

const LoginContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
`
