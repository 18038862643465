import React, { useEffect, useState } from 'react';
import {BigPlayButton, Player} from "video-react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Button, Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import { isMobile } from 'react-device-detect';

/**
 * A property image carousel that supports an imageWrapper component. If no imageWrapper is passed then
 * it defaults to the below CarouselImageContainer
 *
 * @param items
 * @param imageContainer
 * @param preventScrolling - set to true to hide scroll indicators, etc.
 * @returns {JSX.Element}
 * @constructor
 */
export const PropertyAssetsCarousel = ({items, selectedIndex, onNext, onPrev}) => {
    const goToIndex = (newIndex) => {
        // if (animating) return;
    }

    const propertySlides = items.map((item, ndx) => {
        return (
            <CarouselItem key={ndx}>
                <AssetCount>
                    <label>{`${(selectedIndex + 1)} / ${items.length}`}</label>
                </AssetCount>
                <MediaItem item={item} key={`${item.id}`} />
            </CarouselItem>
        );
    })

    return (
        <Carousel interval={false} fade={true} activeIndex={selectedIndex} next={onNext} previous={onPrev}>
            <CarouselIndicators items={items} activeIndex={selectedIndex} onClickHandler={goToIndex} />
            {propertySlides}
            {isMobile &&
            <>
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={onPrev} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={onNext} />
            </>}
        </Carousel>
    )
}

const ImageWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

const MediaItem = ({item, imageContainer}) => {
    if (item.mimeType.startsWith('video')) {
        return <Player playsInline fluid={true} src={item.url} >
            <BigPlayButton position="center" />
        </Player>
    } else {
        return <ImageWrapper condition={!imageContainer}
              wrapper = { children => {
                  return (<CarouselImageContainer>{children}</CarouselImageContainer>)
              }} >
            <CarouselImage src={item.url} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="https://images.bluebid.io/Secondary_blue_edited.png";
            }} width="100%" />
        </ImageWrapper>
    }
}

export const FullScreenCarousel = ({ show, selectedIndex, close, fullAssetsArray }) => {
    const [activeIndex, setActiveIndex] = useState(selectedIndex || 0)

    useEffect(() => {
        const handler = (event) => {
            const t = event.target
            if (t.classList.contains('h-100')) close()
        }

        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    }, [])

    useEffect(() => {
        setActiveIndex(selectedIndex)
    }, [selectedIndex])

    const next = () => {
        const nextIndex = activeIndex === fullAssetsArray.length - 1 ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }

    const previous = () => {
        const prevIndex = activeIndex === 0 ? fullAssetsArray.length - 1 : activeIndex - 1
        setActiveIndex(prevIndex)
    }

    return (
        <FullScreenModal isOpen={show} backdrop={true} toggle={close} className={'modal-fullscreen'}
                         modalTransition={{ timeout: 200 }}>
            <Header toggle={close}>
                <PhotosHeader>Photos</PhotosHeader>
            </Header>
            <ModalBody>
                <Row className='h-100 w-100 ml-0 mr-0'>
                    {!isMobile && <Col xs={0} sm={1} className='m-auto'>
                        <ButtonCarousel onClick={previous}> <FontAwesomeIcon icon={faAngleLeft} /></ButtonCarousel>
                    </Col>}
                    <Col xs={isMobile ? 12 : 10} sm={isMobile ? 12 : 8} className='m-auto px-0'>
                        <PropertyAssetsCarousel items={fullAssetsArray} selectedIndex={activeIndex} onNext={next}
                                                onPrev={previous} />
                    </Col>
                    {!isMobile && <Col xs={0} sm={1} className='m-auto'>
                        <ButtonCarousel onClick={next}> <FontAwesomeIcon icon={faAngleRight} /></ButtonCarousel>
                    </Col>}
                </Row>
            </ModalBody>
        </FullScreenModal>
    )
}

const PhotosHeader = styled.div`
    color: #fff;
    font-size: 16px;
`
const Header = styled(ModalHeader)`
  padding: 0px 1rem;
  margin: 5px 0;
`
const FullScreenModal = styled(Modal)`
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    .modal-header .close {
        color: #fff;
        padding: 0 5px 5px 0;
        opacity: 1;
        
        span {
            font-weight: normal;
            font-size: 3.5rem;
        }
    }
    .modal-content {
        width: 100vw;
        height: 100%;
        left: 0;
        background-color: rgba(0,0,0, 0.8);
        top: 0;
        box-shadow: rgb(0 0 0 / 30%) 0px 8px 12px 0px;
        animation: 0.3s ease-out 0s 1 normal none running iGLmwX;

        .carousel-status {
            background: rgb(0,0,0,0.5);
        }
    }
    .modal-body {
      padding: 0;
    }
`
const CarouselImageContainer = styled.div`
  overflow: hidden;
`
const ButtonCarousel = styled(Button)`
    background: transparent;
    padding: 20px 10px;

    svg {
        font-size: 2rem;
    }
`
const AssetCount = styled.div`
    z-index: 1;
    background: rgb(0, 0, 0, 0.4);
    position: absolute;
    right: 0px;
    width: fit-content;
    
    label {
        width: 100%;
        font-size: 1rem;
        color: #fff;
        font-weight: 600;
        padding: 5px;
        margin: 0px;
        text-align: right;
    }
`
const CarouselImage = styled.img`
    width: 100%;
    border-radius: 4px;
    max-height: 760px;
`
