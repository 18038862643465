import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AccordionBody, AccordionHeader, AccordionItem, Col, Row, UncontrolledAccordion } from 'reactstrap'
import { PropertySearchWidget } from '../PropertySearchWidget'
import { RecentClaims } from '../../../components/RecentClaims'
import { UserSearch } from '../../../components/UserSearch'
import styled from 'styled-components'

export const ClaimNavigation = ({onPropertySelect, onUserSelect}) => {

  const propertySelected = (sel) => onPropertySelect(sel.value)
  const userSelected = (sel) => onUserSelect(sel.value)

  return (
    <AdminAccordion toggle={() => {}}>
      <AdminAccordionItem>
        <AdminAccordionHeader targetId='1'>Recent Claims</AdminAccordionHeader>
        <AccordionBody accordionId={'1'}>
          <RecentClaims onPropertyClick={onPropertySelect} />
        </AccordionBody>
      </AdminAccordionItem>

      <AccordionItem>
        <AdminAccordionHeader targetId='2'>Search Claims</AdminAccordionHeader>
        <AccordionBody accordionId={'2'}>
          <Row>
            <Col md={6}>
              <PropertySearchWidget onSelect={propertySelected} />
            </Col>
            <Col md={6}>
              <UserSearch onSelect={userSelected} label={'Find claims for user'} />
            </Col>

          </Row>
        </AccordionBody>
      </AccordionItem>
    </AdminAccordion>
  )
}

const AdminAccordion = styled(UncontrolledAccordion)`
`

const AdminAccordionItem = styled(AccordionItem)`
`

const AdminAccordionHeader = styled(AccordionHeader).attrs({
  className: 'admin-accordion-header',
})`
  > button {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`
