import { ColDef, ColGroupDef } from '@ag-grid-community/core'

import { DateValueFormatter, InviteActionsCellRenderer, LinkRenderer, MappedCellRenderer } from '../grid/GridRenderers'

export const invitesGridColumns = ({ onToggleDelete, onToggleAdmin }): (ColDef | ColGroupDef)[] => [
  { field: 'id', hide: true, width: 300, headerName: 'Address Id' },
  {
    colId: 'action',
    headerName: 'Action',
    cellRenderer: InviteActionsCellRenderer,
    cellRendererParams: { onToggleDelete, onToggleAdmin },
    suppressColumnsToolPanel: true,
    width: 120,
  },
  {
    field: 'isDeleted',
    headerName: 'Deleted',
    width: 100,
    cellRenderer: MappedCellRenderer({ true: 'Deleted', false: '' }),
  },
  {
    field: 'isAdmin',
    headerName: 'Admin only',
    width: 120,
    cellRenderer: MappedCellRenderer({ true: 'Admin only', false: '' }),
  },

  { field: 'unclaimedAddress', headerName: 'Unclaimed Address', width: 350, filter: 'agTextColumnFilter' },
  { field: 'claimedAddress', headerName: 'Claimed Address', width: 350, filter: 'agTextColumnFilter' },
  {
    field: 'propertyId',
    headerName: 'Claimed Property',
    cellRenderer: LinkRenderer,
    cellRendererParams: { prefix: '/admin/propertyedit' },
    filter: 'agTextColumnFilter',
  },

  { field: 'sender', headerName: 'Sender', width: 150, filter: 'agTextColumnFilter' },
  { field: 'recipient', headerName: 'Recipient', width: 150, filter: 'agTextColumnFilter' },

  {
    headerName: 'Created / Modified',
    children: [
      {
        field: 'createdAt',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
      {
        field: 'modifiedAt',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
    ],
  },
]
