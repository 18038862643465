import { useEffect, useState } from 'react'

import { getPropertyById } from '../../../lib/data/bluebidData'

type PropertyImage = {
  url: string
}
type PropertyAsset = {
  id: string
  url: string
  mimeType: string
  createdAt: string
  modifiedAt: string
  ownerId: string
  propertyId: string
}

type PropertyImages = {
  mainImage: PropertyImage
  mapImage: PropertyImage
  satImage: PropertyImage
  assets: PropertyAsset[]
}

const MIME_TYPE_JPG = 'image/jpeg'

export function usePropertyImages(propertyId: string) {
  const [allAssets, setAllAssets] = useState<PropertyAsset[]>([])
  const [propertyImages, setPropertyImages] = useState<PropertyImages>(undefined)

  const refetchPropertyImages = async () => {
    try {
      const property = await getPropertyById(propertyId, 'assets,mapImages')
      let fixedAssets = [
        { url: property?.expanded?.mapImages?.satViewUrl, mimeType: MIME_TYPE_JPG },
        { url: property?.expanded?.mapImages?.mapViewUrl, mimeType: MIME_TYPE_JPG },
      ]
      let isStreetViewMissing = property?.expanded?.mapImages?.streetViewUrl === 'https://assets.bluebid.io/NoStreetViewDefault.png'
      if (!isStreetViewMissing) {
        fixedAssets.splice(0, 0,
          { url: property?.expanded?.mapImages?.streetViewUrl, mimeType: MIME_TYPE_JPG })
      }
      const fullAssetsArray = [...property?.expanded?.assets || []]
      // console.log(fullAssetsArray)

      setPropertyImages({
        mainImage: { url: property.mainImage },
        satImage: { url: fixedAssets[0].url },
        mapImage: { url: fixedAssets[1].url },
        assets: fullAssetsArray
      })

      setAllAssets(fullAssetsArray)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (propertyId) {
      ;(async () => refetchPropertyImages())()
    }
  }, [propertyId])

  return {
    assets: allAssets,
    propertyImages,
    refetchPropertyImages
  }
}
