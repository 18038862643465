/**
 * A wrapper for making Api calls. This wrapper is mostly designed for use in calling Bluebid API,
 * but it can be used for any URL api call.
 *
 * Samples of using this:
 *
 * // Simple GET request
 * request('/properties/id/fasdfas-fqerqrwf-fadfasf-fasdfasdf')
 *
 * // POST request for claiming
 * request('/properties', {
 *   method: 'POST',
 *   body: { ownerId: id,
 *          detail: { ... } }
 * })
 *
 * // GET request with custom headers
 * request('/secondary/posts', {
 *   headers: {
 *     'Accept-Language': 'it'
 *   }
 * })
 *
 * @param path
 */

import { getApiHost, getConfig } from '../../config'

const config = getConfig();
const errorCodeRegex = /4[0-9][0-9]/;

const LoggingEnabled = process.env.REACT_APP_LOG_API_CALLS === 'true'

const log = (...args) => {
    console.log('[DEBUG][apiCall]', ...args)
}

export const assertPath = (path) => {
    const type = typeof path
    if (type !== 'string') {
        throw new TypeError(
            `The path should be a string, instead received a ${type}`
        )
    }
}

export const parseResponse = async (res) => {
    // check user has invalid token or token expired!
    if (errorCodeRegex.test(res.status)) {
        auth0LogOutAPICall();
    }

    // If a body response exists, parse and extract the possible properties
    let response = res.status === 204 ? {} : await res.json()

    // handle bluebid api return results
    LoggingEnabled && console.log('Api response: ', response)

    if (response.status === 'success') {
        return response.data
    }
    // This was likely some other external api call, so just return
    // and let the caller handle the return structure
    return response
}

export const auth0LogOutAPICall = async () => {
    try {
        if (config) {
            localStorage.clear();
            sessionStorage.clear();
            const url = `https://${config.domain}/v2/logout?returnTo=${config.appOrigin}&client_id=${config.clientId}`;

            const link = document.createElement('a');
                link.href = url;
                // link.target = "_self";

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
        }
    } catch (err) {
        console.log('err', err);
    }
}

const apiCall = (path, options = {}) => {
    let token, bbHeaders
    let clientId = document.cookie.replace(/(?:(?:^|.*;\s*)_ga\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if (!options.host) {
        // There are two tokens being used:
        // 1. the insecure token used for unauthenticated users. This is a hard-coded token that provides a very basic level
        //    of api protection
        // 2. an access token obtained from Auth0 after a user has logged in. This token, if present, will overwrite the
        //    first token

        let accessToken = localStorage.getItem('token')
        token = process.env.REACT_APP_BLUEBID_API_TOKEN
        bbHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(token && { Authorization: `${token}` }),
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
            ...(clientId && { XClientId: `${clientId}` }),
        }
    }

    const {
        headers,
        query = null,
        method,
        body,
        host = getApiHost(),
        ...extraOpts
    } = options
    assertPath(path)

    // Compose the request configuration object
    const reqOptions = {
        method,
        headers: {
            ...bbHeaders,
            ...headers
        },
        ...extraOpts
    }

    // If a body object is passed, automatically stringify it.
    if (body) {
        if (body._parts) {
            reqOptions.body = body
        } else {
            reqOptions.body =
                typeof body === 'object' ? JSON.stringify(body) : body
        }
    }

    let queryString = ''
    if (query) {
        // Convert to encoded string and prepend with ?
        queryString = new URLSearchParams(query).toString()
        queryString = queryString && `?${queryString}`
    }

    let URL = `${host}${path}${queryString}`
    LoggingEnabled && log(URL, reqOptions, body || {})
    return fetch(URL, reqOptions)
        .then(parseResponse)
        .catch((err) => {
            console.error('apiCall encountered an error', { err })
        })
}

export default apiCall
