import React from 'react'
import { useParams } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { ThreadConversation } from '../../components/ThreadConversation'
import Loading from '../../components/Loading'

const ThreadView: React.FC = () => {
  const { userId, threadId } = useParams()

  return <ThreadConversation userId={userId} threadId={threadId} />
}

export default withAuthenticationRequired(ThreadView, {
  onRedirecting: () => <Loading />,
})
