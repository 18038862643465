import styled from 'styled-components'
import { Card, Col } from 'reactstrap'

export const AdminCard = styled(Card)`
  font-size: 10pt;
  margin: 10px 0;
  background-color: #f8f8f8;
`

export const AdminLabel = styled(Col)`
  font-weight: bold;
  padding-bottom: 2px;
`

export const AdminValue = styled(Col)`
`
