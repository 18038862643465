import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import styled from 'styled-components'

import { Datasource, getUser } from 'bb-lib-desktop'

import { ADMIN_ID, AuthContext } from '../../constants/context'
import { useSessionStorage } from '../../lib/utils'
import { DeletePropertyAction } from '../adminActions/DeletePropertyAction'
import { UnclaimPropertyAction } from '../adminActions/UnclaimPropertyAction'
import { AddressLinkRenderer, DateValueFormatter, restoreGridState } from '../grid/GridRenderers'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../CommonGrid'

const UnclaimPropertyLinkFormatter = (params) => {
  return (
    <CellContainer>
      {(params.userId !== ADMIN_ID) &&
        <UnclaimPropertyAction iconOnly={true} propertyId={params.data?.propertyId} afterAction={params.afterAction} />
      }
    </CellContainer>
  )
}
const CellContainer = styled.div.attrs({
  className: 'cell-container',
})`
  & > button {
    font-size: 0.9em;
    border: none;
    padding: 8px;
    min-width: 50px;
  }
`

const DeletePropertyLinkFormatter = (params) => {
  return (
    <div>
      <DeletePropertyAction iconOnly={true} propertyId={params.data?.propertyId} afterAction={params.afterAction} />
    </div>
  )
}

const UserClaimsList = ({userId}) => {
  const gridRef = useRef()
  const { bluebidUser } = useContext(AuthContext)
  const [user, setUserData] = useState(undefined)
  const datasource = useMemo(() => {
    return new Datasource({
      index: 'properties',
      userId: `${userId}`,
      fields: 'owner',
      adminSearch: true,
    })
  }, [userId])

  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const [colState, setColState] = useSessionStorage('properties', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const updateGrid = (propertyId, data) => {
    setTimeout(() => {
      gridRef.current.api.refreshServerSide()
    }, 500)
  }

  const columnDefs = useMemo(() => {
    let cols = [
      { field: 'address', headerName: 'Address', width: 400, filter: 'agTextColumnFilter' },
      {
        field: 'propertyId', headerName: 'Unclaim', width: 80,
        cellRenderer: UnclaimPropertyLinkFormatter,
        cellRendererParams: {
          userId: userId,
          afterAction: updateGrid,
        },
        filter: 'agTextColumnFilter',
      },
      {
        field: 'propertyId', headerName: 'Delete', width: 80,
        cellRenderer: DeletePropertyLinkFormatter,
        cellRendererParams: {
          userId: userId,
          afterAction: updateGrid,
        },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Created / Modified', children: [
          { field: 'createdAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
          { field: 'modifiedAt', columnGroupShow: 'open', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
        ],
      },
    ]
    if (userId !== '00000000-0000-1000-8000-000000000000') {
      cols[0].cellRenderer = AddressLinkRenderer
      cols[0].cellRendererParams = { prefix: '/admin/propertyedit' }
    }
    return cols
  }, [])

  useEffect(() => {
    getUser(userId)
      .then(user => {
        setUserData(user)
      })
  }, [])

  const getRowId = useCallback(function(params) {
    return params.data.propertyId
  }, [])

  if (!bluebidUser) {
    return <h1 style={{ textAlign: 'center', marginTop: '30px' }}>
      Please login
    </h1>
  }

  // https://github.com/facebook/create-react-app/issues/11769
  // https://github.com/facebook/react/issues/20235
  const ownerName = user ? user.firstName?.toUpperCase() + ' ' + user?.lastName?.toUpperCase() : ''
  return (<>

    <div style={GridContainerStyle}>
      <div style={GridStyle} className='ag-theme-balham'>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          onSortChanged={saveGridState}
          onColumnMoved={saveGridState}
          onColumnResized={saveGridState}
          onDisplayedColumnsChanged={saveGridState}
          animateRows='true'
          getRowId={getRowId}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRangeSelection='true'
          rowSelection='multiple'
          rowModelType='serverSide'
          serverSideDatasource={datasource}
          serverSideInfiniteScroll={true}
          suppressRowClickSelection='true'
          cacheBlockSize={20}
          sideBar={sideBar}
        />
      </div>
    </div>
  </>)
}

export default UserClaimsList
