import React, { useState } from 'react'
import { ICellRendererParams } from '@ag-grid-community/core';
import { EditAgentContentModal } from '../EditAgentContentModal'

export const AgentEditCellEditor = (props: ICellRendererParams) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [editAgentModal, setEditAgentModal] = useState(false)
  const userId = props?.data?.id

  const editAgentModalToggle = () => {
    setEditAgentModal(!editAgentModal)
  }

  return (
    <span>
      <span onClick={editAgentModalToggle}>{cellValue}</span>&nbsp;
      {/*<button onClick={editAgentModalToggle}>Edit</button>*/}
      <EditAgentContentModal isOpen={editAgentModal} toggle={editAgentModalToggle} userId={userId} />
    </span>
  );
};
