import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Table } from 'reactstrap'

import { Group, Opportunity } from '@bluebid-sdk/core'
import { isErrorResponse } from '@bluebid-sdk/api-client'
import { BlockUI, getTeamOpportunities } from 'bb-lib-desktop'

import { errorToast } from '../../../utils/common'


// @TODO: this needs to use same component as Desktop for opportunities: OpportunitiesGrid
export const GroupOpportunitiesView: React.FC = () => {
  const { group } = useOutletContext<{ group: Group }>()
  const [isLoading, setIsLoading] = useState(true)
  const [groupOpportunities, setGroupOpportunities] = useState<Opportunity[]>()

  useEffect(() => {
    if (group?.ownerId) {
      setIsLoading(true)
      ;(async () => {
        const response = await getTeamOpportunities(group.ownerId)

        if (isErrorResponse(response)) {
          errorToast(response.errorMessage)
        } else {
          setGroupOpportunities(response.data)
        }

        setIsLoading(false)
      })()
    }
  }, [group?.ownerId])

  return (
    <div className="group-opportunities-view">
      <BlockUI blocking={isLoading} />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Sender</th>
            <th>Recipient</th>
            <th>Property Address</th>
          </tr>
        </thead>
        <tbody>
          {groupOpportunities?.map((opp, index) => (
            <tr key={index}>
              <td width="265px">{opp.id}</td>
              <td>{opp.senderEmail}</td>
              <td>{opp.recipientEmail}</td>
              <td>{opp.sharedAddress}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
