
export const StandardSideBar = {
    toolPanels: [{
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
        },
    }, 'filters'],
    defaultToolPanel: 'columns',
}

export const StandardColumnDefs = {
    resizable: true,
    sortable: true,
    filter: true,
    enablePivot: false,
}

export const GridContainerStyle = { width: '100%', height: 'calc(100% - 90px)' }
export const GridNoHeaderContainerStyle = { width: '100%', height: 'calc(100% - 55px)' }
export const GridStyle = { height: '100%', width: '100%' }
