import React from 'react'

import { Col } from 'reactstrap'

import { addressFromDetail } from '../../lib/utils'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

/**
 * This is a standard error page
 *
 * @return {string}
 * @constructor
 */
export const ErrorPage = () => {
    return 'Error!!'
}

export const PrimaryLabelColumn = styled(Col)`
`
export const SecondaryLabelColumn = styled(Col)`
`

export const PageHelmet = ({ property }) => {
    const isClaimed = property.ownerId
    let fullAddress = addressFromDetail(property)

    let title = `Bluebid | ${fullAddress}`
    let url = isClaimed ?
        `/claimedProperty/${property.propertyId}` :
        `/availableProperty/${property.address}/${property.detail.address.latitude}/${property.detail.address.longitude}`

    return (
        <Helmet>
            <meta charSet='utf-8' />
            <title>Bluebid | {fullAddress}</title>
            <link rel='canonical' href={url} />
            <meta property='og:url' content={url} />

            <meta property='og:type' content='article' />
            <meta property='og:locale' content='en_US' />

            <meta property='og:title' content={title} />
            <meta property='og:description' content={title} />
        </Helmet>
    )
}

export const TextCount = ({ text, max }) => <TextCountStyle>{text?.length || 0} / {max}</TextCountStyle>

const TextCountStyle = styled.div`
  display: block;
  color: #adadae;
  margin-top: 5px;
`
export const UserDetailsLabel = styled.div`
  margin: 2px 0;
  font-weight: bold;
  display: inline-block;
`
export const OfferDetailsLabel = styled.div`
  margin: 2px 0;
  display: inline-block;
`
export const OfferLine = styled.div`
    margin: 1px;
`

export const DisclaimerText = styled.div`
  display: inline-block;
  text-align: justify;
  margin: 4px 0;
  width: 100%;
`


export const OfferTitleLabel = styled(OfferDetailsLabel)`
  //font-size: 16px;
  //font-weight: bold;
  margin: 4px 0;
`

export const OfferDetailsValue = styled(OfferDetailsLabel).attrs({
    className: 'text-right',
})`
  float: right;
  //margin-top: 5px;
  //font-weight: 400;
  //line-height: 16px;
`
export const OfferAmount = styled.div`
  //line-height: 16px;
  margin-top: 5px;
  //font-weight: bold;
  //font-size: 1.1em;
`

export const OfferDetailsAmount = styled(OfferAmount)`
  float: right;
`

