import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col, Form, FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import styled from "styled-components";
import {AuthContext} from "../constants/context";
import { updateProfileAPI } from '../lib/data/bluebidData'
import {errorToast, successToast} from "../utils/common";
import { SecondaryButton, PrimaryButton } from '../components/actions/common'
import {useNavigate} from "react-router-dom";
import BlockUI from "../components/BlockUI";
import { TagManager } from '../lib/gtm/TagManager'
import { isMobile } from 'react-device-detect'

export const ProfileComponent = () => {
    const {bluebidUser, refetchUser} = useContext(AuthContext)

    const userPhoto = {preview: bluebidUser?.picture || 'https://images.bluebid.io/profile_picture.png', raw: undefined}
    const [isLoading, setIsLoading] = useState(true);
    const [firstName, setFirstName] = useState(bluebidUser?.firstName);
    const [lastName, setLastName] = useState(bluebidUser?.lastName);
    const [email, setEmail] = useState(bluebidUser?.email);
    const [phone, setPhone] = useState(bluebidUser?.phone);
    const [validate, setValidate] = useState({firstName: '', lastName: '', phone: ''})
    const [image, setImage] = useState(userPhoto)
    const [first, setFirst] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        if (first) {
            setFirst(false)
        }

        setFirstName(bluebidUser?.firstName)
        setLastName(bluebidUser?.lastName)
        setEmail(bluebidUser?.email)
        setPhone(bluebidUser?.phone)
        setImage( userPhoto)
        setIsLoading(false);
    }, [bluebidUser])

    const getUserFullName = (user) => {
        return `${user?.firstName ? user.firstName : 'Bluebid'} ${user?.lastName ? user.lastName : 'User'}`;
    }

    const validateFirstName = (firstName) => {
        setFirstName(firstName);
        let checkValidation = {...validate};
        if (!(firstName.length > 2)) {
            checkValidation.firstName = 'has-danger'
            setValidate(checkValidation);
        } else {
            checkValidation.firstName = ''
            setValidate(checkValidation);
        }
    }

    const validateLastName = (lastName) => {
        setLastName(lastName);
        let checkValidation = {...validate};
        if (!(lastName.length > 2)) {
            checkValidation.lastName = 'has-danger'
            setValidate(checkValidation);
        } else {
            checkValidation.lastName = ''
            setValidate(checkValidation);
        }
    }

    const validateMobile = (mobile) => {
        setPhone(mobile);
        let checkValidation = {...validate};
        if (mobile.length !== 10) {
            checkValidation.phone = 'has-danger'
            setValidate(checkValidation);
        } else {
            checkValidation.phone = ''
            setValidate(checkValidation);
        }
    }

    const submitForm = async (e) => {
        e.preventDefault();
        TagManager.dataLayer({
            dataLayer: {
                event: 'profile_updated',
                category: 'Profile',
                action: 'User updated profile',
                label: 'profile-updated'
            }
        })

        setIsLoading(true);
        const userData = {}
        if (firstName !== bluebidUser.firstName) {
            userData.firstName = firstName
        }
        if (lastName !== bluebidUser.lastName) {
            userData.lastName = lastName
        }
        if (phone !== bluebidUser.phone) {
            userData.phone = phone
        }
        const user = await updateProfileAPI(bluebidUser.id, userData);
        if (user) {
            refetchUser()
            successToast(`profile updated successfully!`)
        } else {
            errorToast(`something went wrong!`)
        }
        setIsLoading(false);
    }

    const close = () => navigate('/')

    return (<>
        <BlockUI blocking={isLoading} />

        {!isLoading &&
        <ProfileContainer className="mb-5">
            <Row className="profile-header mb-5 text-center text-md-left">
                <ProfileBox md={2} className="mt-2 text-left">
                    <ProfileBoxImage>
                        <ProfilePicture
                            src={image.preview}
                            alt="Profile"
                            referrerPolicy="no-referrer"
                            className="img-fluid profile-picture mb-3 mb-md-0"
                        />
                        {/* <EditCircle htmlFor="upload-button">
                            <FontAwesomeIcon icon={faPen} color="#6c757d" size="1x"/>
                            <input type="file" accept="image/*" id="upload-button" style={{display: 'none'}} onChange={handleChange}/>
                        </EditCircle> */}
                    </ProfileBoxImage>
                    <h5>{getUserFullName(bluebidUser)}</h5>
                    <div className="text-muted">{bluebidUser?.email}</div>
                </ProfileBox>

                <ProfileDetail md>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="firstname">First Name</Label>
                                    <Input type="text" name="firstname" id="firstname"
                                           onChange={(e) => validateFirstName(e.target.value)}
                                           value={firstName}
                                           placeholder="Enter First Name"
                                           invalid={validate.firstName === 'has-danger'}/>
                                    <FormFeedback>
                                        First name required at least 2 characters!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="lastname">Last Name</Label>
                                    <Input type="text" name="lastname" id="lastname"
                                           onChange={(e) => validateLastName(e.target.value)}
                                           value={lastName}
                                           placeholder="Enter Last Name"
                                           invalid={validate.lastName === 'has-danger'}/>
                                    <FormFeedback>
                                        Last name required at least 2 characters!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="email">Email Address</Label>
                                    <Input type="email" name="email" id="email" disabled
                                           value={email}
                                           placeholder="Enter Email Address"/>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Phone Number</Label>
                                    <Input type="text" name="phone" id="phone"
                                           onChange={(e) => validateMobile(e.target.value)}
                                           value={phone}
                                           placeholder="Enter Phone Number"
                                           invalid={validate.phone === 'has-danger'}/>
                                    <FormFeedback>
                                        Phone number required only 10 digits!
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                {/* NOTE: only difference below is the mr-0 (margin right) */}
                                <SecondaryButton className="ml-auto mt-2 float-right mr-0" onClick={() => close()}>Close</SecondaryButton>
                                <PrimaryButton className={`ml-auto mt-2 float-right ${isMobile ? 'mr-0' : ''}`}>Save Profile</PrimaryButton>

                            </Col>
                        </Row>
                    </Form>
                </ProfileDetail>
            </Row>
        </ProfileContainer> }
    </>);
};

export default withAuthenticationRequired(ProfileComponent, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div><BlockUI blocking={true} /></div>
})

const ProfileDetail = styled(Col)`
  width: 100%;
  padding: 30px 30px 30px 30.5px;
  border-radius: 4px;
  border: solid 1px #e1e2e3;
  background-color: #fff;
`
const ProfileBox = styled(Col)`
  margin: 0 30px 13px 0;
`
const ProfileBoxImage = styled.div`
  padding: 0;
  width: 160px;
  border-radius: 5px;
`
const ProfilePicture = styled.img`
  max-width: 160px;
  height: 100%;
  border-radius: 5px;
`
const ProfileContainer = styled(Container)`
    margin-top: 20px
`

