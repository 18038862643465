import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { deleteProperty } from '../../lib/data/bluebidData'
import { successToast } from '../../utils/common'
import { useNavigate } from 'react-router-dom'
import { AdminSecondaryActionButton } from './common'
import styled from 'styled-components'

export const DeletePropertyAction: React.FC<{
  propertyId: string
  redirect?: string
  afterAction: (string, any) => void
  iconOnly?: boolean
}> = ({ propertyId, redirect, afterAction, iconOnly }) => {
  const navigate = useNavigate()

  const deleteAction = async () => {
    if (window.confirm('Are you sure?\n\nThis completely unclaims the property, Bluebids, messages and assets! It\'s useful for cleanup after testing.')) {
      deleteProperty(propertyId)
        .then(result => {
          console.log(result)
          successToast('Property has been deleted')
          if (redirect) {
            navigate(redirect)
          }
          if (afterAction) {
            afterAction(propertyId, result)
          }
        })
    }
  }

  if (iconOnly) {
    return <RedIcon onClick={deleteAction} icon={faTrashAlt} />
  }

  return <AdminSecondaryActionButton className={'btn-danger'} style={{color: 'red'}} onClick={deleteAction}>
    <FontAwesomeIcon icon={faTrashAlt} /> Delete
  </AdminSecondaryActionButton>
}

const RedIcon = styled(FontAwesomeIcon)`
    color: red;
    margin-left: 5px;
`
