import { MessageBubble } from './MessageBubble'
import React, { useContext } from 'react'
import { AuthContext } from '../../constants/context'
import styled from 'styled-components'
import { MessageDate } from './MessageDate'
import { OfferDetailsLabel, OfferLine } from '../detail/common'
import { Col, Row } from 'reactstrap'
import { MessageReadUnread } from './MessageReadUnread'
import { MessageStaging } from './MessageStaging'
import { getUserDisplayName } from '../../utils/common'

export const MessageNoteBubble = ({parent, msg, first, sender, isSender}) => {
    const { bluebidUser: user } = useContext(AuthContext)

    if (!user || !msg)
        return null

    return <>
        <MessageBubble>
            <div className={`${isSender ? 'from-me' : 'from-them'} bubble-div`}>
                <Row>
                    <Col xs>
                        <BubbleParagraph>
                            <OfferLine>
                                <OfferDetailsLabel><strong>{`${getUserDisplayName(sender)}`}</strong></OfferDetailsLabel>
                            </OfferLine>
                            {msg.data.message}
                            <br/>
                            <MessageDate msg={msg} />
                            <MessageStaging staging={msg?.staging} />
                            { !msg?.isRead && <>&nbsp;&mdash; <MessageReadUnread isRead={msg?.isRead} /></> }
                        </BubbleParagraph>
                    </Col>
                </Row>
            </div>
        </MessageBubble>
    </>
}

export const BubbleParagraph = styled.div.attrs({
    className: 'bubble-paragraph'
})`
    margin-bottom: 0;
    padding-bottom: 0;
`
