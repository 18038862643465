export const contingentChoices = [
    {label: "Home inspection", value: 'insp'},
    {label: "Flexible close date", value: 'flex'},
    {label: "In-person home tour", value: 'show'},
    {label: "Exclusivity - 60 days", value: 'exc60'},
    {label: "Virtual home tour", value: 'virtual'}
]

export const offerFinancing = [
    {label: 'I am pre-approved', value: 'preApproved'},
    {label: 'I am not pre-approved', value: 'needApproval'},
    {label: 'Cash', value: 'cash'}
]

export const financeKeyToText = (key) => {
    let option = offerFinancing.find(f => f.value === key)
    return option?.label
}
