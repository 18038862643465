import React, { useEffect, useState } from 'react';
import {Controller, useForm} from "react-hook-form";
import { Col, Collapse, Label, Row } from 'reactstrap';
import Joi from "joi";
import { joiResolver } from '@hookform/resolvers/joi';
import { ErrorMessage } from '@hookform/error-message';
// import { DevTool } from "@hookform/devtools";
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import {SecondaryButton, PrimaryButton} from './actions/common';
import {
    AIR_CONDITIONING_TYPE, AMENITIES, ARCHITECTURE_TYPES, BASEMENT_TYPE, CONSTRUCTION_TYPE,
    EXTERIOR_WALL_TYPES, FLOORING_TYPE, FOUNDATION_TYPE, HEATING_FUEL_TYPE, HEATING_TYPE, INTERIOR_WALL_TYPE,
    OTHER_AREA, OTHER_FEATURES, OTHER_IMPROVEMENT, OTHER_ROOMS, PARKING_TYPES, POOL_TYPES, ROOF_MATERIAL_TYPE,
    ROOF_STYLE_TYPE, SEWER_TYPE, WATER_TYPE, PROPERTY_CLASSIFICATION, CONDITION_TYPES, QUALITY_TYPES,
    WATER_FRONT_OPTIONS, WATER_VIEW_OPTIONS
} from '../lib/data/data'
import { isDesktop, isMobile } from 'react-device-detect'
import { sizes } from '../utils/responsive';
import { CheckboxList } from './CheckboxList'

const numberInput = Joi.string().pattern(new RegExp('^[0-9\b]+$')).allow('').allow(null);
const currentYear = (new Date()).getFullYear();
const schema = Joi.object({
    sqft: Joi.string().allow('', null),
    bedroom: numberInput.allow('', null),
    fullBath: numberInput.allow('', null),
    halfBath: numberInput.allow('', null),
    builtYear: Joi.number()
        .integer()
        .min(1000)
        .max(currentYear).allow(null),
    fireplaces: numberInput,
    parkingSpace: Joi.number()
        .integer()
        .min(0)
        .max(999).allow(null),
    ownerSellPrice: Joi.number(),
    overview: Joi.string().max(1000).allow(null, '')
})


//single selected list item
const filterIgnoreCase = (detail, x, f) => {
    if (detail[f]) {
        const fieldVal = detail[f] || ''
        return x.label.toString().toUpperCase() === fieldVal.toString().toUpperCase()
    }
    return []
}

const arrayedOtherValue = (field) => {
    const selectedItems = [];
    if (field && field.length) {
        field.forEach(fld => {
            selectedItems.push({label: fld.type, value: fld.type, sq_ft: fld.sq_ft })
        });
    }
    return selectedItems
}

const arrayedValue = (field, choices) => {
    const selectedItems = [];
    if (field && field.length) {
        field.forEach(fld => {
            let choice = choices.filter(c => c.value.toUpperCase() === fld.toUpperCase())
            selectedItems.push(choice[0])
        });
    }
    return selectedItems
}

const setValueFromSelectChoices = (choices, data, fieldName) => {
    if (data[fieldName]) {
        let choice = choices.filter(c => filterIgnoreCase(data, c, fieldName))
        return choice[0]
    }
    return []
}

const createSelectedOptions = (selectedValues, options) => {
    const selectedOptions = {}

    options.forEach((option) => {
      selectedOptions[option.value] = !!selectedValues?.find(
        (selectedValue) => selectedValue.toLowerCase() === option.value.toLowerCase()
      )
    })

    return selectedOptions
}

const getSelectedValues = (selectedOptions) => {
    const selectedValues = []

    Object.keys(selectedOptions).forEach((value) => {
      if (selectedOptions[value]) {
        selectedValues.push(value)
      }
    })

    return selectedValues
}

// /**
//  * This function delete html tags from a text, even if the html tag is
//  * not well formed.
//  * This function update the pointer position to maintain it after the replacement.
//  * @param {string} text The text to modify
//  * @param {int} initPos The current position of the pointer in the text
//  * @return {int} The new pointer position
//  */
// function removeHtmlTags( text, initPos ) {
//     // Define the regex to delete html tags
//     if (undefined===removeHtmlTags.htmlTagRegexp) {
//         removeHtmlTags.htmlTagRegexp = new RegExp('</?(?:article|aside|bdi|command|'+
//             'details|dialog|summary|figure|figcaption|footer|header|hgroup|mark|'+
//             'meter|nav|progress|ruby|rt|rp|section|time|wbr|audio|'+
//             'video|source|embed|track|canvas|datalist|keygen|output|'+
//             '!--|!DOCTYPE|a|abbr|address|area|b|base|bdo|blockquote|body|'+
//             'br|button|canvas|caption|cite|code|col|colgroup|dd|del|dfn|div|'+
//             'dl|dt|em|embed|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|'+
//             'h5|h6|head|hr|html|i|iframe|img|input|ins|kdb|keygen|label|legend|'+
//             'li|link|map|menu|meta|noscript|object|ol|optgroup|option|p|param|'+
//             'pre|q|s|samp|script|select|small|source|span|strong|style|sub|'+
//             'sup|table|tbody|td|textarea|tfoot|th|thead|title|tr|u|ul|var|'+
//             'acronym|applet|basefont|big|center|dir|font|frame|'+
//             'frameset|noframes|strike|tt)(?:(?: [^<>]*)>|>?)', 'i');
//     }

//     // </?(?:article|aside|bdi|command|details|dialog|summary|figure|figcaption|footer|header|hgroup|mark|meter|nav|progress|ruby|rt|rp|section|time|wbr|audio|video|source|embed|track|canvas|datalist|keygen|output|!--|!DOCTYPE|a|abbr|address|area|b|base|bdo|blockquote|body|br|button|canvas|caption|cite|code|col|colgroup|dd|del|dfn|div|dl|dt|em|embed|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|h5|h6|head|hr|html|i|iframe|img|input|ins|kdb|keygen|label|legend|li|link|map|menu|meta|noscript|object|ol|optgroup|option|p|param|pre|q|s|samp|script|select|small|source|span|strong|style|sub|sup|table|tbody|td|textarea|tfoot|th|thead|title|tr|u|ul|var|acronym|applet|basefont|big|center|dir|font|frame|frameset|noframes|strike|tt)(?:(?: [^<>]*)>|>?)
//     let thereIsMore = true;
//     removeHtmlTags.htmlTagRegexp.lastIndex=0;
//     while (thereIsMore) {
//         let str = text.match(removeHtmlTags.htmlTagRegexp);
//         if (str != null) {
//             text = text.replace(str[0], '');
//             // Update the position
//                 console.log('testing position', str.index)
//             if (str.index < initPos) {
//                 initPos= Math.max(initPos-str[0].length,str.index);
//             }
//         } else {
//             thereIsMore = false;
//         }
//     }

//     // If getCaretPosition fail, the initPos may be negative
//     if (initPos<0) {
//         initPos = 0;
//     }
//     return {text: text, pos: initPos};
// }

const htmlTagRegexp = new RegExp('</?(?:article|aside|bdi|command|'+
    'details|dialog|summary|figure|figcaption|footer|header|hgroup|mark|'+
    'meter|nav|progress|ruby|rt|rp|section|time|wbr|audio|'+
    'video|source|embed|track|canvas|datalist|keygen|output|'+
    '!--|!DOCTYPE|a|abbr|address|area|b|base|bdo|blockquote|body|'+
    'br|button|canvas|caption|cite|code|col|colgroup|dd|del|dfn|div|'+
    'dl|dt|em|embed|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|'+
    'h5|h6|head|hr|html|i|iframe|img|input|ins|kdb|keygen|label|legend|'+
    'li|link|map|menu|meta|noscript|object|ol|optgroup|option|p|param|'+
    'pre|q|s|samp|script|select|small|source|span|strong|style|sub|'+
    'sup|table|tbody|td|textarea|tfoot|th|thead|title|tr|u|ul|var|'+
    'acronym|applet|basefont|big|center|dir|font|frame|'+
    'frameset|noframes|strike|tt)(?:(?: [^<>]*)>|>?)', 'i');

const MAX_OVERVIEW_LENGTH = 1000

const onOverviewChange = (e, field) => {
    let val = e.target.value
    const valueMatched = val.match(htmlTagRegexp)
    if (!valueMatched) {
        field.onChange(val.substring(0, MAX_OVERVIEW_LENGTH))
    } else {
        val = val.replace(valueMatched[0], '<');
        field.onChange(val.substring(0, MAX_OVERVIEW_LENGTH))
        setTimeout(() => {
            const elem = e.target
            elem.focus()
            elem.selectionStart = valueMatched?.index + 1
            elem.selectionEnd = valueMatched?.index + 1
        }, 0);
    }
    // // For IE Only
    // if (document.selection) {
    //     // Set focus
    //     elem.focus();
    //     // Use IE Ranges
    //     let oSel = document.selection.createRange();
    //     // Reset position to 0 & then set at end
    //     oSel.moveStart('character', -elemLen);
    //     oSel.moveStart('character', elemLen);
    //     oSel.moveEnd('character', 0);
    //     oSel.select();
    // }
}

/**
 * This is the Edit Details component
 *
 */
export const EditDetails = ({detail, onUpdate, onCancel, expandAdvanced = false}) => {
    const detailData = detail?.structure || {}

    const { register, control, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: joiResolver(schema) });

    const [sqft] = useState(detailData.total_area_sq_ft?.toString());
    const [bedroom, setBedroom] = useState(detailData.beds_count?.toString());
    const [fullBath, setFullBath] = useState(detailData.baths?.toString());
    const [halfBath, setHalfBath] = useState(detailData.partial_baths_count?.toString());
    const [builtYear] = useState(detailData.year_built?.toString());
    const [fireplaces, setFireplaces] = useState(detailData.fireplaces?.toString());

    const [parkingSpace, setParkingSpace] = useState(detailData.parking_spaces_count === null ? 0 : detailData.parking_spaces_count?.toString());

    const [selectedParkingType, setSelectedParkingType] = useState(setValueFromSelectChoices(PARKING_TYPES, detailData,'parking_type'));
    const [selectedPoolType, setSelectedPoolType] = useState(setValueFromSelectChoices(POOL_TYPES, detailData, 'pool_type'));
    const [selectedArchitecture, setSelectedArchitecture] = useState(setValueFromSelectChoices(ARCHITECTURE_TYPES, detailData,'architecture_type'));

    const [selectedConstructionType, setSelectedConstructionType] = useState(setValueFromSelectChoices(CONSTRUCTION_TYPE, detailData, 'construction_type'));
    const [selectedExteriorWallType, setSelectedExteriorWallType] = useState(setValueFromSelectChoices(EXTERIOR_WALL_TYPES, detailData, 'exterior_wall_type'));
    const [selectedFoundation, setSelectedFoundation] = useState(setValueFromSelectChoices(FOUNDATION_TYPE, detailData,'foundation_type'));
    const [selectedRoofMaterial, setSelectedRoofMaterial] = useState(setValueFromSelectChoices(ROOF_MATERIAL_TYPE, detailData, 'roof_material_type'));
    const [selectedRoofStyle, setSelectedRoofStyle] = useState(setValueFromSelectChoices(ROOF_STYLE_TYPE, detailData, 'roof_style_type'));

    const [selectedHeatingType, setSelectedHeatingType] = useState(setValueFromSelectChoices(HEATING_TYPE, detailData, 'heating_type'));
    const [selectedHeatingFuelType, setSelectedHeatingFuelType] = useState(setValueFromSelectChoices(HEATING_FUEL_TYPE, detailData, 'heating_fuel_type'));
    const [selectedAirConditioning, setSelectedAirConditioning] = useState(setValueFromSelectChoices(AIR_CONDITIONING_TYPE, detailData, 'air_conditioning_type'));
    const [selectedBasementType, setSelectedBasementType] = useState(setValueFromSelectChoices(BASEMENT_TYPE, detailData,'basement_type'));

    const [selectedInteriorWallType, setSelectedInteriorWallType] = useState(setValueFromSelectChoices(INTERIOR_WALL_TYPE, detailData, 'interior_wall_type'));

    const [selectedWaterType, setSelectedWaterType] = useState(setValueFromSelectChoices(WATER_TYPE, detailData,'water_type'));
    const [selectedSewerType, setSelectedSewerType] = useState(setValueFromSelectChoices(SEWER_TYPE, detailData,'sewer_type'));

    const [selectedOtherArea, setSelectedOtherArea] = useState(arrayedOtherValue(detailData.other_areas));
    const [selectedOtherFeature, setSelectedOtherFeature] = useState(arrayedOtherValue(detailData.other_features));
    const [selectedImprovement, setSelectedImprovement] = useState(arrayedOtherValue(detailData.other_improvements));

    const [selectedOtherRoom, setSelectedOtherRoom] = useState(arrayedValue(detailData.other_rooms, OTHER_ROOMS));
    const [selectedFlooringType, setSelectedFlooringType] = useState(arrayedValue(detailData.flooring_types, FLOORING_TYPE));
    const [selectedAmenities, setSelectedAmenities] = useState(arrayedValue(detailData.amenities, AMENITIES));

    const [propertyClassification, setPropertyClassification] = useState(PROPERTY_CLASSIFICATION.filter(x => x.value === (detail?.property_classification || 'Primary')))
    const [ownerSellPrice] = useState(detail?.owner_sell_price || 0);
    const [overview] = useState(detail?.overview || '')

    const [selectedWaterFront, setSelectedWaterFront] = useState(createSelectedOptions(detail?.parcel?.location_descriptions, WATER_FRONT_OPTIONS))
    const [selectedWaterView, setSelectedWaterView] = useState(createSelectedOptions(detail?.parcel?.location_descriptions, WATER_VIEW_OPTIONS))

    // no UI for these yet
    const [selectedCondition] = useState(setValueFromSelectChoices(CONDITION_TYPES, detailData, 'condition'))
    const [selectedQuality] = useState(setValueFromSelectChoices(QUALITY_TYPES, detailData, 'quality'))

    const [showAdvanced, setShowAdvanced] = useState(expandAdvanced);
    const [showFacts, setShowFacts] = useState(true);

    useEffect(() => {
        setValue("sqft", sqft);
        setValue("bedroom", bedroom);
        setValue("fullBath", fullBath);
        setValue("halfBath", halfBath);
        setValue("builtYear", builtYear);
        setValue("fireplaces", fireplaces);
        setValue("parkingSpace", parkingSpace);
        setValue("ownerSellPrice", ownerSellPrice);
    }, [])

    const typeAndSquareFeet = (x) => {
            return {
                type: x.label,
                sq_ft: x.sq_ft
            }
        }
        // const optionValue = (o) => o

    // Transfer form fields to detail object,
    // then call the parent with the updated object
    const onDetailUpdated = async (data) => {
        // combine water options into one array and update existing location descriptions
        const waterOptions = [
            ...WATER_FRONT_OPTIONS.map((waterFrontOption) => waterFrontOption.value),
            ...WATER_VIEW_OPTIONS.map((waterViewOption) => waterViewOption.value),
        ].map((option) => option.toLowerCase())
        const locationDescriptions = [
            ...(detail?.parcel?.location_descriptions || []).filter(
            (description) => !waterOptions.includes(description.toLowerCase())
            ),
            ...getSelectedValues(selectedWaterFront),
            ...getSelectedValues(selectedWaterView),
        ]

        let detailUpdate = {
            structure: {
                beds_count: data.bedroom?.toString().length ? data.bedroom?.toString().trim() : null,
                baths: data.fullBath?.toString().length ? data.fullBath?.toString().trim() : null,
                partial_baths_count: data.halfBath?.toString().length ? data.halfBath?.toString().trim() : null,
                // rooms_count: 0, //data.roomCount
                parking_spaces_count: data.parkingSpace?.toString().length ? data.parkingSpace?.toString().trim() : null,
                fireplaces: data.fireplaces?.toString().length ? data.fireplaces?.toString().trim() : null,
                total_area_sq_ft: data.sqft?.toString().length ? data.sqft?.toString().trim() : null,
                parking_type: selectedParkingType?.value || '',
                pool_type: selectedPoolType?.value || '',
                architecture_type: selectedArchitecture?.value || '',
                basement_type: selectedBasementType?.value || '',
                construction_type: selectedConstructionType?.value || '',
                exterior_wall_type: selectedExteriorWallType?.value || '',
                foundation_type: selectedFoundation?.value || '',
                roof_material_type: selectedRoofMaterial?.value || '',
                roof_style_type: selectedRoofStyle?.value || '',
                heating_type: selectedHeatingType?.value || '',
                heating_fuel_type: selectedHeatingFuelType?.value || '',
                air_conditioning_type: selectedAirConditioning?.value || '',
                interior_wall_type: selectedInteriorWallType?.value || '',
                sewer_type: selectedSewerType?.value || '',
                water_type: selectedWaterType?.value || '',
                other_rooms: selectedOtherRoom?.map(x => x?.value),
                amenities: selectedAmenities?.map(x => x?.value),
                flooring_types: selectedFlooringType?.map(x => x?.value),
                other_improvements: selectedImprovement.map(typeAndSquareFeet),
                other_areas: selectedOtherArea.map(typeAndSquareFeet),
                other_features: selectedOtherFeature.map(typeAndSquareFeet),
                //pending fields
                // effective_year_built: null,
                // plumbing_fixtures_count: null,
                // quality: null,
                // condition: null,
                // stories: "",
                // units_count: null,
                year_built: data.builtYear?.toString().length ? data.builtYear?.toString().trim() : null,

                // Needs ui
                condition: selectedCondition?.value || '',
                quality: selectedQuality?.value || '',
            },
            property_classification: propertyClassification.value,
            owner_sell_price: data?.ownerSellPrice,
            overview: data?.overview,
            parcel: {
                ...detail?.parcel,
                location_descriptions: locationDescriptions,
              },
        }

        // call parent with updated details
        onUpdate(detailUpdate)
    }

    const onSqftChange = (value, feature, index) => {
        if (value) {
            if (feature === "areas") {
                let otherAreas = selectedOtherArea.slice();
                otherAreas[index].sq_ft = value;
                setSelectedOtherArea(otherAreas);
            } else if (feature === "features") {
                let otherFeatures = selectedOtherFeature.slice();
                otherFeatures[index].sq_ft = value;
                setSelectedOtherFeature(otherFeatures);
            } else if (feature === "improvements") {
                let otherImprovements = selectedImprovement.slice();
                otherImprovements[index].sq_ft = value;
                setSelectedImprovement(otherImprovements);
            }
        }
    }

    const toggleAdvanced = () => setShowAdvanced(!showAdvanced)
    const toggleFacts = () => setShowFacts(!showFacts)

    const saveCancelActions = () =>
        <Col md={12}>
            <Row className='float-right m-0'>
                <PrimaryButton className={"btn-primary btn-margin "} type="submit">Save Changes</PrimaryButton>
                <CancelButton className={"btn-primary btn-margin "} onClick={() => onCancel()}>Cancel</CancelButton>
            </Row>
        </Col>

    return <>
    {/*<DevTool control={control} />*/}

    <form onSubmit={handleSubmit(onDetailUpdated)}>
        <EditFactsContainer>
            <EditFactsTitle>Edit Facts & Features</EditFactsTitle>
            <h5>Would You Consider Selling?</h5>
            <Row>
                <Col md={4} className="mb-2">
                <Label for="owner_sell_price">Owner's estimate (Optional)</Label>
                <Controller
                    defaultValue={ownerSellPrice}
                    control={control}
                    name="ownerSellPrice"
                    id="owner_sell_price"
                    render = {({ field })=> (
                        <NumberInputField thousandSeparator
                              onValueChange={({value}) => field.onChange(value || 0) }
                              value={field.value}
                              prefix={'$'}
                        />)
                    }
                />
                <ErrorText><ErrorMessage errors={errors} name="owner_sell_price" /></ErrorText>
            </Col>
            </Row>

            <Row>
                <Col md={12} className="mb-2">
                    <Label for="owner_sell_price">Property Overview</Label>
                    <Controller
                        defaultValue={overview}
                        control={control}
                        name="overview"
                        id="overview"
                        render = {({ field })=> (
                            <><textarea
                                className={'form-control'}
                                rows={4}
                                id="txtAreaOverview"
                                ref={field.ref}
                                onChange={(e) => onOverviewChange(e, field)}
                                value={field.value} />
                                <TextCount text={field.value} />
                            </>)
                        }
                    />
                    <ErrorText><ErrorMessage errors={errors} name="overview" /></ErrorText>
                </Col>
            </Row>

            <Row>
                { saveCancelActions() }
            </Row>

            <h4 onClick={toggleFacts}>
                Key Facts
                <ClickArrow icon={showFacts ? faChevronUp : faChevronDown} />
            </h4>
            <KeyFactsBox isOpen={showFacts}>
                <Row>
                    <Col md={3} className="mb-2">
                        <Label for="sqft">Sqft</Label>
                        <Controller
                            defaultValue={sqft}
                            control={control}
                            name="sqft"
                            id="sqft"
                            render = {({ field })=> (
                                <NumberInputField thousandSeparator onValueChange={(values) => {
                                    const { value } = values;
                                    field.onChange(value);
                                }} value={field.value} />
                            )}
                        />
                        <ErrorText><ErrorMessage errors={errors} name="sqft" /></ErrorText>
                    </Col>
                    <Col md={3} className="mb-2">
                        <Label for="bedroom">Bedrooms</Label>
                        <PlusMinusNumberInputField register={register} formSetter={setValue}
                               fieldName={'bedroom'} setter={setBedroom} getter={() => bedroom} />
                        <ErrorText><ErrorMessage errors={errors} name="bedroom" /></ErrorText>
                    </Col>
                    <Col md={3} className="mb-2">
                        <Label for="fullBath">Full Baths</Label>
                        <PlusMinusNumberInputField register={register} formSetter={setValue}
                               fieldName={'fullBath'} setter={setFullBath} getter={() => fullBath} />
                        <ErrorText><ErrorMessage errors={errors} name="fullBath" /></ErrorText>
                    </Col>
                    <Col md={3} className="mb-2">
                        <Label for="halfBath">Half Baths</Label>
                        <PlusMinusNumberInputField register={register} formSetter={setValue}
                               fieldName={'halfBath'} setter={setHalfBath} getter={() => halfBath} />
                        <ErrorText><ErrorMessage errors={errors} name="halfBath" /></ErrorText>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="mb-2">
                        <Label for="builtYear">Year Built</Label>
                        <InputField type="text" name="builtYear" id="builtYear" {...register("builtYear", {
                            required: "This is required.",
                            pattern: {
                                value: /^[0-9\b]+$/,
                                message: "This input is number only."
                            }
                        })} />
                        <ErrorText><ErrorMessage errors={errors} name="builtYear" /></ErrorText>
                    </Col>
                    <Col md={3} className="mb-2">
                        <Label for="fireplaces">Fireplaces</Label>
                        <PlusMinusNumberInputField register={register} formSetter={setValue}
                               fieldName={'fireplaces'} setter={setFireplaces} getter={() => fireplaces} />
                        <ErrorText><ErrorMessage errors={errors} name="fireplaces" /></ErrorText>
                    </Col>
                    <Col md={3} className="mb-2">
                        <Label for="parkingSpace">Parking Spaces</Label>
                        <PlusMinusNumberInputField register={register} formSetter={setValue}
                               fieldName={'parkingSpace'} setter={setParkingSpace} getter={() => parkingSpace} />
                        <ErrorText><ErrorMessage errors={errors} name="parkingSpace" /></ErrorText>
                    </Col>

                    <Col md={3}>
                        <Label for="parkingSpace">Property Classification</Label>
                        {/*<Select*/}
                        {/*    isClearable={true}*/}
                        {/*    styles={SelectStyles}*/}
                        <EditSelect
                            defaultValue={propertyClassification}
                            onChange={setPropertyClassification}
                            options={PROPERTY_CLASSIFICATION}
                        />
                    </Col>
                </Row>
            </KeyFactsBox>


            <h4 onClick={toggleAdvanced}>
                Additional Features
                <ClickArrow icon={showAdvanced ? faChevronUp : faChevronDown} />
            </h4>
            <PropertyFeaturesBox isOpen={showAdvanced}>
                <Row>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Parking Type</Label>
                        <EditSelect
                            defaultValue={selectedParkingType}
                            onChange={setSelectedParkingType}
                            options={PARKING_TYPES}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Pool Type</Label>
                        <EditSelect
                            defaultValue={selectedPoolType}
                            onChange={setSelectedPoolType}
                            options={POOL_TYPES}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Architecture Type</Label>
                        <EditSelect
                            defaultValue={selectedArchitecture}
                            onChange={setSelectedArchitecture}
                            options={ARCHITECTURE_TYPES}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Construction Type</Label>
                        <EditSelect
                            defaultValue={selectedConstructionType}
                            onChange={setSelectedConstructionType}
                            options={CONSTRUCTION_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Exterior Wall Type</Label>
                        <EditSelect
                            defaultValue={selectedExteriorWallType}
                            onChange={setSelectedExteriorWallType}
                            options={EXTERIOR_WALL_TYPES}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Foundation Type</Label>
                        <EditSelect
                            defaultValue={selectedFoundation}
                            onChange={setSelectedFoundation}
                            options={FOUNDATION_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Roof Material Type</Label>
                        <EditSelect
                            defaultValue={selectedRoofMaterial}
                            onChange={setSelectedRoofMaterial}
                            options={ROOF_MATERIAL_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Roof Style Type</Label>
                        <EditSelect
                            defaultValue={selectedRoofStyle}
                            onChange={setSelectedRoofStyle}
                            options={ROOF_STYLE_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Heating Type</Label>
                        <EditSelect
                            defaultValue={selectedHeatingType}
                            onChange={setSelectedHeatingType}
                            options={HEATING_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Heating Fuel Type</Label>
                        <EditSelect
                            defaultValue={selectedHeatingFuelType}
                            onChange={setSelectedHeatingFuelType}
                            options={HEATING_FUEL_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Air Conditioning Type</Label>
                        <EditSelect
                            defaultValue={selectedAirConditioning}
                            onChange={setSelectedAirConditioning}
                            options={AIR_CONDITIONING_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Basement Type</Label>
                        <EditSelect
                            defaultValue={selectedBasementType}
                            onChange={setSelectedBasementType}
                            options={BASEMENT_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Interior Wall Type</Label>
                        <EditSelect
                            defaultValue={selectedInteriorWallType}
                            onChange={setSelectedInteriorWallType}
                            options={INTERIOR_WALL_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Water Type</Label>
                        <EditSelect
                                defaultValue={selectedWaterType}
                                onChange={setSelectedWaterType}
                                options={WATER_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Sewer Type</Label>
                        <EditSelect
                            defaultValue={selectedSewerType}
                            onChange={setSelectedSewerType}
                            options={SEWER_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Flooring Type</Label>
                        <EditSelect
                            isMulti
                            defaultValue={selectedFlooringType}
                            onChange={setSelectedFlooringType}
                            options={FLOORING_TYPE}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Waterfront</Label>
                        <CheckboxList
                            options={WATER_FRONT_OPTIONS}
                            selectedOptions={selectedWaterFront}
                            name="waterfront"
                            onChange={setSelectedWaterFront}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Water View</Label>
                        <CheckboxList
                            options={WATER_VIEW_OPTIONS}
                            selectedOptions={selectedWaterView}
                            name="waterview"
                            onChange={setSelectedWaterView}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Other Rooms</Label>
                        <EditSelect
                            isMulti
                            defaultValue={selectedOtherRoom}
                            onChange={setSelectedOtherRoom}
                            options={OTHER_ROOMS}
                        />
                    </Col>
                    <Col md={4} lg={3} className="mb-2">
                        <Label>Other Amenities</Label>
                        <EditSelect
                            isMulti
                            defaultValue={selectedAmenities}
                            onChange={setSelectedAmenities}
                            options={AMENITIES}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Label>Other Areas</Label>
                        <EditSelect
                            isMulti
                            defaultValue={selectedOtherArea}
                            onChange={setSelectedOtherArea}
                            options={OTHER_AREA}
                        />
                        {selectedOtherArea.length ?
                            <div id="otherarea" className='px-4 pt-2'>
                                {selectedOtherArea.map((item, index) => (
                                    <div key={`otherarea-${item.label.replace(/\s/g, "")}`} className="mb-2">
                                        <Label>{item.label} Sqft</Label>
                                        <NumberInputField thousandSeparator value={item.sq_ft} onValueChange={(values) => {
                                            const { value } = values;
                                            onSqftChange(value, 'areas', index)
                                        }}/>
                                    </div>
                                ))}
                            </div>
                            : null}
                    </Col>
                    <Col md={6}>
                        <Label>Other Features</Label>
                        <EditSelect
                            isMulti
                            defaultValue={selectedOtherFeature}
                            onChange={setSelectedOtherFeature}
                            options={OTHER_FEATURES}
                        />
                        {selectedOtherFeature.length ?
                            <div id="features" className='px-4 pt-2'>
                                {selectedOtherFeature.map((item, index) => (
                                    <div key={`features-${item.label.replace(/\s/g, "")}`} className="mb-2">
                                        <Label>Sqft for {item.label}</Label>
                                        <NumberInputField thousandSeparator value={item.sq_ft} onValueChange={(values) => {
                                            const { value } = values;
                                            onSqftChange(value, 'features', index)
                                        }}/>
                                    </div>
                                ))}
                            </div>
                            : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Label>Other Improvements</Label>
                        <EditSelect
                            isMulti
                            defaultValue={selectedImprovement}
                            onChange={setSelectedImprovement}
                            options={OTHER_IMPROVEMENT}
                        />
                        {selectedImprovement.length ?
                            <div id="improvements" className='px-4 pt-2'>
                                {selectedImprovement.map((item, index) => (
                                    <div key={`improvements-${item.label.replace(/\s/g, "")}`} className="mb-2">
                                        <Label>Sqft for {item.label}</Label>
                                        <NumberInputField thousandSeparator value={item.sq_ft} onValueChange={(values) => {
                                            const { value } = values;
                                            onSqftChange(value, 'improvements', index)
                                        }}/>
                                    </div>
                                ))}
                            </div>
                            : null}
                    </Col>
                </Row>
            </PropertyFeaturesBox>

            <Row className={'pb-4'}>
                {isMobile && saveCancelActions() }
                {isDesktop && showAdvanced && saveCancelActions() }
            </Row>
        </EditFactsContainer>
    </form>

</>
}

const EditSelect = (props) => <Select
        isClearable={true}
        styles={SelectStyles}
        {...props} />


const PlusMinusNumberInputField = ({fieldName, setter, getter, register, formSetter}) => {

    const getVal = () => {
        let curValue = getter()
        if (typeof curValue === 'string') {
            curValue = parseInt(curValue)
        }
        return isNaN(curValue) ? 0 : curValue
    }

    const increment = () => {
        let curValue = getVal() + 1
        formSetter(fieldName, curValue.toString())
        setter(curValue)
    }

    const decrement = () => {
        let curValue = getVal() - 1
        if (curValue < 0) curValue = 0
        formSetter(fieldName, curValue.toString())
        setter(curValue)
    }

    return (
        <IncDecWrapper>
            <IncDecButton type='button' onClick={() => decrement()}>-</IncDecButton>
            <IncDecNumInput type="number" name={fieldName} id={fieldName} readOnly className="num"
                        {...register(fieldName, {
                            required: "This is required."
                        })}
            />
            <IncDecButton type='button' onClick={() => increment()}>+</IncDecButton>
        </IncDecWrapper>
    )
}

const BluebidBlue = '#027ffe'

const InputField = styled.input.attrs({
    className: 'form-control'
})`
  color: ${BluebidBlue};
`

const IncDecWrapper = styled.div`
  width: 100%;
  height: 38px;
  padding: 10px;
  display: flex;
  margin: 0;
  border-radius: 5px;
  border: solid 1.5px #ced4da;
  background-color: #fff;
`
const IncDecButton = styled.button`
  height: 30px;
  width: 10%;
  background: white;
  border: none;
  font-size: 24px;
  color: ${BluebidBlue};
  position: relative;
  bottom: 8px;
  :focus {
    outline: none !important;
  }
`
const IncDecNumInput = styled(InputField)`
    text-align: center;
    height: 30px !important;
    top: -8px;
    position: relative;
    background-color: white;
`

const TextCount = ({text}) => <TextCountStyle>{text?.length || 0} / {MAX_OVERVIEW_LENGTH}</TextCountStyle>

const TextCountStyle = styled.div`
  display: block;
  font-family: 'Montserrat', serif, sans-serif;
  color: #adadae;
  margin-top: 5px;
`

const EditFactsContainer = styled.div`
    h4, h5 {
        margin: 20px 30px 20px 30px;
    }
    .row {
        margin: 15px;
    }
    input[type="number"] {
        margin-top: 0;
        font-size: 1rem;
        -webkit-appearance: textfield !important;
        -moz-appearance: textfield !important;
        appearance: textfield !important;
    }
      
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
         
  .num {
    height: 100%;
    width: 80%;
    border: none;
    font-size: 30px;
  }
`
const EditFactsTitle = styled.h3`
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    margin: 10px 30px 10px 30px;
`
const KeyFactsBox = styled(Collapse)`
`
const PropertyFeaturesBox = styled(Collapse)`
`
const ErrorText = styled.span`
    font-family: 'Montserrat', serif, sans-serif;
    font-size: 12px;
    color: #e74c3c;
`

const NumberInputField = styled(NumericFormat).attrs({
    className: 'form-control'
})`
  color: ${BluebidBlue};
`

export const SelectStyles = {
    control: (base, state) => ({
        ...base,
    }),
    input: (styles, state) => ({
        ...styles,
        color: `${BluebidBlue}`,
    }),
    singleValue: (styles, state) => ({
        ...styles,
        color: `${BluebidBlue}`,
    }),
    multiValue: (styles, state) => ({
        ...styles,
        color: `${BluebidBlue}`,
    }),
    multiValueLabel: (styles, state) => ({
        ...styles,
        color: `${BluebidBlue}`,
    }),
    placeholder: (styles, state) => ({
        ...styles,
        color: `${BluebidBlue}`,
    })
}
export const VPad = styled.div`
    height: ${(props) => props.height}px;
    display: block;
`
export const ClickArrow = styled(FontAwesomeIcon)`
    padding-left: 10px;
    color: ${BluebidBlue};
`
export const CancelButton = styled(SecondaryButton)`
    min-width: 152px;

    @media (min-width: ${sizes.mobileXL}) {
        margin-right: 0;
    }
`
