import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { PrimaryButton, SecondaryButton } from '../../../components/actions/common'
import { Description, Title } from './shared'
import LoadingInline from '../../../components/LoadingInline'

type ClearBluebidEstimateProps = {
  onApply: () => void
  onCancel: () => void,
  isWorking: boolean
}

const ClearBluebidEstimateForm: React.FC<ClearBluebidEstimateProps> = ({ onApply, onCancel, isWorking }) => {
  return (
    <Container>
      <Title>Clear Bluebid Estimate</Title>

      <Description>
        Clears the selected properties' Bluebid estimates.
      </Description>

      <Row>
        <Col className="mt-2 text-center">
          <PrimaryButton type="submit" disabled={isWorking} onClick={() => onApply()}>
            {isWorking ? <LoadingInline /> : null} Apply
          </PrimaryButton>
          <SecondaryButton type="button" onClick={() => onCancel()} style={{ minWidth: 0 }}>Cancel</SecondaryButton>
        </Col>
      </Row>
    </Container>
  )
}

export default ClearBluebidEstimateForm
