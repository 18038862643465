import React, { useEffect, useState } from 'react'
import { CardBody, CardTitle, Col, Row } from 'reactstrap'
import { AdminCard } from './Styles'
import { SendClaimedPropertyInquiryAction } from '../../../components/adminActions/SendClaimedPropertyInquiryAction'
import { LicenseCard } from '../../../components/LicenseCard'
import { findTargetLicense } from '../../../lib/data/bluebidData'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

export const AdminInquiries = ({ owner, property }) => {
  const [license, setLicense] = useState()

  const refreshLicense = () => {
    if (!property) {
      return
    }
    
    findTargetLicense({ propertyId: property.propertyId, address: property.address })
      .then(res => {
        setLicense(res.license)
        console.log(res)
      })
  }

  useEffect(() => {
    refreshLicense()
  }, [])

  return <AdminCard>
    <CardBody>
      <CardTitle tag={'h5'} className={"pb-3"}>
        Send Inquiries
      </CardTitle>

      <Row>
        <Col xs={4}>
          <SendClaimedPropertyInquiryAction property={property} owner={owner} onSent={refreshLicense} />
        </Col>
        <Col>
          { license && <>
            <LicenseCard license={license} />
            <LicenseLink>
            {/*@ts-ignore*/}
            <Link to={`/admin/group/${license.group.id}/zipcodes`} target={'_blank'}>
              License <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Link>
          </LicenseLink>
          </> }
        </Col>
      </Row>
    </CardBody>
  </AdminCard>
}

const LicenseLink = styled.div`
  padding: 10px 0 0 0;
    a {
        text-decoration: none;
    }
`
