import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { formatPhoneNumber } from '@bluebid-sdk/core'
import { Datasource } from 'bb-lib-desktop'
import { DateValueFormatter, LinkRenderer, restoreGridState, ResultFieldRenderer } from '../../components/grid/GridRenderers'
import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ModuleRegistry } from '@ag-grid-community/core'

import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import { useSessionStorage } from '../../lib/utils'
import { GridListHeader } from './GridListHeader'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { otpOverride, otpResend } from '../../lib/data/bluebidData'
import { errorToast, successToast } from '../../utils/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
  MenuModule,
])

const VerifiedCellEditor = forwardRef((props, ref) => {
  const initialValue = !props.value ? 'false' : 'true';
  const [value, setValue] = useState(initialValue)
  const refInput = useRef(null)

  useEffect(() => {
    const eInput = refInput.current
    eInput.focus()
  }, [])

  useImperativeHandle(ref, () => ({
    getValue() {
      return value === 'true'
    },

    isCancelAfterEnd() {
      return false
    }
  }))

  const handleChange = event => {
    setValue(event.target.value)
    setTimeout(() => props.api.stopEditing(), 0)
  }

  return (
    <select
      ref={refInput}
      value={value}
      onChange={handleChange}
      style={{ width: '100%' }}
    >
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  )
})

export const ResendRenderer = (params) => {
  const id = params.data.id
  return (
    <FontAwesomeIcon icon={faPaperPlane} className="clickable" onClick={() => {
      otpResend({ id })
        .then(res => {
          if (res.error) {
            throw res.errorMessage
          }
          successToast('OTP resent')
        })
        .catch(err => {
          console.error(err)
          errorToast('Error resending OTP')
        })
    }} />
  )
}

const datasource = new Datasource({ index: 'otps', fields: 'user', adminSearch: true })

const ListOtps = () => {
  const gridRef = useRef()

  const containerStyle = useMemo(() => (GridContainerStyle), [])
  const gridStyle = useMemo(() => (GridStyle), [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])
  const [colState, setColState] = useSessionStorage('users', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const getRowId = useCallback((params) => params.data.id, [])

  const columnDefs = useMemo(() => [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'userId', headerName: 'User Id', width: 280, cellRenderer: LinkRenderer, cellRendererParams: { prefix: '/admin/userprofile' }, filter: 'agTextColumnFilter' },
    { field: 'phone', headerName: 'Phone', width: 130, filter: 'agTextColumnFilter', valueFormatter: (params) => formatPhoneNumber(params.value) },
    { field:'First Name', cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'user', field: 'firstName'}, width: 120, sortable: false },
    { field:'Last Name', cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'user', field: 'lastName'}, width: 120, sortable: false },
    { field:'Email', cellRenderer:ResultFieldRenderer, cellRendererParams:{ object: 'user', field: 'email'}, width: 120, sortable: false },

    { field: 'otp', headerName: 'OTP Code', width: 75 },
    { field: 'expiresAt', headerName: 'OTP Expires At', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
    {
      field: 'verified',
      headerName: 'Verified',
      filter: 'agSetColumnFilter',
      filterParams: {
        keyCreator: params => params.value.code,
        valueFormatter: params => params.value.name,
        values: [{ code: 'true', name: 'Yes' }, { code: 'false', name: 'No' }]
      },
      width: 120,
      valueFormatter: (params) => params.value ? 'Yes' : 'No',
      cellStyle: params => {
          if (!params.value) {
              return {color: 'red'};
          }
          return null;
      },
      editable: true,
      cellEditor: VerifiedCellEditor,
      onCellValueChanged: params => {
        const verified = params.newValue
        otpOverride({ userId: params.data.userId, phone: params.data.phone, verified })
          .then(res => {
            if (res.error) {
              throw res.errorMessage
            }

            successToast('OTP verification updated')
          })
          .catch(err => {
            console.error(err)
            errorToast('Error updating OTP verification')
          })
      }
    },
    { field: 'id', headerName: 'Resend', sortable: false, width: 100, cellRenderer: ResendRenderer }
  ], [])

  return (<>
      <GridListHeader title={'Mobile Verifications'} onRefresh={onBtnRefresh}></GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className='ag-theme-balham'>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows='true'
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection='true'
            rowSelection='multiple'
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            serverSideInfiniteScroll={true}
            getRowId={getRowId}
            suppressRowClickSelection='true'
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

export default withAuthenticationRequired(ListOtps, {
  onRedirecting: () => <Loading />,
})
