import styled from 'styled-components'
import React, { useState } from 'react'
import { addressSuggest } from '../lib/data/bluebidData'
import { Black } from '../constants/colors'
import OutsideClickHandler from './OutsideClickHandler'

export const AddressSearcher = ({onSelect}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [value, setValue] = useState('');

    const clearInput = () => {
        setSuggestions([])
        setSuggestionIndex(0);
        setValue('')
    }

    const valueAt = (ndx) => {
        return suggestions.length > 0 ? suggestions[ndx].description : ''
    }

    const getSuggestions = (query) => {
        if (query.length > 1) {
            addressSuggest(query)
                .then(results => {
                    setSuggestions(results.items)
                })
        }
    }

    const handleChange = (e) => {
        const query = e.target.value.toLowerCase();
        setValue(query);
        getSuggestions(query);
    };

    const onFocus = () => clearInput()

    const handleClick = (selected, entered) => {
        setSuggestions([]);
        setValue(selected.address)
        let item = suggestions.find(s => s.id === selected.id)
        onSelect(item)
    };

    const selectCurrentItem = () => {
        setValue(valueAt(suggestionIndex));
        setSuggestionIndex(0);
        if (suggestions.length > 0) {
            onSelect(suggestions[suggestionIndex])
            setSuggestions([])
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 27) { // ESC
            clearInput()
        }
        if (e.keyCode === 38) { // UP ARROW
            if (suggestionIndex === 0) {
                return;
            }
            setSuggestionIndex(suggestionIndex - 1);
        }
        else if (e.keyCode === 40) { // DOWN ARROW
            if (suggestionIndex - 1 === suggestions.length) {
                return;
            }
            setSuggestionIndex(suggestionIndex + 1);
        }
        else if (e.keyCode === 13) { // ENTER
            selectCurrentItem()
        }
    };

    const formatEntry = (entry = '') => {
        let sentence = entry.address

        let alias = entry.aliasForId ? ` <span style='color: blue; font-weight: bold;'>[alias]</span>` : ''
        let exclude = entry.exclude ? ` <span style='color: red;  font-weight: bold;'>[exclude]</span>` : ''

        const regEx = new RegExp(value, 'gi');
        return sentence.replace(regEx, `<strong>$&</strong>`) + alias  + exclude
    }

    const SuggestionsListComponent = () => {
        return suggestions?.length ? (
            <Suggestions>
                {suggestions.map((suggestion, index) => {
                    return (
                        <SuggestItem
                            className={index === suggestionIndex ? "suggestion-active" : ""}
                            key={suggestion.id}
                            onClick={(e) => handleClick(suggestion, e.target.innerText)} >
                            <SuggestText dangerouslySetInnerHTML={{__html: formatEntry(suggestion)}}></SuggestText>
                        </SuggestItem>
                    );
                })}
            </Suggestions>
        ) : '';
    };

    return (
        <OutsideClickHandler onOutsideClick={() => clearInput()}>
        <Wrapper>
            <SearchBar>
                <SearchInput
                    type="text"
                    id="searchQueryInput"
                    value={value}
                    onFocus={onFocus}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Search address database"
                    />
            </SearchBar>
            <SuggestionsListComponent />
        </Wrapper>
        </OutsideClickHandler>
    )
}

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`

const SearchInput = styled.input`
  width: 100%;
  height: 2.5rem;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 0.325rem;
  padding: 0 40px 0 10px;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  max-width: 800px;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7F7F7F;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #7F7F7F;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #7F7F7F;
  }
`

const Suggestions = styled.ul`
  border: 1px solid #dddcdc;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 90vh;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  min-width: 300px;
  position: absolute;
  z-index: 1061;
  background-color: white;
  border-bottom-left-radius: 0.325rem;
  border-bottom-right-radius: 0.325rem;  
  
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #007fff;
  }
`
const SuggestItem = styled.li`
  padding: 12px 0 12px 0;
  font-size: 10pt;
  margin: 0;

  &:hover, &.suggestion-active {
    background: linear-gradient(
            90deg,
            hsla(220, 4%, 85%, 0.6) 0%,
            hsla(0, 0%, 98%, 1) 200%
    );
    cursor: pointer;
    color: black;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid #f6f4f4;
  }
`
const SuggestText = styled.ul`
    margin: 0;
    padding: 0 12px;
    color: ${Black};
`
