import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'

/**
 * Cell editor for message field in messages.
 */
export default forwardRef((props, ref) => {
    const [data, setData] = useState(props.value)
    const [value, setValue] = useState(props.value.message)
    const refInput = useRef(null)

    useEffect(() => {
        refInput.current.focus()
    }, [])


    const updateMessage = (msg) => {
        setData((prevState) => ({
            ...prevState,
            message: msg,
        }))
        setValue(msg)
    }

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return data
            },
        }
    })

    return (
        <InputEditor value={value}
               ref={refInput}
               onChange={event => updateMessage(event.target.value)} />
    )
})

const InputEditor = styled.input.attrs({
    className: 'message-editor'
})`
  width: 100%;
  border: none;
  background-color: aliceblue;
`
