import { MlsStatuses, Property } from '@bluebid-sdk/core'
import { api } from 'bb-lib-desktop'

export async function updateMlsStatus(propertyId: string, mlsStatus: MlsStatuses) {
  return api<Property>(`/properties/${propertyId}/updateMlsStatus`, {
    method: 'put',
    body: {
      mlsStatus,
    },
  })
}
