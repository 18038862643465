import styled from 'styled-components'
import { OfferCounteredBubble } from './OfferCounteredBubble'
import { MessageNoteBubble } from './MessageNoteBubble'
import { OfferFinalBubble } from './OfferFinalBubble'
import { OfferPlacedBubble } from './OfferPlacedBubble'
import { MessageType } from '../../constants/messsages'
import { MessageImagesBubble } from './MessageImagesBubble'

export const ChildMessageList = ({parent, childThreads, lastmessageActionDetail, onUpdateMessageList}) => {
    return <>
        <ThreadHistoryBlock>
            {childThreads?.map((entry, i) => {
                // let isLast = (i === childThreads.length - 1) && entry.messageType !== MessageType.Offer.Message.title
                const isLast = lastmessageActionDetail?.id === entry.id
                switch (entry.messageType) {
                    case MessageType.Offer.Placed.title:
                        return <OfferPlacedBubble key={`detail-${i}`}
                                                  parent={parent}
                                                  onUpdateMessageList={onUpdateMessageList}
                                                  message={entry}
                                                  isCurrent={isLast} />

                    case MessageType.Offer.Countered.title:
                        return <OfferCounteredBubble key={`detail-${i}`}
                                                     parent={parent}
                                                     property={parent.data.property}
                                                     msg={entry}
                                                     onUpdateMessageList={onUpdateMessageList}
                                                     isCurrent={isLast} />

                    case MessageType.Offer.Message.title:
                        return <MessageNoteBubble key={`detail-${i}`} msg={entry} first={i===0} />

                    case MessageType.Offer.Images.title:
                        return <MessageImagesBubble key={`detail-${i}`} msg={entry} onUpdateMessageList={onUpdateMessageList} />
                    // case MessageType.Offer.Expired.title:
                    //     return <OfferExpireBubble key={`detail-${i}`} msg={entry} property={parent.data.property}/>
                    case MessageType.Offer.Rejected.title:
                    case MessageType.Offer.Accepted.title:
                    case MessageType.Offer.Revoked.title:
                        return <OfferFinalBubble key={`detail-${i}`} msg={entry}
                                                 property={parent.data.property} />
                    default:
                        return null;
                }
            })
            }
        </ThreadHistoryBlock>
    </>
}

export const ThreadHistoryBlock = styled.div`
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    max-width: 600px;
    border: 1px solid rgba(122, 157, 201, 0.54);
`
