import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, FormGroup, Label } from 'reactstrap'

import { User, Property, AdminSearchTypes } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { NewPrimaryButton } from 'bb-lib-desktop'

import { creditAgent } from '../../lib'
import { AdminSearch } from '../../components'
import { successToast, errorToast } from '../../utils/common'

export const CreditAgentView: React.FC = () => {
  const [agentId, setAgentId] = useState<string>()
  const [recipientId, setRecipientId] = useState<string>()
  const [propertyId, setPropertyId] = useState<string>()
  const [isSaving, setIsSaving] = useState(false)
  const navigate = useNavigate()

  const disableSave = !agentId || !recipientId || !propertyId || isSaving

  const handleOnSave = async () => {
    if (agentId && recipientId && propertyId) {
      setIsSaving(true)

      const response = await creditAgent({ agentId, recipientId, propertyId })

      if (isSuccessResponse(response)) {
        successToast('agent credited successfully')

        navigate('/admin/list-invites')
      } else {
        errorToast(response.errorMessage || 'problem crediting agent')
      }

      setIsSaving(false)
    }
  }

  return (
    <Container fluid={true} className="mt-3">
      <h4>Credit Agent</h4>

      <Row className="my-3">
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>Agent</Label>
            <AdminSearch<User>
              searchType={AdminSearchTypes.Users}
              renderSearchResult={(searchResult) => (
                <>
                  {searchResult.firstName?.toUpperCase()} {searchResult.lastName?.toUpperCase()} ({searchResult.email})
                </>
              )}
              onSearchResultSelect={(searchResult) => {
                setAgentId(searchResult?.id)
              }}
              extraSearchParams={['isAgent:true']}
              placeholder="Search for agent"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>User</Label>
            <AdminSearch<User>
              searchType={AdminSearchTypes.Users}
              renderSearchResult={(searchResult) => (
                <>
                  {searchResult.firstName?.toUpperCase()} {searchResult.lastName?.toUpperCase()} ({searchResult.email})
                </>
              )}
              onSearchResultSelect={(searchResult) => {
                setRecipientId(searchResult?.id)
              }}
              placeholder="Search for user"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs={12} sm={4}>
          <FormGroup>
            <Label>Claimed Property</Label>
            <AdminSearch<Property>
              searchType={AdminSearchTypes.Properties}
              renderSearchResult={(searchResult) => <>{searchResult.address}</>}
              onSearchResultSelect={(searchResult) => {
                setPropertyId(searchResult?.propertyId)
              }}
              placeholder="Search for claimed property"
            />
          </FormGroup>
        </Col>
      </Row>
      <NewPrimaryButton className="mt-2" width={100} height={38} onClick={handleOnSave} disabled={disableSave}>
        Submit
      </NewPrimaryButton>
    </Container>
  )
}
