import { api } from 'bb-lib-desktop'

export function deleteImages(propertyId: string, assetIds: string[]) {
  return api('/properties/deleteImages', {
    method: 'POST',
    body: {
      propertyId,
      assetIds,
    },
  })
}
