import { Button, ButtonGroup, Col, Container, Label, Row } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import React, { useState } from 'react'
import apiCall from '../lib/data/apiCall'
import styled from "styled-components";

export const UserSearch = ({onSelect, label}) => {
    const [cSelected, setCSelected] = useState([2,3]);

    const onCheckboxBtnClick = (selected) => {
        const index = cSelected.indexOf(selected);
        if (index < 0) {
            cSelected.push(selected);
        } else {
            cSelected.splice(index, 1);
        }
        setCSelected([...cSelected]);
    }

    const searchFn = async (input) => {
        let terms = []
        if (cSelected.includes(1)) {
            terms.push(`firstName:${input}*`)
        }
        if (cSelected.includes(2)) {
            terms.push(`lastName:${input}*`)
        }
        if (cSelected.includes(3)) {
            terms.push(`email:${input}*`)
        }
        let qry = terms.join(' OR ')
        let result = await apiCall(`/search/users?q=${qry}`)
        if (result && result.count) {
            return result.items.map(r => {
                return {
                    value: r.id,
                    label: `${r?.firstName?.toUpperCase() || ''} ${r?.lastName?.toUpperCase() || ''} <${r?.email || ''}>`,
                    data: r
                }
            })
        }
        return []
    }

    return <>
        <UserSearchContainer>
            <Row>
                <Label>{ label ? label : 'Find user' }</Label>
                <Col sm={5}>
                    <ButtonSet>
                        <ActionButton
                            color="primary"
                            outline
                            onClick={() => onCheckboxBtnClick(1)}
                            active={cSelected.includes(1)}
                        >
                            First
                        </ActionButton>
                        <ActionButton
                            color="primary"
                            outline
                            onClick={() => onCheckboxBtnClick(2)}
                            active={cSelected.includes(2)}
                        >
                            Last
                        </ActionButton>
                        <ActionButton
                            color="primary"
                            outline
                            onClick={() => onCheckboxBtnClick(3)}
                            active={cSelected.includes(3)}
                        >
                            Email
                        </ActionButton>
                    </ButtonSet>
                </Col>
                <HalfCol sm={6}>
                    <AsyncSelect
                      styles={styles}
                        cacheOptions defaultOptions
                        loadOptions={searchFn}
                        isClearable={true}
                        placeholder={'Search'}
                        onChange={onSelect}
                    />
                </HalfCol>
            </Row>
        </UserSearchContainer>
    </>
}

const HalfCol = styled(Col)`
  position: relative;
  top: -10px;min-width: 190px;
`

const styles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      // color: state.data.color,
      // backgroundColor: state.data.bgcolor,
      fontSize: '10pt' // state.selectProps.myFontSize
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '400px',
        padding: 0,
        margin: 0
    })
};


const ButtonSet = styled(ButtonGroup).attrs({
    className: 'button-set'
})`
`

const ActionButton = styled(Button)`
    // margin-right: 10px;
    border-radius: 4px;
    font-size: 12pt ;
    //margin: 4px;
    padding: 2px 10px;
`

const UserSearchContainer = styled(Container)`
    border: 1px solid lightgrey;
    padding: 5px;
    border-radius: 4px;
`
