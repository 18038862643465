import React from 'react'
import { Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'

import { Property } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'

import { sendUpdatePhotosEmail } from '../../lib'
import confirm from '../Confirm'
import { successToast, errorToast } from '../../utils/common'
import { AdminSecondaryActionButton } from './common'

export const UpdatePhotosEmail: React.FC<{ property: Property }> = ({ property }) => {
  const sendUpdatePhotosEmailAction = async () => {
    const result = await confirm({
      title: 'Confirm Email Send',
      bodyComponent: () => UpdatePhotosBody(property),
      cancelText: 'No',
      confirmColor: 'primary',
      confirmText: 'Yes',
    })

    if (result) {
      const response = await sendUpdatePhotosEmail(property.propertyId)

      if (isSuccessResponse(response)) {
        successToast('Email has been sent.')
      } else {
        errorToast(response.errorMessage || 'Error: email could not be sent.')
      }
    }
  }

  return (
    <AdminSecondaryActionButton onClick={sendUpdatePhotosEmailAction}>
      <FontAwesomeIcon icon={faMailBulk} /> Photos Email
    </AdminSecondaryActionButton>
  )
}

const UpdatePhotosBody: React.FC<Property> = (property) => {
  return (
    <>
      <Row>
        <Col>Send an email notification to the property owner that photos have updated?</Col>
      </Row>
      <br />
    </>
  )
}
