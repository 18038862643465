import React, { useMemo, useContext, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../constants/context'
import { useSessionStorage } from '../../lib/utils'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../../components/Loading'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import { Datasource } from 'bb-lib-desktop'
import { DateValueFormatter, LinkRenderer, restoreGridState } from '../../components/grid/GridRenderers'
import { AgGridReact } from '@ag-grid-community/react'

export const MessageType = {
  Offer: {
    Placed: {
      title: 'OfferPlaced',
      actions: ['Accept', 'Reject', 'Counter'],
      ReceivedStatus: 'Bluebid Received',
      SentStatus: 'Bluebid Submitted',
      message: 'Bluebid has been Placed!',
      color: '#000000',
    },
    Accepted: {
      title: 'OfferAccepted',
      actions: [],
      ReceivedStatus: 'Bluebid Accepted',
      SentStatus: 'Bluebid Accepted',
      message: 'Bluebid has been Accepted!',
      color: '#008036',
    },
    Rejected: {
      title: 'OfferRejected',
      actions: [],
      ReceivedStatus: 'Bluebid Rejected',
      SentStatus: 'Bluebid Rejected',
      message: 'Bluebid has been Rejected!',
      color: '#FF1716',
    },
    Revoked: {
      title: 'OfferRevoked',
      actions: [],
      ReceivedStatus: 'Bluebid Revoked',
      SentStatus: 'Bluebid Revoked',
      message: 'Bluebid has been Revoked!',
      color: '#FF1716',
    },
    Countered: {
      title: 'OfferCountered',
      actions: ['Accept', 'Reject', 'Counter'],
      ReceivedStatus: 'Counter Received',
      SentStatus: 'Counter Sent',
      message: 'Bluebid has been Countered!',
      color: '#007FFE',
    },
    Expired: {
      title: 'OfferExpired',
      actions: [],
      ReceivedStatus: 'Bluebid Expired',
      SentStatus: 'Bluebid Expired',
      message: 'Bluebid has been Expired!',
      color: '#FF1716',
    },
    Message: {
      title: 'Message',
      actions: ['Reply'],
      ReceivedStatus: '',
      SentStatus: '',
      message: 'This is just a notification message!',
      color: '#000000',
    },
    Images: {
      title: 'Images',
      actions: ['Reply'],
      ReceivedStatus: '',
      SentStatus: '',
      message: 'This is just a image message!',
      color: '#000000',
    },
  },
}


const OfferHistory = () => {
  const gridRef = useRef()
  const { offerId } = useParams()
  const { bluebidUser } = useContext(AuthContext)

  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])
  const [colState, setColState] = useSessionStorage('messages', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])

  const datasource = new Datasource({
    index: 'messages',
    query: offerId ? `offerId:\"${offerId}\"` : '',
    adminSearch: true,
  })

  const columnDefs = useMemo(() => [
    { field: 'id', headerName: 'Bluebid Id', cellRenderer: LinkRenderer, cellRendererParams: { prefix: '/admin/offerhistory' }, filter: 'agTextColumnFilter' },
    { field: 'address', headerName: 'Address', filter: 'agTextColumnFilter', width: 250 },
    { field: 'type', headerName: 'Bluebid Type', filter: 'agTextColumnFilter', width: 250 },
    { field: 'financing', headerName: 'Financing', filter: 'agTextColumnFilter', width: 100 },
    { field: 'contingencies', headerName: 'Contingencies', filter: 'agTextColumnFilter', width: 100 },
    { field: 'bidAmount', headerName: 'Bid Amount', filter: 'agTextColumnFilter', width: 100 },
    {
      headerName: 'Created / Modified', children: [
        { field: 'createdAt', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter', sort: 'desc' },
        { field: 'modifiedAt', columnGroupShow: 'open', width: 210, valueFormatter: DateValueFormatter, filter: 'agDateColumnFilter' },
      ],
    },
  ], [])

  if (!bluebidUser) {
    return <h1 style={{ textAlign: 'center', marginTop: '30px' }}>
      Please login
    </h1>
  }


  // https://github.com/facebook/create-react-app/issues/11769
  // https://github.com/facebook/react/issues/20235
  return (
    <div style={GridContainerStyle}>
      <div style={GridStyle} className='ag-theme-balham'>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          onSortChanged={saveGridState}
          onColumnMoved={saveGridState}
          onColumnResized={saveGridState}
          onDisplayedColumnsChanged={saveGridState}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRangeSelection='true'
          rowSelection='multiple'
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          serverSideInfiniteScroll={true}
          suppressRowClickSelection='true'
          animateRows='true'
          sideBar={sideBar}
        />
      </div>
    </div>
  )
}

export default withAuthenticationRequired(OfferHistory, {
  onRedirecting: () => <Loading />,
})

