import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {withAuthenticationRequired} from "@auth0/auth0-react";

import {getPropertyByIdAPI, updateProperty} from '../lib/data/bluebidData';
import BlockUI from '../components/BlockUI';
import { errorToast, successToast } from '../utils/common';
import { EditDetails } from '../components/EditDetails'

/**
 * This is the Edit Facts page
 *
 * @return {JSX.Element}
 * @constructor
 */

const EditFacts2 = () => {
    const { propertyId } = useParams()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const [property, setProperty] = useState()
    const [detail, setDetail] = useState({})

    useEffect(() => {
        (async () => {
            await getPropertyDetails();
        })()
    }, [propertyId])

    const getPropertyDetails = async () => {
        setIsLoading(true)
        await getPropertyByIdAPI(propertyId)
            .then(result => {
                setProperty(result)
                setDetail(result.detail)
                // console.log(result.detail)
                setIsLoading(false)
            })
    }


    const onCancel = () => {
        navigate(`/admin/propertyedit/${propertyId}`);
    }

    //call update property api
    const onUpdate = async (update) => {
        setIsLoading(true)
        // console.log('Existing ', detail)
        // console.log('update with ', update)
        await updateProperty(propertyId, {
            detail: update
        }).then(result => {
            if (result.statusCode && result.statusCode >= 400) {
                console.error({result});
                setIsLoading(false);
                errorToast(result.errorMessage);
            } else if (result) {
                setDetail(result.detail)
                setIsLoading(false);
                successToast(`Property facts updated!`);
                navigate(`/claimedProperty/${propertyId}`)
            }
        })
    }

    return (<>
        {!isLoading &&
            <EditDetails detail={detail}
                         onCancel={onCancel}
                         onUpdate={onUpdate} />
        }
    </>)
}

export default withAuthenticationRequired(EditFacts2, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div><BlockUI blocking={true} /></div>
})
