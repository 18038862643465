import React, { useEffect, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, ColGroupDef } from '@ag-grid-community/core'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { Datasource } from 'bb-lib-desktop'

import { GridContainerStyle, GridStyle } from '../../components/CommonGrid'
import { deleteMessage, setStagingFlag, updateMessageNote } from '../../lib/data/bluebidData'
import { errorToast, successToast } from '../../utils/common'
import { GridListHeader, HeaderActionIcon } from '../../views/admin/GridListHeader'
import MessageAnnotationModal from '../../components/adminActions/MessageAnnotationModal'
import { downloadExport } from '../../lib/data/exporting'
import { AuthContext } from '../../constants/context'
import { getApiHost } from '../../config'
import { messagesGridColumns } from './messages-grid-columns.fn'
import { ThreadConversation } from '../ThreadConversation'
import { GridComponent } from '../grid'

export const MessagesGridComponent: React.FC<{
  title?: string
  userId?: string
  newColumns?: (ColDef | ColGroupDef)[]
  columnOrder?: string[]
  sessionKey?: string
}> = ({ title = 'Messages', userId, newColumns = [], columnOrder = [], sessionKey = 'messages' }) => {
  const { getToken } = useContext(AuthContext)
  const gridRef = useRef<AgGridReact>()
  // never changes, so we can use useMemo
  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])

  const [isAnnotationModalOpen, setAnnotationModalOpen] = useState(false)
  const [annotation, setAnnotation] = useState({ messageId: undefined, note: undefined })
  const [isThreadModalOpen, setIsThreadModalOpen] = useState(false)
  const [thread, setThread] = useState({ recipientId: undefined, threadId: undefined })

  const [query, setQuery] = useState<string>()

  const datasource = useMemo(() => new Datasource({ index: 'messages', adminSearch: true, query }), [query])

  useEffect(() => {
    setQuery(userId ? `(senderId:"${userId}" OR recipientId:"${userId}")` : undefined)
  }, [userId])

  const openThreadView = (recipientId: string, threadId: string) => {
    setIsThreadModalOpen(true)
    setThread({ recipientId, threadId })
  }

  const openAnnotationModal = (annotation) => {
    setAnnotation(annotation)
    setAnnotationModalOpen(true)
  }

  const closeAnnotationModal = () => {
    setAnnotation({ messageId: undefined, note: undefined })
    setAnnotationModalOpen(false)
  }

  const saveAnnotation = async () => {
    onBtnRefresh()
  }

  const getRowId = useCallback(function (params) {
    return params.data.id
  }, [])

  // @ts-ignore
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const onBtExport = useCallback(() => {
    try {
      downloadExport({
        getToken,
        exportUrl: `${getApiHost()}/messages/export?${datasource.exportQueryParams}`,
      })
    } catch (error) {
      console.error('error downloading file:', error)
    }
  }, [])

  const setStagingCellData = (messageId, flag) => {
    setTimeout(() => {
      // @ts-ignore
      const api = gridRef.current!.api
      const rowNode = api.getRowNode(messageId)
      rowNode.data.staging = flag
      rowNode.setData({ ...rowNode.data })
    }, 0)
  }

  const setStagingStatus = async ({ messageId, staging }) => {
    setStagingFlag({ messageId, staging: staging })
      .then(() => {
        successToast(staging ? 'Message un-published' : 'Message published')
        setStagingCellData(messageId, staging)
        // return waitForMessageIndexing({ ids: [messageId], threshold })
      })
      .catch((err) => {
        console.error(err)
        errorToast('Error occurred!')
      })
  }

  // TODO: implement this - api needed
  const deleteMessageAction = async ({ messageId }) => {
    deleteMessage({ messageId })
      .then(() => {
        successToast('Message deleted')
      })
      .catch((err) => {
        console.error(err)
        errorToast('Error deleting message')
      })
  }

  const onCellValueChanged = async (event) => {
    console.log('oncell value changed')
    let thread = event.data
    let { id, data } = thread
    updateMessageNote(id, data.message).then((updated) => {
      console.log(updated)
    })
  }

  const columnDefs = useMemo(() => {
    const columns = messagesGridColumns({ setStagingStatus, openAnnotationModal, openThreadView })

    return [
      ...newColumns,
      ...(columnOrder?.length
        ? columnOrder
            .map((headerName) => columns.find((column) => column.headerName === headerName))
            .filter((column) => column)
        : columns),
    ]
  }, [newColumns, columnOrder])

  return (
    <>
      {!!title && (
        <GridListHeader title={title} onRefresh={onBtnRefresh}>
          <HeaderActionIcon icon={faFileArchive} onClick={() => onBtExport()} />
        </GridListHeader>
      )}

      <div style={title ? containerStyle : gridStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          <GridComponent
            gridRef={gridRef}
            sessionKey={sessionKey}
            getRowId={getRowId}
            columnDefs={columnDefs}
            enableRangeSelection={true}
            rowSelection="multiple"
            serverSideDatasource={datasource}
            onCellValueChanged={onCellValueChanged}
            suppressRowClickSelection={true}
          />

          <MessageAnnotationModal
            isOpen={isAnnotationModalOpen}
            initialNote={annotation.note}
            messageId={annotation.messageId}
            onSave={saveAnnotation}
            onClose={closeAnnotationModal}
          />

          <Modal
            size="lg"
            isOpen={isThreadModalOpen}
            toggle={() => {
              setIsThreadModalOpen(!isThreadModalOpen)
            }}
          >
            <ModalHeader
              toggle={() => {
                setIsThreadModalOpen(!isThreadModalOpen)
              }}
            >
              Message Thread
            </ModalHeader>
            <ModalBody style={{ fontSize: '11px' }}>
              <ThreadConversation userId={thread.recipientId} threadId={thread.threadId} />
            </ModalBody>
          </Modal>
        </div>
      </div>
    </>
  )
}
