import React from "react";

import styled from 'styled-components';
import { parseAddress } from 'vladdress';

import { addressFromDetail, currencyFormatter } from '../../lib/utils'

export const getAddressLines = (address) => {
    let {line1, line2} = splitAddress(address)
    if (line1.endsWith(',')) {
        line1 = line1.substring(0, line1.length - 1)
    }

    return {line1, line2}
}

export const splitAddress = (streetAddress) => {
    try {
        if (streetAddress) {
            let addressParts = parseAddress(streetAddress)
            let sep = addressParts?.addressLine2 ? ', ' : ''
            let line1 = `${addressParts.addressLine1}${sep}${addressParts?.addressLine2 || ''}`
            let line2 = `${addressParts.placeName}, ${addressParts.stateAbbreviation} ${addressParts.zipCode}`
            return {
                line1, line2
            }
        }
        return {
            line1: '', line2: ''
        }
    }
    catch(err) {
        const addressLines = streetAddress.split(',')
        const addressLine1 = addressLines ? `${addressLines[0]},` : 'No Address,';
        const addressLine2 = addressLines ? addressLines[1] : 'address details not available';
        return {
            line1: addressLine1, line2: addressLine2
        }
    }
}

/**
 * Display the property address on two lines.
 *
 * @boldLine1 - set to true to make line1 bold
 * @tiled - set to true if this is in a tiled display
 * @shadow - set to true to render with white / black shadow (for popup)
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PropertyAddress = (props) => {
    const {property, tiled, shadow, boldLine1} = props
    let fullAddress = addressFromDetail(property)
    let {line1, line2} = splitAddress(fullAddress)

    return (
        <>
            <PropertyTitle1 className="text-wrap" tiled={tiled} shadow={shadow} bolded={boldLine1}>{ line1 }</PropertyTitle1>
            <PropertyTitle2 className="text-wrap" tiled={tiled} shadow={shadow}>{ line2 }</PropertyTitle2>
        </>
    )
}

/**
 * Displays property estimate info.
 * @param property - the property
 * @param tiled - true if displayed in a grid (needs v-padding)
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const PropertyEstimate = ({property, tiled}) => {
    let price = property?.detail?.owner_sell_price
    if (!property?.detail?.owner_sell_price) {
        price = property?.detail?.valuation?.value
    }
    if (price) {
        return <PriceCell tiled={tiled}>{currencyFormatter.format(price)}</PriceCell>
    }
    return <PriceCell tiled={tiled}>-</PriceCell>
}

/**
 * Property Title line 1 / line 2
 * @tiled - set to true if this is in a tiled display
 * @shadow - set to true to render with white / black shadow (for popup)
 * @bolded - set to true to make bold
 * @type {StyledComponent<"label", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PropertyTitle1 = styled.label`
  font-size: ${(props) => props.shadow ? '1.1em' : (props.tiled ? '1.1em' : '1.3em') };
  font-weight: ${(props) => props.bolded ? 'bold' : undefined };
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
  display: block;
  padding: 5px 0 0 0 ;
  margin: 0;
  color: ${(props) => props.shadow ? 'white' : undefined};
  text-shadow: ${(props) => props.shadow ? '1px 1px #000' : undefined};
`

const PropertyTitle2 = styled(PropertyTitle1)`
 font-size: ${(props) => props.shadow? '0.9em' : (props.tiled ? '1.0em' : '1.2em') };
 font-weight: normal;
 padding: 0;
`;

const PriceCell = styled.div`
    font-size: 1.3em;
    font-weight: bold;
    padding-top: ${(props) => props.tiled ? '10px' : undefined};
`
