import React from 'react';

export const AuthContext = React.createContext()

export const MapContext = React.createContext()

export const PropertyContext = React.createContext()

export const MessageContext = React.createContext()

export const ADMIN_ID = '00000000-0000-1000-8000-000000000000'
