import React, { useState, useEffect } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Input } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHouseUser, faQuestion } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { Property, MlsStatuses, MLS_STATUS_LABELS } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { BlockUI } from 'bb-lib-desktop'

import { updateMlsStatus, updateMlsStatuses } from '../../../lib/api'
import { relativeDate } from '../../../lib/utils'
import { AdminSecondaryActionButton } from '../../../components/adminActions/common'
import { checkListingStatus } from '../../../lib/listingStatus'
import { AdminLabel } from './Styles'
import { successToast, errorToast } from '../../../utils/common'
import { useProperty } from '../hooks/useProperty'

export const MarketStatus: React.FC<{ property: Property }> = ({ property }) => {
  const [loading, setLoading] = useState(false)
  const [listings, setListings] = useState([])
  const [freshProperty, setFreshProperty] = useState(property)
  const { refetchProperty } = useProperty(freshProperty.propertyId)
  const [mlsStatus, setMlsStatus] = useState(freshProperty.mlsStatus)

  useEffect(() => {
    // if not user edited
    if (!freshProperty.mlsEdited) {
      // if it's never been checked, update it
      if (!freshProperty.mlsCheckedAt) {
        checkMLS()
      } else {
        // it has been checked. Ensure we are past 24 hours since last check
        const lastChecked = moment(freshProperty.mlsCheckedAt)
        const now = moment().startOf('day')
        if (lastChecked.diff(now, 'days') > 0) {
          checkMLS()
        }
      }
    }
  }, [freshProperty?.propertyId])

  const changeMlsStatus = async (newMlsStatus?: MlsStatuses) => {
    setMlsStatus(newMlsStatus)
    setLoading(true)

    const response = await updateMlsStatus(freshProperty.propertyId, newMlsStatus)

    if (isSuccessResponse(response)) {
      successToast('MLS Status updated')
      setFreshProperty(response.data)
      refetchProperty()
    } else {
      errorToast(response.errorMessage)
    }

    setLoading(false)
  }

  const checkMLS = async () => {
    setListings([])
    setLoading(true)

    const [mlsStatusesResponse, listings] = await Promise.all([
      updateMlsStatuses([freshProperty.propertyId]),
      checkListingStatus({ address: freshProperty.address }),
    ])

    if (isSuccessResponse(mlsStatusesResponse)) {
      const p = mlsStatusesResponse.data?.results?.[freshProperty.propertyId]?.value

      if (p) {
        setFreshProperty(p)

        if (p.mlsStatus !== mlsStatus) {
          setMlsStatus(p.mlsStatus)
          successToast('MLS Status updated')
        }
      }

      refetchProperty()
    } else {
      errorToast(mlsStatusesResponse.errorMessage)
    }

    if (isSuccessResponse(listings)) {
      setListings(listings.data.value)
    } else {
      errorToast(`MLS Status Error: ${listings.errorMessage}`)
    }
    setLoading(false)
  }

  const ItemIcon = ({ match }) => {
    return (
      <>
        {match && <FontAwesomeIcon icon={faCheck} />}
        {!match && <FontAwesomeIcon icon={faQuestion} />}
      </>
    )
  }

  return (
    <Card style={{ width: '100%' }}>
      <CardBody>
        <BlockUI blocking={loading} />
        <Row>
          <Col xs={6}>
            <Label className="me-1" for="mlsStatusLabel">
              <h5>MLS Status</h5>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <AdminSecondaryActionButton disabled={freshProperty.mlsEdited} onClick={() => checkMLS()}>
              <FontAwesomeIcon icon={faHouseUser} /> Check MLS Status
            </AdminSecondaryActionButton>
          </Col>
          <Col className={'text-end float-end'} xs={3}>
            <MLSStatusInput
              type="select"
              name="mlsStatusLabel"
              id="mlsStatusLabel"
              value={mlsStatus}
              onChange={(e) => changeMlsStatus(e.target.value as any)}
            >
              <option value="">(clear / reset)</option>
              <option value={MlsStatuses.NoMatch}>{MLS_STATUS_LABELS['no-match']}</option>
              <option value={MlsStatuses.OffMarket}>{MLS_STATUS_LABELS['off-market']}</option>
              <option value={MlsStatuses.Listed}>{MLS_STATUS_LABELS.listed}</option>
            </MLSStatusInput>
          </Col>
          <Col xs={5}>
            {!!freshProperty.mlsEdited && (
              <TimeStamp>
                Manually Set <b>{relativeDate(freshProperty.mlsCheckedAt)}</b>
              </TimeStamp>
            )}
            {!freshProperty.mlsEdited && (
              <TimeStamp>
                Last Updated:{' '}
                <b>{freshProperty.mlsCheckedAt ? relativeDate(freshProperty.mlsCheckedAt) : '(not checked)'}</b>
              </TimeStamp>
            )}
          </Col>
        </Row>
        <br />
        <Row>
          {(listings || []).map((item, i) => (
            <Row key={`item-${item.title}`}>
              <AdminLabel xs={2}>Address:</AdminLabel>
              <Address xs={7}>
                <a href={`${item.link}`} rel="noreferrer" target="_blank" className="text-decoration-none">
                  {item.title}
                </a>
              </Address>
              <Status xs={3} onMarket={item.onMarket}>
                <ItemIcon match={item.exactMatch} /> {`${item.onMarket ? 'LISTED' : 'Off-Market'}`}
              </Status>
            </Row>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}

const Address = styled(Col)``

const Status = styled(Col)<{ onMarket: boolean }>`
  color: ${(props) => (props.onMarket ? 'red' : 'green')};
  font-weight: ${(props) => (props.onMarket ? 'bold' : 'normal')};
`

const MLSStatusInput = styled(Input)`
  font-family: Roboto, sans-serif;
  margin-bottom: 4px;
  font-size: 11pt;
  color: rgb(22, 108, 197);
  padding: 4px 10px;
`

const TimeStamp = styled.span`
  margin-top: 4px;
  display: inline-block;
`
