export const OFFERS_MSG_PRETEXT = 'If the homeowner elects to move forward, a licensed real estate agent will contact you to begin a private sale process.'
export const OFFERS_DIALOG_MSG = 'While this Bluebid is not legally binding, you should not send it unless you are genuinely interested in purchasing the property and qualified to fulfill the terms listed above.'
export const OFFERS_MSG_TEXT = 'While Bluebids are not legally binding, the buyer has confirmed they are genuinely interested in purchasing the property and qualified to fulfill the terms. In the event you move forward, a licensed real estate agent will contact you to begin a private sale process.'

export const MessageType = {
    Offer: {
        Placed: {
            title: 'OfferPlaced',
            actions: ['Accept', 'Reject', 'Counter'],
            ReceivedStatus: 'Bluebid Received',
            SentStatus: 'Bluebid Submitted',
            message: 'Bluebid has been Placed!',
            color: '#000000',
        },
        Accepted: {
            title: 'OfferAccepted',
            actions: [],
            ReceivedStatus: 'Bluebid Accepted',
            SentStatus: 'Bluebid Accepted',
            message: 'Bluebid has been Accepted!',
            color: '#008036',
        },
        Rejected: {
            title: 'OfferRejected',
            actions: [],
            ReceivedStatus: 'Bluebid Rejected',
            SentStatus: 'Bluebid Rejected',
            message: 'Bluebid has been Rejected!',
            color: '#FF1716',
        },
        Revoked: {
            title: 'OfferRevoked',
            actions: [],
            ReceivedStatus: 'Bluebid Revoked',
            SentStatus: 'Bluebid Revoked',
            message: 'Bluebid has been Revoked!',
            color: '#FF1716',
        },
        Countered: {
            title: 'OfferCountered',
            actions: ['Accept', 'Reject', 'Counter'],
            ReceivedStatus: 'Counter Received',
            SentStatus: 'Counter Sent',
            message: 'Bluebid has been Countered!',
            color: '#007FFE',
        },
        Expired: {
            title: "OfferExpired",
            actions: [],
            ReceivedStatus: "Bluebid Expired",
            SentStatus: "Bluebid Expired",
            message: "Bluebid has been Expired!",
            color: "#FF1716"
        },
        Message: {
            title: 'Message',
            actions: ['Reply'],
            ReceivedStatus: '',
            SentStatus: '',
            message: 'This is just a notification message!',
            color: '#000000',
        },
        Images: {
            title: 'Images',
            actions: ['Reply'],
            ReceivedStatus: '',
            SentStatus: '',
            message: 'This is just a image message!',
            color: '#000000',
        }
    }
}

export const MessageFontSize = 'inherit'

// messages (above) refer to an Offer/CounterOffer object. The offer object has the below states
export const OfferStatus = {
    Placed: 'Placed',
    Countered: 'Countered',
    Viewed: 'Viewed',
    Revoked: 'Revoked',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
    Expired: 'Expired',  // TBD - will be added
}

export const EXPIRATION_DAYS = process.env.REACT_APP_EXPIRATION_DAYS || 3
