import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardImg, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import * as XLSX from 'xlsx'

import { License, Branding } from '@bluebid-sdk/core'
import {
  ApiClient,
  ApiResponse,
  duplicateAgentServices as duplicateAgentServicesApiFn,
  isErrorResponse,
  isSuccessResponse,
} from '@bluebid-sdk/api-client'
import { api, BlockUI, DangerButton, NewPrimaryButton, NewSecondaryButton } from 'bb-lib-desktop'

import {
  addZipcode as addZipcodeApiFn,
  createLicense as createLicenseApiFn,
  deleteLicense,
  getLicensesByGroupId,
  removeZipcode,
  saveBranding,
} from '../../../lib'
import { AgentServices } from '../../../components'
import { ZipCodeSearch } from '../../../components/ZipCodeSearch'
import { ChangeBrandingPhotoModal } from '../../../components/actions/ChangeBrandingPhoto'
import { errorToast, successToast } from '../../../utils/common'
import { formatDate } from '../../../lib/utils'
import confirm from '../../../components/Confirm'
import { FileDropHandler } from '../../../components/FileDropHandler'
import { bulkAddZipcodes, updateLicenseChannels } from '../../../lib/data/bluebidData'

// @todo: change the License model to include channels, but only after moving to a monorepo
type ChanneledLicense = License & {
  channels: string[]
}

export const GroupZipcodesView: React.FC = () => {
  const apiClient = useMemo(() => new ApiClient(api), [])
  const navigate = useNavigate()
  const { groupId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [license, setLicense] = useState<License>()
  const [addZipcode, setAddZipcode] = useState<string>()
  const [photoModalOpen, setPhotoModalOpen] = useState(false)
  const [channels, setChannels] = useState({})
  const [channelsChanged, setChannelsChanged] = useState(false)
  const [usingDefaultAgentServices, setUsingDefaultAgentServices] = useState(false)
  const [canAddAgentServices, setCanAddAgentServices] = useState(false)
  const [editingAgentServices, setEditingAgentServices] = useState(false)

  useEffect(() => {
    if (groupId) {
      setIsLoading(true)
      ;(async () => {
        const response = await getLicensesByGroupId(groupId)

        if (isErrorResponse(response)) {
          errorToast(response.errorMessage)
        } else {
          const l: License = response.data?.[0] as License

          const licenseChannels = (l as ChanneledLicense)?.channels
          const hasChannels = licenseChannels !== undefined
          const usedChannels = hasChannels ? licenseChannels : []

          if (!hasChannels) {
            // if no channels array set, default to email/sms
            setChannels({
              email: true,
              sms: true,
              anywhere: false,
            })
          } else {
            setChannels({
              email: usedChannels.includes('email'),
              sms: usedChannels.includes('sms'),
              anywhere: usedChannels.includes('anywhere'),
            })
          }

          setUsingDefaultAgentServices(!l?.agentServices?.options?.length || l?.zipcodes?.includes('00000'))
          setCanAddAgentServices(!l?.zipcodes?.includes('00000'))
          setEditingAgentServices(l?.agentServices?.options?.length && !l?.zipcodes?.includes('00000'))

          setLicense(
            l ||
              ({
                groupId,
                zipcodes: [],
              } as License)
          )
        }

        setIsLoading(false)
      })()
    }
  }, [groupId])

  const handleAddZipcode = async () => {
    if (!addZipcode) {
      return
    }

    setIsLoading(true)

    let response: ApiResponse

    if (!license.id) {
      response = await createLicenseApiFn(groupId, addZipcode)
    } else {
      response = await addZipcodeApiFn(license.id, addZipcode)
    }

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      setLicense(response.data)
      successToast('Zipcode added')
    }

    setIsLoading(false)
  }

  const handleRemoveZipcode = async (zipcode: string) => {
    if (!zipcode) {
      return
    }

    setIsLoading(true)

    const response = await removeZipcode(license.id, zipcode)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      const l = response.data

      if (l?.zipcodes?.length) {
        setLicense(l as License)
      } else {
        // delete license if no zipcodes
        const response = await deleteLicense(license.id)

        if (isSuccessResponse(response)) {
          setLicense({
            groupId,
            zipcodes: [],
          } as License)
        } else {
          errorToast(response.errorMessage)
        }
      }

      successToast('Zipcode removed from license')
    }

    setIsLoading(false)
  }

  const handleBrandingChange = (field: string, value: any) => {
    const updatedLicense = { ...license }

    if (!updatedLicense.branding) {
      updatedLicense.branding = {} as Branding
    }

    updatedLicense.branding[field] = value

    setLicense(updatedLicense)
  }

  const handleSaveBranding = async () => {
    if (!license) {
      return
    }

    setIsLoading(true)

    const response = await saveBranding(license.id, license.branding)

    if (isSuccessResponse(response)) {
      successToast('Saved team branding')
    } else {
      errorToast(`Could not save branding: ${response.errorMessage}`)
    }

    setIsLoading(false)
  }

  const handlePhotoUploadSuccess = async (res) => {
    const updatedLicense: License = {
      ...license,
      branding: {
        ...license.branding,
        photoUrl: res.url,
      },
    }
    const response = await saveBranding(license.id, updatedLicense.branding)

    if (isSuccessResponse(response)) {
      setLicense(updatedLicense)
      successToast('Changed branding photo')
      togglePhotoModal()
    } else {
      handlePhotoUploadError(response.errorMessage)
    }
  }

  const handlePhotoUploadError = (err) => {
    console.error(err)
    errorToast('Could not change branding photo')
    togglePhotoModal()
  }

  const togglePhotoModal = () => {
    setPhotoModalOpen(!photoModalOpen)
  }

  const removePhoto = async () => {
    const result = await confirm({ title: 'Remove photo', confirmColor: 'danger' })

    if (result) {
      setIsLoading(true)

      const updatedLicense: License = {
        ...license,
        branding: {
          ...license.branding,
          photoUrl: undefined,
        },
      }
      const response = await saveBranding(license.id, updatedLicense.branding)

      if (isSuccessResponse(response)) {
        setLicense(updatedLicense)
        successToast('Branding photo removed')
      } else {
        errorToast(response.errorMessage)
      }

      setIsLoading(false)
    }
  }

  const readFile = async function (file) {
    const data = await file.arrayBuffer()
    const workbook = XLSX.read(data)
    const worksheet = workbook.Sheets[workbook.SheetNames[0]]
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: '',
    })

    //console.log(e.target.files[0]);
    //console.log(workbook);
    // console.log(jsonData);
    return jsonData
  }

  const fileHandler = (files, event) => {
    if (files.length > 1) {
      alert('Only 1 upload allowed at a time')
      return
    }
    const file = files[0]
    const extension = file.name.split('.')[1]?.toLowerCase()
    if (extension !== '.xls' && extension !== 'xlsx') {
      alert('XLS files only')
      return
    }
    readFile(file).then((data) => bulkAddLicenses(data.flat()))
  }

  const bulkAddLicenses = async (zipcodes) => {
    setIsLoading(true)

    let response = await bulkAddZipcodes(license.id, zipcodes)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Error occurred!')
    } else {
      setLicense(response.license)

      const added = zipcodes.length - response.overlap.length
      const ignored = response.overlap.length

      successToast(`Zipcodes added: ${added}\nIgnored: ${ignored}`)
    }
    setIsLoading(false)
  }

  const handleChannelsChange = (channel: string) => {
    setChannelsChanged(true)
    setChannels((prev) => ({
      ...prev,
      [channel]: !prev[channel],
    }))
  }

  const handleChannelsSave = () => {
    const enabledChannels = Object.keys(channels).filter((key) => channels[key])

    updateLicenseChannels(license.id, enabledChannels)
      .then(() => {
        setChannelsChanged(false)
        successToast('Channels updated')
      })
      .catch((err) => {
        console.error(err)
        errorToast('Could not update channels; refresh and try again.')
      })
  }

  const addAgentServices = () => {
    setEditingAgentServices(true)
    setUsingDefaultAgentServices(false)
  }

  const duplicateAgentServices = async () => {
    setIsLoading(true)

    const response = await apiClient.callFn(duplicateAgentServicesApiFn, { toLicenseId: license.id })

    if (!isSuccessResponse(response)) {
      errorToast('Error duplicating agent services')
      setIsLoading(false)

      return
    }

    successToast('Successfully duplicated agent services. Reloading...')

    window.location.reload()
  }

  return (
    <div className="group-cities-view">
      <BlockUI blocking={isLoading} />

      <Row className="mt-5 mb-4">
        <Col md={5}>
          <Card color="light">
            <CardBody>
              <CardTitle tag="h6">Branding</CardTitle>
              <Row>
                <Col xs={4}>
                  <FormGroup>
                    <Label for="photoUrl">Photo</Label>
                    <div>
                      {license?.branding?.photoUrl && (
                        <CardImg
                          onClick={() => togglePhotoModal()}
                          src={license.branding.photoUrl}
                          alt="Branding Photo"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <NewSecondaryButton className="mt-3" disabled={!license?.id} onClick={() => togglePhotoModal()}>
                      {license?.branding?.photoUrl ? 'Change Photo' : 'Add Photo'}
                    </NewSecondaryButton>
                    {license?.branding?.photoUrl && (
                      <DangerButton className="mt-2" onClick={() => removePhoto()}>
                        Remove Photo
                      </DangerButton>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="label">Label Line 1</Label>
                    <Input
                      type="text"
                      name="label"
                      id="label"
                      value={license?.branding?.label}
                      onChange={(e) => handleBrandingChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="label">Label Line 2</Label>
                    <Input
                      type="text"
                      name="label2"
                      id="label2"
                      value={license?.branding?.label2}
                      onChange={(e) => handleBrandingChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={license?.branding?.email}
                      onChange={(e) => handleBrandingChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email for Buyer Inquiries</Label>
                    <Input
                      type="email"
                      name="emailBuyerInquiries"
                      id="emailBuyerInquiries"
                      value={license?.branding?.emailBuyerInquiries}
                      onChange={(e) => handleBrandingChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">Phone</Label>
                    <Input
                      type="text"
                      name="phone"
                      id="phone"
                      value={license?.branding?.phone}
                      onChange={(e) => handleBrandingChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="websiteUrl">Website URL</Label>
                    <Input
                      type="text"
                      name="websiteUrl"
                      id="websiteUrl"
                      value={license?.branding?.websiteUrl}
                      onChange={(e) => handleBrandingChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="websiteUrlLabel">Website URL Label</Label>
                    <Input
                      type="text"
                      name="websiteUrlLabel"
                      id="websiteUrlLabel"
                      value={license?.branding?.websiteUrlLabel}
                      onChange={(e) => handleBrandingChange(e.target.name, e.target.value)}
                    />
                  </FormGroup>
                  <NewPrimaryButton className="float-end" disabled={!license?.id} onClick={() => handleSaveBranding()}>
                    Update
                  </NewPrimaryButton>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card color="light">
            <CardBody>
              <CardTitle tag="h6">Lead Channels</CardTitle>

              {!license?.zipcodes?.length && <div>Add at least one zipcode before selecting channels.</div>}

              {!!license?.zipcodes?.length && (
                <FormGroup className="ml-4">
                  {Object.keys(channels).map((channel) => (
                    <div key={channel}>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={channels[channel]}
                          onChange={() => handleChannelsChange(channel)}
                        />
                        {channel}
                      </Label>
                    </div>
                  ))}
                </FormGroup>
              )}

              <div className="mt-3 text-right">
                <NewPrimaryButton
                  color="primary"
                  type="submit"
                  disabled={!channelsChanged}
                  onClick={handleChannelsSave}
                >
                  Save
                </NewPrimaryButton>
              </div>
            </CardBody>
          </Card>

          <Card className="mt-4" color="light">
            <CardBody style={{ minHeight: '115px' }}>
              <CardTitle tag="h6">Agent Services</CardTitle>

              {usingDefaultAgentServices && (
                <div className="mt-3">
                  {canAddAgentServices && (
                    <i onClick={() => duplicateAgentServices()}>Using default agent services, click to override</i>
                  )}
                  {!canAddAgentServices && <i>*** Default License ***</i>}
                  <div className="text-right" style={{ display: 'inline-block', margin: '0 auto', float: 'right' }}>
                    {/*</div>*/}
                    {/*<div className="text-right">*/}
                    {canAddAgentServices && (
                      <>
                        {/*<Button*/}
                        {/*  title="Add New Agent Services"*/}
                        {/*  color="none"*/}
                        {/*  size="sm"*/}
                        {/*  onClick={() => addAgentServices()}*/}
                        {/*>*/}
                        {/*  <FontAwesomeIcon icon={faPlus} />*/}
                        {/*</Button>*/}
                        <Button
                          title="Define Agent Services for this License"
                          color="none"
                          size="sm"
                          onClick={() => duplicateAgentServices()}
                        >
                          <FontAwesomeIcon icon={faPlus} style={{ color: 'green' }} />
                        </Button>
                      </>
                    )}

                    {/*<Button*/}
                    {/*  title="Edit Default Agent Services"*/}
                    {/*  color="none"*/}
                    {/*  size="sm"*/}
                    {/*  onClick={() => {*/}
                    {/*    navigate('/admin/default-agent-services')*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <FontAwesomeIcon icon={faPencilAlt} />*/}
                    {/*</Button>*/}
                  </div>
                </div>
              )}
              {editingAgentServices && <AgentServices licenseId={license.id} />}
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card color="light">
            <CardBody>
              <CardTitle tag="h6">Add Zipcodes</CardTitle>
              <Row className="align-items-end">
                <Col>
                  <ZipCodeSearch onSelect={(s) => setAddZipcode(s?.zip)} />
                </Col>
                <Col xs={3}>
                  <NewPrimaryButton
                    className="w-100 mb-3"
                    height={38}
                    disabled={!addZipcode}
                    onClick={() => handleAddZipcode()}
                  >
                    Add
                  </NewPrimaryButton>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FileDropHandler handler={fileHandler} accept={'.xls, .xlsx'} />
                </Col>
              </Row>
              {/*<Row>*/}
              {/*  <Col>*/}
              {/*    <TextInput rows={3} onChange={handleChange} placeholder={'Enter comma separated Zipcodes'}/>*/}
              {/*  </Col>*/}
              {/*  <Col xs={3}>*/}
              {/*    <NewPrimaryButton*/}
              {/*      className="w-100 mt-4"*/}
              {/*      height={38}*/}
              {/*      onClick={() => handleCommaInputZipcodes()}*/}
              {/*    >*/}
              {/*      Add*/}
              {/*    </NewPrimaryButton>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </CardBody>

            <CardBody>
              <CardTitle tag="h6">Current Zipcodes</CardTitle>

              {(license?.zipcodes || []).sort().map((zipcode) => (
                <ListItem key={zipcode}>
                  <Row>
                    <Col md={2}>
                      <Button color="danger" size="sm" onClick={() => handleRemoveZipcode(zipcode)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Col>
                    <ItemTextCol md={2}>
                      <Link to={`/admin/zipcode/${zipcode}`} className="ml-2">
                        {zipcode}
                      </Link>
                    </ItemTextCol>
                    <ItemTextCol>
                      {formatDate(
                        license.zipcodeDates[license.zipcodes.findIndex((z) => z === zipcode)],
                        'MMM DD, YYYY'
                      )}
                    </ItemTextCol>
                  </Row>
                </ListItem>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ChangeBrandingPhotoModal
        license={license}
        isOpen={photoModalOpen}
        toggleModal={togglePhotoModal}
        onUploadSuccess={(res) => handlePhotoUploadSuccess(res)}
        onUploadError={(err) => handlePhotoUploadError(err)}
      />
    </div>
  )
}

const TextInput = styled.textarea`
  margin-top: 20px;
  border-radius: 5px;
  width: 100%;
`

const ListItem = styled.li`
  padding: 5px;
  list-style: none;

  :nth-child(odd) {
    background: #efefef;
  }
`

const ItemTextCol = styled(Col)`
  padding: 6px;
`
