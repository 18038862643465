import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ICellRendererParams } from '@ag-grid-community/core'
import { Container, Card, CardBody, CardTitle, Row, Col, ListGroup, ListGroupItem, CardHeader } from 'reactstrap'
import styled from 'styled-components'

import { Group, License, User } from '@bluebid-sdk/core'
import { isSuccessResponse } from '@bluebid-sdk/api-client'
import { BlockUI } from 'bb-lib-desktop'

import { getLicensesByGroupId, getGroupUsers } from '../../lib'
import { GroupBranding } from '../GroupBranding'

export const GroupRowDetailRender: React.FC<ICellRendererParams<Group>> = ({ data: group }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [groupLicenses, setGroupLicenses] = useState<License[]>()
  const [groupMembers, setGroupMembers] = useState<User[]>()

  useEffect(() => {
    if (group?.id) {
      setIsLoading(true)
      ;(async () => {
        const [groupLicensesResponse, groupUsersResponse] = await Promise.all([
          getLicensesByGroupId(group.id),
          getGroupUsers(group.id),
        ])

        if (isSuccessResponse(groupLicensesResponse)) {
          setGroupLicenses(groupLicensesResponse.data as any)
        }
        if (isSuccessResponse(groupUsersResponse)) {
          setGroupMembers(groupUsersResponse.data)
        }

        setIsLoading(false)
      })()
    }
  }, [group?.id])

  return (
    <>
      <BlockUI blocking={isLoading} />

      {!isLoading && (
        <Container className="py-3 h-100">
          <Card className="h-100 overflow-auto">
            <CardBody>
              <CardTitle tag="h5">License Name: {group.name}</CardTitle>
              <Row>
                {!!groupMembers?.length && (
                  <Col xs={4}>
                    <Card className="h-100">
                      <CardHeader>Agents/Members</CardHeader>
                      <ListGroup flush>
                        {groupMembers.map((groupMember, index) => (
                          <ListGroupItem
                            key={groupMember.id}
                            className={index > 0 ? 'border-top' : ''}
                            action
                            tag="button"
                            onClick={() => navigate(`/admin/userprofile/${groupMember.id}`)}
                          >
                            <div>
                              {!groupMember.firstName && !groupMember.lastName && (
                                <span className="fst-italic">(no name)</span>
                              )}
                              {(!!groupMember.firstName || !!groupMember.lastName) && (
                                <span>
                                  {groupMember.firstName?.toUpperCase()} {groupMember.lastName?.toUpperCase()}
                                </span>
                              )}
                            </div>
                            <div>
                              <span>{groupMember.email}</span>
                            </div>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Card>
                  </Col>
                )}
                {!!groupLicenses?.length && (
                  <>
                    <Col xs={6}>
                      <Card className="h-100">
                        <CardHeader>Zip Codes</CardHeader>
                        <CardBody>
                          <Row>
                            {groupLicenses.map((groupLicense) => (
                              <Col xs={6} className="mb-4">
                                <Card className="h-100">
                                  <WrappedCardHeader>{groupLicense.zipcodes.join(', ')}</WrappedCardHeader>
                                  <CardBody>
                                    {groupLicense.branding && (
                                      <GroupBranding
                                        branding={groupLicense.branding}
                                        asEmailLink={true}
                                        property={undefined}
                                      />
                                    )}
                                    {!groupLicense.branding && <span className="fst-italic">(no branding)</span>}
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs={2}>
                      <Card className="h-100">
                        <CardHeader>Lead Channels</CardHeader>
                        <ListGroup flush>
                          {((groupLicenses[0] as any).channels || ['email', 'sms']).map((channel) => (
                            <ListGroupItem>{channel}</ListGroupItem>
                          ))}
                        </ListGroup>
                      </Card>
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  )
}

const WrappedCardHeader = styled(CardHeader)`
  white-space: pre-wrap;
`
