import { api } from 'bb-lib-desktop'

export function assignUserToGroup(id: string, userId: string) {
  return api(`/groups/${id}/users`, {
    method: 'POST',
    body: {
      userId
    }
  })
}
