import { api } from 'bb-lib-desktop'

type ListingStatus = {
  onMarket: boolean
  exactMatch: boolean
  title: string
  link: string
}

export async function checkListingStatus({address}) {

  return api<{ value: [ListingStatus]}>(`/search/listingStatus`, {
    method: 'POST',
    body: {
      address,
    }
  })
}
