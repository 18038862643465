export const downloadExport = async (args: { method?: 'GET' | 'POST', params?: unknown, getToken: () => Promise<string>, exportUrl: string }) => {
  const token = await args.getToken()

  const body = args.params ? JSON.stringify(args.params) : '{}'
  
  // stream the data into the browser
  const response = await fetch(args.exportUrl, {
    method: args.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : '',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    ...(args.method === 'POST' ? { body } : {})
  })

  if (!response.ok) {
    throw new Error(`Error: ${response.status}`)
  }

  // name the file
  const contentDisposition = response.headers.get('Content-Disposition')
  const filename = contentDisposition
    ? contentDisposition.split('filename=')[1].replace(/"/g, '')
    : 'export.xlsx'

  // click a fake link to download
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}
