import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import ErrorScreen from '../../../components/ErrorScreen'
import Loading from '../../../components/Loading'
import { AdminOwnerInfo } from '../adminComponents/AdminOwnerInfo'
import { AdminPropertyInfo } from '../adminComponents/AdminPropertyInfo'
import { AdminPropertyImages } from '../adminComponents/AdminPropertyImages'
import { ClaimNavigation } from '../adminComponents/ClaimNavigation'
import { useProperty } from '../hooks/useProperty'
import { AdminInquiries } from '../adminComponents/AdminInquiries'
import { AdminListInquiries } from '../adminComponents/AdminListInquiries'

const PropertyEditNew: React.FC = () => {
  const { propertyId } = useParams()
  const [error, setError] = useState<Error>()
  const [errorMsg, setErrorMsg] = useState('')
  const { loading, property, owner, refetchProperty } = useProperty(propertyId)
  const navigate = useNavigate()
  const navigateProperty = (sel) => navigate(`/admin/propertyedit/${sel}`)
  const navigateUser = (sel) => navigate(`/admin/userprofile/${sel}`)

  const getClaimedProperty = () => refetchProperty()

  return (<>
    <ErrorScreen error={error} message={errorMsg} />

    {!loading && property &&
      <ErrorBoundary fallbackRender={({ error }) => <ErrorScreen error={error} message={error?.message} />}>
        <Row>
          <ClaimNavigation onPropertySelect={navigateProperty} onUserSelect={navigateUser}/>
        </Row>
        <Row>
          <Col md={6}>
            <AdminPropertyInfo property={property} onEditComplete={() => getClaimedProperty()} />
          </Col>

          <Col md={6}>
            <AdminOwnerInfo owner={owner} property={property} onEditComplete={() => getClaimedProperty()} />
            <AdminInquiries owner={owner} property={property} />
            <AdminListInquiries owner={owner} property={property} />
          </Col>
        </Row>

        <Row>
          <Col>
            <AdminPropertyImages propertyId={property.propertyId} />
          </Col>
        </Row>
      </ErrorBoundary>
    }

  </>)
}

export default withAuthenticationRequired(PropertyEditNew, {
  onRedirecting: () => <Loading />,
})

const ActionsContainer = styled.div`
  padding-top: 10px;
`
