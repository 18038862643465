import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Spinner = styled(FontAwesomeIcon).attrs({ icon: faSpinner })`
  margin: 0 2px 0 8px
`

const LoadingInline = () => (
  <span>
    <Spinner spin />
  </span>
)

export default LoadingInline
