import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardBody, CardTitle, CloseButton, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

import { deleteImages } from '../../../lib'
import { usePropertyImages } from '../hooks/usePropertyImages'
import { AdminCard } from './Styles'
import { AddPhotosAction } from '../../../components/actions/AddPhotosAction'
import { ManagePhotosAction } from '../../../components/actions/ManagePhotosAction'
import { AdminSecondaryActionButton } from '../../../components/adminActions/common'
import { AuthContext } from '../../../constants/context'
import { ToggleGoogleImagesAction } from '../../../components/adminActions/ToggleGoogleImagesAction'
import { useProperty } from '../hooks/useProperty'
import { PhotoAttribution } from '../../../components/PhotoAttribution'

export const AdminPropertyImages: React.FC<{
  propertyId: string
}> = ({ propertyId }) => {
  const { bluebidUser } = useContext(AuthContext)
  const [showAddPhotos, setShowAddPhotos] = useState(false)
  const [showManagePhotos, setShowManagePhotos] = useState(false)

  const { property, refetchProperty } = useProperty(propertyId)
  const { assets, propertyImages, refetchPropertyImages } = usePropertyImages(propertyId)
  // const { assets } = usePropertyAssets(property.propertyId)
  const navigate = useNavigate()

  const deletePropertyAsset = async (assetId: string) => {
    deleteImages(property.propertyId, [assetId]).then(() => {
      refetchPropertyImages().then(() => {
        console.log('assets', assets)
      })
    })
  }

  const onAddPhotosComplete = () => {
    refetchProperty()
    shouldUpdateProfile()
  }
  const shouldUpdateProfile = () => {
    refetchProperty()
    refetchPropertyImages()
  }

  return (
    <Row>
      <Col>
        <AdminCard>
          <CardBody>
            <CardTitle tag={'h5'}>
              Property Images
              <br />
            </CardTitle>
            <Container>
              <Row>
                <Col xs={6}>
                  <ImageLabel>
                    Main Image ({property?.mainImage === assets?.[0]?.url ? 'Uploaded' : 'Auto Generated'})
                  </ImageLabel>
                  <img src={propertyImages?.mainImage?.url} alt="" className="img-thumbnail mb-3" />
                </Col>
                <Col xs={6}>
                  <Row className="align-items-start">
                    <Col xs={6}>
                      <ImageLabel>Auto Map Image</ImageLabel>
                      <img src={propertyImages?.mapImage?.url} alt="" className="img-thumbnail mb-3" />

                      <ImageLabel>Auto Satellite Image</ImageLabel>
                      <img src={propertyImages?.satImage?.url} alt="" className="img-thumbnail mb-3" />
                    </Col>
                    <Col xs={6}>
                      <ImageLabel>Photo Actions</ImageLabel>

                      <AddPhotosAction
                        bluebidUser={bluebidUser}
                        property={property}
                        shouldUpdateProfile={onAddPhotosComplete}
                        showModal={showAddPhotos}
                        onClose={() => setShowAddPhotos(false)}
                      >
                        <AdminSecondaryActionButton onClick={() => setShowAddPhotos(true)}>
                          <FontAwesomeIcon icon={faCamera} /> Add Photos
                        </AdminSecondaryActionButton>
                      </AddPhotosAction>

                      <ManagePhotosAction
                        bluebidUser={bluebidUser}
                        property={property}
                        shouldUpdateProfile={() => shouldUpdateProfile()}
                        showModal={showManagePhotos}
                        onClose={() => setShowManagePhotos(false)}
                      >
                        <AdminSecondaryActionButton
                          disabled={!property?.expanded?.assets?.length}
                          onClick={() => setShowManagePhotos(true)}
                        >
                          <FontAwesomeIcon icon={faCamera} /> Manage Photos
                        </AdminSecondaryActionButton>
                      </ManagePhotosAction>

                      <AdminSecondaryActionButton
                        onClick={() => navigate(`/admin/propertyedit/${property.propertyId}/google-photos`)}
                      >
                        <FontAwesomeIcon icon={faCamera} /> Google photos
                      </AdminSecondaryActionButton>

                      <ToggleGoogleImagesAction property={property} shouldUpdateProfile={() => shouldUpdateProfile()} />

                      <PhotoAttribution property={property} onSuccess={() => refetchPropertyImages()} />
                    </Col>
                  </Row>
                </Col>

                <ImageLabel>Uploaded Images</ImageLabel>
                {assets.map((asset) => (
                  <Col key={`${asset.id}`} xs={3} style={{ position: 'relative' }}>
                    <DeleteAssetButton
                      onClick={() => deletePropertyAsset(asset.id)}
                      style={{ position: 'absolute', top: 3, right: 15 }}
                    />
                    <img src={asset.url} alt="" className="img-thumbnail mb-3" />
                  </Col>
                ))}
              </Row>
            </Container>
          </CardBody>
        </AdminCard>
      </Col>
    </Row>
  )
}

const DeleteAssetButton = styled(CloseButton)`
  background-color: red;
`

const ImageLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
`
