import apiCall from './data/apiCall'

type User = {
  email: string
  firstName: string
  id: string
  lastName: string
  phone: string
}

enum InquiryTypes {
  ComplimentaryServices = 'complimentaryServices',
  ClaimedProperty = 'claimedProperty',
  BuyerInquiry = 'buyerInquiry',
}

export type ContactSheet = {
  userId?: string
  name?: string
  email: string
  phone?: string
}

export const userToContactSheet = (user: User): ContactSheet => {
  const { firstName, lastName } = user
  const name =
    firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName || undefined

  return {
    userId: user.id,
    name,
    email: user.email,
    phone: user.phone,
  }
}

export const submitInquiry = async (args: {
  type: InquiryTypes,
  propertyId?: string,
  address?: string,
  contactSheet: ContactSheet,
  message?: string,
  agentId?: string
}) => {
  const { type, propertyId, address, contactSheet, message, agentId } = args

  return apiCall(`/inquiries/send`, {
    method: 'POST',
    body: {
      type,
      propertyId,
      address,
      contactSheet,
      message,
      agentId
    }
  })
}

// ---

export const submitClaimedPropertyInquiry = ({ propertyId, address, contactSheet, agentId }: { propertyId: string; address: string; contactSheet: ContactSheet; agentId?: string }) =>
  submitInquiry({
    type: InquiryTypes.ClaimedProperty,
    propertyId,
    address,
    contactSheet,
    agentId
  })

  //userToContactSheet(