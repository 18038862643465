import React from 'react'
import { sendEstimationChangedEmail } from '../../lib/data/bluebidData'
import confirm from '../Confirm'
import { successToast } from '../../utils/common'
import { AdminActionButton, AdminSecondaryActionButton } from './common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'reactstrap'
import { currencyFormatter, formatDate } from '../../lib/utils'

export const SendEstimationEmail = ({property}) => {

    const sendEstimationEmailAction = async () => {
        let result = await confirm({
            title: 'Confirm Email Send',
            bodyComponent: () => EstimateChangedBody(property),
            cancelText: 'No',
            confirmColor: 'primary',
            confirmText: 'Yes'
        })
        if (result) {
            await sendEstimationChangedEmail(property.propertyId)
            successToast('Email has been sent.')
        }
    }

    return (
        <>
            <AdminSecondaryActionButton onClick={sendEstimationEmailAction} >
                <FontAwesomeIcon icon={faMailBulk} /> Estimate Email
            </AdminSecondaryActionButton>
        </>
    )
}

const RowData = ({label, value}) => {
    return <Row>
        <Col>{label}</Col>
        <Col>{value}</Col>
    </Row>
}

const currencyValOr = (value, unknown) => {
    if (!value) return unknown || 'unavailable'
    return currencyFormatter.format(value)
}
const EstimateChangedBody = function(property) {

    const marketValuation = currencyValOr(property?.detail?.valuation?.value)
    const marketValuationDate = formatDate(property?.detail?.valuation?.date) || 'unknown'
    const lastUpdated = formatDate(property?.refreshedAt) || 'unknown'
    const lastUpdateRequested = formatDate(property?.lastCheckedAt) || 'unknown'
    const bluebidEstimate = currencyValOr(property.detail?.bluebid_estimate?.value, 'not set')
    const ownerEstimate = currencyValOr(property.detail?.owner_sell_price, 'not set')
    const atomPubData = formatDate(property?.detail?.metadata?.publishing_date) || 'unknown'

    return <>
        <RowData label={'ATOM Publish Date'} value={atomPubData} />
        <RowData label={'Market Estimate'} value={marketValuation} />
        <RowData label={'Market Estimate Date'} value={marketValuationDate} />
        <RowData label={'Owner Estimate'} value={ownerEstimate} />
        <RowData label={'Bluebid Estimate'} value={bluebidEstimate} />
        <RowData label={'Last Update Request'} value={lastUpdateRequested} />
        <RowData label={'Last Updated'} value={lastUpdated} />

        <br/>
        <br/>
        <Row>
            <Col>
                Send an email notification to the property owner that the estimate has changed?
            </Col>
        </Row>
        <br/>
    </>
}
