import { AdminSearchTypes, SearchResults } from '@bluebid-sdk/core'

import { api } from 'bb-lib-desktop'

export function adminSearch<T = any>(
  searchType: AdminSearchTypes,
  search: string,
  extraSearchParams?: string[],
  limit = 100
) {
  const extraSearchParamsStr = extraSearchParams?.length
    ? ` AND (${extraSearchParams.join(' OR ')})`
    : ''

  return api<SearchResults<T>>(
    `/search/${searchType}?q=(${search})${extraSearchParamsStr}&limit=${limit}`
  )
}
