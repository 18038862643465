import { useState } from 'react'
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import styled from 'styled-components'
import BlockUI from '../BlockUI'
import { expirationReminderEmail } from '../../lib/data/bluebidData'
import { errorToast, successToast } from '../../utils/common'
import { OfferAdminButton, PrimaryButton } from '../actions/common'
import { AdminButton } from '../AdminStyles'
import { ChannelGroup } from './completeOfferAction'
import { OfferDetails } from './OfferDetails'

export const SendExpirationReminderAction = ({ offerId, history }) => {
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [channels, setChannels] = useState({ sms: true, email: true })

    const lastOffer = history.length ? history[history.length - 1] : undefined

    const toggle = () => {
        setShowModal(!showModal)
    }

    // send expiration reminder email
    const sendExpirationReminder = async () => {
        setIsLoading(true)
        expirationReminderEmail(offerId, channels).then((response) => {
            setIsLoading(false)
            if (response.success) {
                toggle()
                setChannels({ sms: true, email: true })
                successToast(`Reminder sent successfully!`)
            } else {
                console.error(response)
                errorToast(response?.errorMessage || response?.message || 'Error sending reminders');
            }
        })
    }

    const onChannelToggle = (key) => {
        const updatedChannels = {...channels}
        updatedChannels[key] = !updatedChannels[key]
        setChannels(updatedChannels)
    }

    return (<>
        <ReminderGridButton className={"btn-margin m-auto"} onClick={toggle}>Expiration Reminder</ReminderGridButton>

        <OfferModal size="lg" isOpen={showModal} backdrop="static" toggle={toggle} modalTransition={{ timeout: 200 }}>
            <ModalHeader toggle={toggle}>Expiration Reminder Notice</ModalHeader>
            <BlockUI blocking={isLoading} />

            {!isLoading &&
            <>
                <OfferModalBodyContainer>
                    <OfferDetails offerId={lastOffer?.id} />
                </OfferModalBodyContainer>
                <CenteredModalFooter>
                    <Row>
                        <Col md={12} >
                            <ChannelGroup className="mx-4 mt-2">
                                Send notifications to:
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="checkbox" onChange={() => onChannelToggle('sms')} checked={channels.sms}/>
                                        SMS
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="checkbox" onChange={() => onChannelToggle('email')} checked={channels.email}/>
                                        Email
                                    </Label>
                                </FormGroup>
                            </ChannelGroup>
                        </Col>
                    </Row>
                    <DialogFooterBlock>
                        <OfferAdminButton className={"mr-4"} disabled={isLoading}
                                          onClick={() => sendExpirationReminder()}>Send Reminder</OfferAdminButton>
                        <PrimaryButton className={"mt-2"} onClick={toggle}>Cancel</PrimaryButton>
                    </DialogFooterBlock>

                </CenteredModalFooter>
            </>
            }

        </OfferModal>
    </>)
}


const ReminderGridButton = styled(AdminButton)`
  max-width: 120px;
  font-size: 8pt;
  background-color: #027ffe;
  padding-bottom: 10px;
`

export const CenteredModalFooter = styled(ModalFooter)`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
`
export const DialogFooterBlock = styled.div`
  justify-content: center;
  padding-bottom: 20px;
`
export const OfferModalBodyContainer = styled(ModalBody)`
  .form-check-input {
    margin-top: 0;
  }
`
export const OfferModal = styled(Modal)`
    .btn-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        color: #000;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        border: 0;
        border-radius: 0.25rem;
        opacity: .5;
        padding: 0.5rem 0.5rem;
        margin: -0.5rem -0.5rem -0.5rem auto;
    }
`
