import styled from 'styled-components'
import { Row } from 'reactstrap'
import { SecondaryLabelColumn } from './common'
import { sizes } from '../../utils/responsive'
import OriginalReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BluebidBlue } from '../../constants/colors'

export const EstimateRow = styled(Row).attrs({
    className: 'estimate-row'
})`
  margin: 0 0 5px 0 !important;
`

export const PriceEstimateRow = styled(EstimateRow)`
    padding: 0;
`

export const EstimateColumnWithBar = styled(SecondaryLabelColumn)`
  padding: 0 10px;
  border-left: 2px solid #cbcbcb;

  @media (max-width: ${sizes.tablet}) {
    border: none;
    padding: 5px 0;
  }
`

/**
 * Column containing basic stats: Beds, Baths, Sqft
 * @tiled - set to true if this is in a tiled display
 * @shadow - set to true to render with white / black shadow (for popup)
 */
export const BasicPropertyStatsColumn = styled(EstimateColumnWithBar)`
  border: none;
  padding: ${(props) => props.tiled ? '5px 0 0 5px' : '0'};
  line-height: 24px;
`
export const BasicPropertyStatsLabel = styled.span`
  font-size: ${(props) => props.tiled ? '0.9em' : undefined };
  color: ${(props) => props.tiled ? undefined : (props.shadow ? 'white' : undefined)};
  text-shadow: ${(props) => props.shadow ? '1px 1px #000' : undefined};
  padding-left: 4px;
`
export const BasicPropertyStatsValue = styled(BasicPropertyStatsLabel)`
  font-weight: bold;
  padding-left: initial;
`

export const ClickArrow = styled(FontAwesomeIcon)`
    padding-left: 10px;
    color: ${BluebidBlue};
`

export const VerticalBar = styled.div`
  display: inline-block;
  width: 10px;
  border-right: 2px solid #d7d7d7;
  height: 16px;
  margin-right: 8px;
  top: 2px;
  position: relative;`

export const UnderlineTip = styled.a`
    text-decoration: underline dotted #72a7f4 !important;
    text-underline-offset: 4px;    
`

export const ReactTooltip = styled(OriginalReactTooltip).attrs({
    className: "custom-tooltip",
})`
    &.custom-tooltip {
        font-family: Montserrat, sans-serif;
        background-color: #007fff;
        color: white;
        width: 100%;
        max-width: 400px;
        opacity: 100 !important;
        .multi-line {
            text-align: left !important;
        }
    }
`;
// Note: ^^ probably a better way than to use 'important' above
