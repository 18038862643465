import React, { useCallback, useMemo, useRef, useState } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { AgGridReact } from '@ag-grid-community/react'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core'
import { Button } from 'reactstrap'
import styled from 'styled-components'

import { formatPhoneNumber } from '@bluebid-sdk/core'
import { Datasource, BlockUI } from 'bb-lib-desktop'

import { LicensesGroupLinkRenderer, restoreGridState } from '../../components/grid/GridRenderers'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import Loading from '../../components/Loading'
import { useSessionStorage } from '../../lib/utils'
import { ZipCodeSearch } from './../../components/ZipCodeSearch'
import { errorToast } from '../../utils/common'
import { GridListHeader } from './GridListHeader'

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
  RowGroupingModule,
  StatusBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ExcelExportModule,
])

const datasource = new Datasource({
  index: 'licenses',
  adminSearch: true,
})

const ListZipLicenses = () => {
  const navigate = useNavigate()

  const gridRef = useRef<AgGridReact>()

  const [selected, setSelected] = useState<{ zip: string; cities: string[]; state: string }>()
  const containerStyle = useMemo(() => GridContainerStyle, [])
  const gridStyle = useMemo(() => GridStyle, [])
  const sideBar = useMemo(() => StandardSideBar, [])
  const defaultColDef = useMemo(() => StandardColumnDefs, [])

  const [isWorking] = useState(false)
  const [colState, setColState] = useSessionStorage('cityLicenses', '')
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const onGridReady = useCallback((params) => restoreGridState(colState, gridRef), [])
  const onBtnRefresh = useCallback(() => gridRef.current.api.refreshServerSide(), [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'zipcodes',
        headerName: 'Zip Codes',
      },
      {
        field: 'groupId',
        headerName: 'License Group Id',
        width: 300,
        filter: 'agTextColumnFilter',
        cellRenderer: LicensesGroupLinkRenderer,
        cellRendererParams: { prefix: `/admin/group` },
      },
      {
        headerName: 'Branding',
        children: [
          { field: 'branding.label', headerName: 'Label', width: 165, filter: 'agTextColumnFilter' },
          { field: 'branding.phone', headerName: 'Phone', width: 110, filter: 'agTextColumnFilter', valueFormatter: (params) => formatPhoneNumber(params.value) },
          { field: 'branding.email', headerName: 'Email', width: 175, filter: 'agTextColumnFilter' },
        ],
      },
    ],
    []
  )

  const handleOnSelect = (s: { zip: string; cities: string[]; state: string }) => {
    setSelected(s)
  }

  const handleGoto = () => {
    if (!selected) {
      errorToast('Please select a zip code')

      return
    }

    navigate(`/admin/zipcode/${selected.zip}`)
  }

  return (
    <>
      <BlockUI blocking={isWorking} />

      <GridListHeader title={'Zip Codes'} onRefresh={onBtnRefresh}>
        <Container>
          <ZipCodeSearch onSelect={handleOnSelect} />

          <Button className="ms-3" color="primary" type="submit" onClick={handleGoto}>
            Go {!!selected && ` to ${selected.zip}`}
          </Button>
        </Container>
      </GridListHeader>

      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-balham">
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            onSortChanged={saveGridState}
            onColumnMoved={saveGridState}
            onColumnResized={saveGridState}
            onDisplayedColumnsChanged={saveGridState}
            animateRows={true}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRangeSelection={true}
            rowSelection="multiple"
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            suppressRowClickSelection={true}
            sideBar={sideBar}
          />
        </div>
      </div>
    </>
  )
}

const Container = styled.div`
  width: 300px;
  display: flex;
  margin: 0 auto;
`
export default withAuthenticationRequired(ListZipLicenses, {
  onRedirecting: () => <Loading />,
})
