import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap'

import { Property, User } from '@bluebid-sdk/core'

import { AdminPropertyInfo } from './../adminComponents/AdminPropertyInfo'
import { AdminOwnerInfo } from './../adminComponents/AdminOwnerInfo'
import { AdminInquiries } from './../adminComponents/AdminInquiries'
import { AdminPropertyImages } from './../adminComponents/AdminPropertyImages'
import { AdminListInquiries } from './../adminComponents/AdminListInquiries'

type PropertyEditModalProps = {
  isOpen: boolean
  onClose: () => void,
  onEdit: () => void,
  property: Property,
  owner: User
}

export const PropertyEditModal: React.FC<PropertyEditModalProps> = ({ isOpen, onClose, onEdit, property, owner }) => {
  const toggle = () => {
    onClose()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalHeader toggle={toggle}>{property?.address}</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <AdminPropertyInfo property={property} onEditComplete={onEdit} />
          </Col>

          <Col md={6}>
            <AdminOwnerInfo owner={owner} property={property} onEditComplete={onEdit} />
            <AdminInquiries owner={owner} property={property} />
            <AdminListInquiries owner={owner} property={property} onSend={onEdit} />
          </Col>
        </Row>

        <Row>
          <Col>
            <AdminPropertyImages propertyId={property?.propertyId} />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}