/**
 * Main Admin page
 * @constructor
 */
import { Container } from 'reactstrap'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AuthContext } from '../../constants/context'
import { LoginHero } from '../../components/adminActions/LoginHero'
import { TopBar } from './TopBar'
import classNames from 'classnames'
import { AdminRoutes } from './AdminRoutes'

const AdminContent = ({ sidebarIsOpen, toggle }) => {
    const { bluebidUser } = useContext(AuthContext)

    if (!bluebidUser) {
        return <>
            <LoginHero />
        </>
    }

    return (
        <AdminContainer fluid className={classNames({ 'is-open': sidebarIsOpen })}>
            <TopBar toggleSidebar={toggle} />
            <ContentBody>
                <AdminRoutes />
            </ContentBody>
        </AdminContainer>
    )
}

export default AdminContent

const ContentBody = styled.div`
    margin: 0 12px;
    height: 94%;
`

const AdminContainer = styled(Container).attrs({
    className: 'content',
})`
  margin-left: 0;
  transition: all 0.5s;
  padding: 0;

  .is-open {
    margin-left: 250px;
    transition: 0.5s;
  }
`
