import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'

import { EditAgentContent } from '../../components/EditAgentContent'
import { successToast, errorToast } from '../../utils/common'

export const EditAgentInfoView: React.FC = () => {
  const { userId } = useParams()
  const navigate = useNavigate()

  const onUserError = (errorMessage: string) => {
    errorToast(errorMessage)
    navigate('/admin/list-users')
  }

  const onSaveSuccess = () => {
    successToast('Agent content saved successfully!')
  }

  const onSaveError = (errorMessage: string) => {
    errorToast(errorMessage)
  }

  return (
    <Container fluid={true} className="mt-3">
      <h4>Edit Agent Info</h4>
      {!!userId && (
        <EditAgentContent
          userId={userId}
          onUserError={onUserError}
          onSaveSuccess={onSaveSuccess}
          onSaveError={onSaveError}
        />
      )}
    </Container>
  )
}
