import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { AlignRight } from './GridStyles'
import moment from 'moment-timezone'
import { startCase } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons/faTrashCan'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock'

import { formatPhoneNumber } from '@bluebid-sdk/core'

import { currencyFormatter } from '../../lib/utils'
import appleLogo from '../../assets/apple.svg'
import googleLogo from '../../assets/google.svg'
import dbLogo from '../../assets/database.svg'

const LinkStyle = styled.div``
const IconStyle = styled.div`
  width: 20px;
  height: 20px;
  img {
    width: 20px;
    height: 20px;
  }
`
export const PhoneNumberRendererFromResultField = (params) => formatPhoneNumber(ResultFieldRenderer(params))

export const PhoneNumberRenderer = (params) => {
  if (params) {
    const data = params.data[params.colDef.field]
    return formatPhoneNumber(data)
  }
  return ''
}


/**
 * cellRendererParams:
 *      object - the field object
 *      field - the field within the object
 * @param params
 * @constructor
 */
export const ResultFieldRenderer = (params) => {
  const fld = params?.data?._fields
  if (fld) {
    let object = fld[params.object]
    if (object) {
      let value = object?.[params.field]
      return value || ''
    }
  }
  return ''
}

export const CurrencyResultFieldRenderer = (params) => {
  const val = ResultFieldRenderer(params)
  let cell = val
  if (val) {
    cell = currencyFormatter.format(val)
  }
  return <AlignRight>{cell}</AlignRight>
}


/**
 * Renders a link to the colDef's 'field' value,
 * Displaying the value from the row's _field object
 * @param params
 * prefix - url target prefix (eg: /admin/userprofile)
 * object - object name in result set field (eg: user)
 * field - field within above object (eg: firstName)
 * linkField - column in result set to use for link suffix (eg: userId)
 *
 * @returns {JSX.Element|string|string}
 * @constructor
 */
export const ResultFieldLinkRenderer = (params) => {
  const fld = params?.data?._fields
  if (fld) {
    let object = fld[params.object]
    if (object) {
      const value = object?.[params.field]
      const lnk = params?.data[params.linkField]
      return value ?
        <div>
          <Link to={`${params.prefix}/${lnk}`}>{value}</Link>
        </div> : ''
    }
  }
  return ''
}

export const getRowStyle = (params) => {
  if (params?.data?.status === 'Unclaimed') {
    return { color: 'lightgrey' }
  }
  return {}
}

export const AddressLinkRenderer = (params) => {
  return (
    <div>
      <Link style={getRowStyle(params)} to={`${params.prefix}/${params.data?.propertyId}`}>{params.value}</Link>
    </div>
  )
}

export const OwnerFieldLinkRenderer = (params) => {
    const first = params.data?._fields?.owner?.firstName?.toUpperCase() || ''
    const last = params.data?._fields?.owner?.lastName?.toUpperCase() || ''
    const name = first || last ? `${first} ${last}` : '(no name)'
    return <div>
      <Link style={getRowStyle(params)} to={`/admin/userprofile/${params?.data?.ownerId || params?.data?.userId}`}>{name}</Link>
    </div>
}

export const LinkRenderer = (params) => {
  if (params.field) {
    const value = params?.data?.[params.field]
    return (<LinkStyle>
      <Link style={getRowStyle(params)} to={`${params.prefix}/${value}`}>{params.value}</Link>
    </LinkStyle>)
  }
  return (<LinkStyle>
    <Link style={getRowStyle(params)} to={`${params.prefix}/${params.value}`}>{params.value}</Link>
  </LinkStyle>)
}

export const GroupLinkRenderer = (params) => (
  <LinkStyle>
    <Link to={`${params.prefix}/${params.data?.id}`}>{params.value}</Link>
  </LinkStyle>
)

export const IconRenderer = (params) => {
  if (!params.value) return ''
  return (
    <IconStyle>
      <img src={`${params.value}`} />
    </IconStyle>
  )
}

export const UserNameFromUserRenderer = (params) => {
  const user = params.data.user
  const name = `${user?.firstName?.toUpperCase() || ''} ${user.lastName?.toUpperCase() || ''} <${user.email}>`
  return <div>{name}</div>
}

export const CurrencyRenderer = (params) => <AlignRight>{
  (params?.value !== undefined && params?.value !== null) ? currencyFormatter.format(params?.value || 0) : ''
}</AlignRight>

export const CommaNumRenderer = (params) => (
  <AlignRight>{params?.value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''}</AlignRight>
)

export const relativeDate = (dte) => {
  const tz = moment.tz.guess()
  const d = moment.tz(dte, tz)
  return d.format('YYYY MM DD hh:mm:ss a')
}
// https://ag-grid.com/react-data-grid/value-formatters/#value-formatter-vs-cell-renderer
export const DateValueFormatter = (params) => (params.value ? relativeDate(params.value) : '')

export const AgeValueFormatter = (params) => {
  if (params.value) {
    const now = moment()
    const d = moment.tz(params.value, 'America/New_York')
    let txt = now.diff(d, 'days')
    return txt ? `${txt} days` : `${now.diff(d, 'hours')} hours`
  }
  return ''
}

export const JSONObjectRenderer = (params) => (params?.value ? JSON.stringify(params.value) : '')

// properties.location
export const LocationValueGetter = (params) =>
  `${params?.data?.location?.lat || ''}, ${params?.data?.location?.lon || ''}`

// users.geoip.location
export const GeoValueGetter = (params) =>
  `${params?.data?.geoip?.latitude || ''}, ${params?.data?.geoip?.longitude || ''}`

export const LocationRenderer = (params) => {
  const loc = params.getValue()

  if (!loc) return ''

  const lat = loc.lat
  const lng = loc.lng || loc.lon

  if (!lat && !lng) return ''
  if (!lat) return `${lng}`
  if (!lng) return `${lat}`
  return `${lat}, ${lng}`
}

export const BooleanYesNoRenderer = (params) => {
  const bool = params.getValue()
  if (bool === undefined) return ''
  return !!bool ? 'Yes' : 'No'
}

export const getSubscriberLogo = (sub) => {
  let icon
  if (sub?.indexOf('google') !== -1) {
    icon = googleLogo
  }
  if (sub?.indexOf('auth0') !== -1) {
    icon = dbLogo
  }
  if (sub?.indexOf('apple') !== -1) {
    icon = appleLogo
  }
  return icon
}

export const SubscriberRenderer = (params) => {
  const source = params.value?.split('|')[0] || ''
  const icon = getSubscriberLogo(source)
  if (!icon) {
    return <span>??</span>
  }
  return <span><img src={icon} width={12} /></span>
}

export const GroupsCitiesGroupLinkRenderer = (params) => (
  <LinkStyle>
    <Link to={`${params.prefix}/${params.data?.groupId}`}>{params.value}</Link>
  </LinkStyle>
)

export const LicensesGroupLinkRenderer = (params) => (
  <LinkStyle>
    <Link to={`${params.prefix}/${params.data?.groupId}/zipcodes`}>{params.value}</Link>
  </LinkStyle>
)

export const ZipLinkRenderer = (params) => (
  <LinkStyle>
    <Link to={`/admin/zipcode/${params.data?.zipcode}`}>
      {params.data?.zipcode}
    </Link>
  </LinkStyle>
)

export const CityLinkRenderer = (params) => (
  <LinkStyle>
    <Link to={`/admin/city/${params.data?.state.toLowerCase()}/${params.data?.city.toLowerCase()}`}>
      {startCase(params.data?.city)}
    </Link>
  </LinkStyle>
)

export const DeleteActionRenderer = ({ onDelete, data }) => (
  <DeleteIcon icon={faTrashCan} onClick={() => onDelete(data.id)} />
)

export const MappedCellRenderer =
  (mapping = {}) =>
  (params) =>
    <>{mapping[params.value] || params.value}</>

export const InviteActionsCellRenderer = ({ onToggleDelete, onToggleAdmin, data: { id, isDeleted, isAdmin } = {} }) => {
  if (!id) return ''

  const handleToggleDelete = () => onToggleDelete(id, !isDeleted)
  const handleToggleAdmin = () => onToggleAdmin(id, !isAdmin)

  return (
    <>
      {isDeleted ? (
        <UndeleteActionIcon onClick={handleToggleDelete} />
      ) : (
        <DeleteActionIcon onClick={handleToggleDelete} />
      )}

      {isAdmin ? (
        <UnsetAdminActionIcon onClick={handleToggleAdmin} />
      ) : (
        <SetAdminActionIcon onClick={handleToggleAdmin} />
      )}
    </>
  )
}

export const IpAddressRenderer = (params) => {
  if (!params.value) return <Greyed>Anonymous</Greyed>
    if (params.value === '::1') return <Greyed>Developer</Greyed>

    return params.value
}

const Greyed = styled.p`
  color: lightgrey
`

export const restoreGridState = (sortState, gridRef) => {
    if (sortState && gridRef) {
      setTimeout(() => {
        gridRef.current?.api.applyColumnState({
          state: sortState,
          defaultState: { sort: null },
          applyOrder: true,
        })
      }, 1000)
    }
}
const IconWrapper = styled.span.attrs({ className: 'icon-wrapper' })`
  position: relative;
  margin-right: 0.25em;
  font-size: 16px;
  cursor: pointer;
  ${(props) =>
    props.color
      ? css`
          color: ${props.color};
        `
      : ''}
  ${(props) =>
    props.embellish
      ? css`
          :after {
            content: '${props.embellish}';
            position: absolute;
            top: 0;
            left: 0;
            height: 1em;
            font-size: 90%;
            font-weight: bold;
            line-height: 1.66em;
            color: #000;
            text-shadow: -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, 1px 1px 0 #fff;
          }
        `
      : ''};
`

const Icon = styled(FontAwesomeIcon).attrs({ className: 'icon' })``

const DeleteIcon = styled(Icon)`
  color: red;
`

const DeleteActionIcon = styled(IconWrapper).attrs({
  title: 'Delete',
  color: 'red',
  children: <Icon icon={faTrashCan} />,
})``

const UndeleteActionIcon = styled(IconWrapper).attrs({
  title: 'Undelete',
  color: '#ff4d4d',
  children: <Icon icon={faTrashCan} />,
  embellish: '↶',
})``

const SetAdminActionIcon = styled(IconWrapper).attrs({
  title: 'Set admin only flag',
  color: '#3c6dd9',
  children: <Icon icon={faLock} />,
})``

const UnsetAdminActionIcon = styled(IconWrapper).attrs({
  title: 'Clear admin only flag',
  color: '#85a8f2',
  children: <Icon icon={faUnlock} />,
})``
