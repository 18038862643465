import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { InvitesGridComponent } from '../../components'
import Loading from '../../components/Loading'

const ListInvites = () => {
  return <InvitesGridComponent />
}

export default withAuthenticationRequired(ListInvites, {
  onRedirecting: () => <Loading />,
})
