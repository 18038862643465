import React, { useCallback, useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

import { useSessionStorage } from '../../lib/utils'
import { StandardColumnDefs, StandardSideBar } from '../CommonGrid'
import { restoreGridState } from './GridRenderers'
import { GridProps } from './grid-props.type'

const gridModules = [ServerSideRowModelModule]

/**
 * Standard server-side row model AG Grid component
 */
export const GridComponent: React.FC<GridProps> = (props) => {
  const [colState, setColState] = useSessionStorage(props.sessionKey, '')

  const onGridReady = useCallback(() => restoreGridState(colState, props.gridRef), [])
  const saveGridState = (params) => setColState(params.api.getColumnState())
  const defaultColDef = useMemo(() => StandardColumnDefs, [])
  const sideBar = useMemo(() => StandardSideBar, [])

  return (
    <AgGridReact
      {...props}
      ref={props.gridRef}
      modules={gridModules}
      onGridReady={onGridReady}
      onSortChanged={saveGridState}
      onColumnMoved={saveGridState}
      onColumnResized={saveGridState}
      onDisplayedColumnsChanged={saveGridState}
      animateRows={true}
      defaultColDef={defaultColDef}
      maintainColumnOrder={true}
      rowModelType="serverSide"
      sideBar={sideBar}
    />
  )
}
