import React from 'react'

import { User } from '@bluebid-sdk/core'

import { AssetsGridComponent } from '../assets'

export const UserAssets: React.FC<{ user: User }> = ({ user }) => {
  if (!user?.id) {
    return
  }

  return (
    <AssetsGridComponent
      userId={user.id}
      columnOrder={['Asset Id', 'Used by Property', 'Image Info', 'Created / Modified']}
      sessionKey="userassets"
      title=""
    />
  )
}
