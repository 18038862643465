import styled from 'styled-components'
import { sizes } from '../../utils/responsive'

export const MessageBubble = ({ key, children }) => {
    return (
        <MessageBubbleDiv key={key}>
            {children}
        </MessageBubbleDiv>
    )
}

export const MessageBubbleDiv = styled.div.attrs({
    className: 'message-bubble-div',
})`
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;

  .bubble-div {
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    max-width: 75%;
    padding: 0.25rem .875rem;
    position: relative;
    word-wrap: break-word;

    @media (max-width: ${sizes.tabletXL}) {
      max-width: 90%;
    }
    @media (max-width: ${sizes.tabletL}) {
      max-width: 100%;
    }
  }

  .bubble-div::before,
  .bubble-div::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }

  div.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff;
  }

  div.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  div.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  .bubble-div[class^="from-"] {
    // margin: 0.5rem 0;
    width: fit-content;
  }

  div.from-me ~ div.from-me {
    margin: 0.25rem 0 0;
  }

  div.from-me ~ div.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }

  div.from-me ~ div.from-me:last-child {
    margin-bottom: 0.5rem;
  }

  div.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }

  div.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  div.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  .bubble-div[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
  }

  .bubble-div[class^="from-"].emoji::before {
    content: none;
  }

  .no-tail::before {
    display: none;
  }
`
