import React from 'react'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { White } from '../../constants/colors'

export const GridListHeader: React.FC<React.PropsWithChildren<{ title: string; size?: 'sm' | 'md', onRefresh?: () => void }>> = ({
  title,
  size,
  onRefresh,
  children,
}) => {
  return (
    <HeaderContainer>
      <HeaderTitle>
        { (!size || size === 'md') && <h3>{title}</h3> }
        { (size === 'sm') && <h5>{title}</h5> }
      </HeaderTitle>
      <HeaderControls>
        <HeaderDivider />

        {children}

        {onRefresh && <HeaderActionIcon icon={faSyncAlt} onClick={() => onRefresh()} />}
      </HeaderControls>
    </HeaderContainer>
  )
}

export const HeaderActionIcon = styled(FontAwesomeIcon)`
  font-size: 2.5em;
  margin: 0 4px;
  color: #005ce0;
  cursor: pointer;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`

const HeaderTitle = styled.div`
  //width: 200px;
  //padding: 0 20px 0 0;
  //font-weight: bold;
`

const HeaderControls = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0 20px 0 0;
`
const HeaderDivider = styled.div`
  margin-left: auto;
  width: 0;
`
