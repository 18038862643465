import { getPropertyDetail } from '../lib/data/bluebidData'
import React, { useEffect, useState } from 'react'
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import styled from 'styled-components'
import { ATOMDataViewer } from './ATOMDataViewer'
import { VPad } from './EditDetails'
import { AdminActionButton } from './adminActions/common'


/**
 * ATOM Data retriever / viewer
 * @param address - the address to get from ATOM
 * @param onSelect - callback when user selected 'use data'
 * @param onCancel - cancel callback
 * @param checkOnly - set to true to disable callbacks and only display the info
 */
export const ATOMDataBrowser = ({address, onSelect, onCancel, checkOnly}) => {
    const [propertyData, setPropertyData] = useState()
    const [overwrite, setOverwrite] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getPropertyInfo()
    }, [])

    /**
     * Attempt to get property detail info
     * *
     * @returns {Promise<void>}
     */
    const getPropertyInfo = async () => {
        setPropertyData()
        setIsLoading(true)
        let result = await getPropertyDetail(address)
        if (result) {
            // remove this if present
            delete result.resolvedWith;
            setPropertyData(result)
        } else {
            setPropertyData()
        }
        setIsLoading(false)
    }

    const toggleOverwrite = () => {
        setOverwrite(!overwrite)
    }

    const selectData = () => {
        onSelect(propertyData, overwrite)
    }

    return (
        <Container>
            <Row>
                {!checkOnly &&
                    <Col md={4}>
                        <AdminActionButton disabled={!propertyData} className={""} onClick={selectData}>
                            Use Data
                        </AdminActionButton>
                        <CheckboxGroup check inline>
                            <Label check>
                                <Input type="checkbox" onChange={toggleOverwrite} checked={overwrite} />
                                Overwrite existing data
                            </Label>
                        </CheckboxGroup>
                    </Col>
                }
                <Col md={4} >
                    <AdminActionButton className={""} onClick={onCancel}>
                        {checkOnly ? 'Ok' : 'Cancel'}
                    </AdminActionButton>
                </Col>
            </Row>
            <VPad height={20} />
            <Row>
                <Col>
                    <ATOMDataViewer isLoading={isLoading} data={propertyData} />
                </Col>
            </Row>
        </Container>
    )
}

const CheckboxGroup = styled(FormGroup)`
    //padding: 10px 0 10px 0;
    //margin-top: 10px;
    //font-size: 1rem;
    //float: right;
    input {
    }
    label {
        color: light-grey;
    }
`

