import React from 'react'
import { Table } from 'reactstrap'
import { Delta, getDiff } from 'json-difference'
import { sanitizeHtmlId, variableToSentenceTitleCase } from '../../../lib/utils'
import { Tag } from './common'

type ComparableValueTypes = string | number | boolean | null | undefined
type Comparable = ComparableValueTypes | Record<string, ComparableValueTypes>

type ValueDelta = {
  newValue: Comparable
  oldValue: Comparable
}

const isPrimitive = (value: unknown): value is ComparableValueTypes => 
  typeof value !== 'object' && value !== null

const isObject = (value: unknown): value is Record<string, ComparableValueTypes> => 
  typeof value === 'object' && value !== null

/**
 * wrapper around json-difference; it only handles objects, this extends it to
 * accept primitives without wrapping those primitives in objects. 
 * 
 * @returns
 * - an empty Delta if oldValue and newValue are not compatible types for comparison
 * - a Delta describing changes
 */
const diff = (oldValue: Comparable, newValue: Comparable): Delta => {
  const emptyDelta: Delta = {
    added: [],
    removed: [],
    edited: [],
  }

  if (isPrimitive(oldValue) && isPrimitive(newValue)) {
    if (oldValue === newValue)
      return emptyDelta;

    if (oldValue === null || oldValue === undefined)
      return { ...emptyDelta, added: [['value', newValue]] }

    if (newValue === null || newValue === undefined)
      return { ...emptyDelta, removed: [['value', oldValue]] }

    return { ...emptyDelta, edited: [['value', oldValue, newValue]] }
  }

  if (isPrimitive(oldValue) && isObject(newValue))
    return { ...emptyDelta, added: Object.entries(newValue) }
  
  if (isObject(oldValue) && isPrimitive(newValue))
    return { ...emptyDelta, removed: Object.entries(oldValue) }

  if (isObject(oldValue) && isObject(newValue))
    return getDiff(oldValue, newValue)

  return emptyDelta
}

export const ModifiedValue: React.FC<ValueDelta> = ({ oldValue, newValue }) => {
  const delta = diff(oldValue, newValue)

  const isDeltaEmpty = 
    delta.added.length + delta.removed.length + delta.edited.length === 0

  if (isDeltaEmpty)
    return <p>No changes detected (item was "modified" but values were not changed.)</p>

  return (
    <div>
      <Table bordered>
        <thead>
          <tr>
            <th>Action</th>
            <th>Field Name</th>
            <th>Old Value</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
          {['added', 'removed', 'edited'].map(type => {
            switch (type) {
              case 'added':
                return delta.added.map(([field, value]) => (
                  <tr key={sanitizeHtmlId(field)}>
                    <td width="50">Added</td>
                    <td>{variableToSentenceTitleCase(field)}</td>
                    <td></td>
                    <td><Tag text={value} variant="added" /></td>
                  </tr>
                ))
              case 'removed':
                return delta.removed.map(([field, value]) => (
                  <tr key={sanitizeHtmlId(field)}>
                    <td width="50">Removed</td>
                    <td>{variableToSentenceTitleCase(field)}</td>
                    <td><Tag text={value} variant="removed" /></td>
                    <td></td>
                  </tr>
                ))
              case 'edited':
                return delta.edited.map(([field, oldValue, newValue]) => (
                  <tr key={sanitizeHtmlId(field)}>
                    <td width="50">Edited</td>
                    <td>{variableToSentenceTitleCase(field)}</td>
                    <td><Tag text={oldValue} variant="removed" /></td>
                    <td><Tag text={newValue} variant="added" /></td>
                  </tr>
                ))
              default:
                return null
            }
          })}
        </tbody>
      </Table>
    </div>
  )
}

export const modifiedValueSummary = ({ oldValue, newValue }: ValueDelta) => {
  const delta = diff(oldValue, newValue)
  const descriptions = []

  // most common case is just one edit from V1->V2, shortcut that here
  if (delta.added.length + delta.removed.length === 0 && delta.edited.length) {
    const [field, oldValue, newValue] = delta.edited[0]
    const fieldDisplay = field === 'value' ? '' : `${variableToSentenceTitleCase(field)}: `
    return `${fieldDisplay}${oldValue} -> ${newValue}`
  }

  if (delta.added.length)
    descriptions.push(`A:${delta.added.map(([field, value]) => `${field}:${value}`).join(',')}`)

  if (delta.removed.length)
    descriptions.push(`R:${delta.removed.map(([field, value]) => `${field}:${value}`).join(',')}`)

  if (delta.edited.length)
    descriptions.push(`E:${delta.edited.map(([field, oldValue, newValue]) => `${field}:${oldValue}->${newValue}`).join(',')}`)

  if (descriptions.length === 0)
    return 'No changes detected'

  return descriptions.join('|')
}