import React from 'react'
import { Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import { currencyFormatter } from '../lib/utils'
import { JsonView, defaultStyles } from 'react-json-view-lite';
import { VPad } from './EditDetails'
import 'react-json-view-lite/dist/index.css';

export const ATOMDataViewer = ({isLoading, data}) => {

    // data = result
    if (!data || isLoading) {
        return <>
            <Card style={{ width: '100%' }}>
                <CardBody>
                    <LoadText>{isLoading ?
                        'Attempting to get data from ATOM...'
                        : 'No data found for address'
                    }</LoadText>
                </CardBody>
            </Card>
        </>
    }

    let address = `${data?.address?.formatted_street_address},${data?.address?.city},${data?.address?.state}`
    return <>
        <Container>
            <Row>
                <DataAddress address={address} data={data} />
            </Row>
            <Row>
                <DataHighlights data={data} />
            </Row>
            <Row>
                <Card style={{ width: '100%' }}>
                    <Row>
                        <h5>Full Data Details</h5>
                    </Row>
                    <Row>
                        <JsonView data={data} shouldExpandNode={() => false} style={defaultStyles} />
                    </Row>
                </Card>
            </Row>
        </Container>
    </>
}
const LoadText = styled(CardText)`
    font-size: 14pt;
`

const DataAddress = ({data, address}) => {
    return <>
        <Card style={{ width: '100%' }}>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <CardTitle tag="h5">
                                    {data?.address?.formatted_street_address}
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 text-muted"
                                    tag="h6"
                                >
                                    {data?.address?.city}, {data?.address?.state} {data?.address?.zip_code}
                                </CardSubtitle>
                            </Col>
                        </Row>
                        <VPad height={20} />
                        <Row>
                            <Col md={12}>
                                <CardText>
                                    <b>Valuation:</b> {currencyFormatter.format(data?.valuation?.value)}
                                </CardText>
                                <CardText>
                                    <b>Range:</b> {currencyFormatter.format(data?.valuation?.high)} - {currencyFormatter.format(data?.valuation?.low)}
                                </CardText>
                                <CardText>
                                    <b>Last Updated:</b> {data?.metadata?.publishing_date}
                                </CardText>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <GoogleMapWithPin address={address} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </>
}

const DataHighlights = ({data}) => {
    return <>
        <Card style={{ width: '100%' }}>
            <Row>
                <h5>Property Highlights</h5>
            </Row>
            <DataRow>
                <DataCol md={4}>
                    <DataTitle>Bedrooms</DataTitle>
                    <DataVal>{data?.structure?.beds_count}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Baths</DataTitle>
                    <DataVal>{data?.structure?.baths}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Partial Baths</DataTitle>
                    <DataVal>{data?.structure?.partial_baths_count}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Sq. Footage</DataTitle>
                    <DataVal>{data?.structure?.total_area_sq_ft}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Year Built</DataTitle>
                    <DataVal>{data?.structure?.year_built}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Heating Type</DataTitle>
                    <DataVal>{data?.structure?.heating_type}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Fuel</DataTitle>
                    <DataVal>{data?.structure?.heating_fuel_type}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Architecture</DataTitle>
                    <DataVal>{data?.structure?.architecture_type}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Parking Type</DataTitle>
                    <DataVal>{data?.structure?.parking_type}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Construction Type</DataTitle>
                    <DataVal>{data?.structure?.construction_type}</DataVal>
                </DataCol>
                <DataCol md={4}>
                    <DataTitle>Condition</DataTitle>
                    <DataVal>{data?.structure?.condition}</DataVal>
                </DataCol>
            </DataRow>

        </Card>
    </>
}

export const GoogleMapWithPin = ({address}) => {
    return (
        <MapContainer>
            <iframe
                width="100%"
                height="300"
                style={{border:0}}
                loading="lazy"
                allowFullScreen
                referrerPolicy={"no-referrer-when-downgrade"}
                src = {`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${address}`}
            >
            </iframe>
        </MapContainer>
    )
}

const DataRow = styled(Row)`
`
const DataCol = styled(Col)`
    font-family: 'Montserrat';
    font-size: 10pt;
`
const DataTitle = styled.p`
    font-weight: 600;
    margin: 0;
    padding: 5px 0 0 5px;
`
const DataVal = styled.p`
    margin: 0;
    padding: 5px 0 15px 5px;
    text-transform: lowercase;
    :first-letter {
        text-transform: capitalize;
    }
`

const MapContainer = styled.div`
    // height: 300px;
    width: 100%;
    // padding: 10px 0;
`

