import React, { useEffect, useState } from 'react'
import { CardBody, CardTitle } from 'reactstrap'
import { AdminCard } from './Styles'
import { findTargetLicense } from '../../../lib/data/bluebidData'
import { InquiriesGridComponent } from '../../../components'

export const AdminListInquiries = ({ owner, property, onSend = () => {} }) => {
  const [licenseId, setLicenseId] = useState()
  const [showReferralInfo, setShowReferralInfo] = useState(false)

  const refreshLicense = () => {
    if (!property) {
      return
    }
    
    findTargetLicense({ propertyId: property.propertyId, address: property.address })
      .then(res => { 
        if (res?.license?.id) {
          setLicenseId(res.license.id)
        }
        setShowReferralInfo(res.license?.channels?.includes('anywhere'))
      })
  }

  useEffect(() => {
    refreshLicense()
  }, [])

  return <AdminCard>
    <CardBody style={{ height: '250px' }}>
      <InquiriesGridComponent
        licenseId={licenseId}
        condensed={true}
        query={`propertyId.keyword:${property?.propertyId}`}
        showReferralInfo={showReferralInfo}
        onSend={onSend}
      />
    </CardBody>
  </AdminCard>
}

