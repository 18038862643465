import React, { PropsWithChildren } from 'react'
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

export const CollapseableCard: React.FC<
  PropsWithChildren<{ title: string; isCollapsed?: boolean; toggleCollapse?: () => void }>
> = ({ title, isCollapsed = false, toggleCollapse, children }) => {
  return (
    <Card className="mb-4">
      <CardHeader className="clickable" tag="h5" onClick={() => toggleCollapse?.()}>
        {title}
        <FontAwesomeIcon className="float-end text-black-50" icon={!isCollapsed ? faCaretUp : faCaretDown} />
      </CardHeader>

      <Collapse isOpen={!isCollapsed}>
        <CardBody style={{ height: '500px', overflow: 'auto' }}>{children}</CardBody>
      </Collapse>
    </Card>
  )
}
