import { calculateTotalBath } from '../utils/common'
import React from 'react'
import { numberFormatter } from '../lib/utils'
import { Col, Row } from 'reactstrap'
import { BasicPropertyStatsColumn, BasicPropertyStatsLabel, EstimateRow, BasicPropertyStatsValue, VerticalBar } from './detail/commonStyles'
import styled from 'styled-components'

/**
 * For claimed homes for which we have property detail information, this will contain the Beds, Baths, etc etc.
 * For unclaimed homes it will show a generic label
 * @param property
 * @param color - override color, default is black
 * @param tiled - true if used in a tiled display
 * @returns {JSX.Element}
 * @constructor
 */

export const BedsBathsSqFt = ({property, tiled, shadow}) => {

    if (property.detail) {
        const bathCounts = calculateTotalBath(property.detail?.structure?.baths, property.detail?.structure?.partial_baths_count);
        return  (
            <React.Fragment>
                <EstimateRow className="mt-2 ml-2">
                    <BasicPropertyStatsColumn tiled={tiled}>
                        <LeftText>
                            <BasicPropertyStatsValue shadow={shadow} tiled={tiled}>{property.detail?.structure?.beds_count || '-'}</BasicPropertyStatsValue>
                            <BasicPropertyStatsLabel shadow={shadow} tiled={tiled}>Beds</BasicPropertyStatsLabel>
                        </LeftText>

                        <VerticalBar />

                        <LeftText>
                            <BasicPropertyStatsValue shadow={shadow} tiled={tiled}>{bathCounts || '-'}</BasicPropertyStatsValue>
                            <BasicPropertyStatsLabel shadow={shadow} tiled={tiled}>Baths</BasicPropertyStatsLabel>
                        </LeftText>

                        <VerticalBar />

                        <LeftText>
                            <BasicPropertyStatsValue shadow={shadow} tiled={tiled}>{
                                property.detail?.structure?.total_area_sq_ft ? numberFormatter(property.detail?.structure?.total_area_sq_ft) : '-'
                            }</BasicPropertyStatsValue>
                            <BasicPropertyStatsLabel shadow={shadow} tiled={tiled}>Sqft</BasicPropertyStatsLabel>
                        </LeftText>

                    </BasicPropertyStatsColumn>
                </EstimateRow>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Row className="mt-2 ml-2">
                    <UnclaimedLabelColumn>Unclaimed property</UnclaimedLabelColumn>
                </Row>
            </React.Fragment>
        )
    }
}

const LeftText = styled.div`
    display: inline-block;
`
const UnclaimedLabelColumn = styled(Col)`
`
