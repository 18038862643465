import React, { useState } from "react";
import { SecondaryButton, PrimaryButton } from "../actions/common";
import { AuthContext } from "../../constants/context";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import { deleteImages } from "../../lib/data/bluebidData";
import styled from "styled-components";
import BlockUI from "../BlockUI";
import { errorToast, successToast } from "../../utils/common";
import { TagManager } from '../../lib/gtm/TagManager'

export const DialogFooterBlock = styled.div`
  justify-content: center;
`

export const ModalSubHeader = styled.h5`
    font-size: 1.1rem;
    font-weight: 400;
`

export const CenteredModalFooter = styled(ModalFooter)`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
`

export const AdminManagePhotosAction = ({message, assets, shouldUpdateProfile}) => {
    const { bluebidUser, signIn } = React.useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [modal, setModal] = useState(false)

    const [selectedImages, setSelectImages] = useState([])
    const [note, setNote] = useState('Content has been removed.')

    const toggle = () => setModal(!modal)

    const managePhotosAction = () => {
        if (!bluebidUser) {
            signIn();
        } else {
            toggle()
            if (!modal) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'pageview',
                        page_location: window.location.pathname + '/manageDeleteMsgImages',
                    }
                })
            }
        }
    }

    const resetPhotos = () => {
        toggle();
        setSelectImages([])
    }

    const selectAll = () => {
        const selectedImages = assets?.map(x => x.url);
        setSelectImages(selectedImages)
    }

    const onCheckImg = (e, astUrl) => {
        let updatedSelectedImages = selectedImages
        if (e.target.checked) {
            if (!updatedSelectedImages.includes(astUrl)) {
                updatedSelectedImages.push(astUrl)
            }
        } else {
            if (updatedSelectedImages.includes(astUrl)) {
                updatedSelectedImages = updatedSelectedImages.filter(x => x !== astUrl)
            }
        }
        setSelectImages([...updatedSelectedImages])
    }

    const deletePhotos = () => {
        if (selectedImages?.length) {
            deleteImages(message?.id, selectedImages, note).then((result)=> {
                if (result.statusCode && result.statusCode >= 400) {
                    console.error({result});
                    setIsLoading(false);
                    errorToast(result.errorMessage);
                   } else {
                    successToast('Selected assets deleted!')
                    setTimeout(() => {
                        shouldUpdateProfile()
                    }, 1000);
                    resetPhotos()
                   }
            }).catch((err) => {
                errorToast(`something went wrong!`)
            })
        } else {
            errorToast(`please select at least one asset!`)
        }
    }

    return (<>
        <ManagePhotosBtn className='btn btn-primary' disabled={!assets?.length} onClick={managePhotosAction}>manage</ManagePhotosBtn>
        <Modal size="lg" isOpen={modal}
            toggle={toggle}
            modalTransition={{ timeout: 200 }}>
            <BlockUI blocking={isLoading} />

            <ModalHeader>
                <div>
                    <h3>Manage and Delete Photos</h3>
                    {/* <ModalSubHeader>Click and drag to re-arrange the order of your home images or click X to remove them.</ModalSubHeader> */}
                </div>
            </ModalHeader>
            <ModalBody>
                <SelectAll href={undefined} disabled={isLoading} onClick={selectAll}>Select All</SelectAll>

                <Row>
                    {assets && assets.length && assets.map((ast, ndx) => (
                        <Col xs={12} md={4}>
                            <ThumbBox>
                                <FormGroup check inline>
                                        <Input type="checkbox"
                                               checked={selectedImages.includes(ast.url) ? true : false}
                                               onChange={(e) => onCheckImg(e, ast.url)}/>
                                        <img className="file-img" alt={`img - ${ast.id}`} src={ast.url} />
                                </FormGroup>
                                <Index>{ndx + 1}</Index>
                            </ThumbBox>
                        </Col>
                    ))}
                    <Col xs={12}>
                        {selectedImages && assets && selectedImages.length === assets.length &&
                            <>
                                <NotesLabel>Replacement Message:</NotesLabel>
                                <Input id="exampleText" name="text" type="textarea"
                                       placeholder="Fallback Note..." value={note}
                                       onChange={(e) => setNote(e.target.value)}/>
                            </>
                        }
                    </Col>
                </Row>
            </ModalBody>
            <CenteredModalFooter>
                <DialogFooterBlock>
                    <PrimaryButton className={"btn-margin"} disabled={isLoading || !selectedImages?.length} onClick={deletePhotos}>Delete</PrimaryButton>
                    <SecondaryButton className={"btn-margin"} disabled={isLoading} onClick={resetPhotos}>Cancel</SecondaryButton>
                </DialogFooterBlock>
            </CenteredModalFooter>
        </Modal>
    </>)
}

const ManagePhotosBtn = styled(Button)`
  position: absolute;
  right: -10px;
  z-index: 11;
  background: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  padding: 0px 5px;
  border: 1px solid #ddd;
  top: -8px;
  color: #248bf5;;
`
const ThumbBox = styled.div.attrs({
    className: "file-item"
})`
    position: relative;
    border-radius: 2px;
    margin: 10px;
    padding: 4px;
    box-sizing: border-box;
    overflow: hidden;

    img {
        width: 190px;
        height: 140px;
    }
    width: 200px;
    height: 150px !important;
`
const Index = styled.div`
  color: #007fff;
  right: 10px;
  position: absolute;
  font-size: 20px;
  font-weight: 600;
  padding: 0 6px;
  display: block;
  height: 25px;
  width: 25px;
  background: #fff;
  bottom: 5px;
`
const SelectAll = styled.a`
    color: var(--bs-link-color) !important;
    cursor: pointer;
`
const NotesLabel = styled(Label)`
    font-size: 0.75rem;
    margin: 0px;
`
