import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { unclaimProperty } from '../../lib/data/bluebidData'
import { successToast } from '../../utils/common'
import { useNavigate } from 'react-router-dom'
import { AdminSecondaryActionButton } from './common'
import styled from 'styled-components'

export const UnclaimPropertyAction:React.FC<{
  propertyId: string
  redirect?: string
  afterAction: (string, any) => void
  iconOnly?: boolean
}> = ({ propertyId, redirect, afterAction, iconOnly }) => {
    const navigate = useNavigate()

    const unclaimAction = async () => {
        if (window.confirm('Are you sure?\n\nThis does not delete the property, but makes it available to be claimed again. The unclaimed property will become owned by the Bluebid Admin user and will not appear on the map.')) {
            unclaimProperty(propertyId)
                .then(result => {
                    console.log(result)
                    successToast('Property has been unclaimed')
                    if (redirect) {
                        navigate(redirect);
                    }
                    if (afterAction) {
                        afterAction(propertyId, result)
                    }
                })
        }
    }

  if (iconOnly) {
    return <YellowIcon onClick={unclaimAction} icon={faMinus} />
  }

    return <AdminSecondaryActionButton className={'btn-warning'} onClick={unclaimAction} >
        <FontAwesomeIcon icon={faMinus} /> Unclaim
    </AdminSecondaryActionButton>
}

const YellowIcon = styled(FontAwesomeIcon)`
    color: orange;
    margin-left: 5px;
`
