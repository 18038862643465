import React, { Table } from 'reactstrap'

import { stringifyPropertyFilters } from '@bluebid-sdk/core'

import { useListingAlerts } from '../hooks/useListingAlerts'
import { relativeDate } from '../../../lib/utils'

export const ListUserListingAlerts = ({header, userId}) => {
  const { alerts } = useListingAlerts(userId)

  return (
    <div className="listing-alerts">
      {header}

      {!alerts.length && <p className="text-center">No listing alerts created</p>}
      {!!alerts.length && (
        <Table striped className="text-start">
          <thead>
            <tr>
              <th>Name</th>
              <th>City/Town</th>
              <th>State</th>
              <th>Filters</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {alerts.map((claimAlert) => (
              <tr key={claimAlert.id}>
                <td>{claimAlert.name}</td>
                <td>{claimAlert.filter.city}</td>
                <td>{claimAlert.filter.state}</td>
                <td>{stringifyPropertyFilters(claimAlert.filter, true)}</td>
                <td>{relativeDate(claimAlert.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  )

}
