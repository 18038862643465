import { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'
import { MapAddressBrowser } from '../MapAddressBrowser'
import { updateLocation } from '../../lib/data/bluebidData'
import { successToast } from '../../utils/common'
import { AdminSecondaryActionButton } from './common'

export const SetLocationAction = ({propertyId, location}) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const onAction = async (data) => {
        toggle()
        // update the location
        const newLocation = {
            latitude: data.latitude,
            longitude: data.longitude
        }
        let updated = await updateLocation(propertyId, newLocation)
        console.log('update location', updated)
        successToast('Location updated')
    }

    return <>
        <AdminSecondaryActionButton onClick={toggle}>
            <FontAwesomeIcon icon={faSearchLocation} /> Set Location
        </AdminSecondaryActionButton>
        <Modal isOpen={modal} toggle={toggle} size={'xl'}>
            <ModalBody>
                <MapAddressBrowser location={location}
                                   locationOnly={true}
                                   onUseAddress={onAction} onCancel={toggle}/>
            </ModalBody>
        </Modal>
    </>
}
