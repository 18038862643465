import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Label, Modal, ModalBody} from "reactstrap";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";

import {CustomButton, SecondaryButton, PrimaryButton} from "./common";
import { counterOffer, getPropertyByIdAPI } from '../../lib/data/bluebidData'
import { errorToast, successToast } from "../../utils/common";
import { currencyFormatter, relativeDate } from '../../lib/utils'
import BlockUI from "../BlockUI";
import { OfferDetailsBlock, OfferTitle } from './SubmitBidAction'
import { TagManager } from '../../lib/gtm/TagManager'
import { OfferDetailsLabel, OfferDetailsValue } from '../detail/common'
import { contingentChoices, financeKeyToText } from '../offers/choices'

const TextRegularHeader = styled.label`
    color: #4f4f4f;
    font-size: 16px;
    font-family: Roboto-Regular,sans-serif;
    margin-top: 5px;
    align-self: center;
    width: 100%;
`
const PayLabel = styled(Label)`
    margin-left: 10px;
    position: relative;
    top: 5px;
`
const PayInput = styled(NumericFormat)`
    width: 100%;
    height: 2.8rem;
    background: #f5f5f5;
    outline: none;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
`
const DialogFooterBlock = styled.div`
    margin: 20px 0 0 0;
    padding: 0 10px;
`
export const CounterOfferAction = ({messageDetail, user, showMessageList}) => {

    const inputReference = useRef();
    const [isLoading, setIsLoading] = useState(false)
    const [counterModal, setCounterModal] = useState(false)
    const [choosenContingent, setChosenContingent] = useState(new Array(contingentChoices.length).fill(false))
    const [paymentAmount, setPaymentAmount] = useState("")
    const isPropertyOwner = messageDetail?.data?.property?.ownerId === user?.id
    const counterToggle = () => setCounterModal(!counterModal)
    const [property, setProperty] = useState()

    useEffect(() => {
        if (counterModal) {
            setTimeout(() => {
                inputReference.current && inputReference.current.focus()
            }, 0);
        }
        getPropertyByIdAPI(messageDetail.propertyId).then(r => setProperty(r))
    }, [counterModal])

    const sendCounterOffer = async () => {
        setIsLoading(true);
        let keyArray = contingentChoices.filter((x, index) => choosenContingent[index]).map(x => x.value)
        await counterOffer({
            propertyId: messageDetail.data.offer.propertyId,
            bidderId: user.id,
            bidAmount: paymentAmount,
            contingencies: keyArray,
            offerId: messageDetail.data.offer.id
        }).then(result => {
            // console.log('Result countered', result);
            if ((result.statusCode && result.statusCode >= 400) || (result.status && result.status === 'error')) {
                console.error({result});
                setIsLoading(false);
                errorToast(result.errorMessage);
            } else {

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'offer_countered',
                        category: 'Bluebid',
                        action: `${isPropertyOwner ? 'Owner' : 'Buyer'} countered Bluebid!`,
                        label: messageDetail?.address
                    }
                })

                setIsLoading(false);
                counterToggle();
                resetCounterBid();
                showMessageList();
                successToast(`Your Counter has been sent!`);
                // history.push('/');
               }
        })
    }

    const resetCounterBid = () => {
        setPaymentAmount('');
        setChosenContingent(new Array(contingentChoices.length).fill(false));
        counterToggle();
    }

    // extract the contingent from list
    const ContingentList = ({ list }) => {
        const contingents = contingentChoices.filter(x => list.includes(x.value));
        if (!list.length) {
            return 'None'
        }
        return (
            contingents.map(contingent =>
                <TextRegularHeader className="text-right" key={contingent.value}>{contingent.label}</TextRegularHeader>
            )
        )
    }

    // const isOwner = messageDetail?.data?.property?.ownerId === user.id;

    return (
        <>
            <CustomButton className={"btn-secondary btn-margin m-2 px-4"} onClick={() => counterToggle()}>Counter</CustomButton>

            <Modal isOpen={counterModal} size="md" backdrop="static"
                toggle={counterToggle}
                modalTransition={{ timeout: 200 }}>
                <OfferTitle>
                    Counter
                </OfferTitle>
                <ModalBody>
                    <BlockUI blocking={isLoading} />

                    {messageDetail &&
                    <>
                        <OfferDetailsBlock>
                            <Row>
                                <Col xs={5}><OfferDetailsLabel>Bluebid amount:</OfferDetailsLabel></Col>
                                <Col xs><OfferDetailsValue>
                                    <b>
                                        {currencyFormatter.format(messageDetail.data?.offer?.bidAmount || 0)}
                                    </b>
                                </OfferDetailsValue></Col>
                            </Row>
                            <Row>
                                <Col xs={4}><OfferDetailsLabel>Address:</OfferDetailsLabel></Col>
                                <Col xs><OfferDetailsValue>{property?.address}</OfferDetailsValue></Col>
                            </Row>
                            {/*{property?.ownerId &&*/}
                            {/*    <Row>*/}
                            {/*        <Col xs={12} className="text-right">*/}
                            {/*            <BedsBathsSqFt property={property} />*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*}*/}
                            <Row>
                                <Col xs={4}><OfferDetailsLabel>Financing:</OfferDetailsLabel></Col>
                                <Col xs><OfferDetailsValue>{financeKeyToText(messageDetail?.data?.offer?.financing)}</OfferDetailsValue></Col>
                            </Row>
                            <Row>
                                <Col xs={3}><OfferDetailsLabel>Contingencies:</OfferDetailsLabel></Col>
                                <Col xs>
                                    <OfferDetailsValue>
                                    <ContingentList list={messageDetail.data?.offer?.contingencies || []} />
                                    </OfferDetailsValue>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}><OfferDetailsLabel>Bluebid Date:</OfferDetailsLabel></Col>
                                <Col xs>
                                    <OfferDetailsValue className="text-right">
                                        {relativeDate(messageDetail.createdAt)}
                                    </OfferDetailsValue>
                                </Col>
                            </Row>
                        </OfferDetailsBlock>

                        {/*<DividerLine color="#d3d7e3" />*/}

                        <Row className="ml-2">
                            <Col xs={3} className="px-0">
                                <PayLabel>Counter amount</PayLabel>
                            </Col>
                            <Col xs={9}>
                                <PayInput getInputRef={inputReference} prefix="$" thousandSeparator
                                          className="form-control"
                                          placeholder="Enter an amount (USD)"
                                          onValueChange={(values) => {
                                              const { value } = values;
                                              setPaymentAmount(value)
                                          }} value={paymentAmount} />
                            </Col>
                        </Row>

                        <DialogFooterBlock>
                            <PrimaryButton className={"btn-margin mr-0 float-right"}
                                           disabled={paymentAmount.length === 0}
                                           onClick={() => sendCounterOffer()}>Send Counter
                            </PrimaryButton>
                            <SecondaryButton className={"btn-margin mr-0"}
                                             onClick={() => resetCounterBid()}>Cancel
                            </SecondaryButton>
                        </DialogFooterBlock>
                    </> }
                </ModalBody>
            </Modal>
        </>
    )

}

