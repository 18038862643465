import React from 'react'
import { Row, Col } from 'reactstrap'

import { formatPhoneNumber } from '@bluebid-sdk/core'

type LicenseCardProps = {
  license: {
    branding: {
      photoUrl: string
      email: string
      phone: string
      label: string
    }
  }
}

export const LicenseCard: React.FC<LicenseCardProps> = ({ license }) => (
  <Row className="align-items-center">
    <Col xs="auto">
      <img src={license.branding.photoUrl} style={{ maxWidth: '75px' }} />
    </Col>
    <Col>
      <div><strong>{license.branding.label}</strong></div>
      <div>{license.branding.email}</div>
      <div>{formatPhoneNumber(license.branding.phone)}</div>
    </Col>
  </Row>
)
