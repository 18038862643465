import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { errorToast, successToast } from '../../utils/common'
import { AdminSecondaryActionButton } from './common'
import { submitClaimedPropertyInquiry, userToContactSheet } from '../../lib/inquiries'
import { SendInquiryModal } from './SendInquiryModal'

export const SendClaimedPropertyInquiryAction = ({ property, owner, onSent }) => {
    const [showSendInquiryModal, setShowSendInquiryModal] = useState(false)
    const [address, setAddress] = useState('')
    const [propertyId, setPropertyId] = useState('')
    const [user, setUser] = useState(null)
    const [contactSheet, setContactSheet] = useState(null)

    useEffect(() => {
        if (!property || !owner) {
            return
        }

        setAddress(property.address)
        setPropertyId(property.propertyId)
        setUser(owner)

       
        if (owner) {
            const contactSheet = {
                userId: owner.id,
                name: `${owner.firstName} ${owner.lastName}`,
                email: owner.email,
                phone: owner.phone
            }
            setContactSheet(contactSheet)
        }
    }, [property, owner])
    
    const submit = async (verifiedContactSheet) => {
        submitClaimedPropertyInquiry({ propertyId, address, contactSheet: verifiedContactSheet }).then(() => {
            successToast('Inquiry sent.')
            onSent()
        }).catch(err => {
            console.error(err)
            errorToast('Error sending inquiry.')
        }).finally(() => {
            setShowSendInquiryModal(false)
        })
    }

    return <>
        <SendInquiryModal
            isOpen={showSendInquiryModal}
            propertyId={propertyId}
            address={address}
            onSend={submit}
            onClose={() => setShowSendInquiryModal(false)}
            contactSheet={contactSheet}
            onContactSheetUpdated={setContactSheet}
        />

        <AdminSecondaryActionButton onClick={() => setShowSendInquiryModal(true)}>
            <FontAwesomeIcon icon={faHome} /> Property Claimed
        </AdminSecondaryActionButton>
    </>
}

