// import './wdyr'
import React from "react";
import { createRoot } from 'react-dom/client'
import "./index.css";
import AdminApp from "./AdminApp";
import * as serviceWorker from "./serviceWorker";
import Providers from "./components/Providers";
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-balham.css'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Providers>
      <AdminApp />
    </Providers>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
