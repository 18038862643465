import React from 'react'

export const Unclaimed: React.FC = () => {
  return (
    <div>
      Unclaimed
    </div>
  )
}

export const unclaimedSummary = () => {
  return `Unclaimed`
}