import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import apiCall from '../lib/data/apiCall'
import { currencyOrNone, formatDate, pctOrNone } from '../lib/utils'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { splitAddress } from './detail/PropertyAddress'

const propertyOwner = (detail) => {
  if (detail.owner) {
    const owner = detail.owner
    return `${owner.name}`
  }
  return 'unknown'
}

const claimOwner = (property) => {
  if (property?._fields?.owner) {
    const owner = property._fields.owner
    return `${owner?.firstName?.toUpperCase() || ''} ${owner?.lastName?.toUpperCase() || ''}`
  }
  return 'unknown'
}


const twoLineAddress = (address) =>  {
    let {line1, line2} = splitAddress(address)
    return (
      <React.Fragment>
        {line1}
        <Break />
        {line2}
      </React.Fragment>
    )
}

export const RecentClaims = ({onPropertyClick}) => {
  const [count, setCount] = useState(5)
  const [recentClaims, setRecentClaims] = useState([])

  const options = [
    { value: 5, label: 'last 5' },
    { value: 10, label: 'Last 10' },
    { value: 15, label: 'Last 15' },
  ]
  const handleTypeSelect = e => {
    setCount(e.value)
  }

  const getRecentClaims = () => {
    apiCall(`/search/properties?fields=owner&q=status:Active&limit=${count}&sort=-createdAt`)
      .then(result => {
        if (result.items) {
          setRecentClaims(result.items)
        }
      })
  }

  useEffect(() => {
    getRecentClaims()
  }, [count])

  const onClick = (e, id) => {
    e.preventDefault();
    onPropertyClick(id)
  }

  return (
    <Inset>
      <Header onClick={() => getRecentClaims()}>
        <CountContainer>
          <Select
            options={options}
            onChange={handleTypeSelect}
            value={options.filter(function(option) {
              return option.value === count
            })}
          />
        </CountContainer>
        <FontAwesomeIcon icon={faSyncAlt} />
      </Header>

      <Table bordered>
        <thead>
        <tr>
          <th>Claim Date</th>
          <th>Address</th>
          <th>Claimed By</th>
          <th>Owner of Record</th>
          <th>Market Value</th>
          <th>Owner Price</th>
          <th>Buyer's Agent Commission</th>
        </tr>
        </thead>
        <tbody>
        {recentClaims.map((claim, ndx) => {
          return (
            <tr key={`recent-${ndx}`}>
              <td>{formatDate(claim.createdAt)}</td>
              <td>
                <Link to='#' onClick={(e) => onClick(e, claim.propertyId)}>{twoLineAddress(claim.address)}</Link>
                {/*<Link to={`/admin/propertyedit/${claim.propertyId}`}>{twoLineAddress(claim.address)}</Link>*/}
              </td>
              <td>
                {claimOwner(claim)}
                <Break />
                <Link to={`/admin/userprofile/${claim.ownerId}`}>{claim?._fields?.owner?.email || '--'}</Link>
              </td>
              <td>{propertyOwner(claim.detail)}</td>
              <td>{currencyOrNone(claim?.detail?.valuation?.value)}</td>
              <td>{currencyOrNone(claim?.detail?.owner_sell_price)}</td>
              <td>{pctOrNone(claim?.detail?.buyers_agent_commission)}</td>
            </tr>
          )
        })
        }
        </tbody>
      </Table>
    </Inset>
  )
}

const Break = styled.div`
  height: 5px;
`

const Header = styled.div`
  float: right;
  font-size: 14pt;
  padding-bottom: 5px;
`

const CountContainer = styled.div`
  display: inline-block;
  padding-right: 10px;
  font-size: 10pt;
`

const Inset = styled.div`
  padding: 10px;
`
