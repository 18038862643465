import styled from 'styled-components'
import React from 'react'

import { Black } from './../../constants/colors'

export const MessageStaging = ({ staging }) => {
    return staging ? <Staged>Unpublished</Staged> : <Unstaged>Published</Unstaged>
}

export const Staged = styled.span`
  color: #dc3545;
  font-weight: 500;
`

export const Unstaged = styled.span`
  color: ${Black};
  font-weight: bold;
`
