import React, { useEffect, useState } from 'react'
import { Outlet, useParams, useNavigate, NavLink } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ErrorBoundary } from 'react-error-boundary'
import { Container, Button, Nav, NavItem, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled from 'styled-components'

import { Group } from '@bluebid-sdk/core'
import { isErrorResponse } from '@bluebid-sdk/api-client'
import { BlockUI } from 'bb-lib-desktop'

import { getGroupById, deleteGroup } from '../../lib'
import { GUID } from '../../lib/utils'
import { GROUP_NOT_FOUND_MESSAGE } from '../../components/ErrorHandling'
import ErrorScreen from '../../components/ErrorScreen'
import Loading from '../../components/Loading'
import { successToast, errorToast } from '../../utils/common'

const GroupDetail: React.FC = () => {
  const { groupId } = useParams()
  const navigate = useNavigate()

  const [error, setError] = useState<Error>()
  const [errorMsg, setErrorMsg] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [isWorking, setIsWorking] = useState(false)

  const [group, setGroup] = useState<Group>()

  const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    if (groupId) {
      if (!GUID.test(groupId)) {
        setError(new Error(`Bad/invalid groupId ${groupId}`))
        setErrorMsg(GROUP_NOT_FOUND_MESSAGE)
        setIsLoading(false)
      } else {
        ;(async () => {
          const response = await getGroupById(groupId)

          if (isErrorResponse(response)) {
            setError(new Error(`Bad/invalid groupId ${groupId}`))
            setErrorMsg(GROUP_NOT_FOUND_MESSAGE)
          } else {
            setGroup(response.data)
          }

          setIsLoading(false)
        })()
      }
    }
  }, [groupId])

  const handleDeleteGroup = async () => {
    setIsWorking(true)

    const response = await deleteGroup(groupId)

    if (isErrorResponse(response)) {
      errorToast(response.errorMessage || 'Could not delete group')
    } else {
      successToast('Deleted group')

      setTimeout(() => {
        setIsWorking(false)
        navigate('/admin/list-groups')
      }, 1000)
    }
  }

  const toggleDeleteGroupModal = () => {
    setDeleteGroupModalOpen(!deleteGroupModalOpen)
  }

  return (
    <>
      <Modal isOpen={deleteGroupModalOpen} toggle={toggleDeleteGroupModal}>
        <ModalHeader toggle={toggleDeleteGroupModal}>Delete Group Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to delete this Team?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteGroupModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleDeleteGroup}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <BlockUI blocking={isLoading || isWorking} />

      <ErrorScreen error={error} message={errorMsg} />

      {!isLoading && group && (
        <ErrorBoundary fallbackRender={({ error }) => <ErrorScreen error={error} message={error?.message} />}>
          <Container fluid>
            <Row>
              <Col>
                <Title>
                  <strong>License Name:</strong> {group.name}
                </Title>
              </Col>
              <Col className="mt-3 text-right">
                <Button color="danger" onClick={toggleDeleteGroupModal}>
                  Delete
                </Button>
              </Col>
            </Row>

            <Nav tabs>
              <TabItem>
                <NavLink className="nav-link" to="members">
                  Agents / Members
                </NavLink>
              </TabItem>
              <TabItem>
                <NavLink className="nav-link" to="zipcodes">
                  Zip Codes
                </NavLink>
              </TabItem>              
              <TabItem>
                <NavLink className="nav-link" to="opportunities">
                  Invites
                </NavLink>
              </TabItem>

              {/*NOTE: there is no point in having a roles tab because simply adding a user to a team gives them the agent role. */}
              {/*  Likewise, removing the user removes the agent role */}
              {/*<TabItem>*/}
              {/*  <NavLink className="nav-link" to="roles">*/}
              {/*    Roles*/}
              {/*  </NavLink>*/}
              {/*</TabItem>*/}
            </Nav>

            <Outlet context={{ group }} />
          </Container>
        </ErrorBoundary>
      )}
    </>
  )
}

const TabItem = styled(NavItem)`
  font-size: 16px;
  font-weight: bold;

  a {
    color: inherit;
  }
`

const Title = styled.h4`
  margin-top: 20px;
`

export default withAuthenticationRequired(GroupDetail, {
  onRedirecting: () => <Loading />,
})
