import React from 'react';
import styled from 'styled-components'
import { Button, Card } from 'reactstrap'

export const FlexContainer = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #eee;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  .fill {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .btn {
    background:#069;
    padding:10px;
    color:#fff;
  }
`


export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: stretch;
`

export const FlexWrap = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;`

export const FlexItem = styled.div`
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  min-height: 50px;
`

export const FlexTitle = styled(FlexItem)`
  height: 150px;
`

export const ActionLink = styled.a`
    text-decoration: none;
`

export const ActionButton = styled(Button)`
    margin-right: 10px;
    border-radius: 4px;
`

export const CardContainer = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  color: #5a5c69;

  height: 100% !important;
  padding-top: 0.5rem !important;
  margin-bottom: 10px;
`
