import { Col, FormGroup, Input, Row } from 'reactstrap'
import { faLock, faCheck } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BluebidBlue } from '../constants/colors'

export const nonEditableRoles = ['user', 'agent', 'teamOwner']

export const UserRoles = ({ userRoles, allRoles, editable, onChange }) => {
  const editableRoles = allRoles.filter((role) => !nonEditableRoles.includes(role))

  return (
    <UserRolesWrapper>
      <h4>Roles</h4>

      {nonEditableRoles.map((role) => (
        <RoleItem key={role} role={role} hasRole={userRoles?.includes(role) || false} editable={editable} readOnly />
      ))}

      {editableRoles.map((role) => (
        <RoleItem
          key={role}
          role={role}
          hasRole={userRoles?.includes(role) || false}
          onChange={onChange(role)}
          editable={editable}
        />
      ))}

      {!editable && userRoles.length === 0 && <i>User has no assigned roles</i>}
    </UserRolesWrapper>
  )
}

const RoleItem = ({ role, hasRole, editable, onChange, readOnly }) =>
  editable || hasRole ? (
    <RoleItemWrapper>
      <Col md={8}>{role}</Col>
      <Col md={2}>
        {editable ? (
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={hasRole}
              onChange={() => onChange?.(!hasRole)}
              disabled={readOnly}
            />
          </FormGroup>
        ) : (
          <CheckIcon />
        )}
      </Col>
      {editable && <Col md={2}>{readOnly ? <LockIcon /> : ''}</Col>}
    </RoleItemWrapper>
  ) : null

const Icon = styled(FontAwesomeIcon)`
  font-size: 11px;
`

const LockIcon = styled(Icon).attrs({ icon: faLock })`
  color: rgb(144, 148, 153);
`

const CheckIcon = styled(Icon).attrs({ icon: faCheck })`
  color: ${BluebidBlue};
`

const UserRolesWrapper = styled.div`
  padding-left: 20px;
  font-size: 14px;
  text-align: left;
`

const RoleItemWrapper = styled(Row)`
  padding-left: 1px;
`
