import React, { useContext, useState } from 'react'

import styled from 'styled-components'
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap'

import '../../Theme.scss'
import { AuthContext } from '../../constants/context'
import { UserDropDownMenu } from '../../components/adminActions/UserDropdownMenu'
import { sizes } from '../../utils/responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons'
import { AdminHeader } from './AdminHeader'


const AdminNavBar = ({toggleSidebar}) => {
    const {bluebidUser} = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false);
    const toggleNav = () => setIsOpen(!isOpen);
    const isAuthenticated = bluebidUser !== undefined

    const BBNav = <NavBarContainer>
        <BluebidNavbar expand="md">
            <ToggleButton onClick={toggleSidebar}><FontAwesomeIcon icon={faAlignLeft} /></ToggleButton>

            <AdminHeader />

            <NavbarToggler className="mr-1" onClick={toggleNav}/>

            <NavBarCollapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <UserDropDownMenu />
                </Nav>

            </NavBarCollapse>
        </BluebidNavbar>
    </NavBarContainer>

    return (
        isAuthenticated ? BBNav : <></>
    );
};

export default AdminNavBar;

const ToggleButton = styled.div.attrs({
    className: 'btn'
})`
  color: white;
  position: relative;
  left: -15px;
`

const NavBarContainer = styled.div.attrs({
    className: "nav-container"
})`
  color: white;
`

const BluebidNavbar = styled(Navbar).attrs({
    className: 'bluebid-navbar navbar shadow-sm bg-white'
})`
  background-color: #3c6dd9 !important;
  color: white;
  height: 40px;
  
  @media (min-width: ${sizes.desktop}) {
    padding: 0 0 10px 0;
  }
`

const NavBarCollapse = styled(Collapse)`
  background-color: #3c6dd9 !important;
  color: white;
  z-index: 999;
  height: 40px;
`

