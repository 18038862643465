import styled from 'styled-components'
import React, { useContext } from 'react'
import { AuthContext } from '../../constants/context'
import { relativeDate } from '../../lib/utils'
import { MessageType } from '../../constants/messsages'
import moment from 'moment'

export const MessageDate = ({msg, isCurrent}) => {
    const { bluebidUser: user } = useContext(AuthContext)

    const isSender = user?.id === msg.senderId
    const from = isSender ? 'Sent' : 'Received'
    const {createdAt} = msg
    let expireDateTime = moment(createdAt).add(3, 'days');
    expireDateTime.set({hour:20,minute:0,second:0,millisecond:0})
    return (<>
    <BidDate>{from}: {relativeDate(createdAt)}</BidDate>
    {isCurrent && msg.messageType === MessageType.Offer.Placed.title ? <BidDate>Expires: {relativeDate(expireDateTime)}</BidDate> : null}
    </>)
}

export const BidDate = styled.p`
  width: 100%;
  font-size: 8pt;
  font-weight: 400;
  margin-bottom: 2px;
`
export const BidMessageReadOrNot = styled.span`
  color: #dc3545;
  font-weight: 500;
`
