import React, { useContext, useRef, useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import { NumericFormat  } from "react-number-format";
import {Row, Col, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import styled from "styled-components";

import {SecondaryButton, PrimaryButton, UnstlyedLink} from "./common";
import { sendBid } from "../../lib/data/bluebidData";
import { successToast, errorToast } from "../../utils/common";
import { AuthContext } from "../../constants/context";
import {ActionContainer, VerifyContent} from "./ClaimPropertyAction";
import {currencyFormatter, useSessionStorage} from "../../lib/utils";
import {VerifyEmailModal} from "./VerifyEmailModal";
import { DividerLine } from "../detail/DividerLine";
import BlockUI from "../BlockUI";
import { useNavigate } from "react-router-dom";
import { TagManager } from '../../lib/gtm/TagManager'
import { PropertyPriceInfo } from '../detail/PropertyPriceInfo'
import { BedsBathsSqFt } from "../BedsBathsSqFt";
import { PropertyAddress } from "../detail/PropertyAddress";
import { OfferDetailsLabel, OfferDetailsValue } from '../detail/common'
import { contingentChoices, financeKeyToText, offerFinancing } from '../offers/choices'
import { OFFERS_DIALOG_MSG, OFFERS_MSG_PRETEXT } from "../../constants/messsages";

const TOASTER_DURATION = 30000;

const contingenciesList = (choices) => contingentChoices
    .filter((x, index) => choices[index])
    .map((x,ndx) =>
        <OfferContingenciesDetailsValue key={ndx}>{x.label}</OfferContingenciesDetailsValue>)


export const SubmitBidAction = ({property}) => {
    const inputReference = useRef();
    const { bluebidUser, signIn } = useContext(AuthContext);

    const [modal, setModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [chooseContingent, setChosenContingent] = useState(new Array(contingentChoices.length).fill(false))
    const [financeOption, setFinanceOption] = useState('needApproval')
    const [payAmount, setPayAmount] = useState(property?.detail?.owner_sell_price || "")
    const [isBidForConfirmation, setBidConfirm] = useState(false)
    const location = useLocation()
    const [, setLastPage] = useSessionStorage('lastPage', '')
    const [returnToOffer, setReturnToOffer] = useSessionStorage('setReturnToOffer', '')
    const [showVerifyModel, setShowVerifyModal] = React.useState(false)
    const isVerified = bluebidUser && bluebidUser.emailVerified === true

    const navigate = useNavigate();
    const loggedIn = bluebidUser !== undefined
    const isClaimed = property.ownerId !== undefined
    const isOwner = loggedIn && isClaimed && property.ownerId === bluebidUser.id

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (bluebidUser && returnToOffer === 'true') {
            submitBidAction()
        }
    }, [bluebidUser])

    useEffect(() => {
        if (modal) {
            setTimeout(() => {
                inputReference.current && inputReference.current.focus()
            }, 0);
        }
    }, [modal])

    const submitBidAction = () => {
        // user is logged in, make sure they have a verified email
        if (!bluebidUser) {
            setLastPage(location.pathname)
            setReturnToOffer('true')
            signIn();
        } else {
            if (!isVerified) {
                setShowVerifyModal(true)
                return
            }
            // send bid
            toggle()
        }
    }

    const handleOnChange = (position) => {
        const updatedCheckedState = chooseContingent.map((item, index) =>
          index === position ? !item : item
        );
        setChosenContingent(updatedCheckedState);
    }

    /**
     * Finance of Satisfactory to choose from
     * @param props
     * @return {*}
     * @constructor
     */
     let FinanceChoiceList = (props) => {
        let {financing} = props
        return financing.map((finance, ndx) => {
            return (
                <FinanceListItem key={`fin-${ndx}`}>
                    <Input name="finance" type="radio"
                           value={finance.value}
                           defaultChecked={financeOption === finance.value}
                           id={`inpfin-${ndx}`} onClick={() => {
                            setFinanceOption(finance.value)
                           }}/>
                    <ContingentFinanceChoice for={`inpfin-${ndx}`}>{finance.label}</ContingentFinanceChoice>
                </FinanceListItem>
            )
        })
    }

    /**
     * Contingent of Satisfactory to choose from
     * @param props
     * @return {*}
     * @constructor
     */
     let ContingentChoiceList = (props) => {
        let {contingents} = props
        return contingents.map((contingent, ndx) => {
            return (
                <ContingentListItem key={`con-${ndx}`}>
                    <Input name="contingent" type="checkbox"
                           value={contingent.value}
                           checked={chooseContingent[ndx]}
                           id={`user-${ndx}`} onChange={(e) => handleOnChange(ndx)} />
                    <ContingentFinanceChoice for={`user-${ndx}`}>{contingent.label}</ContingentFinanceChoice>
                </ContingentListItem>
            )
        })
    }

    const submitBid = async () => {
        if (payAmount.length <= 1) {
            errorToast(`please enter an amount!`);
            return;
        }
        if (isBidForConfirmation) {
            setIsLoading(true);
            const bidData = {
                propertyId: property.propertyId,
                bidderId: bluebidUser.id,
                bidAmount: parseFloat(payAmount),
                contingencies: contingentChoices.filter((x, index) => chooseContingent[index]).map(x => x.value),
                financing: financeOption
                };
           sendBid(bidData).then(result => {
               if (result.statusCode && result.statusCode >= 400) {
                console.error({result});
                setIsLoading(false);
                errorToast(result.errorMessage);
               } else if (result) {
                setIsLoading(false);
                toggle();
                resetBid();

                TagManager.dataLayer({
                    dataLayer: {
                        event: 'offer_sent',
                        category: 'Bluebid',
                        action: 'Buyer sent an Bluebid!',
                        label: property?.address
                    }
                })
                successToast(`Your Bluebid has been sent to the owner`, {duration: TOASTER_DURATION});
                navigate('/');
               }
            }).catch(error => {
                console.error(error);
                errorToast(`something went wrong!`);
                setIsLoading(false);
            })
        } else {
            if (parseInt(payAmount) <= 0) {
                return;
            }
            setBidConfirm(true);
        }
    }

    const resetBid = () => {
        setBidConfirm(false);
        setReturnToOffer('')
        setChosenContingent(new Array(contingentChoices.length).fill(false));
        setFinanceOption('needApproval')
        setPayAmount(property?.detail?.owner_sell_price || "")
        toggle();
    }

    if (isOwner || !isClaimed) {
        return <></>
    }

    // Verification dialog content. before - true before email was sent, false after
    const verifyDialogContent = (before) => {
        if (before) {
            return <VerifyContent>
                We are sorry but your email address has not yet been confirmed.
                Please click here to resend the verification email.
            </VerifyContent>
        }
        return <VerifyContent>
            Please check your email for the confirmation link.
        </VerifyContent>
    }

    const isPaymentEmpty = payAmount.length === 0 || payAmount === 0

    return (
        <SendOfferContainer>
            <PrimaryButton className={"btn-primary btn-margin"} onClick={submitBidAction} >
                Send Bluebid
            </PrimaryButton>

            <VerifyEmailModal show={showVerifyModel}
                              title={"Please verify your email address"}
                              toggle={() => setShowVerifyModal(false)}
                              dialogContent={verifyDialogContent}
                              path={location.pathname}
            />


            <Modal isOpen={modal} backdrop="static"
                   toggle={toggle}
                   modalTransition={{ timeout: 200 }}>
               <OfferTitle>
                   { isBidForConfirmation ? 'Please Confirm' : 'Bluebid Details' }
               </OfferTitle>
                <ModalBody>
                    <BlockUI blocking={isLoading} />

                    {isBidForConfirmation &&
                        <OfferDetailsBlock>
                            <Row>
                                <Col xs={4}><OfferDetailsLabel>Address:</OfferDetailsLabel></Col>
                                <Col xs><OfferDetailsValue>{property?.address}</OfferDetailsValue></Col>
                            </Row>
                            {property?.ownerId &&
                                <Row>
                                    <Col xs={12} className="text-right">
                                        <BedsBathsSqFt property={property} />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col xs={5}><OfferDetailsLabel>Bluebid amount:</OfferDetailsLabel></Col>
                                <Col xs><OfferDetailsValue>{currencyFormatter.format(payAmount)}</OfferDetailsValue></Col>
                            </Row>
                            <Row>
                                <Col xs={4}><OfferDetailsLabel>Financing:</OfferDetailsLabel></Col>
                                <Col xs><OfferDetailsValue>{financeKeyToText(financeOption)}</OfferDetailsValue></Col>
                            </Row>
                            <Row>
                                <Col xs={3}><OfferDetailsLabel>Contingencies:</OfferDetailsLabel></Col>
                                <Col xs>{contingenciesList(chooseContingent)}</Col>
                            </Row>
                        </OfferDetailsBlock>
                    }

                    {isBidForConfirmation ?
                        <MessageTextBlock>
                            <p>{OFFERS_MSG_PRETEXT}</p>
                            <p>
                                {OFFERS_DIALOG_MSG} By sending, you agree to Bluebid's <UnstlyedLink target='_blank'
                                                                                                     href={`https://www.bluebidhomes.com/terms-of-service`}>
                                Terms of Service
                            </UnstlyedLink>.
                            </p>
                        </MessageTextBlock>
                        :
                        <div>
                            <Row className="ml-2 mb-2">
                                <Col xs={12} className="px-0">
                                    <PropertyAddress property={property} />
                                </Col>
                            </Row>
                            <Row className="ml-2">
                                <Col xs={12} className="px-0">
                                    {property?.ownerId &&
                                    <BedsBathsSqFt property={property} /> }
                                </Col>
                            </Row>
                            <Row className="ml-2">
                                <Col xs={12} className="px-0">
                                    <PropertyPriceInfo property={property} />
                                </Col>
                            </Row>
                            <Row className="ml-2">
                                <Col xs={3} className="px-0">
                                    <PayLabel>Bluebid amount</PayLabel>
                                </Col>
                                <Col xs={9}>
                                    <PayInput getInputRef={inputReference} id="testingInput"
                                              prefix="$" thousandSeparator className="form-control"
                                              placeholder="Enter an amount (USD)"
                                              onValueChange={(values) => {
                                                    const { value } = values;
                                                    setPayAmount(value)
                                                }} value={payAmount} autoFocus/>
                                </Col>
                            </Row>
                            <Row className="ml-2">
                                <Col xs={12} className="px-0">
                                    <DialogHeaderBlock>Financing</DialogHeaderBlock>
                                    <ContingentFinanceList>
                                        <FinanceChoiceList financing={offerFinancing} />
                                    </ContingentFinanceList>
                                </Col>
                            </Row>
                            <DividerLine />
                            <Row className="ml-2">
                                <Col xs={12} className="px-0">
                                    <DialogHeaderBlock>Contingencies</DialogHeaderBlock>
                                    <ContingentFinanceList>
                                        <ContingentChoiceList contingents={contingentChoices} />
                                    </ContingentFinanceList>
                                </Col>
                            </Row>
                        </div>
                    }
                </ModalBody>
                <OfferFooter>
                    <PrimaryButton className={"btn-margin mr-0 float-right"} onClick={submitBid} disabled={isPaymentEmpty || isLoading}>Send Bluebid</PrimaryButton>
                    <SecondaryButton className={"btn-margin mr-0"} onClick={resetBid} disabled={isLoading}>Cancel</SecondaryButton>
                </OfferFooter>
            </Modal>
        </SendOfferContainer>
    )

}

const SendOfferContainer = styled(ActionContainer)`
    padding: 0 2px;
`

const MessageTextBlock = styled.div`
    p {
      line-height: 1.5rem;
      padding: 5px;
      margin-bottom: 0px;
    }
`

const PayInput = styled(NumericFormat)`
    width: 100%;
    // height: 2.8rem;
    outline: none;
    border: 1px solid #eee;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom:15px;
    padding: 10px;
`

const ContingentFinanceList = styled.ul`
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    padding-left: 1.5rem;
    list-style-type: none;
`

const ContingentListItem = styled.li`
    padding-top: 4px;
    width: 44%;
    margin-right: 20px;
`
const FinanceListItem = styled.li`
    padding-top: 4px;
    width: 100%;
    margin-right: 20px;
`
const ContingentFinanceChoice = styled(Label)`
  margin: 5px 0 0 5px;
`

const PayLabel = styled(Label)`
    // margin-left: 10px;
    line-height: 2.5rem;
`
const DialogHeaderBlock = styled.div`
    // margin-left: 10px;
    // padding: 10px;
    font-weight: bold;
`
const DialogFooterBlock = styled.div`
    margin-bottom: 15px;
    padding: 10px;
`

export const OfferDetailsBlock = styled.div`
  padding: 15px;
  border: 1px solid #edf3fc;
  border-radius: 4px;
  background-color: #edf3fc;
  margin-bottom: 10px;
`

export const OfferContingenciesDetailsValue = styled(OfferDetailsLabel).attrs({
    className: 'text-right'
})`
  font-size: 14px;
  width: 100%;
  float: right;
  margin: 5px 0 0;
  font-weight: 400;
`

export const OfferTitle = styled(ModalHeader)`
    font-size: 1.75rem;
    text-align: center;
    padding: 10px 0 0 0;
    background: aliceblue;    
`

const OfferFooter = styled(DialogFooterBlock)`
    padding: 0 25px;
    margin: 0;
`
