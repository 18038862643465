import React from 'react'

type EmailActionProps = {
  toEmail: string
}

export const EmailAction: React.FC<EmailActionProps> = ({ toEmail }) => {
  return (
    <div>
      <a href={`mailto:${toEmail}`} target="_blank" rel="noopener noreferrer">
        {toEmail}
      </a>
    </div>
  )
}

export const emailActionSummary = ({ toEmail }: EmailActionProps) => {
  return `To: ${toEmail}`
}