import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { sanitizeHtmlId, variableToSentenceTitleCase } from '../../../lib/utils'
import { Tag } from './common'
import { getPropertyChangeHistory } from '../../../lib/data/bluebidData'
import LoadingInline from '../../LoadingInline'
import styled from 'styled-components'

type FieldChange = {
  field: string
  from?: string
  to?: string
  value?: string
};

type ChangesData = {
  added: FieldChange[]
  edited: FieldChange[]
  removed: FieldChange[]
};

type ChangeItem = {
  field: string
  changes: ChangesData
}

type ModifiedPropertyDataProps = {
  historyId: string
}

const ValueCell = styled.td`
  max-width: 200px;
  white-space: normal;
  overflow-wrap: break-word;
`

export const ModifiedPropertyData: React.FC<ModifiedPropertyDataProps> = ({ historyId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [changes, setChanges] = useState<ChangeItem[]>([])

  useEffect(() => {
    if (historyId) {
      setIsLoading(true)
      getPropertyChangeHistory({ historyId }).then(changes => {
        setChanges(changes)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [historyId])

  const parseFieldName = (field: string): [string | undefined, string] => {
    const path = field.split('/')
    
    if (path.length === 1) {
      return [undefined, path[0]]
    }
  
    const subField = path.pop()
    const baseField = path.join('/')
  
    return [
      baseField || undefined,
      variableToSentenceTitleCase(subField)
    ]
  }

  return (
    <Table bordered>
      <thead>
        <tr>
          <th>Action</th>
          <th>Field Name</th>
          <th>Old Value</th>
          <th>New Value</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && <tr>
          <td colSpan={4}><LoadingInline /></td>
        </tr>}

        {!isLoading && changes && changes.map(item => {
          return Object.keys(item.changes).map(type => {
            return item.changes[type as keyof ChangesData].map(change => {
              const [topField, subField] = parseFieldName(change.field)
              
              // skip anything that's propertyHistory*
              if (subField.startsWith('propertyHistory')) {
                return <></>
              }

              // skip modifiedAt
              if (subField === 'modifiedAt') {
                return <></>
              }

              return (
                <tr key={`${sanitizeHtmlId(`${item.field}-${change.field}`)}`}>
                  <td width="50">{variableToSentenceTitleCase(type)}</td>
                  <td>
                    {topField && <Tag text={topField} variant="field" />}
                    {subField}
                  </td>
                  <ValueCell>
                    {change.from && <Tag text={change.from} variant="removed" />}
                    {type === "removed" && change.value && <Tag text={change.value} variant="removed" />}
                  </ValueCell>
                  <ValueCell>
                    {change.to && <Tag text={change.to} variant="added" />}
                    {type === "added" && change.value && <Tag text={change.value} variant="added" />}
                  </ValueCell>
                </tr>
              )
            })
          })
        })}
      </tbody>
    </Table>
  )
}

export const modifiedPropertyDataSummary = ({ historyId }: ModifiedPropertyDataProps) => {
  // @todo: what to show here, if anything?
  return ``
}