import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

export const ClipboardCopy = ({ copyText, children }) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    // @ts-ignore
    if ('clipboard' in navigator) {
      // @ts-ignore
      return await navigator.clipboard.writeText(copyText);
    } else {
      // @ts-ignore
      return document.execCommand('copy', true, copyText);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (!copyText) return null

  return (
    <div onClick={handleCopyClick}>
      <CopyIcon>
          <FontAwesomeIcon icon={faClipboard} />
          {isCopied ? ' Copied!' : ''}
      </CopyIcon>
      { children }
    </div>
  )
}

const CopyIcon = styled.span`
  padding-right: 10px;
`
