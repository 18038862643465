import { useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DateValueFormatter } from '../../components/grid/GridRenderers'
import { GridHeaderRow } from '../../components/grid/GridStyles'
import { Button, Col, Row } from 'reactstrap'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { GridContainerStyle, GridStyle, StandardColumnDefs, StandardSideBar } from '../../components/CommonGrid'
import { getPropertyOffers } from '../../lib/data/bluebidData'
import { ModuleRegistry } from '@ag-grid-community/core'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../../components/Loading'

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    RangeSelectionModule,
    RowGroupingModule,
    StatusBarModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
    ExcelExportModule
]);

const PropertyOfferHistory = () => {
    const { propertyId } = useParams()
    const gridRef = useRef();

    const [offers, setOffers] = useState([])

    useEffect(() => {
        getPropertyOffers(propertyId)
            .then(offers => setOffers(offers.items))
    }, [propertyId])

    // never changes, so we can use useMemo
    const containerStyle = useMemo(() => (GridContainerStyle), []);
    const gridStyle = useMemo(() => (GridStyle), []);
    const sideBar = useMemo(() => StandardSideBar, []);
    const defaultColDef = useMemo(() => StandardColumnDefs, []);

    const getRowId = useCallback(function (params) {
        return params.data.propertyId;
    }, []);

    const onBtExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel();
    }, []);

    const columnDefs = useMemo(() => [
        { field:'id', headerName:'Bluebid Id', filter:'agTextColumnFilter' },
        { field:'status', headerName:'Status', width:250, filter:'agTextColumnFilter' },
        { field:'type', headerName:'Type', width:250, filter:'agTextColumnFilter' },
        { field:'address', headerName:'Address', width:250, filter:'agTextColumnFilter' },
        { field:'financing', headerName:'Financing', width:100, filter:'agTextColumnFilter' },
        { field:'contingencies', headerName:'Contingencies', width:100, filter:'agTextColumnFilter' },
        { field:'bidAmount', headerName:'Bid Amount', width:100, filter:'agNumberColumnFilter' },

        { headerName:'Created / Modified', children:[
                { field:'createdAt', width:210, valueFormatter:DateValueFormatter, filter:'agDateColumnFilter', sort: 'desc' },
                { field:'modifiedAt', columnGroupShow:'open', width:210, valueFormatter:DateValueFormatter, filter:'agDateColumnFilter' },
            ]},

        // { headerName:'Geo Info', children:[
        //         { field:'geoip.cityName', width:150, filter:'agTextColumnFilter' },
        //         { field:'geoip.countryCode', columnGroupShow:'open', width:80, filter:'agTextColumnFilter', },
        //         { field:'geoip.countryName', columnGroupShow:'open', width:200, filter:'agTextColumnFilter' },
        //         { headerName: 'Location', field:'geoip', columnGroupShow:'open', width:150, filter:false, valueGetter:GeoValueGetter },
        //     ]},

    ], [])

    return (<>
            <GridHeaderRow>
                <Row>
                    <Col sm={2}>
                        <Button
                            onClick={onBtExport}
                            style={{ marginBottom: '5px', fontWeight: 'bold' }}
                        >
                            Export to Excel
                        </Button>
                    </Col>
                </Row>
            </GridHeaderRow>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-balham">
                    <AgGridReact
                        ref={gridRef}
                        rowData={offers}
                        animateRows="true"
                        getRowId={getRowId}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        enableRangeSelection="true"
                        rowSelection="multiple"
                        serverSideInfiniteScroll={true}
                        suppressRowClickSelection="true"
                        cacheBlockSize={20}
                        sideBar={sideBar}
                    />
                </div>
            </div>
        </>
    )

}

export default withAuthenticationRequired(PropertyOfferHistory, {
    onRedirecting: () => <Loading/>,
})
