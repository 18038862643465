import { useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import update from "immutability-helper";
import styled from "styled-components";

const isTouchDevice = () => {
    if ("ontouchstart" in window) {
        return true;
    }
    return false;
};
export const DragNDropList = (props) => {
    const {assetList, updateOrderList} = props;
    const [assets, setAssets] = useState(assetList);
    const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

    const moveAsset = (dragIndex, hoverIndex) => {
        const draggedAsset = assets[dragIndex];
        setAssets(
            update(assets, {
                $splice: [[dragIndex, 1], [hoverIndex, 0, draggedAsset]]
            })
        );
    };

    useEffect(() => {
        updateOrderList(assets.map(({ id }) => id));
    }, [assets])

    const Asset = ({ asset, index }) => {
        const ref = useRef(null); // Initialize the reference

        const [, drop] = useDrop({
            accept: 'Image',
            hover(item) { // item is the dragged element
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                moveAsset(dragIndex, hoverIndex);
                item.index = hoverIndex;
            }
        });

        const [{ isDragging }, drag] = useDrag(() => ({
            type: 'Image',
            item: { id: asset.id, index },
            collect: (monitor) => {
                return {
                    isDragging: monitor.isDragging(),
                };
            },
        }));

        const opacity = isDragging ? 0 : 1
        drag(drop(ref));

        return (<>
            <ThumbBox ref={ref} style={{ opacity: opacity }}>
                <img className="file-img" alt={`img - ${asset.id}`} src={asset.url} />
                {!isDragging &&
                    <>
                        <DeleteIcon onClick={() => { deleteAsset(index) }}>&times;</DeleteIcon>
                        <Index>{index + 1}</Index>
                    </>
                }
            </ThumbBox>
        </>)
    }

    const AssetList = ({ assets, moveAsset }) => {
        const renderAsset = (asset, index) => {
            return asset ? (
                <Asset
                    asset={asset}
                    index={index}
                    key={`${asset.id}`}
                    moveAsset={moveAsset}
                />
            ) : null;
        }
        return <AssetListSection>{assets.map(renderAsset)}</AssetListSection>;
    }

    const deleteAsset = (index) => {
        const assetsArr = assets.slice();
        assetsArr.splice(index, 1);
        setAssets(assetsArr);
    }

    return (
        <DndProvider backend={backendForDND}>
            <AssetList assets={assets} moveAsset={moveAsset} />
        </DndProvider>
    )
}

const DeleteIcon = styled.button`
    right: 0px;
    top: 0px;
    position: absolute;
    font-size: 21px;
    font-weight: 600;
    padding: 0 6px;
    display: block;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ddd;

    @media (max-width: 445px) {
      font-size: 20pt;
      /* padding: 1px 4px; */
      height: 30px;
      width: 30px;
        //font-size: 17px;
        //padding: 1px 4px;
        //height: 20px;
        //width: 20px;
    }
`
const ThumbBox = styled.div.attrs({
    className: "file-item"
})`
    position: relative;
    border-radius: 2px;
    margin: 10px;
    padding: 4px;
    box-sizing: border-box;
    overflow: hidden;

    img {
        width: 190px;
        height: 140px;
    }
    width: 200px;
    height: 150px !important;
`

const AssetListSection = styled.section.attrs({
    className: "file-list"
})`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`

const Index = styled.div`
  color: #007fff;
  right: 10px;
  position: absolute;
  font-size: 20px;
  font-weight: 600;
  padding: 0 6px;
  display: block;
  height: 25px;
  width: 25px;
  background: #fff;
  bottom: 5px;
`
