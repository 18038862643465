import { PrimaryButton } from '../actions/common'
import { AuthContext } from '../../constants/context'
import { useContext } from 'react'
import styled from 'styled-components'

export const SignInAction = () => {
    const {bluebidUser, signIn} = useContext(AuthContext)
    const isAuthenticated = bluebidUser !== undefined

    if (isAuthenticated) return <></>

    return (
        <LoginButton id="qsSignupBtn" color="primary"
                       className="btn-margin" onClick={() => signIn()}>
            Sign In
        </LoginButton>
    )
}

const LoginButton = styled(PrimaryButton)`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 2em;
`
