import { ColDef, ColGroupDef } from '@ag-grid-community/core'

import {
  DateValueFormatter,
  OwnerFieldLinkRenderer,
  PhoneNumberRendererFromResultField,
  ResultFieldLinkRenderer,
  ResultFieldRenderer,
} from '../../components/grid/GridRenderers'

export const assetsGridColumns = (): (ColDef | ColGroupDef)[] => [
  { field: 'id', headerName: 'Asset Id', width: 300, cellRenderer: 'agGroupCellRenderer' },
  {
    field: 'propertyId',
    headerName: 'Used by Property',
    cellRenderer: ResultFieldLinkRenderer,
    cellRendererParams: {
      prefix: '/admin/propertyedit',
      object: 'property',
      field: 'address',
      linkField: 'propertyId'
    },
  },
  {
    headerName: 'Image Info',
    children: [
      {
        field: 'mimeType',
        headerName: 'Mime Type',
        width: 120,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'image/webp',
            'image/bmp',
            'image/gif',
            'video/mp4',
            'video/wav',
            'text/csv',
            'undefined',
          ],
          defaultToNothingSelected: true,
        },
      },
      { field: 'width', headerName: 'Width', width: 80 },
      { field: 'height', headerName: 'Height', width: 80 },
    ],
  },
  {
    headerName: 'User Info',
    children: [
      { field: 'ownerId', headerName: 'User', cellRenderer: OwnerFieldLinkRenderer, sortable: false, width: 120 },
      {
        field: 'Email',
        cellRenderer: ResultFieldRenderer,
        cellRendererParams: { object: 'owner', field: 'email' },
        width: 120,
        sortable: false,
      },
      {
        field: 'Phone',
        cellRenderer: PhoneNumberRendererFromResultField,
        cellRendererParams: { object: 'owner', field: 'phone' },
        width: 90,
        sortable: false,
      },
    ],
  },
  {
    headerName: 'Created / Modified',
    children: [
      {
        field: 'createdAt',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
      {
        field: 'modifiedAt',
        columnGroupShow: 'open',
        width: 210,
        valueFormatter: DateValueFormatter,
        filter: 'agDateColumnFilter',
      },
    ],
  },
]
