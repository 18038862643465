import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import UserCard, { User } from '../../user/UserCard'
import { getUser } from './../../../lib/data/bluebidData'

type ModifiedOwnerProps = {
  oldValue: string,
  newValue: string
}

export const ModifiedOwner: React.FC<ModifiedOwnerProps> = ({ oldValue, newValue }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [oldOwner, setOldOwner] = useState<User | null>(null)
  const [newOwner, setNewOwner] = useState<User | null>(null)

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true)
      
      const [oldUserData, newUserData] = await Promise.all([
        getUser(oldValue), getUser(newValue)
      ])
      
      setOldOwner(oldUserData)
      setNewOwner(newUserData)
      
      setIsLoading(false)
    }

    fetchUsers()
  }, [oldValue, newValue])

  return (
    <Row>
      <Col>
        { !isLoading && oldOwner && <UserCard user={oldOwner} /> }
      </Col>
      <Col xs="auto" className="d-flex align-items-center">
        <span className="text-muted">→</span>
      </Col>
      <Col>
        { !isLoading && newOwner && <UserCard user={newOwner} /> }
      </Col>
    </Row>
  )
}
